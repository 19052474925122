import React from 'react';
import { useLogoThemedTextColor } from 'utils/hooks/useLogoThemedTextColor';

const Day1Logo = ({ isCollapsed }) => {
  const textColor = useLogoThemedTextColor({
    darkModeColor: '#fff',
    lightModeColor: '#181818',
  });

  return (
    <svg
      width={isCollapsed ? 44 : 94}
      height="25"
      viewBox={isCollapsed ? '-8 0 40 20' : '0 0 82 25'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.744141 0.625977H11.5262V4.43338H4.98956V16.0577H11.5262V19.9999H0.744141V0.625977ZM23.9592 2.95085L20.1518 0.625977H13.1772V4.43338H19.7138V16.0577H13.1772V19.9999H20.1518L23.9592 17.7087V2.95085Z"
        fill="#003CBE"
      />

      {!isCollapsed && (
        <>
          <path
            d="M31.1604 20C30.6694 20 30.2194 19.8773 29.8102 19.6318C29.4147 19.3863 29.0942 19.0658 28.8487 18.6703C28.6169 18.2748 28.501 17.8316 28.501 17.3406V12.9628H38.3408V10.8557C38.3408 10.7194 38.293 10.6034 38.1976 10.508C38.1021 10.3989 37.9862 10.3443 37.8498 10.3443H28.501V8.13497H37.9112C38.4021 8.13497 38.8454 8.25771 39.2409 8.5032C39.65 8.73504 39.9705 9.05553 40.2023 9.46467C40.4478 9.87381 40.5706 10.317 40.5706 10.7944V20H31.1604ZM31.2217 17.7907H38.3408V15.029H30.7103V17.2792C30.7103 17.4156 30.7581 17.5383 30.8535 17.6475C30.9626 17.7429 31.0854 17.7907 31.2217 17.7907ZM47.0418 24.5414V22.3116H54.304C54.4404 22.3116 54.5563 22.2571 54.6518 22.148C54.7609 22.0525 54.8155 21.9366 54.8155 21.8002V20H47.6146C47.1373 20 46.6941 19.8841 46.2849 19.6522C45.8758 19.4067 45.5485 19.0794 45.303 18.6703C45.0711 18.2612 44.9552 17.8179 44.9552 17.3406V8.17589H47.1646V17.2792C47.1646 17.4156 47.2123 17.5383 47.3078 17.6475C47.4169 17.7429 47.5396 17.7907 47.676 17.7907H54.304C54.4404 17.7907 54.5563 17.7429 54.6518 17.6475C54.7609 17.5383 54.8155 17.4156 54.8155 17.2792V8.17589H57.0248V21.882C57.0248 22.373 56.9021 22.8162 56.6566 23.2117C56.4247 23.6209 56.1043 23.9414 55.6951 24.1732C55.2996 24.4187 54.8564 24.5414 54.3654 24.5414H47.0418ZM65.1783 20V8.19634L63.4804 10.242H60.5755L64.7487 5.271H67.3877V20H65.1783Z"
            fill={textColor}
          />
          <path
            d="M73 20V19.7052L76.4336 15.4224L73 11.2948V11H74.9059L77.4924 14.119L80.0941 11H81.9849V11.2948L78.5664 15.4224L82 19.7052V20H80.0941L77.4924 16.7259L74.9059 20H73Z"
            fill={textColor}
          />
        </>
      )}
    </svg>
  );
};

export default Day1Logo;
