import { createAsyncAction } from 'typesafe-actions';

export default {
  loadDashboardList: createAsyncAction(
    'dashboardData/action/request',
    'dashboardData/action/success',
    'dashboardData/action/failure',
  )(),
  loadEditableValue: createAsyncAction(
    'dashboardEditable/action/request',
    'dashboardEditable/action/success',
    'dashboardEditable/action/failure',
  )(),
  loadStaffDashboardSettings: createAsyncAction(
    'loadStaffDashboardSettings/action/request',
    'loadStaffDashboardSettings/action/success',
    'loadStaffDashboardSettings/action/failure',
  )(),
};
