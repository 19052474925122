import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu, Typography } from 'antd';
import CustomIcon from 'components/Icons';
import { MENU_ICONS } from './config';
import { useBrand } from 'modules/shared/brand/hooks/useBrand';

import styles from './styles.module.less';

const { Text } = Typography;

const MenuItem = ({ title, path, icon, ...other }) => {
  const { brand } = useBrand();
  const iconCollection = MENU_ICONS[brand];

  return (
    <Menu.Item
      key={path}
      icon={CustomIcon({
        type: icon,
        className: styles.sideMenuItemIcon,
        iconCollection,
      })}
      title={null}
      className={styles.sideMenuItem}
      {...other}
    >
      <Link to={path}>
        <Text className={styles.sideMenuItemText}>{title}</Text>
      </Link>
    </Menu.Item>
  );
};

MenuItem.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  icon: PropTypes.string,
  other: PropTypes.any,
};

export default React.memo(MenuItem);
