import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Actions from 'redux/actions';

import Spinner from 'components/Spinner';

import { ROUTING } from 'config';
import useRequestLoading from 'utils/hooks/useRequestLoading';
import useSaveDetails from 'utils/hooks/useSaveDetails';
import { useThemePersistor } from 'modules/shared/theme/hooks/useThemePersistor';

import styles from './styles.module.less';

const getRedirectUrl = (pathname) => {
  switch (pathname) {
    case ROUTING.LOGIN:
    // TODO: disabled registration
    // case ROUTING.SIGNUP:
    case ROUTING.NEWPASSWORD:
    case ROUTING.RESET:
      return ROUTING.DASHBOARD;
    default:
      return pathname;
  }
};

const Root = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { loadingType } = useSelector(({ auth }) => auth);

  const isAuthLoading = useRequestLoading(loadingType, [
    `${Actions.auth.request}`,
    `${Actions.logInWithPartner.request}`,
    `${Actions.logOutWithPartner.request}`,
  ]);

  useThemePersistor();
  useSaveDetails();
  useEffect(() => {
    const { pathname, search } = location;
    const path = `${pathname}${search}`;

    dispatch(
      Actions.auth.request({
        onRedirect: () => {
          history.push(getRedirectUrl(path));
        },
      }),
    );
  }, []);

  return (
    <div style={{ height: '100%' }}>
      {isAuthLoading ? (
        <Spinner
          spinning
          wrapperClassName={styles.globalSpinner}
          spinFontSize={78}
        />
      ) : (
        children
      )}
    </div>
  );
};

export default Root;

Root.propTypes = {
  children: PropTypes.node,
};
