import React from 'react';

const GPAvatar = () => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20.5" cy="20.5" r="20.5" fill="#EFFAD0" />
    <path
      d="M10 37.9695H16.9153C17.5246 35.7456 17.8293 34.131 17.8902 33.5522C17.8902 33.5522 18.0425 30.2621 14.661 30.5667C14.661 30.5667 10.8225 30.5058 10.1828 34.9536C10.2132 34.9536 10.2437 36.0503 10 37.9695Z"
      fill="#C0F826"
    />
    <path
      d="M22.0332 24.8395H18.2861V29.8661H22.0332V24.8395Z"
      fill="#FE6B6B"
    />
    <path
      d="M28.6747 32.0291C27.639 30.4145 25.9939 29.988 25.9939 29.988L22.064 28.5257H18.2865L14.3566 29.988C14.3566 29.988 12.7116 30.4145 11.6758 32.0291H28.6747Z"
      fill="#FE6B6B"
    />
    <path
      d="M27.7295 37.9695V37.5735L29.4659 34.1311C28.948 30.78 26.0235 29.9879 26.0235 29.9879L23.0075 28.8608L19.5955 30.0184L17.2803 28.8912L14.3557 29.9879C14.3557 29.9879 11.4007 30.78 10.8828 34.1311L10 37.9999C10 37.9999 12 40.9999 20 40.9999C28 40.9999 31 37.9695 31 37.9695H27.7295Z"
      fill="#C0F826"
    />
    <path
      d="M23.6472 37.9696H31.1109C30.1361 34.8013 29.1917 32.6079 28.8261 31.9377C28.8261 31.9377 27.0592 29.1654 24.4697 31.3589C24.4697 31.3589 21.3015 33.5218 23.3426 37.5431C23.3426 37.5431 23.434 37.6954 23.6472 37.9696Z"
      fill="#C0F826"
    />
    <path
      d="M13.8683 17.6804C13.7769 17.0102 13.716 16.7665 13.6855 15.1519C13.6855 14.3903 13.8379 13.2327 14.7823 13.1413C16.0922 13.0194 16.5187 15.2738 16.5796 16.2791C16.7015 17.65 16.3359 19.1122 16.0922 20.4222C16.0008 20.9096 16.0008 21.854 15.4525 22.0977C14.9955 22.311 15.0564 22.3719 14.8432 22.0368C14.2644 21.1838 13.9902 18.6553 13.8683 17.6804Z"
      fill="#110D3D"
    />
    <path
      d="M14.5703 24.1388C15.0882 26.1799 16.0021 26.7283 16.0021 26.7283L17.2207 26.972C17.9213 26.8806 19.1399 26.6064 19.1399 26.6064C21.9426 25.662 22.887 25.723 23.4658 24.2607C23.4963 24.2302 25.4155 16.6751 25.4155 16.6751C25.8115 12.7148 21.6989 11.8923 21.0896 11.8009C20.4803 11.6486 16.5504 10.5823 15.3928 14.3294C15.3928 14.3294 13.6868 18.7162 14.5703 24.1388Z"
      fill="#FE6B6B"
    />
    <path
      d="M22.5818 24.9004C21.4546 25.7838 19.5354 26.3627 18.134 26.4845C15.636 26.7587 15.0876 24.9308 14.6611 22.9811C14.6916 23.4381 14.7525 23.8951 14.8135 24.352C15.3009 26.2408 16.1539 26.7892 16.1539 26.7892L17.3115 27.0024C17.9817 26.911 19.1089 26.6368 19.1089 26.6368C21.7288 25.7534 22.6123 25.7838 23.1301 24.4434C23.1301 24.4434 23.1606 24.2911 23.2215 24.0778C23.0692 24.3825 22.856 24.6871 22.5818 24.9004Z"
      fill="#FE6B6B"
    />
    <path
      d="M20.1736 10.4909C21.3313 10.3386 22.7021 10.0339 23.8598 10.43C25.383 10.9479 25.383 12.6843 26.0837 13.8724C26.8148 15.1519 27.3022 16.2182 27.2413 17.6195C27.1804 18.6857 26.7843 18.1679 26.632 19.2646C26.4492 20.4831 26.236 20.5745 25.6572 21.6103L23.4942 24.2302C23.8293 22.8898 23.5856 22.6461 23.8902 21.6103C24.2558 20.3613 24.1644 18.7162 23.6161 17.4672C23.1286 16.34 22.7935 16.3705 21.7578 16.0963C20.4173 15.7612 19.2902 15.6089 17.8888 15.6089C16.6398 15.5784 15.2384 15.5479 14.2331 14.6949C13.2887 13.9029 12.9536 12.1969 13.2887 11.0088C13.2887 11.0088 13.8676 9.69884 15.4822 10.0644C16.4875 10.2777 17.4928 10.5823 18.5286 10.6128C19.0769 10.6128 19.6253 10.5823 20.1736 10.4909Z"
      fill="#110D3D"
    />
    <path
      d="M23.6179 20.8792C23.6179 20.8792 24.3186 19.356 25.4153 20.2699C25.4153 20.2699 25.9027 20.666 25.2935 21.6713C25.2935 21.6713 24.41 23.1336 23.3438 22.311L23.6179 20.8792Z"
      fill="#FE6B6B"
    />
    <path
      d="M23.7386 17.3453C23.7386 17.3453 23.7386 20.0566 23.2207 21.4885L23.7386 21.5798C23.7386 21.5798 24.0128 19.7215 24.9876 19.6911L23.7386 17.3453Z"
      fill="#110D3D"
    />
  </svg>
);

export default GPAvatar;
