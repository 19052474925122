import { createAsyncAction, createAction } from 'typesafe-actions';

const bankWires = {
  loadBankWires: createAsyncAction(
    'loadBankWires/request',
    'loadBankWires/success',
    'loadBankWires/failure',
  )(),
  bankWiresRequest: createAsyncAction(
    'bankWiresRequest/request',
    'bankWiresRequest/success',
    'bankWiresRequest/failure',
  )(),
  bankWiresConfirm: createAsyncAction(
    'bankWiresConfirm/request',
    'bankWiresConfirm/success',
    'bankWiresConfirm/failure',
  )(),
};

const accountTransfers = {
  loadAccountTransfers: createAsyncAction(
    'loadAccountTransfers/request',
    'loadAccountTransfers/success',
    'loadAccountTransfers/failure',
  )(),
  loadAccountTransferOptions: createAsyncAction(
    'loadAccountTransferOptions/request',
    'loadAccountTransferOptions/success',
    'loadAccountTransferOptions/failure',
  )(),
  createAccountTransfer: createAsyncAction(
    'createAccountTransfer/request',
    'createAccountTransfer/success',
    'createAccountTransfer/failure',
  )(),
  resetAccountTransferOptions: createAction('resetAccountTransferOptions')(),
};

export default {
  ...bankWires,
  ...accountTransfers,
};
