import { api } from 'api';
import { takeEvery, all, put, call } from 'redux-saga/effects';

import actions from 'redux/actions';

function* callLoadTrades({ payload }) {
  try {
    const { accountId, ...data } = payload;
    const response = yield api.getAccountsTrades(accountId, data);

    yield put(actions.loadClientTrades.success(response.data));
  } catch (error) {
    yield put(actions.loadClientTrades.failure(error));
  }
}

function* loadSymbolsCount(payload) {
  try {
    const { accountId, ...params } = payload;

    const response = yield api.getAccountsTradesSymbolsOverview(
      accountId,
      params,
    );

    yield put(actions.loadClientTradesOverview.success(response.data));
  } catch (error) {
    yield put(actions.loadClientTradesOverview.failure(error));
  }
}

function* loadFilterLimits(payload) {
  try {
    const { accountId, ...params } = payload;
    const response = yield api.getTradesLimitsByAccountId(accountId, params);

    yield put(actions.loadFilterLimits.success(response.data));
  } catch (error) {
    yield put(actions.loadFilterLimits.failure(error));
  }
}

function* callLoadClientPayments({ payload }) {
  try {
    const { accountId, ...params } = payload;
    const response = yield api.getAccountsDeposits(accountId, params);

    yield put(actions.loadClientPayments.success(response.data));
  } catch (error) {
    yield put(actions.loadClientPayments.failure(error));
  }
}

function* callLoadClientPaymentsLimits({ payload }) {
  try {
    const { accountId, ...params } = payload;
    const response = yield api.getAccountsDepositsLimits(accountId, params);

    yield put(actions.loadClientPaymentsLimits.success(response.data));
  } catch (error) {
    yield put(actions.loadClientPaymentsLimits.failure(error));
  }
}

function* loadClientCommissions(payload) {
  try {
    const { accountId, ...params } = payload;

    const response = yield api.getAccountCommissionsTimeSeries(accountId, {
      ...params,
      classification: 'EARNED',
    });

    yield put(actions.loadClientCommissions.success(response.data));
  } catch (error) {
    yield put(actions.loadClientCommissions.failure(error));
  }
}

function* loadTotalCommissions(payload) {
  try {
    const { accountId, ...params } = payload;

    const response = yield api.getAccountCommissionsTimeSeries(accountId, {
      ...params,
      classification: 'TOTAL',
    });

    yield put(actions.loadTotalCommissions.success(response.data));
  } catch (error) {
    yield put(actions.loadTotalCommissions.failure(error));
  }
}

function* callLoadPartnerEarnings({ payload }) {
  try {
    const { partnerId, ...params } = payload;
    const response = yield api.getPartnerEarnings(partnerId, params);

    yield put(actions.loadPartnerEarnings.success(response.data));
  } catch (error) {
    yield put(actions.loadPartnerEarnings.failure(error));
  }
}

function* callExportPartnerEarnings({
  payload: { requestData, onSuccess, onFailure, fileExtension },
}) {
  try {
    const { partnerId, ...params } = requestData;

    const response = yield api.getPartnerEarnings(partnerId, params);

    if (response) {
      onSuccess(response.data, fileExtension);
    }
  } catch (error) {
    onFailure(error);
  }
}

function* callLoadPartnerEarningsFilterLimits({ payload }) {
  try {
    const { partnerId, ...params } = payload;

    const response = yield api.getPartnerEarningsLimits(partnerId, params);

    yield put(actions.loadPartnerEarningsFilterLimits.success(response.data));
  } catch (error) {
    yield put(actions.loadPartnerEarningsFilterLimits.failure(error));
  }
}

function* callLoadTradesOverview({ payload }) {
  try {
    yield all([
      call(loadSymbolsCount, payload),
      call(loadClientCommissions, payload),
      call(loadFilterLimits, payload),
      call(loadTotalCommissions, payload),
    ]);
  } catch (error) {
    yield put(actions.loadClientTradesOverview.failure(error));
  }
}

export default function* clientInfoSaga() {
  yield all([
    yield takeEvery(actions.loadClientTrades.request, callLoadTrades),
    yield takeEvery(actions.loadClientPayments.request, callLoadClientPayments),
    yield takeEvery(
      actions.loadClientPaymentsLimits.request,
      callLoadClientPaymentsLimits,
    ),
    yield takeEvery(
      actions.loadClientTradesOverview.request,
      callLoadTradesOverview,
    ),
    yield takeEvery(
      actions.loadPartnerEarnings.request,
      callLoadPartnerEarnings,
    ),
    yield takeEvery(
      actions.exportPartnerEarnings.request,
      callExportPartnerEarnings,
    ),
    yield takeEvery(
      actions.loadPartnerEarningsFilterLimits.request,
      callLoadPartnerEarningsFilterLimits,
    ),
  ]);
}
