export function replaceArrayItem(items, replacement, options) {
  const { key } = options || { key: 'id' };

  return items.map((item) => {
    if (item[key] === replacement[key]) {
      return replacement;
    }

    return item;
  });
}
