import { api } from 'api';
import {
  takeEvery,
  all,
  put,
  call,
  takeLatest,
  spawn,
} from 'redux-saga/effects';

import actions from 'redux/actions';

import { accountTransfersSaga } from './payments/accountTransfers';
import { bankWiresSaga } from './payments/bankWires';
import { adjustmentsSaga } from './adjustments';
import { balancesSaga } from './balances';
import { commissionsSaga } from './commissions';

function* callLoadReport(method, action) {
  try {
    const response = yield method;

    if (response) {
      yield put(action.success(response.data));
    }
  } catch (error) {
    yield put(action.failure(error));
  }
}

function* callLoadAllReport(method, { onSuccess, onFailure, fileExtension }) {
  try {
    const response = yield method;

    if (response) {
      onSuccess(response.data, fileExtension);
    }
  } catch (error) {
    onFailure(error);
  }
}

function* callLoadRegistrationsReport({ payload }) {
  yield call(
    callLoadReport,
    api.getBsUsersRegistrationsReport(payload),
    actions.loadRegistrationReport,
  );
}

function* callLoadLatestPartnersReport({ payload }) {
  yield call(
    callLoadReport,
    api.getUsersRegistrationsReport(payload),
    actions.loadLatestPartnersReport,
  );
}

function* callLoadLatestPartnersReportLimits({ payload }) {
  yield call(
    callLoadReport,
    api.getUsersRegistrationsReportLimits(payload),
    actions.loadLatestPartnersReportLimits,
  );
}

function* callLoadTradesReport({ payload }) {
  yield call(callLoadReport, api.getTrades(payload), actions.loadTradesReport);
}

function* callLoadTradesReportLimits({ payload }) {
  yield call(
    callLoadReport,
    api.getTradesLimits(payload),
    actions.loadTradesReportLimits,
  );
}

function* callLoadPaymentsReport({ payload }) {
  yield call(
    callLoadReport,
    api.userUsersWithdrawsBankWire(payload),
    actions.loadPaymentsReport,
  );
}

function* callLoadPaymentsReportLimits({ payload }) {
  yield call(
    callLoadReport,
    api.userUsersWithdrawsBankWireLimits(payload),
    actions.loadPaymentsReportLimits,
  );
}

function* callAllLoadTradesReport({ payload }) {
  yield call(callLoadAllReport, api.getTrades(payload.requestData), payload);
}

function* callLoadAllPaymentsReport({ payload }) {
  yield call(
    callLoadAllReport,
    api.userUsersWithdrawsBankWire(payload.requestData),
    payload,
  );
}

function* callLoadAllLatestPartnersReport({ payload }) {
  yield call(
    callLoadAllReport,
    api.getUsersRegistrationsReport(payload.requestData),
    payload,
  );
}

function* callLoadAllRegistrationsReport({ payload }) {
  yield call(
    callLoadAllReport,
    api.getBsUsersRegistrationsReport(payload.requestData),
    payload,
  );
}

export default function* reportsSaga() {
  yield all([
    yield takeEvery(actions.loadTradesReport.request, callLoadTradesReport),
    yield takeEvery(
      actions.loadTradesReportLimits.request,
      callLoadTradesReportLimits,
    ),
    yield takeEvery(actions.loadPaymentsReport.request, callLoadPaymentsReport),
    yield takeEvery(
      actions.loadPaymentsReportLimits.request,
      callLoadPaymentsReportLimits,
    ),
    yield takeEvery(
      actions.loadRegistrationReport.request,
      callLoadRegistrationsReport,
    ),
    yield takeEvery(
      actions.loadLatestPartnersReport.request,
      callLoadLatestPartnersReport,
    ),
    yield takeEvery(
      actions.loadLatestPartnersReportLimits.request,
      callLoadLatestPartnersReportLimits,
    ),
    yield takeLatest(
      actions.loadAllTradesReport.request,
      callAllLoadTradesReport,
    ),
    yield takeLatest(
      actions.loadAllPaymentsReport.request,
      callLoadAllPaymentsReport,
    ),
    yield takeLatest(
      actions.loadAllLatestPartnersReport.request,
      callLoadAllLatestPartnersReport,
    ),
    yield takeLatest(
      actions.loadAllRegistrationReport.request,
      callLoadAllRegistrationsReport,
    ),
    yield spawn(adjustmentsSaga),
    yield spawn(balancesSaga),
    yield spawn(commissionsSaga),
    yield spawn(accountTransfersSaga),
    yield spawn(bankWiresSaga),
  ]);
}
