import React from 'react';
import PropTypes from 'prop-types';

import StyledButton from 'components/Common/Buttons/StyledButton';
import TabsOverview from 'components/Tabs/TabsOverview';

import styles from './styles.module.less';

const HeaderWithButtons = ({
  title,
  showDrawer,
  currentPageData,
  customMenuItems,
  filters,
}) => (
  <div className={styles.tableHeader}>
    <h2 className={styles.title}>{title}</h2>
    <div className={styles.controls}>
      {showDrawer && (
        <StyledButton
          type="iconDefault"
          icon="filter"
          onClick={showDrawer}
          className={styles.filter}
        />
      )}
      {customMenuItems && (
        <TabsOverview
          customMenuItems={customMenuItems(currentPageData, filters)}
        />
      )}
    </div>
  </div>
);

HeaderWithButtons.propTypes = {
  title: PropTypes.string.isRequired,
  showDrawer: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  currentPageData: PropTypes.array,
  customMenuItems: PropTypes.func,
  filters: PropTypes.object,
};

export default React.memo(HeaderWithButtons);
