import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { api } from 'api';
import { mapDateRange } from '../mapDateRange';
import { getErrorHandler } from '../getErrorHandler';
import { ReferralSource } from 'constants/referalSource';

export const useMyCommissionClientFees = ({
  userId,
  dateRange,
  pagination,
  filters,
  sorter,
  source = ReferralSource.DIRECT,
}) => {
  const { data: fees, isFetching: isFeesFetching } = useQuery({
    queryKey: queryKeys.myCommissionClientFees(
      userId,
      dateRange,
      pagination,
      filters,
      sorter,
    ),
    queryFn: async () => {
      const response = await api.getClientFees(userId, {
        ...mapDateRange(dateRange),
        ...pagination,
        ...filters,
        ...sorter,
        source,
      });

      return response.data;
    },
    keepPreviousData: true,
    onError: getErrorHandler('Unable to load fees '),
  });

  return {
    fees,
    isFeesFetching,
  };
};
