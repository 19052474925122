import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';

import CustomIcon from 'components/Icons';
import DropdownMenu from 'components/Common/Dropdown/DropdownMenu';
import PopupContainer from 'components/PopupContainer';
import StyledButton from 'components/Common/Buttons/StyledButton';

import styles from './styles.module.less';

const TabsOverview = ({ customMenuItems, containerClass }) => (
  <PopupContainer containerClass={containerClass}>
    <Dropdown
      overlay={DropdownMenu({
        menuItems: customMenuItems,
        className: styles.menu,
      })}
      placement="bottomRight"
      trigger="click"
      icon={CustomIcon({ type: 'options' })}
    >
      <StyledButton
        type="iconDefault"
        icon="options"
        size="small"
        shape="circle"
      />
    </Dropdown>
  </PopupContainer>
);

TabsOverview.propTypes = {
  containerClass: PropTypes.string,
  customMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

export default TabsOverview;
