import { message } from 'antd';
import { takeEvery, all, put } from 'redux-saga/effects';

import { api } from 'api';
import actions from 'redux/actions';

function* callPaymentRequest({
  payload: { onSuccess, payment, dateRange, partnerId },
}) {
  try {
    const response = yield api.createUserPayment(partnerId, payment);

    if (response?.data) {
      yield put(
        actions.loadMyPayments.request({
          partnerId,
          offset: 0,
          limit: 10,
          dateFrom: dateRange[0].toISOString(),
          dateTo: new Date().toISOString(),
        }),
      );
      message.success('Payment has been sent!');
      onSuccess();
    }
  } catch {
    message.error('Unable to process payment!');
  }
}

export default function* paymentSaga() {
  yield all([yield takeEvery(actions.makePayment.request, callPaymentRequest)]);
}
