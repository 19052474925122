import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Tag, Row } from 'antd';
import CustomIcon from 'components/Icons';

import styles from './styles.module.less';

const StyledTags = ({ disabled = false, tags, onTagRemove, className }) => {
  const removeTag = (selectedSymbol) => (event) => {
    if (!disabled) {
      event.stopPropagation();
      onTagRemove(selectedSymbol);
    }
  };

  return (
    tags && (
      <Row className={clsx(styles.tags, className)}>
        {tags.map(({ value, amount, sign, label }) => (
          <Tag key={value} className={styles.tag}>
            <CustomIcon
              type="close"
              className={styles.icon}
              onClick={removeTag(value)}
            />
            <span>{label || value}</span>
            {amount && (
              <span>
                &nbsp;{amount}
                {sign || '%'}
              </span>
            )}
          </Tag>
        ))}
      </Row>
    )
  );
};

StyledTags.propTypes = {
  disabled: PropTypes.bool,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      amount: PropTypes.string,
    }),
  ),
  onTagRemove: PropTypes.func,
  className: PropTypes.string,
};

export default StyledTags;
