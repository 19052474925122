import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles.module.less';

import NoDataSVG from '../../assets/NoDataSVG/NoDataSVG';
import placeholderImg from '../../assets/notFoundImage.png';
import { useBrand } from 'modules/shared/brand/hooks/useBrand';
import { Brand } from 'constants/brand';

const EmptyText = ({ isBlackText, height, isTile, width, emptyMode }) => {
  const { brand } = useBrand();

  return (
    <div
      className={clsx(styles.noData, {
        [styles.tile]: isTile,
      })}
    >
      <div>
        {emptyMode === 'new' && brand === Brand.GP ? (
          <img
            src={placeholderImg}
            alt="No data to display yet"
            width={width}
            height={height}
          />
        ) : (
          <NoDataSVG width={width} height={height} />
        )}

        <p
          className={clsx({
            [styles.blackText]: isBlackText,
          })}
        >
          No data to display yet
        </p>
      </div>
    </div>
  );
};

EmptyText.defaultProps = {
  isBlackText: false,
  tile: false,
};

EmptyText.propTypes = {
  isBlackText: PropTypes.bool,
  isTile: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  emptyMode: PropTypes.oneOf(['new']),
};

export default EmptyText;
