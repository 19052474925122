import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import FormSwitch from 'components/Modals/DetailAndPermissionsModal/FormSwitch';
import FormInput from 'components/Common/Input/FormInput';
import TagsInput from 'components/Common/Input/TagsInput';

import { mapToOptions } from 'utils/mapToOptions';
import { COUNTRY_CODES_MAP } from 'utils/countries';

import styles from './styles.module.less';

const Permissions = ({ form }) => {
  const [isCountriesView, setIsCountriesView] = useState(
    form.getFieldValue('isCountryControl'),
  );

  const getValueProps = useCallback((value) => {
    setIsCountriesView(value);
  }, []);

  const countryOptions = useMemo(
    () => mapToOptions(COUNTRY_CODES_MAP, 'value'),
    [],
  );

  return (
    <>
      <div>
        <h2 className={styles.title}>Access Control</h2>
        <div className={styles.accessControl}>
          <FormSwitch
            type="expand"
            label="Country Control"
            name="isCountryControl"
            checked={isCountriesView}
            getValueProps={getValueProps}
          />
          <FormInput
            label="Select Countries"
            name="controlledCountries"
            className={clsx(styles.selectCountries, {
              [styles.selectCountriesDisabled]: !isCountriesView,
            })}
            type="select"
            isRequired={isCountriesView}
          >
            {TagsInput({
              options: countryOptions,
              placeholder: 'Select Countries',
            })}
          </FormInput>
        </div>
      </div>
    </>
  );
};

Permissions.propTypes = {
  form: PropTypes.object,
};

export default Permissions;
