import { createAction, createAsyncAction } from 'typesafe-actions';

export default {
  auth: createAsyncAction('auth/request', 'auth/success', 'auth/failure')(),
  logIn: createAsyncAction('logIn/request', 'logIn/success', 'logIn/failure')(),
  logInWithPartner: createAsyncAction(
    'logInWithPartner/request',
    'logInWithPartner/success',
    'logInWithPartner/failure',
  )(),
  logOut: createAsyncAction(
    'logOut/request',
    'logOut/success',
    'logOut/failure',
  )(),
  logOutWithPartner: createAsyncAction(
    'logOutWithPartner/request',
    'logOutWithPartner/success',
    'logOutWithPartner/failure',
  )(),
  sendLinkToEmailForSignUp: createAsyncAction(
    'signUp/sendLinkToEmail/request',
    'signUp/sendLinkToEmail/success',
    'signUp/sendLinkToEmail/failure',
  )(),
  sendLinkToEmailForResetPassword: createAsyncAction(
    'resetPassword/sendLinkToEmail/request',
    'resetPassword/sendLinkToEmail/success',
    'resetPassword/sendLinkToEmail/failure',
  )(),
  generateTFAqr: createAsyncAction(
    'generateTFAqr/request',
    'generateTFAqr/success',
    'generateTFAqr/failure',
  )(),
  turnTwoFactorAuth: createAsyncAction(
    'turnTwoFactorAuth/request',
    'turnTwoFactorAuth/success',
    'turnTwoFactorAuth/failure',
  )(),
  twoFactorAuth: createAsyncAction(
    'twoFactorAuth/request',
    'twoFactorAuth/success',
    'twoFactorAuth/failure',
  )(),
  findManager: createAsyncAction(
    'manager/find/request',
    'manager/find/success',
    'manager/find/failure',
  )(),
  getBalance: createAsyncAction(
    'getBalance/request',
    'getBalance/success',
    'getBalance/failure',
  )(),
  changeIsTwoFactorAuth: createAction('isTwoFactorAuth/change')(),
  saveDetails: createAction('saveDetails/change')(),
  stopHandlingTokenExpiration: createAction('stopHandlingTokenExpiration')(),
  setUser: createAction('setUser')(),
};
