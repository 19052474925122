import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import { replaceArrayItem } from 'utils/replaceArrayItem';
import Actions from '../actions';

const initialState = {
  items: [],
  total: 0,
  options: {
    partnerIdOptions: [],
  },
  loadingType: '',
  error: '',
  cpaQualifier: {},
  tiers: [],
};

export default createReducer(initialState)
  .handleAction(
    [
      Actions.findPartnersGroups.request,
      Actions.createPartnersGroup.request,
      Actions.patchPartnersGroup.request,
      Actions.removePartnersGroup.request,
      Actions.loadCpaQualifier.request,
      Actions.loadTiers.request,
    ],
    produce((state, { type }) => {
      state.loadingType = type;
      state.error = '';
    }),
  )
  .handleAction(
    [
      Actions.findPartnersGroups.failure,
      Actions.createPartnersGroup.failure,
      Actions.patchPartnersGroup.failure,
      Actions.removePartnersGroup.failure,
      Actions.findPartnersForGroups.failure,
      Actions.loadCpaQualifier.failure,
      Actions.loadTiers.failure,
    ],
    produce((state, { type, payload }) => {
      state.loadingType = type;
      state.error = payload;
    }),
  )
  .handleAction(
    [Actions.createPartnersGroup.success],
    produce((state, { payload }) => {
      state.items = [...new Set([...state.items, payload])];
      state.total = state.total + 1;
      state.loadingType = '';
      state.error = '';
    }),
  )
  .handleAction(
    [Actions.loadCpaQualifier.success],
    produce((state, { payload }) => {
      state.cpaQualifier = payload;
    }),
  )
  .handleAction(
    [Actions.loadTiers.success],
    produce((state, { payload }) => {
      state.tiers = payload;
    }),
  )
  .handleAction(
    [Actions.findPartnersGroups.success],
    produce((state, { payload }) => {
      state.items = payload.rows;
      state.total = payload.count;
      state.loadingType = '';
      state.error = '';
    }),
  )
  .handleAction(
    [Actions.patchPartnersGroup.success],
    produce((state, { payload }) => {
      state.items = replaceArrayItem(state.items, payload);
      state.loadingType = '';
      state.error = '';
    }),
  )
  .handleAction(
    [Actions.removePartnersGroup.success],
    produce((state, { payload }) => {
      state.items = state.items.filter((partnersGroup) =>
        payload.every((item) => item.id !== partnersGroup.id),
      );
      state.loadingType = '';
      state.error = '';
    }),
  )
  .handleAction(
    [Actions.findPartnersForGroups.success],
    produce((state, { payload }) => {
      state.options.partnerIdOptions = payload.rows.map(({ id, groupId }) => ({
        value: `${id}`,
        disabled: !!groupId,
      }));

      state.loadingType = '';
      state.error = '';
    }),
  )
  .handleAction(
    Actions.resetCpaQualifier,
    produce((state) => {
      state.cpaQualifier = {};
      state.tiers = [];
    }),
  );
