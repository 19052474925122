import React from 'react';
import PropTypes from 'prop-types';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

export const RouterProvider = ({ children }) => (
  <Router history={history}>{children}</Router>
);

RouterProvider.propTypes = {
  children: PropTypes.node,
};
