import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import styles from './styles.module.less';

const StyledLink = ({ text, className, type, href, ...other }) => (
  <Link to={href} className={clsx(styles[type], className)} {...other}>
    <span className={styles.linkText}>{text}</span>
  </Link>
);

StyledLink.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(['gradient', 'link']),
};

StyledLink.defaultProps = {
  type: 'link',
};

export default StyledLink;
