import { createAsyncAction, createAction } from 'typesafe-actions';

export default {
  createSettings: createAsyncAction(
    'createSettings/action/request',
    'createSettings/action/success',
    'createSettings/action/failure',
  )(),
  loadSettings: createAsyncAction(
    'loadSettings/action/request',
    'loadSettings/action/success',
    'loadSettings/action/failure',
  )(),
  resetSettings: createAction('commissionSettings/settings/reset')(),
};
