import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './styles.module.less';

const Loader = ({ type, text }) => (
  <div className={clsx(styles.loaderContainer, styles[type])}>
    <div className={styles.loader} />
    {text && <span className={styles.text}>{text}</span>}
  </div>
);

Loader.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
};

export default Loader;
