import React from 'react';
import { useSelector } from 'react-redux';

import { getUserRole } from 'redux/sagas/auth/selectors';

import { USER_ROLES } from 'utils/constants';

import BrokersRouting from './BrokerRouting';
import StaffRouting from './StaffRouter';
import { useIsStaff } from '../../../../utils/hooks/useIsStaff';
import { MfaGuard } from '../guards/MfaGuard';

const Routing = () => {
  const isStaff = useIsStaff();

  return isStaff ? (
    <MfaGuard>
      <StaffRouting />
    </MfaGuard>
  ) : (
    <BrokersRouting />
  );
};

export default Routing;
