import { message } from 'antd';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { Providers } from 'providers';
import { App } from './App';

import './modules/shared/theme/fonts.css';
import './index.less';

message.config({
  maxCount: 1,
});

ReactDOM.render(
  <StrictMode>
    <Providers>
      <App />
    </Providers>
  </StrictMode>,
  document.getElementById('root'),
);
