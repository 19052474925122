import { FORM_INPUT_TYPES } from 'utils/constants';

export const FirebaseError = {
  EMAIL_ALREADY_IN_USE: 'auth/email-already-in-use',
  EXPIRED_ACTION_CODE: 'auth/expired-action-code',
  INVALID_ACTION_CODE: 'auth/invalid-action-code',
  USER_NOT_FOUND: 'auth/user-not-found',
  WRONG_PASSWORD: 'auth/wrong-password',
  WRONG_CREDENTIALS: 'auth/invalid-login-credentials',
};

export const ErrorMessage = {
  USER_EXIST: 'A user with the same email already exists',
  NO_REFRESH_TOKEN: 'No refresh token',
  ACCESS_TOKEN_EXPIRED: 'Access token expired',
  WRONG_CREDENTIALS: 'Wrong credentials provided',
  WRONG_PASSWORD: 'Wrong password',
  WRONG_TFA_CODE: 'Invalid two factor auth code',
  USER_NOT_FOUND: 'User with this email does not exist',
  USER_ID_NOT_FOUND: 'User with this ID does not exist',
  STH_WENT_WRONG: 'Something went wrong. Please try again later',
  WRONG_PASSWORD_RESET_LINK:
    'You have used an outdated link, please check for a more recent email in your inbox and retry. Please reach out to our help Team if this persists.',
};

export const ErrorMessageByFirebaseError = {
  [FirebaseError.INVALID_ACTION_CODE]: ErrorMessage.WRONG_PASSWORD_RESET_LINK,
  [FirebaseError.EXPIRED_ACTION_CODE]: ErrorMessage.WRONG_PASSWORD_RESET_LINK,
};

export default {
  SUCCESS: {
    CREATE_PARTNER: 'Partner successfully created',
    PATCH_PARTNER: 'Partner successfully updated',
    REMOVE_PARTNER: 'Partner successfully removed',
    CREATE_PARTNERS_GROUP: 'Partners group successfully created',
    PATCH_PARTNERS_GROUP: 'Partners group successfully updated',
    REMOVE_PARTNERS_GROUP: 'Partners group successfully removed',
    CREATE_EMPLOYEE: 'Employee successfully created',
    PATCH_EMPLOYEE: 'Employee successfully updated',
    REMOVE_EMPLOYEE: 'Employee successfully removed',
  },
};

export const getFirebaseErrorMessage = (error) =>
  ErrorMessageByFirebaseError[error?.code] ?? error?.message;

export const getErrorMessagebyServerName = (error) => {
  if (error?.name === 'FirebaseError') {
    return error?.code;
  }

  return error?.response?.data?.message || error?.message || error;
};

export const errorAuthHandler = (form, error) => {
  switch (error) {
    case FirebaseError.EMAIL_ALREADY_IN_USE:
    case ErrorMessage.USER_EXIST: {
      form.setFields([
        {
          name: FORM_INPUT_TYPES.EMAIL,
          errors: [ErrorMessage.USER_EXIST],
        },
      ]);

      break;
    }
    case FirebaseError.WRONG_CREDENTIALS:
    case ErrorMessage.WRONG_CREDENTIALS: {
      form.setFields([
        {
          name: FORM_INPUT_TYPES.EMAIL,
        },
        {
          name: FORM_INPUT_TYPES.PASSWORD,
          errors: [ErrorMessage.WRONG_CREDENTIALS],
        },
      ]);

      break;
    }
    case ErrorMessage.USER_NOT_FOUND:
    case FirebaseError.USER_NOT_FOUND: {
      form.setFields([
        {
          name: FORM_INPUT_TYPES.EMAIL,
          errors: [ErrorMessage.USER_NOT_FOUND],
        },
      ]);

      break;
    }
    case FirebaseError.WRONG_PASSWORD: {
      form.setFields([
        {
          name: FORM_INPUT_TYPES.PASSWORD,
          errors: [ErrorMessage.WRONG_PASSWORD],
        },
      ]);

      break;
    }
    case ErrorMessage.WRONG_TFA_CODE: {
      form.setFields([
        {
          name: 'code',
          errors: [ErrorMessage.WRONG_TFA_CODE],
        },
      ]);

      break;
    }
  }
};

export const errorProfileDetailsHandler = (form, error) => {
  switch (error) {
    case FirebaseError.PHONE_NUMBER_ALREADY_EXISTS: {
      form.setFields([
        {
          name: FORM_INPUT_TYPES.PHONE_NUMBER,
          errors: [ErrorMessage.PHONE_NUMBER_ALREADY_EXISTS],
        },
      ]);

      break;
    }
    case FirebaseError.INVALID_PHONE_NUMBER: {
      form.setFields([
        {
          name: FORM_INPUT_TYPES.PHONE_NUMBER,
          errors: [ErrorMessage.INVALID_PHONE_NUMBER],
        },
      ]);

      break;
    }
  }
};

export const errorAddNewPartnerHandler = (form, error) => {
  switch (error) {
    case ErrorMessage.USER_NOT_FOUND: {
      console.log(error);
      form.setFields([
        {
          name: FORM_INPUT_TYPES.EMAIL,
          errors: [error],
        },
      ]);

      break;
    }
  }
};
