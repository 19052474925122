import { createSelector } from 'reselect';
import _ from 'lodash';

export const getBankWires = ({ withdrawals }) => withdrawals.bankWires;

const _getAccountTransfers = ({ withdrawals }) => withdrawals.accountTransfers;

export const getAccountTransferOptions = createSelector(
  _getAccountTransfers,
  (accountTransfers) =>
    _(accountTransfers.options)
      .uniqBy(({ value }) => value)
      .map((option) => ({
        ...option,
        key: option.value,
      }))
      .value(),
);

export const getAccountTransfers = createSelector(
  _getAccountTransfers,
  (accountTransfers) => ({
    dataSource: accountTransfers.dataSource,
    isLoading: accountTransfers.isLoading,
    isInitialLoading: accountTransfers.isInitialLoading,
    total: accountTransfers.total,
    isOptionsLoading: accountTransfers.isOptionsLoading,
  }),
);
