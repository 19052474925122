import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import TableWithFilter from 'components/Table/TableWithFilter';
import VolumeOverview from 'components/Common/StatisticTiles/VolumeOverview';

import Actions from 'redux/actions';
import {
  getClientCommissionOverview,
  getClientSymbolsOverview,
  getClientTotalCommissionOverview,
  getClientTradesFilters,
  getClientTradesTable,
} from 'redux/sagas/clientInfo/selectors';
import { getExportMenu } from 'utils/export';
import { EXPORT_DATA_TYPES } from 'utils/constants';
import useLoad from 'utils/hooks/useLoad';
import { CLIENTS_TRADES_COLUMNS, TRADES_FILTER } from './config';

import VolumeTrades from './TradesOverview/VolumeTrades';
import TotalCommissions from './TradesOverview/TotalCommissions';
import styles from './styles.module.less';
import { useAccountById } from 'modules/shared/account/hooks/useAccountById';
import { mapDateRangeToSplitType } from 'utils/mapDateRangeToSplitType';

const Trades = ({ dateRange, accountId }) => {
  const dispatch = useDispatch();
  const { accountDetails } = useAccountById({ accountId });
  const loadData = useLoad(Actions.loadClientTrades, dateRange, {
    accountId,
    orderBy: 'closeDate',
    orderDirection: 'descend',
  });

  useEffect(() => {
    const [dateFrom, dateTo] = dateRange;

    const splitType = mapDateRangeToSplitType(dateRange);

    const payload = {
      accountId,
      splitType,
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
    };

    dispatch(Actions.loadClientTradesOverview.request(payload));
  }, [accountId, dispatch, dateRange]);

  const menuItems = useCallback(
    (currentData) =>
      getExportMenu({
        fileName: accountDetails?.name || '',
        exportType: EXPORT_DATA_TYPES.TABLE,
        sourceList: currentData,
        exportFields: CLIENTS_TRADES_COLUMNS.map(({ dataIndex }) => dataIndex),
        header: CLIENTS_TRADES_COLUMNS.map(({ title }) => title),
      }),
    [accountDetails],
  );

  const onVolumeSeriesChanged = useCallback(
    (value) => {
      dispatch(Actions.updateClientTradesOverview(value));
    },
    [dispatch],
  );

  return (
    <>
      <div className={styles.tradesTiles}>
        <TotalCommissions selector={getClientTotalCommissionOverview} />
        <VolumeTrades selector={getClientCommissionOverview} />
        <VolumeOverview
          selector={getClientSymbolsOverview}
          onSeriesChanged={onVolumeSeriesChanged}
          title="Volume"
          className={styles.volumeChart}
        />
      </div>
      <TableWithFilter
        customMenuItems={menuItems}
        filterSelector={getClientTradesFilters}
        selector={getClientTradesTable}
        loadData={loadData}
        rowKey="id"
        columns={CLIENTS_TRADES_COLUMNS}
        filterItems={TRADES_FILTER}
        title="Trades"
        type="trades"
        className={styles.tradesTable}
      />
    </>
  );
};

Trades.propTypes = {
  accountId: PropTypes.number.isRequired,
  dateRange: PropTypes.array,
};

export default Trades;
