import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Row } from 'antd';
import Chart from 'react-apexcharts';

import CustomIcon from 'components/Icons';
import Spinner from 'components/Spinner';

import { getFormattedNumber } from 'utils/helpers';
import { getChartOptions } from 'utils/charts';
import { NUMBER_FORMATS } from 'utils/constants';

import styles from './styles.module.less';
import { useBrand } from 'modules/shared/brand/hooks/useBrand';

const BigStatisticTile = ({
  type,
  text,
  percentChanges,
  totalCount,
  series,
  isLoading,
  className,
}) => {
  const { brand } = useBrand();

  const changesStyle =
    percentChanges >= 0 ? styles.positiveChanges : styles.negativeChanges;

  const changes = useMemo(() => {
    if (percentChanges === 0) return null;

    return getFormattedNumber({
      number: percentChanges,
      style: NUMBER_FORMATS.percent,
      signDisplay: true,
    });
  }, [percentChanges]);

  const count = useMemo(
    () =>
      getFormattedNumber({
        number: totalCount,
        style: NUMBER_FORMATS.currency,
        signDisplay: false,
        group: {
          count: 3,
          replaceWith: ' ',
        },
      }),
    [totalCount],
  );

  const { options, chartType } = useMemo(
    () => getChartOptions(type, brand, 300, 93),
    [type, brand],
  );
  const chartSeries = useMemo(() => [{ data: series }], [series]);

  return (
    <div className={clsx(styles.tile, className)}>
      <Row className={styles[type]}>
        <CustomIcon type={type} />
        <div className={styles.tileInfoContainer}>
          <p className={styles.tileInfoText}>{text}</p>
          {!isLoading && <p className={styles.tileInfoTotalCount}>{count}</p>}
        </div>
        {!isLoading && (
          <Row className={changesStyle}>
            <CustomIcon type="small-arrow-changes" />
            <span className={styles.percentChanges}>
              <span className={styles.firstLetter}>{changes?.charAt(0)}</span>
              <span>{changes?.slice(1)}</span>
            </span>
          </Row>
        )}
      </Row>
      {isLoading ? (
        <Spinner />
      ) : (
        <Chart
          options={options}
          series={chartSeries}
          type={chartType}
          height="93"
        />
      )}
    </div>
  );
};

BigStatisticTile.defaultProps = {
  type: 'commissions-earned',
  text: 0,
  percentChanges: 0,
  totalCount: 0,
  series: [],
  isLoading: true,
  className: '',
};

BigStatisticTile.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  percentChanges: PropTypes.number,
  totalCount: PropTypes.number,
  series: PropTypes.array,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

export default React.memo(BigStatisticTile);
