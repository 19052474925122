import React from 'react';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import moment from 'moment';

const TableColDate = ({ date = 0, formatType = '', ...others }) => (
  <Text {...others}>
    {date ? moment(date).local().format(formatType) : '--'}
  </Text>
);

TableColDate.propTypes = {
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  formatType: PropTypes.string,
  others: PropTypes.any,
};
export default TableColDate;
