import { takeLatest, put } from 'redux-saga/effects';
import actions from 'redux/actions';
import { api } from 'api';
import { getErrorHandler } from 'utils/getErrorHandler';
import { notification } from 'antd';

function* callRejectBankWire({ payload: bankWireId }) {
  try {
    const response = yield api.rejectBankWire(bankWireId);
    yield put(actions.rejectBankWire.success(response.data));
  } catch (error) {
    getErrorHandler('Unable to reject the bank wire')(error);
    yield put(actions.rejectBankWire.failure(error));
  }
}

function* callApproveBankWireTaxInvoice({ payload: bankWireId }) {
  try {
    const response = yield api.approveBankWireTaxInvoice(bankWireId);
    yield put(actions.approveBankWireTaxInvoice.success(response.data));
    notification.success({
      message: "BankWire's Tax Invoice has been successfully approved",
    });
  } catch (error) {
    getErrorHandler('Unable to approve the bank wire')(error);
    yield put(actions.approveBankWireTaxInvoice.failure(error));
  }
}

export function* bankWiresSaga() {
  yield takeLatest(actions.rejectBankWire.request, callRejectBankWire);
  yield takeLatest(
    actions.approveBankWireTaxInvoice.request,
    callApproveBankWireTaxInvoice,
  );
}
