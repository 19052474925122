import React from 'react';
import { takeEvery, all, put, call } from 'redux-saga/effects';
import { notification } from 'antd';

import { api } from 'api';
import actions from 'redux/actions';

import { USER_ROLES } from 'utils/constants';
import messages, { getErrorMessagebyServerName } from 'utils/messages';
import { callLoadClientsBasedOnType } from '../clients';

function* callLoadReport(method, action) {
  try {
    const response = yield method;

    if (response) {
      yield put(action.success(response.data));
    }
  } catch (error) {
    yield put(action.failure(error));
  }
}

function* callLoadLatestClientsList({ payload }) {
  yield call(
    callLoadReport,
    callLoadClientsBasedOnType(payload),
    actions.loadDashboardLatestClients,
  );
}

function* callLoadLatestPartners({ payload }) {
  const data = {
    role: [USER_ROLES.BROKER],
    ...payload,
  };

  yield call(
    callLoadReport,
    api.getUsers(data),
    actions.loadDashboardLatestClients,
  );
}

function* callPatchPartner({ payload }) {
  try {
    const { id, ...data } = payload;
    const response = yield api.updateUser(id, data);

    if (Array.isArray(response?.data)) {
      yield put(actions.dashboardPatchPartner.success(response.data));
    } else {
      yield put(actions.dashboardPatchPartner.success([response.data]));
    }

    notification.success({ message: messages.SUCCESS.PATCH_PARTNER });
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    notification.error({
      message: 'Update partner error',
      description: <span>{errorMessage}</span>,
      duration: 4,
    });
    yield put(actions.dashboardPatchPartner.failure(errorMessage));
  }
}

function* callFindManagers({ payload }) {
  try {
    const response = yield api.getUsers({
      role: [USER_ROLES.MANAGER],
      ...payload,
    });

    yield put(actions.dashboardFindManagers.success(response.data));
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.dashboardFindManagers.failure(errorMessage));
  }
}

export default function* dashboardSaga() {
  yield all([
    yield takeEvery(
      actions.loadDashboardLatestClients.request,
      callLoadLatestClientsList,
    ),
    yield takeEvery(
      actions.loadDashboardPartners.request,
      callLoadLatestPartners,
    ),
    yield takeEvery(actions.dashboardPatchPartner.request, callPatchPartner),
    yield takeEvery(actions.dashboardFindManagers.request, callFindManagers),
  ]);
}
