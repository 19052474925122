import { createAction, createAsyncAction } from 'typesafe-actions';

export default {
  loadMyCommissionOverview: createAsyncAction(
    'loadMyCommissionOverview/action/request',
    'loadMyCommissionOverview/action/success',
    'loadMyCommissionOverview/action/failure',
  )(),
  loadMyClientsTrades: createAsyncAction(
    'loadMyClientsTrades/request',
    'loadMyClientsTrades/success',
    'loadMyClientsTrades/failure',
  )(),
  loadMyClientsTradesFilterLimits: createAsyncAction(
    'loadMyClientsTradesFilterLimits/request',
    'loadMyClientsTradesFilterLimits/success',
    'loadMyClientsTradesFilterLimits/failure',
  )(),
  loadMyClientsTradesCommissions: createAsyncAction(
    'loadMyClientsTradesCommissions/request',
    'loadMyClientsTradesCommissions/success',
    'loadMyClientsTradesCommissions/failure',
  )(),
  loadMyPartnersTradesCommissions: createAsyncAction(
    'loadMyPartnersTradesCommissions/request',
    'loadMyPartnersTradesCommissions/success',
    'loadMyPartnersTradesCommissions/failure',
  )(),
  exportMyClientsTrades: createAsyncAction(
    'exportMyClientsTrades/request',
    'exportMyClientsTrades/success',
    'exportMyClientsTrades/failure',
  )(),
  loadMyPartnersTrades: createAsyncAction(
    'loadMyPartnersTrades/request',
    'loadMyPartnersTrades/success',
    'loadMyPartnersTrades/failure',
  )(),
  loadMyPartnersTradesFilterLimits: createAsyncAction(
    'loadMyPartnersTradesFilterLimits/request',
    'loadMyPartnersTradesFilterLimits/success',
    'loadMyPartnersTradesFilterLimits/failure',
  )(),
  exportMyPartnersTrades: createAsyncAction(
    'exportMyPartnersTrades/request',
    'exportMyPartnersTrades/success',
    'exportMyPartnersTrades/failure',
  )(),
  updateMyCommissionOverview: createAction(
    'myCommission/updateMyCommissionOverview',
  )(),
  loadMyPayments: createAsyncAction(
    'loadMyPayments/request',
    'loadMyPayments/success',
    'loadMyPayments/failure',
  )(),
  exportMyPayments: createAsyncAction(
    'exportMyPayments/request',
    'exportMyPayments/success',
    'exportMyPayments/failure',
  )(),
  loadMyBalance: createAsyncAction(
    'loadMyBalance/request',
    'loadMyBalance/success',
    'loadMyBalance/failure',
  )(),
  loadMyPaymentsFilterLimits: createAsyncAction(
    'loadMyPaymentsFilterLimits/request',
    'loadMyPaymentsFilterLimits/success',
    'loadMyPaymentsFilterLimits/failure',
  )(),
  loadMyBalanceFilterLimits: createAsyncAction(
    'loadMyBalanceFilterLimits/request',
    'loadMyBalanceFilterLimits/success',
    'loadMyBalanceFilterLimits/failure',
  )(),
  exportBalance: createAsyncAction(
    'exportBalance/request',
    'exportBalance/success',
    'exportBalance/failure',
  )(),
};
