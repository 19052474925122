import { useDeepCallback } from 'react-deep-hooks';
import { useDispatch } from 'react-redux';
import { isArray } from 'lodash';

const useLoad = (action, dateRange, params, enabled = true) => {
  const dispatch = useDispatch();

  return useDeepCallback(
    (pageIndex, limit, filters, sorter) => {
      const data = {
        offset: limit * pageIndex,
        limit,
        ...filters,
        ...params,
        ...sorter,
      };

      if (isArray(dateRange)) {
        if (dateRange?.[1]) {
          data.dateFrom = dateRange[0].toISOString();
          data.dateTo = dateRange[1].toISOString();
        }
      } else if (dateRange?.toISOString) {
        data.dateTo = dateRange?.toISOString();
      }

      if (enabled) {
        dispatch(action.request(data));
      }
    },
    [params, dateRange, dispatch, action, enabled],
  );
};

export default useLoad;
