import React from 'react';
import PropTypes from 'prop-types';
import FormRow from './FormRow';
import { useBrandRevShares } from 'utils/hooks/useBrandRevShares';

import styles from './styles.module.less';

const RevShare = ({ selectedSymbols, setSelectedSymbols, isOpen }) => {
  const { symbols, isSymbolsLoading, rows } = useBrandRevShares({
    runQuery: isOpen,
  });

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Symbol Based RevShare</h2>
      {rows.map(({ name, label }) => (
        <FormRow
          key={name}
          name={name}
          selectedSymbols={selectedSymbols[name]}
          setSelectedSymbols={setSelectedSymbols}
          className={styles.symbolContainer}
          label={label}
          symbols={symbols}
          isSymbolsLoading={isSymbolsLoading}
        />
      ))}
    </div>
  );
};

RevShare.propTypes = {
  selectedSymbols: PropTypes.object,
  setSelectedSymbols: PropTypes.func,
  groupId: PropTypes.number,
  isOpen: PropTypes.bool,
};

export default RevShare;
