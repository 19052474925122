import React from 'react';
import { Tooltip } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import Status from 'components/Common/Identifier/Status';
import SelectorCell from 'components/Table/TableCell/SelectorCell';
import Flags from 'country-flag-icons/react/3x2';

import { COUNTRY_CODES_MAP } from 'utils/countries';

import styles from './styles.module.less';
import BackgroundWrapper from '../BackgroundWrapper';
import { getOptionsForPartnersTableByType } from 'redux/sagas/users/selectors';
import { usePartnersOptions } from 'utils/hooks/usePartnersOptions';

const Identifier = ({
  className,
  name,
  value = 'US',
  type,
  onChange,
  onCopyLink,
  brand,
}) => {
  switch (name) {
    case 'Country':
      let CountryFlag = Flags[value];

      if (value === 'UM') {
        CountryFlag = Flags['US'];
      }

      const countryName = COUNTRY_CODES_MAP[value]?.value ?? '';

      return CountryFlag ? (
        <div className={styles.flagContainer}>
          <Tooltip title={countryName}>
            <CountryFlag className={clsx(styles.flag, className)} />
          </Tooltip>
        </div>
      ) : (
        value
      );

    case 'IB Status':
    case 'Account Status':
    case 'Status':
      return <Status status={value.toLowerCase()} />;
    case 'Partner IB ID':
    case 'Master IB ID':
      const SubIbIdSelectorCell = () => {
        const { partnersOptions = [] } = usePartnersOptions({ brand });

        return (
          <SelectorCell
            type={type}
            currentValue={value}
            onChange={(_record, values) => onChange(values)}
            getOptionsFromStore={() => partnersOptions}
            isShowSearch={true}
          />
        );
      };

      return <SubIbIdSelectorCell />;
    case 'Affiliate Link':
      return (
        <BackgroundWrapper
          icon="copy-link"
          onClick={() => onCopyLink(value)}
          type="button"
          className={styles.copyLink}
          text="Copy Link"
        />
      );
    case 'Partner group':
      return (
        <SelectorCell
          type={type}
          currentValue={value}
          onChange={(_record, values) => onChange(values)}
          getOptionsFromStore={getOptionsForPartnersTableByType(
            'groupIdOptions',
          )}
        />
      );
    default:
      return <span className={styles.identityContent}>{value}</span>;
  }
};

Identifier.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  onChange: PropTypes.func,
  onCopyLink: PropTypes.func,
  brand: PropTypes.string,
};

export default Identifier;
