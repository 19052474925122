import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { api } from 'api';
import { mapDateRange } from '../mapDateRange';
import { getErrorHandler } from '../getErrorHandler';

export const useClientFeesLimits = ({ accountId, dateRange }) => {
  const { data: feesLimits = {}, isLoading: isFeesLimitsLoading } = useQuery({
    queryKey: queryKeys.clientFeesLimits(accountId, dateRange),
    queryFn: async () => {
      const response = await api.getFeesLimits(
        accountId,
        mapDateRange(dateRange),
      );

      return response.data;
    },
    onError: getErrorHandler('Unable to load fees report filters data'),
  });

  return {
    feesLimits,
    isFeesLimitsLoading,
  };
};
