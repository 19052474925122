import { spawn, all } from 'redux-saga/effects';
import authSaga from './auth';
import usersSaga from './users';
import partnersGroupsSaga from './partnersGroups';
import logsSaga from './logs';
import dashboardSaga from './dashboard';
import clientInfoSaga from './clientInfo';
import searchSaga from './search';
import withdrawalsSaga from './withdrawals';
import clientsSaga from './clients';
import myCommissionSaga from './myCommission';
import reportsSaga from './reports';
import commissionSettingsSaga from './commissionSettings';
import paymentSaga from './payment';

export default function* rootSaga() {
  yield all(
    [
      authSaga,
      usersSaga,
      partnersGroupsSaga,
      dashboardSaga,
      clientInfoSaga,
      searchSaga,
      withdrawalsSaga,
      clientsSaga,
      myCommissionSaga,
      reportsSaga,
      commissionSettingsSaga,
      paymentSaga,
      logsSaga,
    ].map(spawn),
  );
}
