import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import clsx from 'clsx';

import styles from './styles.module.less';

const StyledCheckbox = ({ label, onChange, className, ...other }) => (
  <Checkbox
    onChange={onChange}
    className={clsx(styles.checkbox, className)}
    {...other}
  >
    {label}
  </Checkbox>
);

StyledCheckbox.propTypes = {
  label: PropTypes.node,
  onChange: PropTypes.func,
  className: PropTypes.string,
  other: PropTypes.string,
};

export default StyledCheckbox;
