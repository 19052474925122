import React from 'react';
import { Row, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';
import clsx from 'clsx';

import PersonAvatar from './PersonAvatar';

import styles from './styles.module.less';

const Person = ({
  type,
  className,
  personName,
  email,
  isOnline,
  imageURL,
  hasAvatar,
}) => (
  <Row wrap={false} className={clsx(className, styles[type])}>
    {hasAvatar && (
      <PersonAvatar imageURL={imageURL} isOnline={isOnline} type={type} />
    )}
    {personName ? (
      <div className={styles.infoContainer}>
        <Text className={styles.name}>{personName}</Text>
        <Text className={styles.email}>{email}</Text>
      </div>
    ) : (
      <Skeleton active paragraph={{ rows: 1 }} className={styles.skeleton} />
    )}
  </Row>
);

Person.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  personName: PropTypes.string,
  email: PropTypes.string,
  isOnline: PropTypes.bool,
  imageURL: PropTypes.string,
  hasAvatar: PropTypes.bool,
};

Person.defaultProps = {
  hasAvatar: true,
};

export default Person;
