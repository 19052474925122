import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import StyledDatePicker from 'components/Common/DatePicker/StyledDatePicker';

import styles from './styles.module.less';

const DateInput = ({ placeholder, className, ...other }) => (
  <StyledDatePicker
    placeholder={placeholder}
    className={clsx(styles.input, className)}
    {...other}
  />
);

DateInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  other: PropTypes.any,
};

export default DateInput;
