import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import StyledButton from 'components/Common/Buttons/StyledButton';
import Logo from 'components/Common/Logo';
import { ROUTING } from 'config';
import { useThemePrefix } from 'utils/hooks/useThemePrefix';
import { useTheme } from 'modules/shared/theme/hooks/useTheme';

import styles from './styles.module.less';

const MenuHeader = ({ isCollapsed, onTriggerClick, className, type }) => {
  const { theme } = useTheme();
  const themedArrows = useThemePrefix('collapseArrows', theme);

  return (
    <div
      className={clsx(styles.handlerContainer, styles[type], className, {
        [styles.handlerContainerCollapsed]: isCollapsed,
      })}
    >
      <Link
        to={isCollapsed ? '#' : ROUTING.DASHBOARD}
        onClick={isCollapsed ? onTriggerClick : null}
      >
        <Logo isCollapsed={isCollapsed} />
      </Link>

      {!isCollapsed && (
        <StyledButton
          type="icon"
          icon={themedArrows}
          onClick={onTriggerClick}
          className={styles.menuHandlerButton}
        />
      )}
    </div>
  );
};

MenuHeader.propTypes = {
  isCollapsed: PropTypes.bool,
  onTriggerClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
};

MenuHeader.defaultProps = {
  type: 'sidebar',
};

export default MenuHeader;
