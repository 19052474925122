import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useTheme } from 'modules/shared/theme/hooks/useTheme';
import { useBrand } from 'modules/shared/brand/hooks/useBrand';
import getSVGColor from './getSVGColor';

const NoDataSVG = ({ width = 200, height = 100 }) => {
  const { theme } = useTheme();
  const { brand } = useBrand();
  const id = uuidv4();
  const color = getSVGColor(theme, brand);

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 125 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="-0.0994624"
        y="0.0994624"
        width="0.198925"
        height="36.8011"
        transform="matrix(-1 0 0 1 121.747 1)"
        stroke="#202020"
        strokeOpacity="0.2"
        strokeWidth="0.198925"
      />
      <rect
        x="-0.0994624"
        y="0.0994624"
        width="0.198925"
        height="36.8011"
        transform="matrix(-1 0 0 1 97.6375 1)"
        stroke="#202020"
        strokeOpacity="0.2"
        strokeWidth="0.198925"
      />
      <rect
        x="-0.0994624"
        y="0.0994624"
        width="0.198925"
        height="36.8011"
        transform="matrix(-1 0 0 1 73.5281 1)"
        stroke="#202020"
        strokeOpacity="0.2"
        strokeWidth="0.198925"
      />
      <rect
        x="-0.0994624"
        y="0.0994624"
        width="0.198925"
        height="36.8011"
        transform="matrix(-1 0 0 1 49.4183 1)"
        stroke="#202020"
        strokeOpacity="0.2"
        strokeWidth="0.198925"
      />
      <rect
        x="-0.0994624"
        y="0.0994624"
        width="0.198925"
        height="36.8011"
        transform="matrix(-1 0 0 1 25.3084 1)"
        stroke="#202020"
        strokeOpacity="0.2"
        strokeWidth="0.198925"
      />
      <rect
        x="-0.0994624"
        y="0.0994624"
        width="0.198925"
        height="36.8011"
        transform="matrix(-1 0 0 1 1.19902 1)"
        stroke="#202020"
        strokeOpacity="0.2"
        strokeWidth="0.198925"
      />
      <path
        d="M21.5995 14.3026C12.6536 14.9451 9.0804 25.219 1.65039 25.219V38H12.9266H121.536L121.548 11.8558C114.032 13.7711 109.266 -0.227844 101.599 1.08737C93.1705 2.53339 91.7435 18.8197 81.6502 18.8197C73.6998 18.8197 69.5625 12.6356 61.7012 13.7379C52.4781 15.0312 50.8386 31.7234 41.5486 32.5054C33.362 33.1946 29.7952 13.7139 21.5995 14.3026Z"
        fill={`url(#${id})`}
        fillOpacity="0.25"
      />
      <path
        d="M1.65039 25.0533C9.0804 25.0533 12.6536 14.8497 21.5995 14.2116C29.7952 13.627 33.362 32.9744 41.5486 32.2899C50.8386 31.5132 52.4781 14.9352 61.7012 13.6508C69.5625 12.556 73.6998 18.6978 81.6502 18.6978C91.7435 18.6978 93.1705 2.52291 101.599 1.08677C109.266 -0.219446 114.032 13.6838 121.548 11.7815"
        stroke={color}
        strokeWidth="1.5914"
        strokeLinecap="round"
      />
      <circle cx="121.549" cy="12.1399" r="2.78495" fill={color} />
      <defs>
        <linearGradient
          id={id}
          x1="61.5994"
          y1="1"
          x2="61.5994"
          y2="38"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoDataSVG;
