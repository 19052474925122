import React from 'react';
import PropTypes from 'prop-types';

import Person from 'components/Common/Person';
import FormSwitch from 'components/Modals/DetailAndPermissionsModal/FormSwitch';

import styles from './styles.module.less';

const ModalHeader = ({ employee }) => (
  <div className={styles.header}>
    <Person
      type="modal"
      personName={employee.fullName}
      hasAvatar={false}
      email={employee.email}
      className={styles.person}
    />
    <FormSwitch label="Enabled" name="isApproved" />
    <FormSwitch label="Admin Role" name="isAdmin" />
  </div>
);

ModalHeader.propTypes = {
  employee: PropTypes.object,
};

export default ModalHeader;
