import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import produce from 'immer';

import CustomIcon from 'components/Icons';
import ModalContainer from 'components/Common/ModalContainer';
import StyledSwitch from 'components/Common/Buttons/StyledSwitch';

import styles from './styles.module.less';

const DragHandle = SortableHandle(() => (
  <CustomIcon type="drag-handle" className={styles.dragHandle} />
));

const SortableItem = SortableElement(({ enabled, text, onChange }) => (
  <li className={styles.settingsOption}>
    <Row justify="space-between">
      <Row align="middle">
        <DragHandle />
        <span className={styles.settingsOptionText}>{text}</span>
      </Row>
      <StyledSwitch
        defaultChecked={enabled}
        onChange={onChange}
        className={styles.switch}
      />
    </Row>
  </li>
));

SortableItem.propTypes = {
  enabled: PropTypes.bool,
  text: PropTypes.string,
  onChange: PropTypes.func,
};

const SortableComponent = SortableContainer(({ children }) => (
  <ul className={styles.optionsContainer}>{children}</ul>
));

SortableComponent.propTypes = {
  children: PropTypes.node,
};

const TableSettingsModal = ({
  settings,
  isOpen,
  onCancel,
  onFinish,
  returnEnabled,
}) => {
  const [settingsOptions, setSettingsOptions] = useState(settings);
  const [initialSettings] = useState(settings);
  const [forceUpdate, setForceUpdate] = useState(false);

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }) => {
      setSettingsOptions(arrayMove(settingsOptions, oldIndex, newIndex));
    },
    [settingsOptions],
  );

  const onSwitch = useCallback(
    (checked, key) => () => {
      const newSettingsOptions = produce(settingsOptions, (draft) => {
        const settingIndex = draft.findIndex((item) => item.key === key);
        draft[settingIndex].enabled = checked;
      });

      setSettingsOptions(newSettingsOptions);
    },
    [settingsOptions],
  );

  const onDefaultHandler = useCallback(() => {
    setSettingsOptions(initialSettings);
    onFinish(initialSettings);
    onCancel();
    setForceUpdate((prevState) => !prevState);
  }, [initialSettings, onCancel, onFinish]);

  const cancelHandler = useCallback(() => {
    onCancel();
    setSettingsOptions(settings);
    setForceUpdate((prevState) => !prevState);
  }, [onCancel, settings]);

  const saveHandler = useCallback(() => {
    onFinish(
      returnEnabled
        ? settingsOptions.filter(
            ({ enabled }) => enabled === undefined || enabled,
          )
        : settingsOptions,
    );
    onCancel();
  }, [onFinish, returnEnabled, settingsOptions, onCancel]);

  return (
    <ModalContainer
      title="Settings"
      isOpen={isOpen}
      keyboard
      onDefault={onDefaultHandler}
      onCancel={cancelHandler}
      onFinish={saveHandler}
      footerType="defaultSaveCancel"
      className={styles.modalContainer}
    >
      <SortableComponent key={forceUpdate} onSortEnd={onSortEnd} useDragHandle>
        {settingsOptions.map(
          ({ enabled, title, key }, index) =>
            enabled !== undefined && (
              <SortableItem
                key={key}
                index={index}
                text={title}
                enabled={enabled}
                onChange={onSwitch(!enabled, key)}
              />
            ),
        )}
      </SortableComponent>
    </ModalContainer>
  );
};

TableSettingsModal.defaultProps = {
  returnEnabled: true,
};

TableSettingsModal.propTypes = {
  settings: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  returnEnabled: PropTypes.bool,
};

export default TableSettingsModal;
