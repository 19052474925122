import { isDateRangeMoreThanYear } from './isDateRangeMoreThanYear';
import { SplitType } from '../constants/splitType';

export function mapDateRangeToSplitType(dateRange) {
  const [dateFrom, dateTo] = dateRange;

  return isDateRangeMoreThanYear(dateFrom, dateTo)
    ? SplitType.MONTHLY
    : SplitType.DAILY;
}
