import React from 'react';
import PropTypes from 'prop-types';

import {
  QueryClient,
  QueryClientProvider as QueryClientProviderBase,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export const QUERY_CLIENT_CONFIG = {
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
};

export const QueryClientProvider = ({ children }) => (
  <QueryClientProviderBase client={new QueryClient(QUERY_CLIENT_CONFIG)}>
    {children}
    {process.env.NODE_ENV === 'development' && (
      <ReactQueryDevtools position="bottom-right" />
    )}
  </QueryClientProviderBase>
);

QueryClientProvider.propTypes = {
  children: PropTypes.node,
};
