import { HOUR_MIN_SEC_DATE_FORMAT } from 'utils/timeConstants';
import { TABLE_DATA_INDEX, TABLE_TITLE, tableCell } from 'utils/tableSchema';
import { FORM_INPUT_TYPES, NUMBER_FORMATS, TITLES } from 'utils/constants';
import { ACCOUNT_PLATFORM_OPTIONS } from 'constants/accountPlatformOptions';

export const CLIENTS_TRADES_COLUMNS = [
  {
    title: TABLE_TITLE.LOGIN,
    dataIndex: TABLE_DATA_INDEX.LOGIN,
    key: TABLE_DATA_INDEX.LOGIN,
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.ACCOUNT_TYPE,
    dataIndex: TABLE_DATA_INDEX.ACCOUNT_TYPE,
    key: TABLE_DATA_INDEX.ACCOUNT_TYPE,
  },
  {
    title: TABLE_TITLE.PLATFORM,
    dataIndex: TABLE_DATA_INDEX.ACCOUNT_PLATFORM,
    key: TABLE_DATA_INDEX.ACCOUNT_PLATFORM,
    render: tableCell.toUpper(),
  },
  {
    title: TABLE_TITLE.NAME,
    dataIndex: TABLE_DATA_INDEX.ACCOUNT_NAME,
    key: TABLE_DATA_INDEX.ACCOUNT_NAME,
  },
  {
    title: TABLE_TITLE.COUNTRY,
    dataIndex: TABLE_DATA_INDEX.COUNTRY,
    key: TABLE_DATA_INDEX.COUNTRY,
    render: tableCell.flag(),
  },
  {
    title: TABLE_TITLE.ORDER_NUMBER,
    dataIndex: TABLE_DATA_INDEX.TICKET,
    key: TABLE_DATA_INDEX.TICKET,
  },
  {
    title: TABLE_TITLE.OPEN_TIME,
    key: TABLE_DATA_INDEX.OPEN_DATE,
    dataIndex: TABLE_DATA_INDEX.OPEN_DATE,
    render: tableCell.date(HOUR_MIN_SEC_DATE_FORMAT),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.CLOSE_TIME,
    key: TABLE_DATA_INDEX.CLOSE_DATE,
    dataIndex: TABLE_DATA_INDEX.CLOSE_DATE,
    render: tableCell.date(HOUR_MIN_SEC_DATE_FORMAT),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
  },
  {
    title: TABLE_TITLE.SIZE,
    key: TABLE_DATA_INDEX.VOLUME,
    dataIndex: TABLE_DATA_INDEX.VOLUME,
    render: tableCell.formatted({}),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.TYPE,
    key: TABLE_DATA_INDEX.DIRECTION,
    dataIndex: TABLE_DATA_INDEX.DIRECTION,
    render: tableCell.background({
      icon: '',
    }),
  },
  {
    title: TABLE_TITLE.SYMBOL,
    key: TABLE_DATA_INDEX.SYMBOL,
    dataIndex: TABLE_DATA_INDEX.SYMBOL,
  },
  {
    title: TABLE_TITLE.OPEN_PRICE,
    key: TABLE_DATA_INDEX.OPEN_PRICE,
    dataIndex: TABLE_DATA_INDEX.OPEN_PRICE,
    render: tableCell.formatted({
      style: NUMBER_FORMATS.currency,
    }),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.CLOSE_PRICE,
    key: TABLE_DATA_INDEX.CLOSE_PRICE,
    dataIndex: TABLE_DATA_INDEX.CLOSE_PRICE,
    render: tableCell.formatted({
      style: NUMBER_FORMATS.currency,
    }),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.COMMISSION_EARNED,
    key: TABLE_DATA_INDEX.COMMISSION,
    dataIndex: TABLE_DATA_INDEX.COMMISSION,
    render: tableCell.formatted({
      style: NUMBER_FORMATS.currency,
    }),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.PROFIT,
    key: TABLE_DATA_INDEX.PROFIT,
    dataIndex: TABLE_DATA_INDEX.PROFIT,
    render: tableCell.formatted({
      style: NUMBER_FORMATS.currency,
      minimumFractionDigits: 1,
    }),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.SWAP,
    key: TABLE_DATA_INDEX.SWAP,
    dataIndex: TABLE_DATA_INDEX.SWAP,
    render: tableCell.formatted({ style: NUMBER_FORMATS.currency }),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
];

export const PARTNERS_TRADES_COLUMNS = [
  {
    title: TABLE_TITLE.IB_ID,
    dataIndex: TABLE_DATA_INDEX.USER_ID,
    key: TABLE_DATA_INDEX.USER_ID,
  },
  {
    title: TABLE_TITLE.LOGIN,
    dataIndex: TABLE_DATA_INDEX.LOGIN,
    key: TABLE_DATA_INDEX.LOGIN,
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.ACCOUNT_TYPE,
    dataIndex: TABLE_DATA_INDEX.ACCOUNT_TYPE,
    key: TABLE_DATA_INDEX.ACCOUNT_TYPE,
  },
  {
    title: TABLE_TITLE.PLATFORM,
    dataIndex: TABLE_DATA_INDEX.ACCOUNT_PLATFORM,
    key: TABLE_DATA_INDEX.ACCOUNT_PLATFORM,
    render: tableCell.toUpper(),
  },
  {
    title: TABLE_TITLE.COUNTRY,
    dataIndex: TABLE_DATA_INDEX.COUNTRY,
    key: TABLE_DATA_INDEX.COUNTRY,
    render: tableCell.flag(),
  },
  {
    title: TABLE_TITLE.ORDER_NUMBER,
    dataIndex: TABLE_DATA_INDEX.TICKET,
    key: TABLE_DATA_INDEX.TICKET,
  },
  {
    title: TABLE_TITLE.OPEN_TIME,
    key: TABLE_DATA_INDEX.OPEN_DATE,
    dataIndex: TABLE_DATA_INDEX.OPEN_DATE,
    render: tableCell.date(HOUR_MIN_SEC_DATE_FORMAT),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.CLOSE_TIME,
    key: TABLE_DATA_INDEX.CLOSE_DATE,
    dataIndex: TABLE_DATA_INDEX.CLOSE_DATE,
    render: tableCell.date(HOUR_MIN_SEC_DATE_FORMAT),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
    defaultSortOrder: 'descend',
  },
  {
    title: TABLE_TITLE.SIZE,
    key: TABLE_DATA_INDEX.VOLUME,
    dataIndex: TABLE_DATA_INDEX.VOLUME,
    render: tableCell.formatted({}),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.TYPE,
    key: TABLE_DATA_INDEX.DIRECTION,
    dataIndex: TABLE_DATA_INDEX.DIRECTION,
    render: tableCell.background({
      icon: '',
    }),
  },
  {
    title: TABLE_TITLE.SYMBOL,
    key: TABLE_DATA_INDEX.SYMBOL,
    dataIndex: TABLE_DATA_INDEX.SYMBOL,
  },
  {
    title: TABLE_TITLE.OPEN_PRICE,
    key: TABLE_DATA_INDEX.OPEN_PRICE,
    dataIndex: TABLE_DATA_INDEX.OPEN_PRICE,
    render: tableCell.formatted({
      style: NUMBER_FORMATS.currency,
    }),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.CLOSE_PRICE,
    key: TABLE_DATA_INDEX.CLOSE_PRICE,
    dataIndex: TABLE_DATA_INDEX.CLOSE_PRICE,
    render: tableCell.formatted({
      style: NUMBER_FORMATS.currency,
    }),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.COMMISSION_EARNED,
    key: TABLE_DATA_INDEX.COMMISSION,
    dataIndex: TABLE_DATA_INDEX.COMMISSION,
    render: tableCell.formatted({
      style: NUMBER_FORMATS.currency,
    }),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.PROFIT,
    key: TABLE_DATA_INDEX.PROFIT,
    dataIndex: TABLE_DATA_INDEX.PROFIT,
    render: tableCell.formatted({
      style: NUMBER_FORMATS.currency,
      minimumFractionDigits: 1,
    }),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.SWAP,
    key: TABLE_DATA_INDEX.SWAP,
    dataIndex: TABLE_DATA_INDEX.SWAP,
    render: tableCell.formatted({ style: NUMBER_FORMATS.currency }),
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
];

export const TRADES_FILTER = [
  {
    type: FORM_INPUT_TYPES.SELECT,
    label: 'County',
    placeholder: TITLES.ALL_COUNTRIES,
    name: 'country',
    options: [{ value: '', label: TITLES.ALL_COUNTRIES }],
    mode: 'multiple',
  },
  {
    type: FORM_INPUT_TYPES.SELECT,
    label: TABLE_TITLE.PLATFORM,
    placeholder: TITLES.ALL_PLATFORMS,
    name: TABLE_DATA_INDEX.ACCOUNT_PLATFORM,
    options: ACCOUNT_PLATFORM_OPTIONS,
    mode: 'multiple',
  },
  {
    type: FORM_INPUT_TYPES.SELECT,
    label: 'Symbol',
    placeholder: TITLES.ALL_SYMBOLS,
    name: 'symbol',
    allowClear: true,
    mode: 'multiple',
  },
  {
    type: FORM_INPUT_TYPES.CHECKBOX_GROUP,
    label: 'Direction',
    name: 'direction',
    value: ['Buy', 'Sell'],
    options: [
      { value: 'Buy', label: 'Buy' },
      { value: 'Sell', label: 'Sell' },
    ],
  },
  {
    type: FORM_INPUT_TYPES.SLIDER,
    label: 'Price',
    name: 'closePrice',
    numberFormat: NUMBER_FORMATS.currency,
  },
  {
    type: FORM_INPUT_TYPES.SLIDER,
    label: 'Profit',
    name: 'profit',
    numberFormat: NUMBER_FORMATS.currency,
  },
  {
    type: FORM_INPUT_TYPES.SLIDER,
    label: 'Commission',
    name: 'commission',
    numberFormat: NUMBER_FORMATS.currency,
  },
];

export const CLIENTS_TRADES_FILTER = [
  {
    label: TABLE_TITLE.LOGIN,
    placeholder: TITLES.ALL_ACCOUNT_LOGINS,
    name: TABLE_DATA_INDEX.LOGIN,
  },
  {
    label: TABLE_TITLE.NAME,
    placeholder: TITLES.ALL_NAMES,
    name: TABLE_DATA_INDEX.ACCOUNT_NAME,
  },
  {
    type: FORM_INPUT_TYPES.SELECT,
    label: 'County',
    placeholder: TITLES.ALL_COUNTRIES,
    name: 'country',
    options: [{ value: '', label: TITLES.ALL_COUNTRIES }],
    mode: 'multiple',
  },
  {
    type: FORM_INPUT_TYPES.SELECT,
    label: TABLE_TITLE.PLATFORM,
    placeholder: TITLES.ALL_PLATFORMS,
    name: TABLE_DATA_INDEX.ACCOUNT_PLATFORM,
    options: ACCOUNT_PLATFORM_OPTIONS,
    mode: 'multiple',
  },
  {
    type: FORM_INPUT_TYPES.SELECT,
    label: 'Symbol',
    placeholder: TITLES.ALL_SYMBOLS,
    name: 'symbol',
    allowClear: true,
    mode: 'multiple',
  },
  {
    type: FORM_INPUT_TYPES.CHECKBOX_GROUP,
    label: 'Direction',
    name: 'direction',
    value: ['Buy', 'Sell'],
    options: [
      { value: 'Buy', label: 'Buy' },
      { value: 'Sell', label: 'Sell' },
    ],
  },
  {
    type: FORM_INPUT_TYPES.SLIDER,
    label: 'Price',
    name: 'closePrice',
    numberFormat: NUMBER_FORMATS.currency,
  },
  {
    type: FORM_INPUT_TYPES.SLIDER,
    label: 'Profit',
    name: 'profit',
    numberFormat: NUMBER_FORMATS.currency,
  },
  {
    type: FORM_INPUT_TYPES.SLIDER,
    label: 'Commission',
    name: 'commission',
    numberFormat: NUMBER_FORMATS.currency,
  },
];

export const PARTNERS_TRADES_FILTER = [
  {
    label: TABLE_TITLE.LOGIN,
    placeholder: TITLES.ALL_ACCOUNT_LOGINS,
    name: TABLE_DATA_INDEX.LOGIN,
  },
  {
    type: FORM_INPUT_TYPES.SELECT,
    label: 'County',
    placeholder: TITLES.ALL_COUNTRIES,
    name: 'country',
    options: [{ value: '', label: TITLES.ALL_COUNTRIES }],
    mode: 'multiple',
  },
  {
    type: FORM_INPUT_TYPES.SELECT,
    label: TABLE_TITLE.PLATFORM,
    placeholder: TITLES.ALL_PLATFORMS,
    name: TABLE_DATA_INDEX.ACCOUNT_PLATFORM,
    options: ACCOUNT_PLATFORM_OPTIONS,
    mode: 'multiple',
  },
  {
    type: FORM_INPUT_TYPES.SELECT,
    label: 'Symbol',
    placeholder: TITLES.ALL_SYMBOLS,
    name: 'symbol',
    allowClear: true,
    mode: 'multiple',
  },
  {
    type: FORM_INPUT_TYPES.CHECKBOX_GROUP,
    label: 'Direction',
    name: 'direction',
    value: ['Buy', 'Sell'],
    options: [
      { value: 'Buy', label: 'Buy' },
      { value: 'Sell', label: 'Sell' },
    ],
  },
  {
    type: FORM_INPUT_TYPES.SLIDER,
    label: 'Price',
    name: 'closePrice',
    numberFormat: NUMBER_FORMATS.currency,
  },
  {
    type: FORM_INPUT_TYPES.SLIDER,
    label: 'Profit',
    name: 'profit',
    numberFormat: NUMBER_FORMATS.currency,
  },
  {
    type: FORM_INPUT_TYPES.SLIDER,
    label: 'Commission',
    name: TABLE_DATA_INDEX.COMMISSION,
    numberFormat: NUMBER_FORMATS.currency,
  },
];
