import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.less';

const TableColProgressBar = ({ value, max, title }) => {
  const percent = useMemo(() => (max ? (value / max) * 100 : 0), [max, value]);
  const { colorLevel, colorCircle, colorProgressBg } = useMemo(() => {
    let colorLevel = '#e1e1e1';
    let colorCircle = '#c1c1c1';
    let colorProgressBg = '#e1e1e1';

    if (percent >= 0 && percent <= 25 && max) {
      colorLevel = '#FE6B6B';
      colorProgressBg = '#FFDEDE';
      colorCircle = 'rgba(254, 107, 107, 0.3))';
    }

    if (percent > 25 && percent < 100) {
      colorLevel = '#FF9100';
      colorProgressBg = '#FFE7C6';
      colorCircle = 'rgba(255, 145, 0, 0.3)';
    }

    if (percent === 100) {
      colorLevel = '#51B56D';
      colorProgressBg = '#51B56D';
      colorCircle = 'rgba(81, 181, 109, 0.3)';
    }

    return {
      colorLevel,
      colorCircle,
      colorProgressBg,
    };
  }, [max, percent]);

  return (
    <>
      <p className={styles.title}>
        {value}/{max} {title}
      </p>

      <div
        className={styles.progressBg}
        style={{
          background: colorProgressBg,
        }}
      >
        <div
          className={styles.level}
          style={{
            width: `${percent}%`,
            background: colorLevel,
          }}
        />
      </div>

      <div
        className={styles.circle}
        style={{
          marginLeft: `calc(${percent}% - 5px)`,
          background: colorLevel,
          boxShadow: `0px 1px 4px 1px ${colorCircle}`,
        }}
      />
    </>
  );
};

TableColProgressBar.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
};

export default TableColProgressBar;
