import { takeEvery, all, put } from 'redux-saga/effects';
import { api } from 'api';

import actions from 'redux/actions';

function* callCreateSettings({ payload }) {
  try {
    const response = yield api.createCommissionSettings(payload);

    yield put(actions.createSettings.success(response.data));
  } catch (error) {
    yield put(actions.createSettings.failure(error));
  }
}

function* callLoadSettings({ payload }) {
  try {
    const response = yield api.getCommissionSettings(payload);

    yield put(actions.loadSettings.success(response.data));
  } catch (error) {
    yield put(actions.loadSettings.failure(error));
  }
}

export default function* commissionSettingsSaga() {
  yield all([
    yield takeEvery(actions.createSettings.request, callCreateSettings),
    yield takeEvery(actions.loadSettings.request, callLoadSettings),
  ]);
}
