import { Brand } from 'constants/brand';
import { api } from 'api';

export const FEES_SYMBOLS_OVERVIEW_BY_USER_ID_REQUEST_CONFIG = {
  [Brand.FM]: (userId, payload) =>
    api.getTradesSymbolsOverview(userId, payload),
  [Brand.GP]: (userId, payload) =>
    api.getTradesSymbolsOverview(userId, payload),
  [Brand.DAY1]: (userId, payload) =>
    api.getFeesSymbolsOverview(userId, payload),
};
