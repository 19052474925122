import { ROUTING } from 'config';

const HEADER_TITLES = [
  {
    path: 'dashboard',
    title: 'Dashboard',
  },
  {
    path: 'clients',
    title: 'Clients',
  },
  {
    path: 'commission',
    title: 'My Commission',
  },
  {
    path: 'marketing-tools',
    title: 'My Marketing tools',
  },
  {
    path: 'settings',
    title: 'Profile Settings',
  },
  {
    path: 'partners',
    title: 'Partners',
    excludes: ['partner-profile'],
  },
  {
    path: 'latest-partners-reports',
    title: 'Reports',
    excludes: ['partner-profile'],
  },
  {
    path: 'registration-reports',
    title: 'Reports',
    excludes: ['partner-profile'],
  },
  {
    path: 'trades-reports',
    title: 'Reports',
  },
  {
    path: 'fees-report',
    title: 'Reports',
  },
  {
    path: 'commission-reports',
    title: 'Reports',
    excludes: ['partner-profile'],
  },
  {
    path: 'cpa-commission-reports',
    title: 'Reports',
    excludes: ['partner-profile'],
  },
  {
    path: 'commission-re-run-reports',
    title: 'Reports',
    excludes: ['partner-profile'],
  },
  {
    path: 'payment-reports',
    title: 'Reports',
  },
  {
    path: 'balance-reports',
    title: 'Reports',
  },
  {
    path: ROUTING.ADJUSTMENTS_REPORT.slice(1),
    title: 'Reports',
  },
  {
    path: 'staff-management',
    title: 'Staff Management',
  },
  {
    path: 'withdrawals',
    title: 'Withdrawals',
  },
];

export const getSectionTitle = (section, sectionChild) => {
  const currentPage = HEADER_TITLES.find(
    ({ path, excludes = [] }) =>
      path === section &&
      (!excludes.length || excludes.every((child) => sectionChild !== child)),
  );

  return currentPage?.title || '';
};
