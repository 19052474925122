import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import Actions from '../actions';

export const TILE_CHART_SERIES_ACCESS_KEYS = {
  ACTIVE_ACCOUNTS: 'active-accounts',
  COMMISSIONS: 'commissions',
  DEPOSITS: 'deposits',
  WITHDRAWALS: 'withdrawals',
  REGISTRATIONS: 'registrations',
  OPEN_ACCOUNTS: 'open-accounts',
  FUNDED_ACCOUNTS: 'funded-accounts',
  NET_DEPOSITS: 'net-deposits',
};

const BROKER_OVERVIEW = [
  {
    enabled: true,
    isLoading: true,
    key: 'registrations',
    type: TILE_CHART_SERIES_ACCESS_KEYS.REGISTRATIONS,
    title: 'registrations',
  },
  {
    enabled: true,
    isLoading: true,
    key: 'open-accounts',
    type: TILE_CHART_SERIES_ACCESS_KEYS.OPEN_ACCOUNTS,
    title: 'opened accounts',
  },
  {
    enabled: true,
    isLoading: true,
    key: 'funded-accounts',
    type: TILE_CHART_SERIES_ACCESS_KEYS.FUNDED_ACCOUNTS,
    title: 'funded accounts',
  },
  {
    enabled: true,
    isLoading: true,
    key: 'active-accounts',
    type: TILE_CHART_SERIES_ACCESS_KEYS.ACTIVE_ACCOUNTS,
    title: 'active accounts',
  },
  {
    enabled: true,
    isLoading: true,
    key: 'net-deposits',
    type: TILE_CHART_SERIES_ACCESS_KEYS.NET_DEPOSITS,
    title: 'Net Deposits',
    valueType: 'currency',
  },
  {
    enabled: true,
    isLoading: true,
    key: 'commissions',
    type: TILE_CHART_SERIES_ACCESS_KEYS.COMMISSIONS,
    title: 'Commissions Earned',
    valueType: 'currency',
  },
];

const STAFF_OVERVIEW = [
  {
    enabled: true,
    isLoading: true,
    key: 'registrations',
    type: TILE_CHART_SERIES_ACCESS_KEYS.REGISTRATIONS,
    title: 'Leads',
    icon: 'active-accounts',
    link: './registration-reports',
  },
  {
    enabled: true,
    isLoading: true,
    key: TILE_CHART_SERIES_ACCESS_KEYS.OPEN_ACCOUNTS,
    type: 'open-accounts',
    title: 'Opened Accounts',
    link: '#',
  },
  {
    enabled: true,
    isLoading: true,
    key: 'commissions',
    type: TILE_CHART_SERIES_ACCESS_KEYS.COMMISSIONS,
    title: 'Commissions Paid',
    valueType: 'currency',
    link: './commission-reports',
  },
  {
    enabled: true,
    isLoading: true,
    key: 'deposits',
    type: TILE_CHART_SERIES_ACCESS_KEYS.DEPOSITS,
    title: 'Deposits',
    link: '#',
    valueType: 'currency',
  },
  {
    enabled: true,
    isLoading: true,
    key: 'withdrawals',
    type: TILE_CHART_SERIES_ACCESS_KEYS.WITHDRAWALS,
    title: 'Withdrawals',
    valueType: 'currency',
    link: './payment-reports',
  },
];

const initialState = {
  latestClients: {
    table: {
      dataSource: [],
      total: 0,
      isLoading: true,
    },
  },
  overview: [],
};

export default createReducer(initialState)
  .handleAction(
    [
      Actions.loadDashboardLatestClients.request,
      Actions.dashboardPatchPartner.request,
      Actions.dashboardFindManagers.request,
    ],
    produce((state) => {
      state.latestClients.table.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadDashboardLatestClients.success,
    produce((state, { payload }) => {
      state.latestClients.table.isLoading = false;
      state.latestClients.table.dataSource = payload.rows;
      state.latestClients.table.total = payload.count;
    }),
  )
  .handleAction(
    Actions.loadDashboardLatestClients.failure,
    produce((state) => {
      state.latestClients.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadDashboardOverviewType.request,
    produce((state) => {
      state.overview = state.overview.map((item) => ({
        ...item,
        isLoading: true,
      }));
    }),
  )
  .handleAction(
    Actions.loadDashboardOverviewType.success,
    produce((state, { payload }) => {
      const item = state.overview.find(({ type }) => type === payload.type);
      item.isLoading = false;
      item.totalCount = payload.value;
      item.percentChanges = payload.percent;
    }),
  )
  .handleAction(
    [Actions.dashboardPatchPartner.success],
    produce((state, { payload }) => {
      const partners = state.latestClients.table.dataSource.map((partner) => {
        const findIndex = payload.findIndex((item) => item.id === partner.id);

        if (findIndex !== -1) {
          return {
            ...partner,
            ...payload[findIndex],
            groupId: payload[findIndex].groupId || null,
          };
        }

        return partner;
      });

      state.latestClients.table.dataSource = partners;
      state.latestClients.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.updateDashboardOverview,
    produce((state, { payload }) => {
      state.overview = payload;
    }),
  )
  .handleAction(
    Actions.setDashboardType,
    produce((state, { payload }) => {
      state.overview = payload ? STAFF_OVERVIEW : BROKER_OVERVIEW;
    }),
  )
  .handleAction(
    [
      Actions.logOutWithPartner.success,
      Actions.logOut.success,
      Actions.logInWithPartner.success,
    ],
    () => initialState,
  );
