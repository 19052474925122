import React, { useCallback, useEffect } from 'react';
import { Form, Row, Image } from 'antd';
import PropTypes from 'prop-types';

import StyledSwitch from 'components/Common/Buttons/StyledSwitch';
import StyledButton from 'components/Common/Buttons/StyledButton';
import FormInput from 'components/Common/Input/FormInput';
import CustomIcon from 'components/Icons';
import Spinner from 'components/Spinner';
import { errorAuthHandler } from 'utils/messages';
import fallbackImage from 'assets/fallback.png';
import styles from './styles.module.less';

export const TfaForm = ({
  isTfaLoading,
  isTfaEnabled,
  onTfaEnable,
  onTfaDisable,
  qrCode,
  isQrCodeLoading,
  onQrCodeClick,
  error,
  description = 'Use the button on the right to change Two-Factor Authentication.',
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ checked: isTfaEnabled });
  }, [form, isTfaEnabled]);

  useEffect(() => {
    errorAuthHandler(form, error);
  }, [error, form]);

  const onValuesChange = useCallback(
    (values) => {
      if (!values.checked && isTfaEnabled) {
        onTfaDisable(values);
      }
    },
    [isTfaEnabled, onTfaDisable],
  );

  return (
    <div className={styles.notificationContainer}>
      <Spinner
        spinning={isTfaLoading}
        wrapperClassName={styles.notificationContainerSpinnerWrapper}
      >
        <Form
          form={form}
          onFinish={onTfaEnable}
          initialValues={{ checked: isTfaEnabled }}
          onValuesChange={onValuesChange}
          colon={false}
          className={styles.form}
          // To use explicit form submission
          component={'div'}
        >
          <Row justify="space-between">
            <h2 className={styles.title}>Two-Factor Authentication</h2>
            {onTfaDisable && (
              <Form.Item
                name="checked"
                valuePropName="checked"
                className={styles.formItem}
              >
                <StyledSwitch checked={isTfaEnabled} disabled={!isTfaEnabled} />
              </Form.Item>
            )}
          </Row>
          {description && <p className={styles.description}>{description}</p>}
          {!isTfaEnabled && (
            <>
              <Row
                justify="space-between"
                wrap={false}
                className={styles.instructionRow}
              >
                <ol className={styles.instructionContainer}>
                  <li className={styles.instruction}>
                    Download the Google Authenticator app on your iOS or Android
                    device.
                  </li>
                  <li className={styles.instruction}>
                    Using the app, scan this barcode:
                  </li>
                  <li className={styles.instruction}>
                    Once this code has been scanned, enter the 6-digit code
                    generated by the app here:
                  </li>
                </ol>
                <Spinner
                  spinning={isQrCodeLoading}
                  wrapperClassName={styles.qrCodeSpinnerWrapper}
                  className={styles.qrCodeSpinner}
                >
                  {!qrCode ? (
                    <span className={styles.qrCode} onClick={onQrCodeClick}>
                      <CustomIcon type="qrcode" className={styles.qrCodeIcon} />
                      <span className={styles.qrCodeText}>View QR code</span>
                    </span>
                  ) : (
                    <Image
                      width={127}
                      height={127}
                      src={qrCode}
                      fallback={fallbackImage}
                    />
                  )}
                </Spinner>
              </Row>
              <Row className={styles.codeContainer}>
                <FormInput
                  type="code"
                  label="Code"
                  placeholder="000000"
                  maxLength={6}
                  className={styles.code}
                />
                <StyledButton
                  type="primary"
                  text="Confirm"
                  className={styles.submitButton}
                  // To use explicit form submission
                  onClick={() => {
                    form.submit();
                  }}
                />
              </Row>
            </>
          )}
        </Form>
      </Spinner>
    </div>
  );
};

TfaForm.propTypes = {
  isTfaLoading: PropTypes.bool,
  isTfaEnabled: PropTypes.bool,
  onTfaEnable: PropTypes.func,
  onTfaDisable: PropTypes.func,
  qrCode: PropTypes.string,
  isQrCodeLoading: PropTypes.bool,
  onQrCodeClick: PropTypes.func,
  error: PropTypes.string,
  description: PropTypes.string,
};
