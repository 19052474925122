import React from 'react';
import FormInput from 'components/Common/Input/FormInput';
import { usePartnersOptions } from 'utils/hooks/usePartnersOptions';
import { FORM_INPUT_TYPES } from 'utils/constants';
import styles from 'components/Common/Input/DropdownSelect/styles.module.less';

const PartnerIdInput = () => {
  const { partnersOptions } = usePartnersOptions();

  return (
    <FormInput
      inputClassName={styles.white}
      name="partnerId"
      label="Partner IB ID"
      type={FORM_INPUT_TYPES.SELECT}
      options={partnersOptions}
      isRequired={false}
      showSearch={true}
      allowClear={true}
    />
  );
};

export default PartnerIdInput;
