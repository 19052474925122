import { createSelector } from 'reselect';

export const getClientTradesTable = ({ clientInfo }) => clientInfo.trades.table;

export const getClientTradesFilters = ({ clientInfo }) =>
  clientInfo.trades.filterLimits;

export const getClientPaymentsTable = ({ clientInfo }) =>
  clientInfo.payments.table;

export const getClientPaymentsFilters = ({ clientInfo }) =>
  clientInfo.payments.filterLimits;

export const getClientTradesOverview = ({ clientInfo }) =>
  clientInfo.trades.overview;

export const getTotalCommissionsOverview = ({ clientInfo }) =>
  clientInfo.trades.overview;

export const getClientSymbolsOverview = createSelector(
  getClientTradesOverview,
  ({ volume }) => {
    let enabledSeries = volume.series.filter(({ enabled }) => enabled);
    const others = volume.series
      .filter(({ enabled }) => !enabled)
      .reduce((acc, { value }) => acc + value, 0);

    if (others) {
      enabledSeries.push({
        key: 'Others',
        title: 'Others',
        value: Number(others.toFixed(2)),
      });
    }

    if (enabledSeries.length === 1 && enabledSeries[0].title === 'Other') {
      enabledSeries = [
        {
          key: 'EURUSD',
          title: 'EURUSD',
          value: 0,
        },
        {
          key: 'AUDUSD',
          title: 'AUDUSD',
          value: 0,
        },
        {
          key: 'GBPUSD',
          title: 'GBPUSD',
          value: 0,
        },
        {
          key: 'Others',
          title: 'Others',
          value: 0,
        },
      ];
    }

    return {
      enabledSeries,
      series: volume.series,
      ...volume,
    };
  },
);

export const getClientCommissionOverview = createSelector(
  getClientTradesOverview,
  ({ volumeTraded }) => volumeTraded,
);

export const getClientTotalCommissionOverview = createSelector(
  getTotalCommissionsOverview,
  ({ totalCommissions }) => totalCommissions,
);

export const getPartnerEarnings = ({ clientInfo }) => clientInfo.earnings.table;

export const getPartnerEarningsFilters = ({ clientInfo }) =>
  clientInfo.earnings.filterLimits;
