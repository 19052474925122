import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { getErrorHandler } from '../getErrorHandler';
import { api } from 'api';
import { mapDateRange } from '../mapDateRange';
import { ReferralSource } from 'constants/referalSource';

const createCommissionStats = (commissionData) => ({
  totalCount: commissionData.value,
  percentChanges: commissionData.growth,
  series: commissionData.series.map(({ value, date }) => ({
    x: date,
    y: value,
  })),
  isLoading: false,
});

export const usePartnerFeesCommissionsTimeSeries = ({
  userId,
  dateRange,
  splitType,
}) => {
  const { refetch: loadCommissionStats, data: commissionStats = {} } = useQuery(
    {
      queryKey: queryKeys.totalCommissionTimeSeries(userId, dateRange),
      queryFn: async () => {
        const totalCommissionsRequest =
          api.getUserFeesTotalCommissionsTimeSeries(userId, {
            splitType,
            source: ReferralSource.SUB,
            ...mapDateRange(dateRange),
          });

        const earnedCommissionsRequest = api.getUserCommissionsTimeSeries(
          userId,
          {
            splitType,
            ...mapDateRange(dateRange),
            classification: 'EARNED_FROM_SUB_IBS',
          },
        );

        const [total, earned] = await Promise.all([
          totalCommissionsRequest,
          earnedCommissionsRequest,
        ]);

        return {
          totalCommissions: createCommissionStats(total.data),
          earnedCommissions: createCommissionStats(earned.data),
        };
      },
      onError: getErrorHandler('Unable to load total commission time series'),
      enabled: false,
    },
  );

  return {
    loadCommissionStats,
    totalCommissions: commissionStats.totalCommissions,
    earnedCommissions: commissionStats.earnedCommissions,
  };
};
