import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';
import { FORM_INPUT_TYPES } from './constants';

const LOCATION_VALIDATOR = {
  type: 'string',
  pattern: /^([0-9a-z\s]+[-.,\s]?)*[0-9a-z\s]+$/i,
};

const WITHDRAWAL_MESSAGE = {
  accountName: 'Please enter your account name',
  bsbCode: 'Please enter your BSB Number',
  accountNumber: 'Please enter your account number',
  withdrawAmount: ' ',
};

const getNameValidationSchema = (name, withNumber = false) =>
  yup
    .string()
    .trim()
    .min(2, `${name} must be at least \${min\} characters`)
    .max(30)
    .test({
      name: 'No numbers',
      test: (value) => withNumber || !/[0-9]/.test(value),
      message: `Please enter a valid ${name} with no numbers`,
    })
    .matches(
      withNumber ? /^([a-z]+[-\s]?[a-z0-9]+)*$/i : /^([a-z]+[-\s]?[a-z]+)*$/i,
      `Please enter a valid ${name}`,
    );

const validationTypes = {
  [FORM_INPUT_TYPES.EMAIL]: () => ({
    validator(_, email) {
      return yup.string().email('Please enter valid email').validate(email);
    },
  }),
  [FORM_INPUT_TYPES.PASSWORD]: {
    type: 'string',
    pattern: /^[\d\w\S]{8,}$/i,
    message: 'Please enter valid password',
  },
  [FORM_INPUT_TYPES.NEW_PASSWORD]: {
    type: 'string',
    pattern: /^[\d\w\S]{8,}$/i,
    message: 'Please enter valid password',
  },
  [FORM_INPUT_TYPES.CONFIRM_PASSWORD]: ({ getFieldValue }) => ({
    validator(_, value) {
      if (
        !value ||
        getFieldValue(FORM_INPUT_TYPES.PASSWORD) === value ||
        getFieldValue(FORM_INPUT_TYPES.NEW_PASSWORD) === value
      ) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error('The two passwords that you entered do not match'),
      );
    },
  }),
  [FORM_INPUT_TYPES.PHONE_NUMBER]: () => ({
    validator(_, value) {
      if (parsePhoneNumber(value).isValid()) {
        return Promise.resolve();
      }

      return Promise.reject(new Error('Please enter valid Phone number'));
    },
  }),
  name: {
    validator: (_, value) => getNameValidationSchema('Name').validate(value),
  },
  firstName: {
    validator: (_, value) =>
      getNameValidationSchema('First Name').validate(value),
  },
  lastName: {
    validator: (_, value) =>
      getNameValidationSchema('Last Name').validate(value),
  },
  zipCode: {
    type: 'string',
    pattern: /^([0-9a-z]+[-.,\s]?[0-9a-z]+)*$/i,
    message: 'Please enter valid Zip code',
  },
  amount: () => ({
    validator(_, value) {
      if (!Number(value)) {
        return Promise.reject(new Error('Invalid values'));
      } else if (value >= 0 && value <= 100) {
        return Promise.resolve();
      }

      return Promise.reject(new Error('The value must be between 0 and 100'));
    },
  }),
  id: {
    type: 'string',
    min: 3,
    max: 8,
    pattern: /^\d+$/,
    message: 'Please enter valid ID',
  },
  skype: {
    type: 'string',
    pattern: /^([0-9a-z]+[_,.:-]?[0-9a-z]+)*$/i,
    message: 'Skype name can consist of numbers and letters',
  },
  address: {
    ...LOCATION_VALIDATOR,
    message: 'Please enter valid Apartment',
  },
  city: {
    ...LOCATION_VALIDATOR,
    message: 'Please enter valid City',
  },
  country: {
    ...LOCATION_VALIDATOR,
    message: 'Please enter valid Country',
  },
  state: {
    ...LOCATION_VALIDATOR,
    message: 'Please enter valid State',
  },
  [FORM_INPUT_TYPES.DATE]: () => ({
    validator() {
      return Promise.resolve();
    },
  }),
  bankName: {
    validator: (_, value) =>
      getNameValidationSchema('Bank Name', true).validate(value),
  },
  bankAddress: {
    type: 'string',
    min: 7,
    message: 'Please enter a valid Bank Address',
  },
  accountName: {
    type: 'string',
    min: 1,
    message: 'Please enter a valid Beneficiary Account Name',
  },
  accountNumber: {
    type: 'string',
    min: 1,
    message: 'Please enter a valid Account Number',
  },
  accountAddress: {
    type: 'string',
    min: 7,
    message: 'Please enter a valid Beneficiary Address',
  },
  withdrawAmount: {
    validator: (_, value) => {
      const num = Number(value);
      const minValue = 0.01;

      if (isNaN(num)) {
        return Promise.reject(new Error('Please enter your amount'));
      }

      if (num >= 0 && num < minValue && _?.max > minValue) {
        return Promise.reject(
          new Error(
            `Withdrawal amount must be between ${minValue} and ${_?.max}`,
          ),
        );
      } else if (num >= minValue && num <= _?.max) {
        return Promise.resolve();
      } else if (num > _?.max) {
        return Promise.reject(
          new Error(`Please enter an amount above $${_?.max}`),
        );
      } else if (!_?.max || _?.max <= 0) {
        return Promise.reject(new Error('You do not have funds to withdraw'));
      }

      return Promise.reject(new Error('Please enter your amount'));
    },
  },
};

export const getInputRules = (label, type, max) => {
  const enabledValidators = [
    {
      required: true,
      message:
        WITHDRAWAL_MESSAGE[type] ||
        `Please enter your ${String(label).toLowerCase() || 'value'}`,
    },
  ];

  if (type !== 'select') {
    if (max > 0) {
      validationTypes[type].max = Number(max);
    }

    enabledValidators.push(
      validationTypes[type] || {
        type: 'string',
        pattern: /^\w+$/,
        message:
          WITHDRAWAL_MESSAGE[type] ||
          `Please enter valid ${String(label).toLowerCase()}`,
      },
    );
  }

  return enabledValidators;
};
