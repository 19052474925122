import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { ICONS } from './constants';

export default function CustomIcon({
  type,
  link,
  iconCollection = ICONS,
  ...others
}) {
  if (link) {
    return (
      <Link to={link}>
        <Icon component={iconCollection[type]} {...others} />
      </Link>
    );
  }

  return <Icon component={iconCollection[type]} {...others} />;
}

CustomIcon.propTypes = {
  type: PropTypes.string,
  link: PropTypes.string,
  others: PropTypes.any,
  iconCollection: PropTypes.any,
};
