import { Brand } from 'constants/brand';

import { FirebaseService } from '../../service';

export class FirebaseServiceDay1 extends FirebaseService {
  constructor(name) {
    super(
      JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG_DAY1),
      Brand.DAY1,
      name,
    );
  }
}
