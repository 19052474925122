import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import persistedStorage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducers from './reducers';
import rootSaga from './sagas';

const initialState = window.INITIAL_REDUX_STATE || {};

export default function configureStore() {
  const persistConfig = {
    key: 'root',
    storage: persistedStorage,
    whitelist: [],
  };
  const persistedReducer = persistReducer(persistConfig, rootReducers);

  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers = composeWithDevTools({});

  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  );

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, rootReducers, persistor };
}
