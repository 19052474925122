import jwtDecode from 'jwt-decode';

export function getUserIdFromIdToken(idToken) {
  if (!idToken) {
    return;
  }

  const decodedIdToken = jwtDecode(idToken);

  return decodedIdToken.user_id;
}
