import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import HeaderContent from 'components/Header/HeaderContent';
import MenuHeader from 'components/Sidebar/MenuHeader';
import { PLATFORM_TYPES } from 'utils/constants';

import { getSectionTitle } from './config';

import styles from './styles.module.less';

const Header = ({ type, section, sectionChild, showMenu }) => {
  const title = getSectionTitle(section, sectionChild);

  const isStaff = type === PLATFORM_TYPES.STAFF;

  return (
    <header className={styles.header}>
      <MenuHeader
        onTriggerClick={showMenu}
        type="header"
        className={styles.headerAction}
      />
      <div id="headerClient">
        {title ? (
          <Typography level={1} className={styles.headerTitle}>
            {title}
          </Typography>
        ) : null}
      </div>
      <HeaderContent isStaff={isStaff} type={type} />
    </header>
  );
};

Header.propTypes = {
  section: PropTypes.string,
  sectionChild: PropTypes.string,
  showMenu: PropTypes.func,
  type: PropTypes.oneOf([PLATFORM_TYPES.STAFF, PLATFORM_TYPES.CLIENTS]),
};

export default Header;
