import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FormInput from 'components/Common/Input/FormInput';
import { TITLES } from 'utils/constants';
import { normalizeFields } from 'utils/subIBs';
import { SUB_IBS_COMPONENTS } from './config';
import styles from './styles.module.less';
import { FORM_INPUT_TYPES } from 'utils/constants';

const SubIBs = ({ form, isCpa }) => {
  const handleBlur = useCallback(
    (name) => (event) => {
      form.setFields(
        normalizeFields(event.target.value, name, form.getFieldsValue()),
      );
    },
    [form],
  );

  return (
    <div className={styles.container}>
      <h2>{isCpa ? TITLES.MULTI_TIER_CPAS : TITLES.MULTI_TIER_IBS}</h2>
      <div className={styles.content}>
        {SUB_IBS_COMPONENTS.map(({ name, label, initialValue, Component }) => (
          <div key={name} className={styles.value}>
            <FormInput
              type={FORM_INPUT_TYPES.NUMBER}
              inputClassName={styles.input}
              name={name}
              label={label}
              defaultValue={initialValue}
              suffix="%"
              isRequired={false}
              onBlur={handleBlur(name)}
            />
            {Component && <Component form={form} />}
          </div>
        ))}
      </div>
    </div>
  );
};

SubIBs.propTypes = {
  form: PropTypes.shape({
    setFields: PropTypes.func,
    getFieldsValue: PropTypes.func,
  }),
  isCpa: PropTypes.bool,
};

export default SubIBs;
