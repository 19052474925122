import { ROUTING } from 'config';

export const SUBMENU_KEYS = {
  REPORT: 'report',
};

export const sideMenuBrokerItems = [
  {
    icon: 'dashboard',
    path: ROUTING.DASHBOARD,
    title: 'Dashboard',
  },
  {
    icon: 'clients',
    path: ROUTING.CLIENTS,
    title: 'Clients',
  },
  {
    icon: 'my-commission',
    path: ROUTING.COMMISSIONS,
    title: 'My Commission',
  },
  {
    icon: 'withdrawalsSidebar',
    path: ROUTING.WITHDRAWALS,
    title: 'Withdrawals',
  },
];

export const sideMenuClientItems = (reportSubMenuItems = []) => [
  {
    icon: 'dashboard',
    path: ROUTING.CLIENT_DASHBOARD,
    title: 'Dashboard',
  },
  {
    icon: 'partners',
    path: ROUTING.CLIENT_PARTNERS,
    title: 'Partners',
  },
  {
    icon: 'reports',
    title: 'Reports',
    path: SUBMENU_KEYS.REPORT,
    subMenuItems: reportSubMenuItems,
  },
  {
    icon: 'clients',
    path: ROUTING.STAFF_MANAGEMENT,
    title: 'Staff Management',
  },
];

export const sideMenuItems = (userType, reportSubMenuItems) => {
  const config = {
    broker: sideMenuBrokerItems,
    client: sideMenuClientItems(reportSubMenuItems),
  };

  return config[userType];
};
