import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Slider, Form } from 'antd';

import useDebounce from 'utils/hooks/useDebounce';
import { getFormattedNumber } from 'utils/helpers';

import styles from './styles.module.less';
import max from 'lodash/max';

const FRACTIONAL_STEP = 0.01;
const INTEGER_STEP = 1;

const getNumberOfDecimalPlaces = (value) =>
  String(value).split('.')[1]?.length || FRACTIONAL_STEP;

const CustomSlider = ({
  form,
  name,
  label,
  minValue,
  maxValue,
  numberFormat,
  isInteger,
  className,
}) => {
  const [sliderValue, setSliderValue] = useState([
    getFormattedNumber({ number: minValue }),
    getFormattedNumber({ number: maxValue }),
  ]);
  const sliderChangeHandler = useDebounce(setSliderValue, 300);

  const step = useMemo(() => {
    if (isInteger) return INTEGER_STEP;

    return Number(
      `1e-${max([
        getNumberOfDecimalPlaces(minValue),
        getNumberOfDecimalPlaces(maxValue),
      ])}`,
    );
  }, [isInteger, minValue, maxValue]);

  useEffect(() => {
    setSliderValue([minValue, maxValue]);
  }, [maxValue, minValue]);

  useEffect(() => {
    form.setFields([
      {
        name: name,
        value: sliderValue,
      },
    ]);
  }, [form, name, sliderValue]);

  const marks = useMemo(
    () => ({
      0: {
        label: getFormattedNumber({
          number: minValue,
          style: numberFormat,
          signDisplay: minValue < 0,
        }),
      },
      100: {
        label: getFormattedNumber({
          number: maxValue,
          style: numberFormat,
          signDisplay: maxValue < 0,
        }),
      },
    }),
    [maxValue, minValue, numberFormat],
  );

  const onMinChange = useCallback((value) => {
    setSliderValue((prevState) => [value, prevState[1]]);
  }, []);

  const onMaxChange = useCallback((value) => {
    setSliderValue((prevState) => [prevState[0], value]);
  }, []);

  return (
    <div className={className}>
      <span className={styles.title}>{label}</span>
      <div className={styles.content}>
        <InputNumber
          step={step}
          min={minValue}
          max={maxValue}
          value={sliderValue[0]}
          onChange={onMinChange}
          className={styles.inputLeft}
        />
        <InputNumber
          step={step}
          min={minValue}
          max={maxValue}
          value={sliderValue[1]}
          onChange={onMaxChange}
          className={styles.inputRight}
        />
        <Form.Item name={name} className={styles.sliderContainer}>
          <Slider
            range
            min={minValue}
            max={maxValue}
            step={step}
            onChange={sliderChangeHandler}
            value={sliderValue}
            marks={marks}
            className={styles.slider}
          />
        </Form.Item>
      </div>
    </div>
  );
};

CustomSlider.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  limits: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
  numberFormat: PropTypes.oneOf(['decimal', 'currency', 'percent']),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  isInteger: PropTypes.bool,
};

export default CustomSlider;
