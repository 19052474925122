import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu, Typography } from 'antd';
import clsx from 'clsx';
import CustomIcon from 'components/Icons';

import styles from './styles.module.less';
import { MENU_ICONS } from '../MenuItem/config';
import { useBrand } from 'modules/shared/brand/hooks/useBrand';

const { SubMenu } = Menu;
const { Text } = Typography;

const SubMenuComponent = ({
  isCollapsed,
  icon,
  title,
  subMenuItems,
  className,
  ...other
}) => {
  const { brand } = useBrand();
  const iconCollection = MENU_ICONS[brand];

  return (
    <SubMenu
      icon={CustomIcon({ type: icon, iconCollection })}
      title={title}
      className={clsx(className, styles.subMenu, {
        [styles.hideSubMenu]: isCollapsed,
      })}
      popupClassName={styles.popup}
      {...other}
    >
      {subMenuItems.map(({ path, title }) => (
        <Menu.Item key={path} className={styles.subMenuItem}>
          <Link to={path}>
            <Text className={styles.subMenuItemText}>{title}</Text>
          </Link>
        </Menu.Item>
      ))}
    </SubMenu>
  );
};

SubMenuComponent.propTypes = {
  isCollapsed: PropTypes.bool,
  icon: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  subMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  others: PropTypes.any,
};

export default React.memo(SubMenuComponent);
