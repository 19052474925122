import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Row, Skeleton } from 'antd';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';
import StyledDropdown from 'components/Common/Dropdown/StyledDropdown';
import CustomIcon from 'components/Icons';
import { CHART_SIZES, getDonutChartOptions } from 'utils/charts';
import { CHART_COLORS } from 'utils/chartColors';

import styles from './styles.module.less';

const VolumeTile = ({
  type,
  title,
  series,
  menuItems,
  isLoading,
  className,
}) => {
  const options = useMemo(
    () =>
      getDonutChartOptions(
        series.map(({ key }) => key),
        CHART_COLORS,
      ),
    [series],
  );

  const isEmpty = useMemo(
    () => !series.find(({ value }) => value > 0),
    [series],
  );

  return (
    <div className={clsx(styles.container, styles[type], className)}>
      <Row justify="space-between" className={styles.containerHeader}>
        <h3 className={styles.containerTitle}>{title}</h3>
        <StyledDropdown menuItems={menuItems} />
      </Row>
      <Skeleton
        active
        title={false}
        paragraph={{ rows: 4, width: '100%' }}
        loading={isLoading}
        className={styles.skeleton}
      />
      {isLoading ? (
        <Spinner className={styles.spinner} />
      ) : (
        <>
          <ul className={styles.labels}>
            {series.map(({ title, value }, index) => {
              const dotStyle = { background: CHART_COLORS[index] };

              return (
                <li key={title} className={styles.label}>
                  <Row align="middle">
                    <div style={dotStyle} className={styles.labelDot} />
                    <span className={styles.labelName}>{title}</span>
                  </Row>
                  <span className={styles.labelValue}>{value}%</span>
                </li>
              );
            })}
          </ul>
          {!isEmpty && (
            <Chart
              className={styles.chart}
              options={options}
              series={series.map(({ value }) => value)}
              type="donut"
              {...CHART_SIZES[type]}
            />
          )}
          {isEmpty && (
            <CustomIcon type={type ? 'noChartData' : 'noChartDataSmall'} />
          )}
        </>
      )}
    </div>
  );
};

VolumeTile.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  series: PropTypes.arrayOf(PropTypes.object),
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

VolumeTile.defaultProps = {
  type: 'tile',
  title: 'Volume',
};

export default React.memo(VolumeTile);
