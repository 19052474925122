import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { THEME } from 'utils/constants';
import { ThemeContext } from '../../contexts/ThemeContext';

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(THEME.DARK);

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  const changeTheme = useCallback((brand) => {
    setTheme(brand);
  }, []);

  const value = useMemo(
    () => ({
      theme,
      changeTheme,
    }),
    [theme, changeTheme],
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
