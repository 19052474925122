import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import clsx from 'clsx';
import CustomIcon from 'components/Icons';

import styles from './styles.module.less';

const DropdownMenu = ({ menuItems, className, optionClasses }) => (
  <Menu className={clsx(styles.menu, className)}>
    {menuItems?.map(({ text, icon, onClick, Component }) =>
      Component ? (
        <Menu.Item disabled key="component" className={styles.disabledStyle}>
          <Component />
        </Menu.Item>
      ) : (
        <Menu.Item
          key={text}
          className={clsx(styles.menuItem, optionClasses)}
          onClick={onClick}
        >
          {icon && <CustomIcon type={icon} className={styles.menuIcon} />}
          {text}
        </Menu.Item>
      ),
    )}
  </Menu>
);

DropdownMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  className: PropTypes.string,
  optionClasses: PropTypes.string,
};

export default DropdownMenu;
