import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import CustomIcon from 'components/Icons';

import styles from './styles.module.less';

const BackgroundWrapper = ({
  text,
  icon = '',
  type = '',
  className = '',
  record,
  onClick,
  justify = 'center',
}) => {
  if (!text) {
    return null;
  }

  const lowerText = String(text).toLowerCase();

  return (
    <div
      style={{ justifyContent: justify }}
      className={clsx(
        styles.backgroundWrapper,
        styles[lowerText],
        styles[type],
        className,
      )}
      onClick={(event) => onClick?.(event, record)}
    >
      {icon && <CustomIcon type={icon} className={styles.icon} />}
      <span className={styles.text}>{lowerText}</span>
    </div>
  );
};

BackgroundWrapper.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  record: PropTypes.object,
  onClick: PropTypes.func,
  justify: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
};

export default BackgroundWrapper;
