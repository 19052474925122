import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { api } from 'api';
import { getErrorHandler } from '../getErrorHandler';
import { mapDateRange } from '../mapDateRange';
import { useCallback, useMemo, useState } from 'react';

export const useVolumeOverview = ({ accountId, dateRange }) => {
  const [chartSeries, setChartSeries] = useState([]);

  const { isLoading: isVolumeOverviewLoading } = useQuery({
    queryKey: queryKeys.volumeOverview(accountId, dateRange),
    queryFn: async () => {
      const { data } = await api.getVolumeOverviewBreakDownTimeSeries(
        accountId,
        { ...mapDateRange(dateRange) },
      );

      return data.series;
    },
    onSuccess: (data) => {
      const enabledSeries = data.map(({ title, value }, index) => ({
        enabled: index <= 3,
        key: title,
        title,
        value,
      }));

      setChartSeries(enabledSeries);
    },
    onError: getErrorHandler('Unable to load volume overview'),
  });

  const handleSeriesChange = useCallback((data) => {
    setChartSeries(data);
  }, []);

  const enabledSeries = useMemo(
    () => chartSeries.filter(({ enabled }) => enabled),
    [chartSeries],
  );

  return {
    series: chartSeries,
    enabledSeries,
    isVolumeOverviewLoading,
    handleSeriesChange,
  };
};
