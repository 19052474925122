import { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';

import { EXPORT_MENU_ITEMS } from '../constants';
import { exportTable } from '../export';
import { isArray } from 'lodash';

const useExportMenu = ({
  fileName,
  exportType,
  exportFields,
  processorName,
  header,
  action,
  dateRange,
  sorter,
  acceptFilters = true,
  isDispatch = true,
  ...params
}) => {
  const dispatch = useDispatch();

  const requestData = useMemo(() => {
    const data = {
      offset: 0,
      // TODO: user rows count returned from api
      limit: 1_000_000,
      ...sorter,
      ...params,
    };

    if (isArray(dateRange)) {
      if (dateRange?.[1]) {
        data.dateFrom = dateRange[0].toISOString();
        data.dateTo = dateRange[1].toISOString();
      }
    } else if (dateRange?.toISOString) {
      data.dateFrom = dateRange?.toISOString();
      data.dateTo = dateRange?.toISOString();
    }

    return data;
  }, [dateRange, params, sorter]);

  const executeAction = useCallback(
    (data) => {
      if (isDispatch) {
        return dispatch(action.request(data));
      }

      return action(data);
    },
    [isDispatch, dispatch, action],
  );

  const onSuccess = useCallback(
    ({ rows }, fileExtension) => {
      message.success({ content: 'Loaded', key: fileName, duration: 2 });
      exportTable(
        fileName,
        exportType,
        rows,
        exportFields,
        header,
        fileExtension,
        processorName,
      );
    },
    [exportFields, exportType, fileName, header, processorName],
  );

  const onFailure = useCallback(
    (error) => {
      console.log(error);
      message.error({
        content: 'Failed to load data',
        key: fileName,
        duration: 2,
      });
    },
    [fileName],
  );

  return useCallback(
    (_currentPageData, filters) =>
      EXPORT_MENU_ITEMS.map(({ buttonText, fileExtension }) => ({
        text: buttonText,
        onClick: () => {
          message.loading({
            content: 'Loading...',
            key: fileName,
            duration: null,
          });
          executeAction({
            requestData: acceptFilters
              ? {
                  ...requestData,
                  ...filters,
                }
              : requestData,
            onSuccess,
            onFailure,
            fileExtension,
          });
        },
      })),
    [executeAction, fileName, onFailure, onSuccess, requestData, acceptFilters],
  );
};

export default useExportMenu;
