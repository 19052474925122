export const ResourceName = {
  LOGO: 'logo',
  TITLE: 'title',
  SIGN_IN_BACKGROUND: 'signInBackground',
  RESET_PASSWORD_BACKGROUND: 'resetPasswordBackground',
  ACTIVITY_METRICS: 'activityMetrics',
  AVATAR: 'avatar',
  REV_SHARE: 'revShare',
  REPORTS: 'reports',
  CLIENT_PROFILE_COMMISSION_SOURCE_TAB: 'clientProfileCommissionSourceTab',
  MY_COMMISSION_COM_SOURCE_TAB: 'myCommissionComSourceTab',
  HAS_SUB_IB_API_CALL_FUNCTION: 'hasSubIbAPiCallFunction',
  SIGN_UP_LINK: 'signUpLink',
  FEES_SYMBOLS_OVERVIEW_BY_USER_ID_REQUEST:
    'feesSymbolsOverviewByUserIdApiRequest',
};
