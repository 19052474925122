import { createAsyncAction, createAction } from 'typesafe-actions';

export default {
  searchClients: createAsyncAction(
    'searchClients/request',
    'searchClients/success',
    'searchClients/failure',
  )(),
  searchMoreClients: createAsyncAction(
    'searchMoreClients/request',
    'searchMoreClients/success',
    'searchMoreClients/failure',
  )(),
  searchPartners: createAsyncAction(
    'searchPartners/request',
    'searchPartners/success',
    'searchPartners/failure',
  )(),
  resetSearch: createAction('search/reset')(),
};
