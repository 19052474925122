import React, { useCallback } from 'react';
import clsx from 'clsx';

import StyledButton from 'components/Common/Buttons/StyledButton';
import { THEME } from 'utils/constants';
import { useTheme } from 'modules/shared/theme/hooks/useTheme';

import styles from './styles.module.less';

const BUTTONS = [THEME.LIGHT, THEME.DARK];

const ThemeButtons = () => {
  const { theme, changeTheme } = useTheme();

  const onButtonClick = useCallback(
    (name) => () => {
      changeTheme(name);
    },
    [changeTheme],
  );

  return (
    <div className={styles.container}>
      <span className={styles.title}>Theme</span>
      <div className={styles.buttonsContainer}>
        {BUTTONS.map((name) => (
          <StyledButton
            key={name}
            type="primary"
            icon={name}
            text={name}
            onClick={onButtonClick(name)}
            className={clsx(styles.button, {
              [styles.defaultButton]: theme !== name,
            })}
          />
        ))}
      </div>
    </div>
  );
};

export default ThemeButtons;
