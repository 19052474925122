import React from 'react';
import clsx from 'clsx';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';

import styles from './styles.module.less';
const { TabPane } = Tabs;

const renderTabBar = (props, DefaultTabBar) => (
  <DefaultTabBar {...props} className={styles.tab}>
    {(node) => <div key={node.key}>{node}</div>}
  </DefaultTabBar>
);

const TabsSection = ({
  type,
  tabNames,
  children,
  onChange,
  className,
  forceRender = true,
  ...other
}) => (
  <Tabs
    onChange={onChange}
    renderTabBar={renderTabBar}
    className={clsx(styles[type], className)}
    moreIcon={null}
    {...other}
  >
    {tabNames
      .map((tab, idx) => ({ ...tab, tabContent: children[idx] }))
      .filter(({ enabled = true }) => enabled)
      .map(({ key, tab, tabContent }) => (
        <TabPane key={key} tab={tab} forceRender={forceRender}>
          {tabContent}
        </TabPane>
      ))}
  </Tabs>
);

TabsSection.propTypes = {
  tabNames: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func,
  className: PropTypes.string,
  forceRender: PropTypes.bool,
  other: PropTypes.any,
};

TabsSection.defaultProps = {
  onChange: () => null,
  type: 'widget',
  children: [],
};

export default TabsSection;
