import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import { getFormattedNumber } from 'utils/helpers';
import { NUMBER_FORMATS } from 'utils/constants';

import Actions from '../actions';

const initialState = {
  overview: {
    isLoading: true,
    totalCommission: 0,
    commissionPercent: 0,
    volume: [],
  },
  clientsTrades: {
    table: {
      dataSource: [],
      total: 0,
      isLoading: true,
    },
    totalCommissionTileChartData: {
      percentChanges: 0,
      totalCount: 0,
      series: [],
      isLoading: false,
    },
    earnedCommissionTileChartData: {
      percentChanges: 0,
      totalCount: 0,
      series: [],
      isLoading: false,
    },
    filterLimits: null,
  },
  partnersTrades: {
    table: {
      dataSource: [],
      total: 0,
      isLoading: true,
    },
    totalCommissionTileChartData: {
      percentChanges: 0,
      totalCount: 0,
      series: [],
      isLoading: false,
    },
    earnedCommissionTileChartData: {
      percentChanges: 0,
      totalCount: 0,
      series: [],
      isLoading: false,
    },
    filterLimits: null,
  },
  payments: {
    table: {
      dataSource: [],
      total: 0,
      isLoading: true,
    },
    filterLimits: null,
  },
  balance: {
    table: {
      dataSource: [],
      total: 0,
      isLoading: true,
    },
    filterLimits: null,
  },
};

export default createReducer(initialState)
  .handleAction(
    Actions.loadMyClientsTrades.request,
    produce((state) => {
      state.clientsTrades.table.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadMyClientsTrades.success,
    produce((state, { payload }) => {
      state.clientsTrades.table.total = payload.count;
      state.clientsTrades.table.dataSource = payload.rows;
      state.clientsTrades.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadMyClientsTrades.failure,
    produce((state) => {
      state.clientsTrades.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadMyCommissionOverview.request,
    produce((state) => {
      state.overview.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadMyCommissionOverview.success,
    produce((state, { payload }) => {
      state.overview.volume = payload.series.map(({ title, value }, index) => ({
        enabled: payload.series.length <= 4 || index < 3,
        key: title,
        title,
        value,
      }));
      state.overview.totalCommission = getFormattedNumber({
        number: payload.commission || 0,
        style: NUMBER_FORMATS.currency,
        group: {
          count: 3,
          replaceWith: ' ',
        },
      });
      state.overview.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadMyClientsTradesFilterLimits.success,
    produce((state, { payload }) => {
      state.clientsTrades.filterLimits = payload;
    }),
  )
  .handleAction(
    Actions.updateMyCommissionOverview,
    produce((state, { payload }) => {
      state.overview.volume = payload;
    }),
  )
  .handleAction(
    Actions.loadMyPayments.success,
    produce((state, { payload }) => {
      state.payments.table.total = payload.count;
      state.payments.table.dataSource = payload.rows;
      state.payments.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadMyPayments.request,
    produce((state) => {
      state.payments.table.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadMyBalance.request,
    produce((state) => {
      state.balance.table.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadMyBalance.success,
    produce((state, { payload }) => {
      state.balance.table.total = payload.count;
      state.balance.table.dataSource = payload.rows;
      state.balance.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadMyPaymentsFilterLimits.success,
    produce((state, { payload }) => {
      state.payments.filterLimits = payload;
    }),
  )
  .handleAction(
    Actions.loadMyBalanceFilterLimits.success,
    produce((state, { payload }) => {
      state.balance.filterLimits = payload;
    }),
  )
  .handleAction(
    Actions.loadMyPayments.failure,
    produce((state) => {
      state.payments.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadMyBalance.failure,
    produce((state) => {
      state.balance.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadMyPartnersTrades.request,
    produce((state) => {
      state.partnersTrades.table.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadMyPartnersTrades.success,
    produce((state, { payload }) => {
      state.partnersTrades.table.total = payload.count;
      state.partnersTrades.table.dataSource = payload.rows;
      state.partnersTrades.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadMyPartnersTrades.failure,
    produce((state) => {
      state.partnersTrades.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadMyPartnersTradesFilterLimits.success,
    produce((state, { payload }) => {
      state.partnersTrades.filterLimits = payload;
    }),
  )
  .handleAction(
    Actions.loadMyClientsTradesCommissions.request,
    produce((state) => {
      state.clientsTrades.totalCommissionTileChartData.isLoading = true;
      state.clientsTrades.earnedCommissionTileChartData.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadMyClientsTradesCommissions.success,
    produce((state, { payload }) => {
      const { totalCommissions, earnedCommissions } = payload;

      state.clientsTrades.totalCommissionTileChartData.isLoading = false;
      state.clientsTrades.totalCommissionTileChartData.totalCount =
        totalCommissions.value;
      state.clientsTrades.totalCommissionTileChartData.percentChanges =
        totalCommissions.growth;
      state.clientsTrades.totalCommissionTileChartData.series =
        totalCommissions.series.map(({ value, date }) => ({
          x: date,
          y: value,
        }));

      state.clientsTrades.earnedCommissionTileChartData.isLoading = false;
      state.clientsTrades.earnedCommissionTileChartData.totalCount =
        earnedCommissions.value;
      state.clientsTrades.earnedCommissionTileChartData.percentChanges =
        earnedCommissions.growth;
      state.clientsTrades.earnedCommissionTileChartData.series =
        earnedCommissions.series.map(({ value, date }) => ({
          x: date,
          y: value,
        }));
    }),
  )
  .handleAction(
    Actions.loadMyClientsTradesCommissions.failure,
    produce((state) => {
      state.clientsTrades.totalCommissionTileChartData.isLoading = false;
      state.clientsTrades.earnedCommissionTileChartData.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadMyPartnersTradesCommissions.request,
    produce((state) => {
      state.partnersTrades.totalCommissionTileChartData.isLoading = true;
      state.partnersTrades.earnedCommissionTileChartData.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadMyPartnersTradesCommissions.success,
    produce((state, { payload }) => {
      const { totalCommissions, earnedCommissions } = payload;

      state.partnersTrades.totalCommissionTileChartData.isLoading = false;
      state.partnersTrades.totalCommissionTileChartData.totalCount =
        totalCommissions.value;
      state.partnersTrades.totalCommissionTileChartData.percentChanges =
        totalCommissions.growth;
      state.partnersTrades.totalCommissionTileChartData.series =
        totalCommissions.series.map(({ value, date }) => ({
          x: date,
          y: value,
        }));

      state.partnersTrades.earnedCommissionTileChartData.isLoading = false;
      state.partnersTrades.earnedCommissionTileChartData.totalCount =
        earnedCommissions.value;
      state.partnersTrades.earnedCommissionTileChartData.percentChanges =
        earnedCommissions.growth;
      state.partnersTrades.earnedCommissionTileChartData.series =
        earnedCommissions.series.map(({ value, date }) => ({
          x: date,
          y: value,
        }));
    }),
  )
  .handleAction(
    Actions.loadMyPartnersTradesCommissions.failure,
    produce((state) => {
      state.partnersTrades.totalCommissionTileChartData.isLoading = false;
      state.partnersTrades.earnedCommissionTileChartData.isLoading = false;
    }),
  );
