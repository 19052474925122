import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

import styles from './styles.module.less';

const TradesSummary = ({ summary }) => {
  const { totalCommission, totalVolume, totalProfit } = summary;

  return (
    <Table.Summary fixed>
      <Table.Summary.Row className={styles.summary}>
        <Table.Summary.Cell colSpan={8} index={1}>
          Total
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1} index={2}>
          {totalVolume}
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={4} index={3} />
        <Table.Summary.Cell colSpan={1} index={4}>
          ${totalCommission}
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1} index={5}>
          {totalProfit}
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1} index={6} />
      </Table.Summary.Row>
    </Table.Summary>
  );
};

TradesSummary.propTypes = {
  summary: PropTypes.shape({
    totalCommission: PropTypes.number,
    totalVolume: PropTypes.number,
    totalProfit: PropTypes.number,
  }),
};

export default TradesSummary;
