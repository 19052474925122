import { takeLatest, put } from 'redux-saga/effects';
import actions from 'redux/actions';
import { api } from 'api';

function* callLoadBalancesReport({ payload }) {
  try {
    const response = yield api.getBalancesReport(payload);
    yield put(actions.loadBalancesReport.success(response.data));
  } catch (error) {
    yield put(actions.loadBalancesReport.failure(error));
  }
}

function* callExportBalancesReport({
  payload: { requestData, onSuccess, onFailure, fileExtension },
}) {
  try {
    const response = yield api.getBalancesReport(requestData);
    onSuccess(response.data, fileExtension);
  } catch (error) {
    onFailure(error);
  }
}

export function* balancesSaga() {
  yield takeLatest(actions.loadBalancesReport.request, callLoadBalancesReport);
  yield takeLatest(
    actions.loadAllBalancesReport.request,
    callExportBalancesReport,
  );
}
