import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { getAutocompleteStrings } from 'utils/helpers';

import styles from '../../styles.module.less';

const Option = ({ currentValue, suggested, name, justify }) => {
  const [commonId, suggestedId] = getAutocompleteStrings(
    String(currentValue),
    String(suggested),
  );
  const [commonName, suggestedName] = getAutocompleteStrings(
    String(currentValue),
    name,
  );

  return (
    <div
      className={clsx(styles.autoCompleteValues, {
        [styles.positionStart]: justify === 'start',
      })}
    >
      <span>
        <span className={styles.searchedValue}>{commonId}</span>
        <span
          className={clsx(styles.suggestedValue, {
            [styles.defaultContent]: !commonId,
          })}
        >
          {suggestedId}
        </span>
      </span>
      <span className={styles.valueIndent}>
        <span className={styles.searchedValue}>{commonName}</span>
        <span
          className={clsx(styles.suggestedValue, {
            [styles.defaultContent]: !commonName,
          })}
        >
          {suggestedName}
        </span>
      </span>
    </div>
  );
};

Option.propTypes = {
  currentValue: PropTypes.string,
  suggested: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  refcode: PropTypes.string,
  justify: PropTypes.string,
};

export default Option;
