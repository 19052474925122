import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import styles from './styles.module.less';

const AuthLayout = ({ children, type }) => (
  <Row className={clsx(styles.authLayout, styles[type])}>
    <Col className={styles.authLayoutBg} xs={24} sm={20}>
      <Row wrap={false} className={styles.authLayoutAlign}>
        {children}
      </Row>
    </Col>
  </Row>
);

AuthLayout.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
};

AuthLayout.defaultProps = {
  type: 'signIn',
};

export default AuthLayout;
