import { Brand } from 'constants/brand';
import { RevShareService } from './revShareService';
import { api } from 'api';

export const REV_SHARE_CONFIG = {
  [Brand.FM]: {
    rows: RevShareService.getFmRevSharesRows(),
    symbolsLoadFunction: async (options = {}) => {
      const payload = {
        ...options,
        brand: process.env.REACT_APP_BRAND,
      };

      const data = await Promise.all([
        api.getTradesSecuritiesGroups(payload),
        api.getTradesSymbols(payload),
      ]);

      return data.flatMap((response) => response.data);
    },
  },
  [Brand.GP]: {
    rows: RevShareService.getGpRevSharesRows(),
    symbolsLoadFunction: async (options = {}) => {
      const payload = {
        ...options,
        brand: process.env.REACT_APP_BRAND,
      };
      const data = await Promise.all([
        api.getTradesSecuritiesGroups(payload),
        api.getTradesSymbols(payload),
      ]);

      return data.flatMap((response) => response.data);
    },
  },
  [Brand.DAY1]: {
    rows: RevShareService.getDay1RevSharesRows(),
    symbolsLoadFunction: async (options = {}) => {
      const { data } = await api.getFeesSymbols(options);

      return data.map((symbol) => ({
        label: symbol.name,
        value: symbol.id,
      }));
    },
  },
};
