import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import clsx from 'clsx';

import styles from './styles.module.less';

const StyledSwitch = ({ className, ...other }) => (
  <Switch {...other} className={clsx(styles.switch, className)} />
);

StyledSwitch.propTypes = {
  className: PropTypes.string,
  other: PropTypes.any,
};

export default StyledSwitch;
