import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { Button, Select } from 'antd';
import PropTypes from 'prop-types';

import CustomIcon from 'components/Icons';
import PopupContainer from 'components/PopupContainer';
import { normalizeValueByMode } from './utils/normalizeValueByMode';

import styles from './styles.module.less';

const { Option } = Select;

const DropdownSelect = ({
  options = [],
  value = null,
  onChange = () => {},
  showSelectAll = false,
  placeholder,
  className,
  containerClass,
  children,
  type,
  mode,
  ...other
}) => {
  const handleUnselectAll = useCallback(
    (event) => {
      event.stopPropagation();
      onChange([]);
    },
    [onChange],
  );

  const handleSelectAll = useCallback(
    (event) => {
      event.stopPropagation();
      onChange(options.map(({ value }) => value));
    },
    [options, onChange],
  );

  const enchancedOptions = useMemo(() => {
    if (!showSelectAll) return options;

    return [
      value?.length
        ? {
            label: (
              <Button type="link" onClick={handleUnselectAll}>
                Unselect All
              </Button>
            ),
          }
        : {
            label: (
              <Button type="link" onClick={handleSelectAll}>
                Select All
              </Button>
            ),
          },
      ...options,
    ];
  }, [value, options, showSelectAll, handleUnselectAll, handleSelectAll]);

  return (
    <PopupContainer containerClass={containerClass}>
      <Select
        showSearch
        showArrow
        notFoundContent=""
        filterOption={(input, option) =>
          option.children.toLowerCase?.().includes?.(input.toLowerCase())
        }
        suffixIcon={CustomIcon({
          type: 'arrow-down',
          className: 'ant-select-suffix',
        })}
        placeholder={placeholder}
        className={clsx(styles.input, styles[type], className)}
        dropdownClassName={styles.dropdown}
        value={normalizeValueByMode(value, mode)}
        onChange={onChange}
        mode={mode}
        {...other}
      >
        {children ||
          enchancedOptions.map(({ label, value, key }) => (
            <Option
              key={key || value || label}
              value={value}
              className={styles.option}
            >
              {label || value}
            </Option>
          ))}
      </Select>
    </PopupContainer>
  );
};

DropdownSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      key: PropTypes.string,
    }),
  ),
  containerClass: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  other: PropTypes.any,
  showSelectAll: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  mode: PropTypes.any,
};

export default DropdownSelect;
