import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import Actions from '../actions';

const initialState = {
  items: [],
  total: 0,
  loadingType: '',
  error: '',
};

export default createReducer(initialState)
  .handleAction(
    [Actions.findLogs.request],
    produce((state, { type }) => {
      state.loadingType = type;
      state.error = '';
    }),
  )
  .handleAction(
    [Actions.findLogs.failure],
    produce((state, { type, payload }) => {
      state.loadingType = type;
      state.error = payload;
    }),
  )
  .handleAction(
    [Actions.findLogs.success],
    produce((state, { payload }) => {
      state.items = payload.rows;
      state.total = payload.count;
      state.loadingType = '';
      state.error = '';
    }),
  );
