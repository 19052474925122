import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import TableSettingsModal from 'components/Modals/TableSettingsModal';
import VolumeTile from './VolumeTile';

import { getExportMenu } from 'utils/export';
import { EXPORT_DATA_TYPES } from 'utils/constants';
import useToggle from 'utils/hooks/useToggle';

const VolumeOverview = ({
  selector,
  type,
  onSeriesChanged,
  title,
  className,
}) => {
  const [isModalOpen, onModalToggle] = useToggle();
  const { enabledSeries, series, isLoading } = useSelector(selector);

  const menuItems = useMemo(
    () => [
      {
        text: 'Settings',
        onClick: onModalToggle(true),
      },
      ...getExportMenu({
        fileName: 'Overview',
        exportType: EXPORT_DATA_TYPES.TABLE,
        sourceList: series,
        exportFields: ['title', 'value'],
        header: ['Symbol', 'Percent'],
      }),
    ],
    [onModalToggle, series],
  );

  return (
    <>
      <VolumeTile
        title={title}
        type={type}
        series={enabledSeries}
        isLoading={isLoading}
        menuItems={menuItems}
        className={className}
      />
      {!!series.length && (
        <TableSettingsModal
          isOpen={isModalOpen}
          onCancel={onModalToggle(false)}
          onFinish={onSeriesChanged}
          settings={series}
          returnEnabled={false}
        />
      )}
    </>
  );
};

VolumeOverview.defaultProps = {
  type: '',
  title: 'Overview',
};

VolumeOverview.propTypes = {
  onSeriesChanged: PropTypes.func.isRequired,
  selector: PropTypes.func.isRequired,
  type: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
};

export default VolumeOverview;
