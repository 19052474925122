import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TableFilterDrawer from 'components/Table/TableFilterDrawer';
import useToggle from 'utils/hooks/useToggle';

import TableWithPagination from './TableWithPagination';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';

const TableWithFilter = ({
  filterSelector,
  selector,
  loadData,
  additionalLoadData,
  title,
  columns,
  onRow,
  filterItems,
  ...others
}) => {
  const [filters, setFilters] = useState({});
  const [isFilterVisible, onFilterToggle] = useToggle();

  return (
    <>
      <TableWithPagination
        selector={selector}
        loadData={loadData}
        additionalLoadData={additionalLoadData}
        title={title}
        columns={columns}
        onRow={onRow}
        showDrawer={!!filterSelector && onFilterToggle(true)}
        filters={filters}
        {...others}
      />
      {filterSelector && (
        <TableFilterDrawer
          selector={filterSelector}
          handleFinish={setFilters}
          isFilterVisible={isFilterVisible}
          hideDrawer={onFilterToggle(false)}
          filterItems={filterItems}
        />
      )}
    </>
  );
};

TableWithFilter.defaultProps = {
  initialPageSize: DEFAULT_PAGE_SIZE,
  onRow: () => null,
  filterItems: [],
};

TableWithFilter.propTypes = {
  filterSelector: PropTypes.func,
  selector: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
  additionalLoadData: PropTypes.arrayOf(
    PropTypes.shape({ action: PropTypes.func, value: PropTypes.object }),
  ),
  title: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.func.isRequired,
        PropTypes.string.isRequired,
        PropTypes.node.isRequired,
      ]),
      dataIndex: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      render: PropTypes.func,
    }).isRequired,
  ).isRequired,
  filterItems: PropTypes.array,
  onRow: PropTypes.func,
};

export default React.memo(TableWithFilter);
