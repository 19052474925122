import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';

import StyledCheckbox from 'components/Common/Buttons/StyledCheckbox';
import FormInput from 'components/Common/Input/FormInput';

import styles from './styles.module.less';

const CheckboxGroup = ({ name, formLabel, options, ...other }) => (
  <FormInput name={name} label={formLabel} {...other}>
    <Checkbox.Group>
      {options.map(({ label, value }) => (
        <StyledCheckbox
          key={value}
          label={label}
          value={value}
          className={styles.checkbox}
        />
      ))}
    </Checkbox.Group>
  </FormInput>
);

CheckboxGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  formLabel: PropTypes.string,
};

export default CheckboxGroup;
