import { useQuery } from '@tanstack/react-query';
import { api } from 'api';
import { queryKeys } from 'utils/queryKeys';

export const useAccountById = ({ accountId }) => {
  const { data: account, isLoading: isAccountLoading } = useQuery({
    queryKey: queryKeys.account(accountId),
    queryFn: async () => {
      const { data } = await api.getAccountById(accountId);

      return data;
    },
  });

  return {
    account,
    isAccountLoading,
  };
};
