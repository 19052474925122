import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import Actions from '../actions';

const initialState = {
  settings: [],
};

export default createReducer(initialState)
  .handleAction(
    Actions.loadSettings.success,
    produce((state, { payload }) => {
      state.settings = payload;
    }),
  )
  .handleAction(
    Actions.resetSettings,
    produce((state) => {
      state.settings = [];
    }),
  );
