import { clamp, pick, sum, values, round, entries } from 'lodash';

const fieldsNames = ['ib', 'partnerIb', 'masterIb', 'selfIb'];

export const normalizeFields = (value, name, allFields) => {
  const normalizedValue = clamp(round(value, 2), 0, 100);
  const fields = pick(allFields, fieldsNames);
  fields[name] = normalizedValue;

  const fieldsSum = sum(values(fields));
  const diff = round(100 - fieldsSum, 2);

  if (diff !== 0) {
    const nameIdx = fieldsNames.findIndex((fieldName) => fieldName === name);
    const nextName = fieldsNames[nameIdx + 1] ?? fieldsNames[0];

    return normalizeFields(fields[nextName] + diff, nextName, fields);
  }

  return entries(fields).map(([name, value]) => ({
    name,
    value,
  }));
};
