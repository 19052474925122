import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import Actions from '../actions';

const getPartnerGroups = () => {
  const tools = Array(20).fill(0);

  return tools.map((tool, index) => ({
    id: Math.floor(Math.random() * 100),
    key: index,
    name: 'cpa1stfund-' + index,
    members: Math.floor(Math.random() * 100),
  }));
};

const initialState = {
  manager: [
    { value: 'Zulfia' },
    { value: 'Fusion support' },
    { value: 'Phil' },
  ],
  access: [{ value: 'Manager' }, { value: 'AD' }],
  status: [{ value: 'approved' }, { value: 'declined' }, { value: 'pending' }],
  group: [{ value: 'Standard' }, { value: 'Unassigned' }],
  editableStatus: [{ value: 'Enabled' }, { value: 'Disabled' }],
  partnersGroup: getPartnerGroups(),
  settings: [
    {
      enabled: true,
      type: 'sourceType',
      text: 'Source type',
    },
    {
      enabled: true,
      type: 'created',
      text: 'Created',
    },
    {
      enabled: true,
      type: 'lastLogin',
      text: 'Last Login',
    },
    {
      enabled: true,
      type: 'managerId',
      text: 'Manager',
    },
    {
      enabled: true,
      type: 'status',
      text: 'Status',
    },
    {
      enabled: true,
      type: 'groupId',
      text: 'Group',
    },
    {
      enabled: true,
      type: 'earnings',
      text: 'Earnings',
    },
    {
      enabled: true,
      type: 'leads',
      text: 'leads',
    },
  ],
  editable: false,
  list: [
    {
      key: '11',
      id: '123123123132',
      name: 'Marvin McKinney',
      country: 'US',
      sourceType: 'Client',
      ftd: 1,
      created: Date.now(),
      lastLogin: Date.now(),
      manager: 'Zulfia',
      status: 'approved',
      group: 'Standard',
      earning: 123123,
      leads: 123123123,
      editable: false,
    },
    {
      key: '111',
      id: '6517',
      name: 'Max Brown',
      country: 'US',
      sourceType: 'IB',
      ftd: 1120000,
      created: Date.now(),
      lastLogin: Date.now(),
      manager: 'Fusion support',
      status: 'approved',
      group: 'Unassigned',
      earning: 11,
      leads: 11,
      editable: false,
    },
    {
      key: '1111',
      id: '6571',
      name: 'Max Brown',
      country: 'US',
      sourceType: 'Client',
      ftd: 120000,
      created: 1241235135,
      lastLogin: Date.now(),
      manager: 'Fusion support',
      status: 'approved',
      group: 'Unassigned',
      earning: 11,
      leads: 11,
      editable: false,
    },
    {
      key: '11111',
      id: '657',
      name: 'Max Brown',
      country: 'US',
      sourceType: 'Client',
      ftd: 120000,
      created: 12512351253,
      lastLogin: Date.now(),
      manager: 'Fusion support',
      status: 'approved',
      group: 'Unassigned',
      earning: 11,
      leads: 11,
      editable: false,
    },
    {
      key: '1111111',
      id: '657',
      name: 'Max Brown',
      country: 'US',
      sourceType: 'Client',
      ftd: 120000,
      created: 14251235,
      lastLogin: Date.now(),
      manager: 'Fusion support',
      status: 'approved',
      group: 'Unassigned',
      earning: 11,
      leads: 11,
      editable: false,
    },
    {
      key: '11asd',
      id: '657',
      name: 'Max Brown',
      country: 'US',
      sourceType: 'Client',
      ftd: 120000,
      created: Date.now(),
      lastLogin: Date.now(),
      manager: 'Fusion support',
      status: 'approved',
      group: 'Unassigned',
      earning: 11,
      leads: 11,
      editable: false,
    },
    {
      key: '1zdv1',
      id: '657',
      name: 'Max Brown',
      country: 'US',
      sourceType: 'Client',
      ftd: 120000,
      created: Date.now(),
      lastLogin: Date.now(),
      manager: 'Fusion support',
      status: 'approved',
      group: 'Unassigned',
      earning: 11,
      leads: 11,
      editable: false,
    },
    {
      key: '11sd',
      id: '657',
      name: 'Max Brown',
      country: 'US',
      sourceType: 'Client',
      ftd: 120000,
      created: Date.now(),
      lastLogin: Date.now(),
      manager: 'Fusion support',
      status: 'approved',
      group: 'Unassigned',
      earning: 11,
      leads: 11,
      editable: false,
    },
    {
      key: '11sdf',
      id: '657',
      name: 'Max Brown',
      country: 'US',
      sourceType: 'Client',
      ftd: 120000,
      created: Date.now(),
      lastLogin: Date.now(),
      manager: 'Fusion support',
      status: 'approved',
      group: 'Unassigned',
      earning: 11,
      leads: 11,
      editable: false,
    },
    {
      key: '11fasd',
      id: '657',
      name: 'Max Brown',
      country: 'US',
      sourceType: 'Client',
      ftd: 120000,
      created: Date.now(),
      lastLogin: Date.now(),
      manager: 'Fusion support',
      status: 'approved',
      group: 'Unassigned',
      earning: 11,
      leads: 11,
      editable: false,
    },
    {
      key: '1fcs1',
      id: '657',
      name: 'Max Brown',
      country: 'US',
      sourceType: 'Client',
      ftd: 120000,
      created: Date.now(),
      lastLogin: Date.now(),
      manager: 'Fusion support',
      status: 'approved',
      group: 'Unassigned',
      earning: 11,
      leads: 11,
      editable: false,
    },
  ],
};

export default createReducer(initialState)
  .handleAction(
    Actions.loadDashboardList.request,
    produce((state, { payload }) => {
      state.list = payload;
    }),
  )
  .handleAction(
    Actions.loadEditableValue.request,
    produce((state, { payload }) => {
      state.editable = payload;
    }),
  )
  .handleAction(
    Actions.loadStaffDashboardSettings.request,
    produce((state, { payload }) => {
      state.settings = payload;
    }),
  );
