import { LOGO_CONFIG } from './logo';
import { TITLE_CONFIG } from './title';
import { ResourceName } from './resourceNames';
import { RESET_PASSWORD_BACKGROUND_CONFIG } from './resetPasswordBackground';
import { SIGN_IN_BACKGROUND_CONFIG } from './signinBackground';
import { ACTIVITY_METRICS_CONFIG } from './activityMetrics';
import { AVATAR_CONFIG } from './avatar';
import { REV_SHARE_CONFIG } from './revShare';
import { REPORTS_CONFIG } from './reports';
import { CLIENT_PROFILE_COMMISSION_SOURCE_TAB_CONFIG } from './clientProfileCommissionSourceTab';
import { MY_COMMISSION_COM_SOURCE_CONFIG } from './myCommissionComSourceTab';
import { HAS_SUB_IB_API_CALL_CONFIG } from './subIbApiCall';
import { SIGN_UP_LINK_CONFIG } from './signUpLink';
import { FEES_SYMBOLS_OVERVIEW_BY_USER_ID_REQUEST_CONFIG } from './feesSymbolsOverviewByUserIdApiRequest';

const GENERIC_BRANDED_RESOURCE_MAP = {
  [ResourceName.LOGO]: LOGO_CONFIG,
  [ResourceName.TITLE]: TITLE_CONFIG,
  [ResourceName.SIGN_IN_BACKGROUND]: SIGN_IN_BACKGROUND_CONFIG,
  [ResourceName.RESET_PASSWORD_BACKGROUND]: RESET_PASSWORD_BACKGROUND_CONFIG,
  [ResourceName.ACTIVITY_METRICS]: ACTIVITY_METRICS_CONFIG,
  [ResourceName.AVATAR]: AVATAR_CONFIG,
  [ResourceName.REV_SHARE]: REV_SHARE_CONFIG,
  [ResourceName.REPORTS]: REPORTS_CONFIG,
  [ResourceName.CLIENT_PROFILE_COMMISSION_SOURCE_TAB]:
    CLIENT_PROFILE_COMMISSION_SOURCE_TAB_CONFIG,
  [ResourceName.MY_COMMISSION_COM_SOURCE_TAB]: MY_COMMISSION_COM_SOURCE_CONFIG,
  [ResourceName.HAS_SUB_IB_API_CALL_FUNCTION]: HAS_SUB_IB_API_CALL_CONFIG,
  [ResourceName.SIGN_UP_LINK]: SIGN_UP_LINK_CONFIG,
  [ResourceName.FEES_SYMBOLS_OVERVIEW_BY_USER_ID_REQUEST]:
    FEES_SYMBOLS_OVERVIEW_BY_USER_ID_REQUEST_CONFIG,
};

export const getResource = (resource) => GENERIC_BRANDED_RESOURCE_MAP[resource];
