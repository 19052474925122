import { useCallback, useMemo, useState } from 'react';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import merge from 'lodash/merge';

const DEFAULT_STATE = {
  pagination: {
    limit: DEFAULT_PAGE_SIZE,
    offset: 0,
  },
  filters: {},
  sorter: {
    orderBy: 'createdAt',
    orderDirection: 'descend',
  },
};

export const useTableState = (initialState = {}) => {
  const defaultState = useMemo(
    () => merge({}, DEFAULT_STATE, initialState),
    [],
  );

  const [state, setState] = useState(defaultState);

  return {
    tableState: state,
    setTableState: useCallback(
      (page, pageSize, filters, sorter) => {
        const newState = {
          pagination: {
            limit: pageSize || defaultState.pagination.limit,
            offset: page ? page * pageSize : 0,
          },
          filters,
          sorter: {
            orderBy: sorter?.orderBy || defaultState.sorter.orderBy,
            orderDirection:
              sorter?.orderDirection || defaultState.sorter.orderDirection,
          },
        };

        setState(newState);
      },
      [defaultState],
    ),
  };
};
