import React from 'react';

const FMAvatar = () => (
  <svg
    width="41"
    height="41"
    viewBox="0 0 41 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41 20.5721C41 25.4267 39.3137 29.8968 36.4712 33.4536C35.1704 35.0879 33.6287 36.4818 31.8942 37.6354C31.6533 37.8276 31.3643 37.9718 31.1234 38.116C29.8707 38.8851 28.5699 39.4618 27.1727 39.9425C27.0282 39.9906 26.8355 40.0386 26.6428 40.0867C26.2092 40.2309 25.7756 40.327 25.342 40.4232C25.1974 40.4712 25.0047 40.5193 24.8602 40.5193C23.463 40.8077 21.9694 40.9999 20.4759 40.9999C19.9459 40.9999 19.416 41 18.886 40.9519C18.7415 40.9519 18.6451 40.9038 18.5006 40.9038C16.5734 40.7116 14.7427 40.279 13.0082 39.606C12.8155 39.5099 12.6228 39.4618 12.4783 39.3657C11.9001 39.1254 11.2738 38.837 10.7438 38.5486C10.5993 38.4525 10.4548 38.3563 10.3102 38.3083C10.3102 38.3083 10.262 38.3083 10.262 38.2602C10.1175 38.1641 9.92479 38.0679 9.78026 37.9718C8.14219 36.9624 6.69683 35.7608 5.39601 34.3669C5.34783 34.3188 5.34783 34.2708 5.29965 34.2708C2.0235 30.6178 0 25.8112 0 20.524C0 9.18053 9.20212 0 20.5723 0C31.9424 0 41 9.22859 41 20.5721Z"
      fill="url(#paint0_linear_510_3751)"
    />
    <path
      d="M12.0453 36.241L10.6481 38.5962C10.5036 38.5001 10.359 38.404 10.2145 38.3559C10.2145 38.3559 10.1663 38.3559 10.1663 38.3078C10.0218 38.2117 9.82908 38.1156 9.68454 38.0194C8.04647 37.0101 6.60111 35.8084 5.30028 34.4145L5.54118 33.9819L9.58818 35.3758L10.7445 35.7604L11.6599 36.0968L12.0453 36.241Z"
      fill="#943E29"
    />
    <path
      d="M13.8758 32.8286L11.6596 36.0971L11.4669 36.3855L10.1661 38.3081C10.0216 38.212 9.82884 38.1159 9.68431 38.0197C8.04623 37.0103 6.60087 35.8087 5.30005 34.4148C5.25187 34.3667 5.25187 34.3187 5.20369 34.3187L6.45634 32.2038L9.39523 27.3492L9.44341 27.253L9.58795 27.0127C9.63613 26.9166 9.68431 26.8685 9.78066 26.7724L9.82884 26.7243C10.0216 26.532 10.2625 26.3879 10.5515 26.2917C10.5997 26.2917 10.6479 26.2437 10.6961 26.2437C10.7924 26.2437 10.8888 26.2437 10.9851 26.2437C11.6596 26.2437 12.2859 26.6282 12.5268 27.3011L13.4422 29.7044L13.7313 30.4254L14.0686 31.3386C14.2613 31.8193 14.1649 32.396 13.8758 32.8286Z"
      fill="#2CCDCD"
    />
    <path
      d="M32.8094 27.3007L32.2795 33.1647L32.2313 33.5973L32.0386 35.616L31.8458 37.6348C31.605 37.8271 31.3159 37.9713 31.075 38.1154C29.8223 38.8845 28.5215 39.4613 27.1243 39.9419C26.9798 39.99 26.7871 40.0381 26.5944 40.0861C26.1608 40.2303 25.7272 40.3265 25.2936 40.4226C25.149 40.4707 24.9563 40.5187 24.8118 40.5187C23.4146 40.8071 21.9211 40.9994 20.4275 40.9994C19.8976 40.9994 19.3676 40.9994 18.8376 40.9513C18.6931 40.9513 18.5967 40.9033 18.4522 40.9033C16.525 40.711 14.6943 40.2784 12.9598 39.6055C12.7671 39.5094 12.5744 39.4613 12.4299 39.3652C11.8517 39.1248 11.2254 38.8364 10.6954 38.548C10.5509 38.4519 10.4064 38.3558 10.2618 38.3077C10.2618 38.3077 10.2137 38.3077 10.2137 38.2596C10.0691 38.1635 9.8764 38.0674 9.73186 37.9713L9.6355 35.3277V34.7509L9.34644 28.0697C9.34644 27.9255 9.34643 27.7333 9.39461 27.5891C9.39461 27.5891 9.39461 27.5891 9.39461 27.541C9.39461 27.493 9.44279 27.4449 9.44279 27.3968C9.44279 27.3488 9.44279 27.3488 9.49097 27.3007C9.49097 27.3007 9.49097 27.2526 9.53915 27.2526C9.6355 27.0604 9.73186 26.9162 9.8764 26.772L9.92458 26.7239C10.1173 26.5316 10.3582 26.3874 10.6473 26.2913L10.7918 26.2432L17.7777 24.1764L19.8975 23.5516L20.3312 23.4554L21.0538 23.2632L23.4628 23.7919L25.1008 24.1284L25.5344 24.2245L31.4122 25.4742C31.5568 25.5223 31.7495 25.5703 31.894 25.6184C32.4722 25.8587 32.8576 26.5316 32.8094 27.3007Z"
      fill="#2CCDCD"
    />
    <path
      d="M36.4706 33.4061C35.1698 35.0403 33.628 36.4342 31.8936 37.5878C31.6527 37.78 31.3636 37.9242 31.1228 38.0684L30.4964 36.4823L29.1956 33.2619L29.0029 32.7812L27.943 30.1376C27.2203 28.4073 28.0393 26.3885 29.7738 25.6194C30.2555 25.4272 30.6891 25.3311 31.1709 25.3311C31.4118 25.3311 31.7009 25.3791 31.9418 25.4272C33.0499 25.6675 34.0135 26.4846 34.4471 27.6382L36.4706 33.0696V33.4061Z"
      fill="#2CCDCD"
    />
    <path
      d="M15.4656 12.7859C15.4656 12.7859 13.5866 11.7765 13.8757 10.4307C14.1647 9.08485 20.717 7.16222 23.3187 9.4213C23.3187 9.4213 25.2458 9.13291 24.4268 14.7566C23.6077 20.3322 16.4291 15.814 16.4291 15.814"
      fill="#3B140B"
    />
    <path
      d="M15.1285 11.2954C15.1285 11.2954 12.7677 19.7549 18.2119 21.1969L17.4411 25.8112C17.3447 26.2438 17.6338 26.6764 18.0674 26.8206C19.1273 27.157 21.199 27.4935 23.2707 25.667C23.5116 25.4748 23.6079 25.1864 23.5597 24.898C23.367 23.6483 22.9334 20.0433 23.1261 17.7843C23.1261 17.7843 25.3424 17.7362 25.1978 15.7655C25.0533 13.7948 23.5116 13.9871 22.6443 15.2848C22.6443 15.2848 21.6808 13.8909 21.9698 11.5838C22.018 11.5838 17.8747 12.2567 15.1285 11.2954Z"
      fill="#FAA08A"
    />
    <path
      d="M18.2117 21.1969C18.2117 21.1969 20.187 21.3892 21.0543 19.8511C21.0543 19.8511 21.4879 22.0621 18.019 22.3505L18.2117 21.1969Z"
      fill="#5A281D"
    />
    <path
      d="M16.8 31.9316H23.2599C24.0108 31.9316 24.6195 32.5404 24.6195 33.2913C24.6195 34.0422 24.0108 34.6509 23.2599 34.6509H16.8C16.0491 34.6509 15.4404 34.0422 15.4404 33.2913C15.4404 32.5404 16.0491 31.9316 16.8 31.9316Z"
      fill="#FAFF00"
    />
    <defs>
      <linearGradient
        id="paint0_linear_510_3751"
        x1="41"
        y1="20.5"
        x2="0"
        y2="20.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#742CCE" />
        <stop offset="1" stopColor="#1811A2" />
      </linearGradient>
    </defs>
  </svg>
);

export default FMAvatar;
