import React from 'react';
import MyClientsTradesTable from './MyClientsTradesTable';
import MyPartnersTradesTable from './MyPartnersTradesTable';
import PropTypes from 'prop-types';

const TradesTable = ({ dateRange, showSummary = false, title, showSubIb }) =>
  showSubIb ? (
    <MyPartnersTradesTable
      dateRange={dateRange}
      showSummary={showSummary}
      title={title}
    />
  ) : (
    <MyClientsTradesTable
      dateRange={dateRange}
      showSummary={showSummary}
      title={title}
    />
  );

TradesTable.propTypes = {
  dateRange: PropTypes.array,
  title: PropTypes.string,
  showSummary: PropTypes.bool,
  showSubIb: PropTypes.bool,
};

export default TradesTable;
