import { takeLatest, put } from 'redux-saga/effects';
import actions from 'redux/actions';
import { api } from 'api';

function* callLoadAdjustmentsReport({ payload }) {
  try {
    const response = yield api.getPayments(payload);
    yield put(actions.loadAdjustmentsReport.success(response.data));
  } catch (error) {
    yield put(actions.loadAdjustmentsReport.failure(error));
  }
}

function* callLoadAdjustmentsReportLimits({ payload }) {
  try {
    const response = yield api.getPaymentsLimits(payload);
    yield put(actions.loadAdjustmentsReportLimits.success(response.data));
  } catch (error) {
    yield put(actions.loadAdjustmentsReportLimits.failure(error));
  }
}

function* callExportAdjustmentsReport({
  payload: { requestData, onSuccess, onFailure, fileExtension },
}) {
  try {
    const response = yield api.getPayments(requestData);
    onSuccess(response.data, fileExtension);
  } catch (error) {
    onFailure(error);
  }
}

export function* adjustmentsSaga() {
  yield takeLatest(
    actions.loadAdjustmentsReport.request,
    callLoadAdjustmentsReport,
  );
  yield takeLatest(
    actions.loadAdjustmentsReportLimits.request,
    callLoadAdjustmentsReportLimits,
  );
  yield takeLatest(
    actions.exportAdjustmentsReport.request,
    callExportAdjustmentsReport,
  );
}
