import React from 'react';
import PropTypes from 'prop-types';

import PaginationPanel from 'components/PaginationPanel';
import StyledLink from 'components/Common/Buttons/StyledLink';

import styles from './styles.module.less';

const TableFooter = ({
  link,
  pageSize,
  total,
  currentPage,
  currentPageCount,
  handlePageChange,
  handleLoadMore,
}) => {
  if (link) {
    return (
      <StyledLink
        href={link}
        text="See all clients"
        className={styles.seeAllLink}
      />
    );
  }

  return (
    <PaginationPanel
      totalItems={total}
      displayedCount={currentPageCount}
      handlePageChange={handlePageChange}
      handleLoadMore={handleLoadMore}
      currentPage={currentPage}
      pageSize={pageSize}
      paginationClass={styles.pagination}
    />
  );
};

TableFooter.propTypes = {
  handlePageChange: PropTypes.func,
  handleLoadMore: PropTypes.func,
  currentPageCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  total: PropTypes.number,
  link: PropTypes.string,
};

export default TableFooter;
