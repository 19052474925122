import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import AuthLayout from 'HOC/AuthLayout';
import SuspenseWrapper from 'HOC/SuspenseWrapper';
import { PrivateRoute } from './PrivateRoute';
import Routing from './Routing';

import { ROUTING } from 'config';

const SignIn = React.lazy(() =>
  import('containers/Common/Authorization/SignIn'),
);
const SetupMfa = React.lazy(() => import('containers/Staff/SetupMfa'));

const NewPassword = React.lazy(() =>
  import('containers/Common/Authorization/NewPassword'),
);
const ResetPassword = React.lazy(() =>
  import('containers/Common/Authorization/ResetPassword'),
);

const Routes = () => (
  <Switch>
    <Route exact path={ROUTING.LOGIN}>
      <AuthLayout>
        <SuspenseWrapper position="center">
          <SignIn />
        </SuspenseWrapper>
      </AuthLayout>
    </Route>
    <Route exact path={ROUTING.NEWPASSWORD}>
      <AuthLayout type="newPassword">
        <SuspenseWrapper position="center">
          <NewPassword />
        </SuspenseWrapper>
      </AuthLayout>
    </Route>
    <Route exact path={ROUTING.RESET}>
      <AuthLayout type="resetPassword">
        <SuspenseWrapper position="center">
          <ResetPassword />
        </SuspenseWrapper>
      </AuthLayout>
    </Route>
    <PrivateRoute exact path={ROUTING.SETUP_MFA}>
      <AuthLayout type="mfaSetup">
        <SuspenseWrapper position="center">
          <SetupMfa />
        </SuspenseWrapper>
      </AuthLayout>
    </PrivateRoute>
    <PrivateRoute path={'/:section/:sectionChild?'}>
      <Routing />
    </PrivateRoute>
    <Redirect from="*" to={ROUTING.DASHBOARD} />
  </Switch>
);
export default Routes;
