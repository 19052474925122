export const COUNTRY_CODES_MAP = {
  AD: { value: 'Andorra', code: '+376' },
  AE: { value: 'United Arab Emirates', code: '+971' },
  AF: { value: 'Afghanistan', code: '+93' },
  AG: { value: 'Antigua and Barbuda', code: '+1268' },
  AI: { value: 'Anguilla', code: '+1264' },
  AL: { value: 'Albania', code: '+355' },
  AM: { value: 'Armenia', code: '+374' },
  AO: { value: 'Angola', code: '+244' },
  AQ: { value: 'Antarctica', code: '+672' },
  AR: { value: 'Argentina', code: '+54' },
  AS: { value: 'American Samoa', code: '+1684' },
  AT: { value: 'Austria', code: '+43' },
  AU: { value: 'Australia', code: '+61' },
  AW: { value: 'Aruba', code: '+297' },
  AX: { value: 'Åland Islands', code: '+35818' },
  AZ: { value: 'Azerbaijan', code: '+994' },
  BA: { value: 'Bosnia and Herzegovina', code: '+387' },
  BB: { value: 'Barbados', code: '+1246' },
  BD: { value: 'Bangladesh', code: '+880' },
  BE: { value: 'Belgium', code: '+32' },
  BF: { value: 'Burkina Faso', code: '+226' },
  BG: { value: 'Bulgaria', code: '+359' },
  BH: { value: 'Bahrain', code: '+973' },
  BI: { value: 'Burundi', code: '+257' },
  BJ: { value: 'Benin', code: '+229' },
  BL: { value: 'Saint Barthélemy', code: '+590' },
  BM: { value: 'Bermuda', code: '+1441' },
  BN: { value: 'Brunei', code: '+673' },
  BO: { value: 'Bolivia', code: '+591' },
  BQ: { value: 'Caribbean Netherlands', code: '+599' },
  BR: { value: 'Brazil', code: '+55' },
  BS: { value: 'Bahamas', code: '+1242' },
  BT: { value: 'Bhutan', code: '+975' },
  BV: { value: 'Bouvet Island', code: '+47' },
  BW: { value: 'Botswana', code: '+267' },
  BY: { value: 'Belarus', code: '+375' },
  BZ: { value: 'Belize', code: '+501' },
  CA: { value: 'Canada', code: '+1' },
  CC: { value: 'Cocos (Keeling) Islands', code: '+61' },
  CD: { value: 'DR Congo', code: '+243' },
  CF: { value: 'Central African Republic', code: '+236' },
  CG: { value: 'Republic of the Congo', code: '+242' },
  CH: { value: 'Switzerland', code: '+41' },
  CI: { value: 'Ivory Coast', code: '+225' },
  CK: { value: 'Cook Islands', code: '+682' },
  CL: { value: 'Chile', code: '+56' },
  CM: { value: 'Cameroon', code: '+237' },
  CN: { value: 'China', code: '+86' },
  CO: { value: 'Colombia', code: '+57' },
  CR: { value: 'Costa Rica', code: '+506' },
  CU: { value: 'Cuba', code: '+53' },
  CV: { value: 'Cape Verde', code: '+238' },
  CW: { value: 'Curaçao', code: '+599' },
  CX: { value: 'Christmas Island', code: '+61' },
  CY: { value: 'Cyprus', code: '+357' },
  CZ: { value: 'Czechia', code: '+420' },
  DE: { value: 'Germany', code: '+49' },
  DJ: { value: 'Djibouti', code: '+253' },
  DK: { value: 'Denmark', code: '+45' },
  DM: { value: 'Dominica', code: '+1767' },
  DO: { value: 'Dominican Republic', code: '+1809' },
  DZ: { value: 'Algeria', code: '+213' },
  EC: { value: 'Ecuador', code: '+593' },
  EE: { value: 'Estonia', code: '+372' },
  EG: { value: 'Egypt', code: '+20' },
  EH: { value: 'Western Sahara', code: '+2125288' },
  ER: { value: 'Eritrea', code: '+291' },
  ES: { value: 'Spain', code: '+34' },
  ET: { value: 'Ethiopia', code: '+251' },
  FI: { value: 'Finland', code: '+358' },
  FJ: { value: 'Fiji', code: '+679' },
  FK: { value: 'Falkland Islands', code: '+500' },
  FM: { value: 'Micronesia', code: '+691' },
  FO: { value: 'Faroe Islands', code: '+298' },
  FR: { value: 'France', code: '+33' },
  GA: { value: 'Gabon', code: '+241' },
  GB: { value: 'United Kingdom', code: '+44' },
  GD: { value: 'Grenada', code: '+1473' },
  GE: { value: 'Georgia', code: '+995' },
  GF: { value: 'French Guiana', code: '+594' },
  GG: { value: 'Guernsey', code: '+44' },
  GH: { value: 'Ghana', code: '+233' },
  GI: { value: 'Gibraltar', code: '+350' },
  GL: { value: 'Greenland', code: '+299' },
  GM: { value: 'Gambia', code: '+220' },
  GN: { value: 'Guinea', code: '+224' },
  GP: { value: 'Guadeloupe', code: '+590' },
  GQ: { value: 'Equatorial Guinea', code: '+240' },
  GR: { value: 'Greece', code: '+30' },
  GS: { value: 'South Georgia', code: '+500' },
  GT: { value: 'Guatemala', code: '+502' },
  GU: { value: 'Guam', code: '+1671' },
  GW: { value: 'Guinea-Bissau', code: '+245' },
  GY: { value: 'Guyana', code: '+592' },
  HK: { value: 'Hong Kong', code: '+852' },
  HN: { value: 'Honduras', code: '+504' },
  HR: { value: 'Croatia', code: '+385' },
  HT: { value: 'Haiti', code: '+509' },
  HU: { value: 'Hungary', code: '+36' },
  ID: { value: 'Indonesia', code: '+62' },
  IE: { value: 'Ireland', code: '+353' },
  IL: { value: 'Israel', code: '+972' },
  IM: { value: 'Isle of Man', code: '+44' },
  IN: { value: 'India', code: '+91' },
  IO: { value: 'British Indian Ocean Territory', code: '+246' },
  IQ: { value: 'Iraq', code: '+964' },
  IR: { value: 'Iran', code: '+98' },
  IS: { value: 'Iceland', code: '+354' },
  IT: { value: 'Italy', code: '+39' },
  JE: { value: 'Jersey', code: '+44' },
  JM: { value: 'Jamaica', code: '+1876' },
  JO: { value: 'Jordan', code: '+962' },
  JP: { value: 'Japan', code: '+81' },
  KE: { value: 'Kenya', code: '+254' },
  KG: { value: 'Kyrgyzstan', code: '+996' },
  KH: { value: 'Cambodia', code: '+855' },
  KI: { value: 'Kiribati', code: '+686' },
  KM: { value: 'Comoros', code: '+269' },
  KN: { value: 'Saint Kitts and Nevis', code: '+1869' },
  KP: { value: 'North Korea', code: '+850' },
  KR: { value: 'South Korea', code: '+82' },
  KW: { value: 'Kuwait', code: '+965' },
  KY: { value: 'Cayman Islands', code: '+1345' },
  KZ: { value: 'Kazakhstan', code: '+76' },
  LA: { value: 'Laos', code: '+856' },
  LB: { value: 'Lebanon', code: '+961' },
  LC: { value: 'Saint Lucia', code: '+1758' },
  LI: { value: 'Liechtenstein', code: '+423' },
  LK: { value: 'Sri Lanka', code: '+94' },
  LR: { value: 'Liberia', code: '+231' },
  LS: { value: 'Lesotho', code: '+266' },
  LT: { value: 'Lithuania', code: '+370' },
  LU: { value: 'Luxembourg', code: '+352' },
  LV: { value: 'Latvia', code: '+371' },
  LY: { value: 'Libya', code: '+218' },
  MA: { value: 'Morocco', code: '+212' },
  MC: { value: 'Monaco', code: '+377' },
  MD: { value: 'Moldova', code: '+373' },
  ME: { value: 'Montenegro', code: '+382' },
  MF: { value: 'Saint Martin', code: '+590' },
  MG: { value: 'Madagascar', code: '+261' },
  MH: { value: 'Marshall Islands', code: '+692' },
  MK: { value: 'North Macedonia', code: '+389' },
  ML: { value: 'Mali', code: '+223' },
  MM: { value: 'Myanmar', code: '+95' },
  MN: { value: 'Mongolia', code: '+976' },
  MO: { value: 'Macau', code: '+853' },
  MP: { value: 'Northern Mariana Islands', code: '+1670' },
  MQ: { value: 'Martinique', code: '+596' },
  MR: { value: 'Mauritania', code: '+222' },
  MS: { value: 'Montserrat', code: '+1664' },
  MT: { value: 'Malta', code: '+356' },
  MU: { value: 'Mauritius', code: '+230' },
  MV: { value: 'Maldives', code: '+960' },
  MW: { value: 'Malawi', code: '+265' },
  MX: { value: 'Mexico', code: '+52' },
  MY: { value: 'Malaysia', code: '+60' },
  MZ: { value: 'Mozambique', code: '+258' },
  NA: { value: 'Namibia', code: '+264' },
  NC: { value: 'New Caledonia', code: '+687' },
  NE: { value: 'Niger', code: '+227' },
  NF: { value: 'Norfolk Island', code: '+672' },
  NG: { value: 'Nigeria', code: '+234' },
  NI: { value: 'Nicaragua', code: '+505' },
  NL: { value: 'Netherlands', code: '+31' },
  NO: { value: 'Norway', code: '+47' },
  NP: { value: 'Nepal', code: '+977' },
  NR: { value: 'Nauru', code: '+674' },
  NU: { value: 'Niue', code: '+683' },
  NZ: { value: 'New Zealand', code: '+64' },
  OM: { value: 'Oman', code: '+968' },
  PA: { value: 'Panama', code: '+507' },
  PE: { value: 'Peru', code: '+51' },
  PF: { value: 'French Polynesia', code: '+689' },
  PG: { value: 'Papua New Guinea', code: '+675' },
  PH: { value: 'Philippines', code: '+63' },
  PK: { value: 'Pakistan', code: '+92' },
  PL: { value: 'Poland', code: '+48' },
  PM: { value: 'Saint Pierre and Miquelon', code: '+508' },
  PN: { value: 'Pitcairn Islands', code: '+64' },
  PR: { value: 'Puerto Rico', code: '+1787' },
  PS: { value: 'Palestine', code: '+970' },
  PT: { value: 'Portugal', code: '+351' },
  PW: { value: 'Palau', code: '+680' },
  PY: { value: 'Paraguay', code: '+595' },
  QA: { value: 'Qatar', code: '+974' },
  RE: { value: 'Réunion', code: '+262' },
  RO: { value: 'Romania', code: '+40' },
  RS: { value: 'Serbia', code: '+381' },
  RU: { value: 'Russia', code: '+73' },
  RW: { value: 'Rwanda', code: '+250' },
  SA: { value: 'Saudi Arabia', code: '+966' },
  SB: { value: 'Solomon Islands', code: '+677' },
  SC: { value: 'Seychelles', code: '+248' },
  SD: { value: 'Sudan', code: '+249' },
  SE: { value: 'Sweden', code: '+46' },
  SG: { value: 'Singapore', code: '+65' },
  SH: { value: 'Saint Helena, Ascension and Tristan da Cunha', code: '+290' },
  SI: { value: 'Slovenia', code: '+386' },
  SJ: { value: 'Svalbard and Jan Mayen', code: '+4779' },
  SK: { value: 'Slovakia', code: '+421' },
  SL: { value: 'Sierra Leone', code: '+232' },
  SM: { value: 'San Marino', code: '+378' },
  SN: { value: 'Senegal', code: '+221' },
  SO: { value: 'Somalia', code: '+252' },
  SR: { value: 'Suriname', code: '+597' },
  SS: { value: 'South Sudan', code: '+211' },
  ST: { value: 'São Tomé and Príncipe', code: '+239' },
  SV: { value: 'El Salvador', code: '+503' },
  SX: { value: 'Sint Maarten', code: '+1721' },
  SY: { value: 'Syria', code: '+963' },
  SZ: { value: 'Eswatini', code: '+268' },
  TC: { value: 'Turks and Caicos Islands', code: '+1649' },
  TD: { value: 'Chad', code: '+235' },
  TF: { value: 'French Southern and Antarctic Lands', code: '+262' },
  TG: { value: 'Togo', code: '+228' },
  TH: { value: 'Thailand', code: '+66' },
  TJ: { value: 'Tajikistan', code: '+992' },
  TK: { value: 'Tokelau', code: '+690' },
  TL: { value: 'Timor-Leste', code: '+670' },
  TM: { value: 'Turkmenistan', code: '+993' },
  TN: { value: 'Tunisia', code: '+216' },
  TO: { value: 'Tonga', code: '+676' },
  TR: { value: 'Turkey', code: '+90' },
  TT: { value: 'Trinidad and Tobago', code: '+1868' },
  TV: { value: 'Tuvalu', code: '+688' },
  TW: { value: 'Taiwan', code: '+886' },
  TZ: { value: 'Tanzania', code: '+255' },
  UA: { value: 'Ukraine', code: '+380' },
  UG: { value: 'Uganda', code: '+256' },
  UM: { value: 'United States Minor Outlying Islands', code: '+268' },
  US: { value: 'United States', code: '+1' },
  UY: { value: 'Uruguay', code: '+598' },
  UZ: { value: 'Uzbekistan', code: '+998' },
  VA: { value: 'Vatican City', code: '+3906698' },
  VC: { value: 'Saint Vincent and the Grenadines', code: '+1784' },
  VE: { value: 'Venezuela', code: '+58' },
  VG: { value: 'British Virgin Islands', code: '+1-284' },
  VI: { value: 'United States Virgin Islands', code: '+1-340' },
  VN: { value: 'Vietnam', code: '+84' },
  VU: { value: 'Vanuatu', code: '+678' },
  WF: { value: 'Wallis and Futuna', code: '+681' },
  WS: { value: 'Samoa', code: '+685' },
  XK: { value: 'Kosovo', code: '+383' },
  YE: { value: 'Yemen', code: '+967' },
  YT: { value: 'Mayotte', code: '+262' },
  ZA: { value: 'South Africa', code: '+27' },
  ZM: { value: 'Zambia', code: '+260' },
  ZW: { value: 'Zimbabwe', code: '+263' },
  HM: { value: 'Heard Island and McDonald Islands', code: '' },
};
