import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import clsx from 'clsx';

import styles from './styles.module.less';

const Spinner = ({
  spinning,
  wrapperClassName,
  className,
  spinFontSize = 48,
  children,
  ...props
}) => (
  <div className={clsx(styles.spinnerWrapper, wrapperClassName)}>
    <Spin
      spinning={spinning}
      indicator={<LoadingOutlined style={{ fontSize: spinFontSize }} spin />}
      className={clsx(styles.spinner, className)}
      {...props}
    >
      {children}
    </Spin>
  </div>
);

Spinner.propTypes = {
  spinning: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  spinFontSize: PropTypes.number,
  children: PropTypes.node,
};

export default Spinner;
