import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import MainLayout from 'HOC/MainLayout';
import SuspenseWrapper from 'HOC/SuspenseWrapper';

const Dashboard = React.lazy(() => import('containers/Brokers/Dashboard'));
const MyCommission = React.lazy(() =>
  import('containers/Brokers/MyCommission'),
);
const Settings = React.lazy(() => import('containers/Common/Settings'));
const Clients = React.lazy(() => import('containers/Brokers/Clients'));
const ClientsProfile = React.lazy(() =>
  import('containers/Brokers/ClientsProfile/ClientsProfileWrapper'),
);

const Withdrawal = React.lazy(() => import('containers/Brokers/Withdrawals'));

import { ROUTING } from 'config';
import { PLATFORM_TYPES } from 'utils/constants';

const ROUTES = [
  {
    path: ROUTING.DASHBOARD,
    Component: Dashboard,
  },
  {
    path: ROUTING.CLIENTS,
    Component: Clients,
  },
  {
    path: ROUTING.CLIENTS_ACTIVE,
    Component: Clients,
  },
  {
    path: ROUTING.CLIENTS_PROFILE,
    Component: ClientsProfile,
  },
  {
    path: ROUTING.COMMISSIONS,
    Component: MyCommission,
  },
  {
    path: ROUTING.SETTING,
    Component: Settings,
  },
  {
    path: ROUTING.WITHDRAWALS,
    Component: Withdrawal,
  },
];

const BrokerRouting = () => (
  <MainLayout type={PLATFORM_TYPES.CLIENTS}>
    <Switch>
      {ROUTES.map(({ path, Component }) => (
        <Route exact path={path} key={path}>
          <SuspenseWrapper>
            <Component />
          </SuspenseWrapper>
        </Route>
      ))}
      <Redirect from="*" to={ROUTING.DASHBOARD} />
    </Switch>
  </MainLayout>
);

export default BrokerRouting;
