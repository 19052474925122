import React from 'react';
import { useUser } from 'utils/hooks/useUser';
import { Redirect } from 'react-router';
import { ROUTING } from 'config';

export const MfaGuard = ({ children }) => {
  const { isTwoFactorAuthEnabled } = useUser();

  if (!isTwoFactorAuthEnabled) {
    return <Redirect to={ROUTING.SETUP_MFA} />;
  }

  return children;
};
