import { createAsyncAction } from 'typesafe-actions';

const accountTransfersReport = {
  loadAccountTransfersReport: createAsyncAction(
    'loadAccountTransfersReport/action/request',
    'loadAccountTransfersReport/action/success',
    'loadAccountTransfersReport/action/failure',
  )(),
  loadAccountTransfersReportLimits: createAsyncAction(
    'loadAccountTransfersReportLimits/action/request',
    'loadAccountTransfersReportLimits/action/success',
    'loadAccountTransfersReportLimits/action/failure',
  )(),
  exportAccountTransfersReport: createAsyncAction(
    'exportAccountTransfersReport/action/request',
    'exportAccountTransfersReport/action/success',
    'exportAccountTransfersReport/action/failure',
  )(),
  approveAccountTransferTaxInvoice: createAsyncAction(
    'approveAccountTransferTaxInvoice/action/request',
    'approveAccountTransferTaxInvoice/action/success',
    'approveAccountTransferTaxInvoice/action/failure',
  )(),
};

const adjustmentsReport = {
  loadAdjustmentsReport: createAsyncAction(
    'loadAdjustmentsReport/action/request',
    'loadAdjustmentsReport/action/success',
    'loadAdjustmentsReport/action/failure',
  )(),
  loadAdjustmentsReportLimits: createAsyncAction(
    'loadAdjustmentsReportLimits/action/request',
    'loadAdjustmentsReportLimits/action/success',
    'loadAdjustmentsReportLimits/action/failure',
  )(),
  exportAdjustmentsReport: createAsyncAction(
    'exportAdjustmentsReport/action/request',
    'exportAdjustmentsReport/action/success',
    'exportAdjustmentsReport/action/failure',
  )(),
};

export default {
  loadCommissionsReport: createAsyncAction(
    'loadCommissionsReport/action/request',
    'loadCommissionsReport/action/success',
    'loadCommissionsReport/action/failure',
  )(),
  loadCommissionsReportLimits: createAsyncAction(
    'loadCommissionsReportLimits/action/request',
    'loadCommissionsReportLimits/action/success',
    'loadCommissionsReportLimits/action/failure',
  )(),
  loadLatestPartnersReport: createAsyncAction(
    'loadLatestPartnersReport/action/request',
    'loadLatestPartnersReport/action/success',
    'loadLatestPartnersReport/action/failure',
  )(),
  loadLatestPartnersReportLimits: createAsyncAction(
    'loadLatestPartnersReportLimits/action/request',
    'loadLatestPartnersReportLimits/action/success',
    'loadLatestPartnersReportLimits/action/failure',
  )(),
  loadRegistrationReport: createAsyncAction(
    'loadRegistrationReport/action/request',
    'loadRegistrationReport/action/success',
    'loadRegistrationReport/action/failure',
  )(),
  loadAllRegistrationReport: createAsyncAction(
    'loadAllRegistrationReport/action/request',
    'loadAllRegistrationReport/action/success',
    'loadAllRegistrationReport/action/failure',
  )(),
  loadTradesReport: createAsyncAction(
    'loadTradesReport/action/request',
    'loadTradesReport/action/success',
    'loadTradesReport/action/failure',
  )(),
  loadTradesReportLimits: createAsyncAction(
    'loadTradesReportLimits/action/request',
    'loadTradesReportLimits/action/success',
    'loadTradesReportLimits/action/failure',
  )(),
  loadPaymentsReport: createAsyncAction(
    'loadPaymentsReport/action/request',
    'loadPaymentsReport/action/success',
    'loadPaymentsReport/action/failure',
  )(),
  loadPaymentsReportLimits: createAsyncAction(
    'loadPaymentsReportLimits/action/request',
    'loadPaymentsReportLimits/action/success',
    'loadPaymentsReportLimits/action/failure',
  )(),
  loadBalancesReport: createAsyncAction(
    'loadBalancesReport/action/request',
    'loadBalancesReport/action/success',
    'loadBalancesReport/action/failure',
  )(),
  loadAllTradesReport: createAsyncAction(
    'loadAllTradesReport/action/request',
    'loadAllTradesReport/action/success',
    'loadAllTradesReport/action/failure',
  )(),
  loadAllPaymentsReport: createAsyncAction(
    'loadAllPaymentsReport/action/request',
    'loadAllPaymentsReport/action/success',
    'loadAllPaymentsReport/action/failure',
  )(),
  loadAllCommissionsReport: createAsyncAction(
    'loadAllCommissionsReport/action/request',
    'loadAllCommissionsReport/action/success',
    'loadAllCommissionsReport/action/failure',
  )(),
  loadAllLatestPartnersReport: createAsyncAction(
    'loadAllLatestPartnersReport/action/request',
    'loadAllLatestPartnersReport/action/success',
    'loadAllLatestPartnersReport/action/failure',
  )(),
  loadAllBalancesReport: createAsyncAction(
    'loadAllBalancesReport/action/request',
    'loadAllBalancesReport/action/success',
    'loadAllBalancesReport/action/failure',
  )(),
  ...accountTransfersReport,
  ...adjustmentsReport,
  rejectBankWire: createAsyncAction(
    'rejectBankWire/action/request',
    'rejectBankWire/action/success',
    'rejectBankWire/action/failure',
  )(),
  approveBankWireTaxInvoice: createAsyncAction(
    'approveBankWireTaxInvoice/action/request',
    'approveBankWireTaxInvoice/action/success',
    'approveBankWireTaxInvoice/action/failure',
  )(),
};
