import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Common/Loader';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    return this.state.hasError
      ? Loader({ text: 'Failed to load the page.' })
      : this.props.children;
  }
}

ErrorBoundary.propTypes = {
  hasError: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
