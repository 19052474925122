import { firebaseManager } from 'modules/shared/firebase/manager';

export async function appendAuthTokens(config) {
  const firebase = firebaseManager.getCurrentInstance();

  const fbUser = await firebase.getCurrentUser();
  const idToken = await fbUser?.getIdToken();

  if (idToken) {
    config.headers = {
      ...config.headers,
      'access-token': idToken,
    };
  }

  return config;
}
