import React from 'react';
import { takeEvery, spawn, all, put } from 'redux-saga/effects';
import { notification, message } from 'antd';

import actions from 'redux/actions';

import messages, { getErrorMessagebyServerName } from 'utils/messages';
import { USER_ROLES } from 'utils/constants';
import { createAffiliateLink } from 'utils/createAffiliateLink';
import { api } from 'api';
import { mapPatchEmployeePayloadToBody } from './utils/mapPatchEmployeePayloadToBody';
import { mapPartnersToTableRows } from './selectors';

function* callFindPartners({ payload }) {
  try {
    const response = yield api.getUsers({
      role: [USER_ROLES.BROKER],
      ...payload,
    });

    yield put(actions.findPartners.success(response.data));
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.findPartners.failure(errorMessage));
  }
}

function* callFindManagers({ payload }) {
  try {
    const response = yield api.getUsers({
      ...payload,
      role: [USER_ROLES.MANAGER, USER_ROLES.ADMIN],
      brand: process.env.REACT_APP_BRAND,
    });

    yield put(actions.findManagers.success(response.data));
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.findManagers.failure(errorMessage));
  }
}

function* callFindEmployees({ payload }) {
  try {
    const response = yield api.getUsers({
      role: [USER_ROLES.MANAGER, USER_ROLES.ADMIN],
      brand: process.env.REACT_APP_BRAND,
      ...payload,
    });

    yield put(actions.findEmployees.success(response.data));
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.findEmployees.failure(errorMessage));
  }
}

function* callCreatePartner({ payload }) {
  try {
    const data = {
      ...payload,
      role: USER_ROLES.BROKER,
    };

    const response = yield api.createUser(data);

    yield put(actions.createPartner.success(response.data));

    notification.success({ message: messages.SUCCESS.CREATE_PARTNER });
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    notification.error({
      message: 'Create partner error',
      description: <span>{errorMessage}</span>,
      duration: 4,
    });

    yield put(actions.createPartner.failure(errorMessage));
  }
}

function* callPatchPartner({ payload }) {
  try {
    const { id, ...data } = payload;

    const response = yield api.updateUser(id, data);

    if (Array.isArray(response?.data)) {
      yield put(actions.patchPartner.success(response.data));
    } else {
      yield put(actions.patchPartner.success([response.data]));
    }

    notification.success({ message: messages.SUCCESS.PATCH_PARTNER });
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    notification.error({
      message: 'Update partner error',
      description: <span>{errorMessage}</span>,
      duration: 4,
    });
    yield put(actions.patchPartner.failure(errorMessage));
  }
}

function* callRemovePartner({ payload: { keys, callBack } }) {
  try {
    const response = yield api.deleteUser({ userIds: keys });

    yield put(actions.removePartner.success(response.data));

    notification.success({ message: messages.SUCCESS.REMOVE_PARTNER });
    callBack();
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    notification.error({
      message: 'Remove partner error',
      description: <span>{errorMessage}</span>,
      duration: 4,
    });
    yield put(actions.removePartner.failure(errorMessage));
  }
}

function* callCreateEmployee({ payload }) {
  try {
    const data = {
      ...payload,
      role: USER_ROLES.MANAGER,
      brand: process.env.REACT_APP_BRAND,
    };

    const response = yield api.createUser(data);

    yield put(actions.createEmployee.success(response.data));

    notification.success({ message: messages.SUCCESS.CREATE_EMPLOYEE });
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    notification.error({
      message: 'Create employee error',
      description: <span>{errorMessage}</span>,
      duration: 4,
    });

    yield put(actions.createEmployee.failure(errorMessage));
  }
}

function* callPatchEmployee({ payload }) {
  try {
    const { id, ...data } = mapPatchEmployeePayloadToBody(payload);

    const response = yield api.updateUser(id, data);

    if (Array.isArray(response?.data)) {
      yield put(actions.patchEmployee.success(response.data));
    } else {
      yield put(actions.patchEmployee.success([response.data]));
    }

    notification.success({ message: messages.SUCCESS.PATCH_EMPLOYEE });
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    notification.error({
      message: 'Update employee error',
      description: <span>{errorMessage}</span>,
      duration: 4,
    });
    yield put(actions.patchEmployee.failure(errorMessage));
  }
}

function* callRemoveEmployee({ payload: { keys, callBack } }) {
  try {
    const response = yield api.deleteUser({ userIds: keys });

    yield put(actions.removeEmployee.success(response.data));

    notification.success({ message: messages.SUCCESS.REMOVE_EMPLOYEE });
    callBack();
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    notification.error({
      message: 'Remove employee error',
      description: <span>{errorMessage}</span>,
      duration: 4,
    });
    yield put(actions.removeEmployee.failure(errorMessage));
  }
}

function* callCopyAffiliateLink({ payload: { affiliateId, brand } }) {
  try {
    const affiliateLink = createAffiliateLink(affiliateId, brand);

    navigator.clipboard.writeText(affiliateLink).then(
      function () {
        message.success('Affiliate link was copied to clipboard');
      },
      function () {
        message.error("Can't copy text to the clipboard");
      },
    );
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.copyAffiliateLink.failure(errorMessage));
  }
}

function* callFindAllPartners({
  payload: { requestData, onSuccess, onFailure, fileExtension, brand },
}) {
  try {
    const response = yield api.getUsers({
      role: [USER_ROLES.BROKER],
      brand,
      ...requestData,
    });

    if (response) {
      onSuccess(
        {
          ...response.data,
          rows: mapPartnersToTableRows(response.data.rows),
        },
        fileExtension,
      );
    }
  } catch (error) {
    onFailure(error);
  }
}

function* callFindAllEmployees({
  payload: { requestData, onSuccess, onFailure, fileExtension },
}) {
  try {
    const response = yield api.getUsers({
      role: [USER_ROLES.MANAGER, USER_ROLES.ADMIN],
      ...requestData,
    });

    if (response) {
      onSuccess(response.data, fileExtension);
    }
  } catch (error) {
    onFailure(error);
  }
}

function* watchAuth() {
  yield takeEvery(actions.findPartners.request, callFindPartners);
  yield takeEvery(actions.findManagers.request, callFindManagers);
  yield takeEvery(actions.findEmployees.request, callFindEmployees);
  yield takeEvery(actions.createPartner.request, callCreatePartner);
  yield takeEvery(actions.patchPartner.request, callPatchPartner);
  yield takeEvery(actions.copyAffiliateLink.request, callCopyAffiliateLink);
  yield takeEvery(actions.removePartner.request, callRemovePartner);
  yield takeEvery(actions.createEmployee.request, callCreateEmployee);
  yield takeEvery(actions.patchEmployee.request, callPatchEmployee);
  yield takeEvery(actions.removeEmployee.request, callRemoveEmployee);
  yield takeEvery(actions.findAllPartners.request, callFindAllPartners);
  yield takeEvery(actions.findAllEmployees.request, callFindAllEmployees);
}

export default function* authSaga() {
  yield all([watchAuth].map(spawn));
}
