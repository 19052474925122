import { spawn, all, put, takeEvery } from 'redux-saga/effects';
import actions from 'redux/actions';
import { getErrorMessagebyServerName } from 'utils/messages';
import { api } from 'api';

function* callFindLogs({ payload }) {
  try {
    const response = yield api.getLogs(payload);

    yield put(actions.findLogs.success(response.data));
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);

    yield put(actions.findLogs.failure(errorMessage));
  }
}

function* callLoadAllLogs({
  payload: { requestData, onSuccess, onFailure, fileExtension },
}) {
  try {
    const response = yield api.getLogs(requestData);

    if (response) {
      onSuccess(response.data, fileExtension);
    }
  } catch (error) {
    onFailure(error);
  }
}

function* watchAuth() {
  // yield takeEvery(actions.findAllLogs.request, callLoadAllLogs);
  yield takeEvery(actions.findLogs.request, callFindLogs);
}

export default function* authSaga() {
  yield all([watchAuth].map(spawn));
}
