import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import Actions from '../actions';

const initialState = {
  payments: {
    table: {
      dataSource: [],
      total: 0,
      isLoading: true,
    },
    filterLimits: null,
  },
  trades: {
    overview: {
      volumeTraded: {
        isLoading: true,
        percentChanges: 0,
        totalCount: 0,
        series: [],
      },
      totalCommissions: {
        isLoading: true,
        percentChanges: 0,
        totalCount: 0,
        series: [],
      },
      volume: {
        isLoading: true,
        series: [],
      },
    },
    table: {
      dataSource: [],
      total: 0,
      isLoading: true,
    },
    filterLimits: null,
  },
  earnings: {
    table: {
      dataSource: [],
      total: 0,
      isLoading: true,
    },
    filterLimits: null,
  },
};

export default createReducer(initialState)
  .handleAction(
    Actions.loadClientTrades.request,
    produce((state) => {
      state.trades.table.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadClientTrades.success,
    produce((state, { payload }) => {
      state.trades.table.total = payload.count;
      state.trades.table.dataSource = payload.rows;
      state.trades.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadClientTrades.failure,
    produce((state) => {
      state.trades.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadClientCommissions.request,
    produce((state) => {
      state.trades.overview.volumeTraded.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadClientCommissions.success,
    produce((state, { payload }) => {
      state.trades.overview.volumeTraded.isLoading = false;
      state.trades.overview.volumeTraded.totalCount = payload.value;
      state.trades.overview.volumeTraded.percentChanges = payload.growth;
      state.trades.overview.volumeTraded.series = payload.series.map(
        ({ value, date }) => ({ x: date, y: value }),
      );
    }),
  )
  .handleAction(
    Actions.loadTotalCommissions.request,
    produce((state) => {
      state.trades.overview.totalCommissions.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadTotalCommissions.success,
    produce((state, { payload }) => {
      state.trades.overview.totalCommissions.isLoading = false;
      state.trades.overview.totalCommissions.totalCount = payload.value;
      state.trades.overview.totalCommissions.percentChanges = payload.growth;
      state.trades.overview.totalCommissions.series = payload.series.map(
        ({ value, date }) => ({ x: date, y: value }),
      );
    }),
  )
  .handleAction(
    Actions.loadClientTradesOverview.request,
    produce((state) => {
      state.trades.overview.volume.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadClientTradesOverview.success,
    produce((state, { payload }) => {
      state.trades.overview.volume.isLoading = false;
      state.trades.overview.volume.series = payload.series.map(
        ({ title, value }, index) => ({
          enabled: payload.series.length <= 4 || index < 3,
          key: title,
          title,
          value,
        }),
      );
    }),
  )
  .handleAction(
    Actions.loadFilterLimits.success,
    produce((state, { payload }) => {
      state.trades.filterLimits = payload;
    }),
  )
  .handleAction(
    Actions.loadClientPayments.request,
    produce((state) => {
      state.payments.table.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadClientPayments.success,
    produce((state, { payload }) => {
      state.payments.table.total = payload.count;
      state.payments.table.dataSource = payload.rows;
      state.payments.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadClientPayments.failure,
    produce((state) => {
      state.payments.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadClientPaymentsLimits.success,
    produce((state, { payload }) => {
      state.payments.filterLimits = payload;
      state.payments.filterLimits.typeOptions = [
        { value: null, label: 'All Methods' },
        ...payload.typeOptions,
      ];
    }),
  )
  .handleAction(
    Actions.updateClientTradesOverview,
    produce((state, { payload }) => {
      state.trades.overview.volume.series = payload;
    }),
  )
  .handleAction(
    Actions.loadPartnerEarnings.request,
    produce((state) => {
      state.earnings.table.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadPartnerEarnings.success,
    produce((state, { payload }) => {
      state.earnings.table.total = payload.count;
      state.earnings.table.dataSource = payload.rows;
      state.earnings.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadPartnerEarnings.failure,
    produce((state) => {
      state.earnings.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadPartnerEarningsFilterLimits.success,
    produce((state, { payload }) => {
      state.earnings.filterLimits = payload;
    }),
  );
