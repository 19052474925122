import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { api } from 'api';
import { getErrorHandler } from '../../../../../utils/getErrorHandler';
import { queryKeys } from 'utils/queryKeys';
import actions from 'redux/actions';
import { useDispatch } from 'react-redux';

export const useUserById = ({ userId }) => {
  const queryKey = queryKeys.user(userId);

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  const {
    data: user,
    isLoading: isUserLoading,
    error: userError,
  } = useQuery({
    queryKey,
    queryFn: async () => {
      const response = await api.getUserById(userId);

      return response.data;
    },
    onError: getErrorHandler('Unable to load user'),
  });

  const { mutateAsync: updateUser, isLoading: isUpdatingUser } = useMutation({
    mutationFn: async ({ data }) => {
      const updatedUser = await api.updateUser(userId, data);

      return updatedUser.data;
    },
    onMutate: async ({ optimisticUpdate, data }) => {
      if (optimisticUpdate) {
        // Cancel any outgoing refetches, so they don't overwrite our optimistic update
        await queryClient.cancelQueries({ queryKey });

        // Snapshot the previous value
        const prevData = queryClient.getQueryData(queryKey);

        // Optimistically update to the new value
        queryClient.setQueryData(queryKey, (prevData) => ({
          ...prevData,
          ...data,
        }));

        // Return a context object with the snapshotted value
        return { prevData };
      }
    },
    onSuccess: (updatedUser) => {
      queryClient.setQueryData(queryKey, updatedUser);
    },
    onError: (err, { optimisticUpdate }, context) => {
      if (optimisticUpdate) {
        // If the mutation fails, use the context we returned above
        queryClient.setQueryData(queryKey, context.prevData);
        // Always refetch after error or success
        queryClient.invalidateQueries(queryKey);
      }

      getErrorHandler('Unable to update user')(err);
    },
  });

  const { mutateAsync: changeUserPassword, isLoading: isChangingUserPassword } =
    useMutation({
      mutationFn: (data) => api.updateUserPassword(userId, data),
      onSuccess: () => {
        dispatch(actions.logOut.request({}));
      },
      onError: getErrorHandler('Unable to change user password'),
    });

  return {
    user,
    isUserLoading,
    userError,

    updateUser,
    isUpdatingUser,

    changeUserPassword,
    isChangingUserPassword,
  };
};
