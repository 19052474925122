import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import Actions from '../actions';
import { combineReducers } from 'redux';

const initialState = {
  bankWires: {
    dataSource: [],
    options: [],
    total: 0,
    isLoading: true,
    isInitialLoading: true,
  },
  accountTransfers: {
    dataSource: [],
    options: [],
    total: 0,
    isLoading: true,
    isInitialLoading: true,
    isOptionsLoading: true,
  },
};

const bankWires = createReducer(initialState.bankWires)
  .handleAction(
    Actions.loadBankWires.request,
    produce((state) => {
      state.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadBankWires.success,
    produce((state, { payload }) => {
      state.total = payload.count;
      state.dataSource = payload.rows.map((row) => ({
        ...row,
        accountName: payload.options.find(({ id }) => id === row.bankAccountId)
          ?.accountName,
      }));
      state.options = payload.options.filter(
        ({ bankName, accountName, bsbCode }) =>
          (bankName && accountName) || (bsbCode && accountName),
      );
      state.isLoading = false;
      state.isInitialLoading = false;
    }),
  )
  .handleAction(
    Actions.loadBankWires.failure,
    produce((state) => {
      state.isLoading = false;
      state.isInitialLoading = false;
    }),
  );

const accountTransfers = createReducer(initialState.accountTransfers)
  .handleAction(
    Actions.loadAccountTransfers.request,
    produce((state) => {
      state.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadAccountTransfers.success,
    produce((state, { payload }) => {
      state.total = payload.count;
      state.dataSource = payload.rows;
      state.isLoading = false;
      state.isInitialLoading = false;
    }),
  )
  .handleAction(
    Actions.loadAccountTransfers.failure,
    produce((state) => {
      state.isLoading = false;
      state.isInitialLoading = false;
    }),
  )
  .handleAction(
    Actions.loadAccountTransferOptions.request,
    produce((state) => {
      state.isOptionsLoading = true;
    }),
  )
  .handleAction(
    Actions.loadAccountTransferOptions.success,
    produce((state, { payload }) => {
      state.options = payload.map(({ login, platform, regulator, name }) => ({
        label: name,
        value: JSON.stringify({ login, platform, regulator }),
      }));
      state.isOptionsLoading = false;
    }),
  )
  .handleAction(
    Actions.loadAccountTransferOptions.failure,
    produce((state) => {
      state.isOptionsLoading = false;
    }),
  )
  .handleAction(
    Actions.resetAccountTransferOptions,
    produce((state) => {
      state.options = [];
    }),
  );

export default combineReducers({ accountTransfers, bankWires });
