import { Brand } from 'constants/brand';
import { THEME } from '../../utils/constants';

const colors = {
  [Brand.FM]: {
    [THEME.LIGHT]: '#AA74EF',
    [THEME.DARK]: '#AA74EF',
  },
  [Brand.GP]: {
    [THEME.LIGHT]: '#110D3D',
    [THEME.DARK]: '#FFFFFF',
  },
  [Brand.DAY1]: {
    [THEME.LIGHT]: '#AA74EF',
    [THEME.DARK]: '#AA74EF',
  },
};

const getSVGColor = (theme, brand) => colors[brand][theme];

export default getSVGColor;
