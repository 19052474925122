import { useMemo } from 'react';
import { firebaseManager } from '../../manager';

export const useFirebase = () => {
  const firebase = useMemo(() => firebaseManager.getCurrentInstance(), []);

  return {
    firebase,
  };
};
