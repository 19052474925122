import React from 'react';
import FormInput from 'components/Common/Input/FormInput';

import { detailsFields } from './config';

import styles from './styles.module.less';

const Details = () => (
  <div className={styles.container}>
    {detailsFields.map(({ type, ...params }) => (
      <FormInput key={type} type={type} {...params} />
    ))}
  </div>
);

export default Details;
