import React from 'react';
import PropTypes from 'prop-types';

import styles from 'components/Common/Identifier/Status/styles.module.less';

const Status = ({ status }) => (
  <div className={styles[status]}>
    <span>{status}</span>
  </div>
);

Status.propTypes = {
  status: PropTypes.string,
};

export default Status;
