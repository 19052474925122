import { takeLatest, put } from 'redux-saga/effects';
import actions from 'redux/actions';
import { api } from 'api';
import { getErrorHandler } from 'utils/getErrorHandler';
import { notification } from 'antd';

function* callLoadAccountTransfersReport({ payload }) {
  try {
    const response = yield api.getAccountTransfers(payload);
    yield put(actions.loadAccountTransfersReport.success(response.data));
  } catch (error) {
    yield put(actions.loadAccountTransfersReport.failure(error));
  }
}

function* callLoadAccountTransfersReportLimits({ payload }) {
  try {
    const response = yield api.getAccountTransfersLimits(payload);
    yield put(actions.loadAccountTransfersReportLimits.success(response.data));
  } catch (error) {
    yield put(actions.loadAccountTransfersReportLimits.failure(error));
  }
}

function* callExportAccountTransfersReport({
  payload: { requestData, onSuccess, onFailure, fileExtension },
}) {
  try {
    const response = yield api.getAccountTransfers(requestData);
    onSuccess(response.data, fileExtension);
  } catch (error) {
    onFailure(error);
  }
}

function* approveAccountTransferTaxInvoice({ payload }) {
  try {
    const response = yield api.approveAccountTransferTaxInvoice(payload);
    yield put(actions.approveAccountTransferTaxInvoice.success(response.data));
    notification.success({
      message:
        "Trading Account Transfer's Tax Invoice has been successfully approved",
    });
  } catch (error) {
    getErrorHandler("Unable to approve Trading Account Transfer's Tax Invoice")(
      error,
    );
    yield put(actions.approveAccountTransferTaxInvoice.failure(error));
  }
}

export function* accountTransfersSaga() {
  yield takeLatest(
    actions.loadAccountTransfersReport.request,
    callLoadAccountTransfersReport,
  );
  yield takeLatest(
    actions.loadAccountTransfersReportLimits.request,
    callLoadAccountTransfersReportLimits,
  );
  yield takeLatest(
    actions.exportAccountTransfersReport.request,
    callExportAccountTransfersReport,
  );
  yield takeLatest(
    actions.approveAccountTransferTaxInvoice.request,
    approveAccountTransferTaxInvoice,
  );
}
