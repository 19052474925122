import React from 'react';
import PropTypes from 'prop-types';
import {
  Helmet,
  HelmetProvider as HelmetProviderBase,
} from 'react-helmet-async';

import useBrandTitle from 'utils/hooks/useBrandTitle';
import useBrandFavicon from 'utils/hooks/useBrandFavicon';

export const HelmetProvider = ({ children }) => {
  const appTitle = useBrandTitle();
  const faviconPath = useBrandFavicon();

  return (
    <HelmetProviderBase>
      <Helmet>
        <title>{appTitle}</title>
        <link rel="icon" href={faviconPath} />
      </Helmet>
      {children}
    </HelmetProviderBase>
  );
};

HelmetProvider.propTypes = {
  children: PropTypes.node,
};
