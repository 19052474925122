export const detailsFields = [
  {
    type: 'firstName',
    label: 'First name',
    placeholder: 'Enter',
    isRequired: true,
  },
  {
    type: 'lastName',
    label: 'Last name',
    placeholder: 'Enter',
    isRequired: true,
  },
  {
    type: 'phoneNumber',
    label: 'Phone number',
    placeholder: 'Enter',
    isRequired: false,
  },
  {
    type: 'email',
    label: 'Email',
    placeholder: 'Enter',
    isRequired: true,
  },
  {
    type: 'skype',
    label: 'Skype',
    placeholder: 'Enter',
    isRequired: false,
  },
];
