import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Form } from 'antd';
import StyledSwitch from 'components/Common/Buttons/StyledSwitch';

import styles from './styles.module.less';

const FormSwitch = ({ label, name, type, initialValue, checked, ...other }) => (
  <Form.Item
    label={label}
    name={name}
    colon={false}
    className={clsx(styles.formItem, styles[type])}
    valuePropName="checked"
    initialValue={initialValue}
    {...other}
  >
    <StyledSwitch checked={checked} />
  </Form.Item>
);

FormSwitch.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  initialValue: PropTypes.bool,
  value: PropTypes.bool,
  checked: PropTypes.bool,
  other: PropTypes.any,
};

export default FormSwitch;
