import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Actions from 'redux/actions';
import { getSaveDetails } from 'redux/sagas/auth/selectors';

const useSaveDetails = () => {
  const dispatch = useDispatch();
  const saveDetails = useSelector(getSaveDetails);

  useEffect(() => {
    const value = localStorage.getItem('saveDetails');
    dispatch(Actions.saveDetails(value));
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem('saveDetails', saveDetails);
  }, [saveDetails]);
};

export default useSaveDetails;
