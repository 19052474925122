import React from 'react';
import { useSelector } from 'react-redux';

import BigStatisticTile from 'components/Common/StatisticTiles/BigStatisticTile';

import styles from '../styles.module.less';
import PropTypes from 'prop-types';

const VolumeTrades = ({ selector }) => {
  const { percentChanges, totalCount, series, isLoading } =
    useSelector(selector);

  return (
    <BigStatisticTile
      type="commissions-earned"
      percentChanges={percentChanges}
      totalCount={totalCount}
      series={series}
      text="Commissions Earned"
      isLoading={isLoading}
      className={styles.tradesTilesItem}
    />
  );
};

VolumeTrades.propTypes = {
  selector: PropTypes.func.isRequired,
};

export default VolumeTrades;
