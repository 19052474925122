class ActivityMetrics {
  static instance = new ActivityMetrics();

  MetricName = {
    LOT_VOLUME: 'lotVolume',
    TRADES_COUNT: 'tradesCount',
    CLOSED_PNL: 'closedPnl',
    FLOATING_PNL: 'floatingPnl',
    NET_PNL: 'netPnl',
  };

  getFmMetrics() {
    return {
      [this.MetricName.LOT_VOLUME]: {
        name: 'Volume traded',
        format: 'decimal',
      },
      [this.MetricName.TRADES_COUNT]: {
        name: 'Total Trades',
        format: 'decimal',
      },
      [this.MetricName.CLOSED_PNL]: {
        name: 'Closed P&L',
        format: 'currency',
      },
      [this.MetricName.FLOATING_PNL]: {
        name: 'Floating P&L',
        format: 'currency',
      },
      [this.MetricName.NET_PNL]: {
        name: 'Net P&L',
        format: 'currency',
      },
    };
  }

  getGPMetrics() {
    return this.getFmMetrics();
  }

  getDay1Metrics() {
    return {
      [this.MetricName.TRADES_COUNT]: {
        name: 'Total Trades',
        format: 'decimal',
      },
    };
  }
}

export const ActivityMetricsService = ActivityMetrics.instance;
