import moment from 'moment';
import * as momentTz from 'moment-timezone';
import parsePhoneNumber from 'libphonenumber-js';

import { ROUTING } from 'config';
import {
  NUMBER_FORMATS,
  PERIODS_RANGE_MAP,
  RANGE_PERIOD_MAP,
  TIME_PERIODS,
} from 'utils/constants';
import { DAY_MONTH_YEAR_FORMAT } from './timeConstants';

const getDefaultMaximumFractionDigits = (style, number) => {
  switch (style) {
    case NUMBER_FORMATS.decimal:
    case NUMBER_FORMATS.currency:
      // If the modulus of a number is less than 1, we should show 4 digits after the decimal point by default
      return Math.abs(number) < 1 ? 4 : 2;
    default:
      return 2;
  }
};

export const getFormattedNumber = (options) => {
  const {
    number = 0,
    style = NUMBER_FORMATS.decimal,
    signDisplay = false,
    group = {},
    minimumFractionDigits = 0,
    useGrouping = false,
  } = options;

  if (style === NUMBER_FORMATS.integer) {
    return number;
  }

  const maximumFractionDigits =
    options.maximumFractionDigits ||
    getDefaultMaximumFractionDigits(style, number);

  const formatterOptions = {
    style,
    signDisplay: signDisplay || number < 0 ? 'always' : 'never',
    maximumFractionDigits,
    useGrouping,
    minimumFractionDigits,
  };

  if (style === NUMBER_FORMATS.currency) {
    formatterOptions.currency = 'USD';
  }

  const formatter = new Intl.NumberFormat('en-US', formatterOptions);
  const formattedNumber = formatter.format(number);

  if (group) {
    const pattern = `\\B(?=(\\d{${group.count}})+(?!\\d))`;
    const regexp = new RegExp(pattern, 'g');

    return formattedNumber.toString().replace(regexp, group.replaceWith);
  }

  return formattedNumber;
};

export const BytesToMegaBytes = (bytes) => bytes / 1024 / 1024;

export const getAutocompleteStrings = (enteredValue, suggestedValue) => {
  let commonSymbolsCounter = 0;

  const from = enteredValue.toLowerCase();
  const to = suggestedValue.toLowerCase();

  for (let index = 0; index < enteredValue.length; index++) {
    if (from[index] === to[index]) {
      commonSymbolsCounter++;
    }
  }

  const commonValue = suggestedValue.substring(0, commonSymbolsCounter);
  const restOfSuggestedValue = suggestedValue.substring(commonSymbolsCounter);

  return [commonValue, restOfSuggestedValue];
};

export const createRangeAccessKey = (start, end) =>
  `${start.format(DAY_MONTH_YEAR_FORMAT)}_${end.format(DAY_MONTH_YEAR_FORMAT)}`;

export const getPeriodByDateRange = (dateRange) => {
  const [startAt, endAt] = dateRange;
  const accessKey = createRangeAccessKey(startAt, endAt);

  return RANGE_PERIOD_MAP[accessKey] ?? null;
};

export const getDateRangeByTimePeriod = (period) =>
  PERIODS_RANGE_MAP[period] || PERIODS_RANGE_MAP[TIME_PERIODS.MONTH_TO_DATE];

export const getPhoneObject = (value) => {
  const phoneNumber = value && parsePhoneNumber(value);

  if (phoneNumber) {
    return {
      phoneCode: '+' + phoneNumber.countryCallingCode,
      phoneNumber: phoneNumber.formatInternational(),
      countryCode: phoneNumber.country,
    };
  }

  return {
    phoneCode: '',
    phoneNumber: value,
    countryCode: '',
  };
};

export const calculatePercentChanges = (currentPeriod, previousPeriod) => {
  switch (true) {
    case previousPeriod === 0:
      return currentPeriod !== 0 ? 1 : 0;
    case currentPeriod === 0:
      return previousPeriod !== 0 ? -1 : 0;
    default:
      return (currentPeriod - previousPeriod) / previousPeriod;
  }
};

export const getBackPath = (pathname) => {
  const backPathnameList = pathname.split('/');

  if (backPathnameList.length <= 3) {
    return ROUTING.DASHBOARD;
  }

  backPathnameList.splice(-2);

  return backPathnameList.join('/');
};

export const getPrevPeriod = (dateRange) => {
  let prevPeriod = null;

  switch (getPeriodByDateRange(dateRange)) {
    case TIME_PERIODS.TODAY:
    case TIME_PERIODS.YESTERDAY: {
      prevPeriod = [
        moment(dateRange[0]).subtract(1, 'day').startOf('day'),
        moment(dateRange[1]).subtract(1, 'day').endOf('day'),
      ];

      break;
    }
    case TIME_PERIODS.SEVEN_DAYS: {
      prevPeriod = [
        moment(dateRange[0]).subtract(7, 'days').startOf('day'),
        moment(dateRange[0]).subtract(1, 'days').endOf('day'),
      ];

      break;
    }
    case TIME_PERIODS.THIRTY_DAYS: {
      prevPeriod = [
        moment(dateRange[0]).subtract(30, 'days').startOf('day'),
        moment(dateRange[0]).subtract(1, 'days').endOf('day'),
      ];

      break;
    }
    case TIME_PERIODS.WEEK_TO_DATE: {
      const weekDay = moment().weekday();
      prevPeriod = [
        moment(dateRange[0]).subtract(weekDay, 'days').startOf('day'),
        moment(dateRange[0]).subtract(1, 'days').endOf('day'),
      ];

      break;
    }
    case TIME_PERIODS.MONTH_TO_DATE: {
      prevPeriod = [
        moment(dateRange[0]).subtract(1, 'months').startOf('month'),
        moment(dateRange[1]).subtract(1, 'months'),
      ];

      break;
    }
    default: {
      const diffDays = moment(dateRange[1]).diff(moment(dateRange[0]), 'days');

      prevPeriod = [
        moment(dateRange[0]).subtract(diffDays, 'days').startOf('day'),
        moment(dateRange[0]).subtract(1, 'days').endOf('day'),
      ];
    }
  }

  return prevPeriod;
};
