import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { getAutocompleteStrings } from 'utils/helpers';

import styles from '../../styles.module.less';

const DefaultOptions = ({ filter, options, currentValue, justify }) =>
  options?.filter(filter)?.map(({ value, disabled }) => {
    const [commonValue, suggestedValue] = getAutocompleteStrings(
      currentValue,
      value,
    );

    return {
      value,
      disabled,
      label: (
        <div
          className={clsx(styles.autoCompleteValues, {
            [styles.positionStart]: justify === 'start',
          })}
        >
          <span className={styles.searchedValue}>{commonValue}</span>
          {suggestedValue && (
            <span className={styles.suggestedValue}>{suggestedValue}</span>
          )}
        </div>
      ),
    };
  });

DefaultOptions.propTypes = {
  options: PropTypes.array.isRequired,
  currentValue: PropTypes.string,
  justify: PropTypes.string,
};

export default DefaultOptions;
