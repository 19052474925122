import { Brand } from 'constants/brand';

import { FirebaseServiceFm } from './fm';
import { FirebaseServiceGp } from './gp';
import { FirebaseServiceDay1 } from './day1';

class FirebaseFactory {
  create(brand, name) {
    switch (brand) {
      case Brand.FM:
        return new FirebaseServiceFm(name);
      case Brand.GP:
        return new FirebaseServiceGp(name);
      case Brand.DAY1:
        return new FirebaseServiceDay1(name);
    }
  }
}

export const firebaseFactory = new FirebaseFactory();
