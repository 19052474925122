import { useEffect, useState } from 'react';

export const getIsIncludeActions = (loadingType, actions) =>
  actions.some((action) => action === loadingType);

export default function useRequestLoading(
  loadingType = '',
  actions = [''],
  onFinish,
) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const isLoaded = isLoading && !getIsIncludeActions(loadingType, actions);

    setIsLoading(getIsIncludeActions(loadingType, actions));

    if (!!onFinish && isLoaded && !loadingType) {
      onFinish();
    }
  }, [loadingType]);

  return isLoading;
}
