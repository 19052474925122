import { Brand } from 'constants/brand';

const HUB_URL = {
  [Brand.FM]: process.env.REACT_APP_HUB_URL_FM,
  [Brand.GP]: process.env.REACT_APP_HUB_URL_GP,
  [Brand.DAY1]: process.env.REACT_APP_HUB_URL_DAY1,
};

export function createAffiliateLink(refCode, brand) {
  return `${HUB_URL[brand]}/?refcode=${refCode}`;
}
