import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';

import Actions from 'redux/actions';

import BackgroundWrapper from 'components/Common/BackgroundWrapper';
import Person from 'components/Common/Person';
import StyledDropdown from 'components/Common/Dropdown/StyledDropdown';

import { getUserBalanceNum } from 'redux/sagas/auth/selectors';
import { getFormattedNumber } from 'utils/helpers';
import { THOUSANDS_DEVIDER, NUMBER_FORMATS, USER_ROLES } from 'utils/constants';
import { ROUTING } from 'config';

import UserSearch from './UserSearch';
import ThemeButtons from './ThemeButtons';

import styles from './styles.module.less';
import { useFirebase } from 'modules/shared/firebase/hooks/useFirebase';
import { RegSource } from 'constants/regSource';
import { useUser } from 'utils/hooks/useUser';
import { useIsStaff } from 'utils/hooks/useIsStaff';

const SEARCH_BAR_ALLOWED_ROUTES = [
  ROUTING.DASHBOARD,
  ROUTING.PARTNERS,
  ROUTING.CLIENTS,
];

export const SEARCH_BAR_ALLOWED_REG_SOURCES = [RegSource.IB];

const HeaderContent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { firebase } = useFirebase();

  const { id, fullName, email, role, regSource } = useUser();
  const isStaff = useIsStaff();

  let balance = useSelector(getUserBalanceNum);
  balance = getFormattedNumber({
    number: balance,
    style: NUMBER_FORMATS.currency,
    signDisplay: false,
    ...THOUSANDS_DEVIDER,
  });
  const isBroker = role === USER_ROLES.BROKER;

  useEffect(() => {
    if (isBroker) {
      dispatch(Actions.getBalance.request());
    }
  }, [dispatch, isBroker]);

  const isPartnerLogin = firebase.isSecondaryInstance();

  const menuItems = useMemo(() => {
    let menu = [
      {
        text: 'Profile Settings',
        icon: 'profileSettings',
        onClick: () => history.push(ROUTING.SETTING),
      },
      {
        text: isPartnerLogin ? 'Return to Staff' : 'Logout',
        icon: 'logout',
        onClick: () => {
          if (isPartnerLogin) {
            dispatch(Actions.logOutWithPartner.request());
          } else {
            dispatch(Actions.logOut.request());
          }
        },
      },
    ];

    if (isBroker) {
      menu = [
        {
          Component: ThemeButtons,
        },
        ...menu,
      ];
    }

    return menu;
  }, [dispatch, history, isPartnerLogin, isBroker, id]);

  const isSearchVisible = useMemo(() => {
    const visibleByUrl = SEARCH_BAR_ALLOWED_ROUTES.includes(pathname);
    const visibleByRegSource =
      isStaff || SEARCH_BAR_ALLOWED_REG_SOURCES.includes(regSource);

    return visibleByUrl && visibleByRegSource;
  }, [isStaff, pathname, regSource]);

  return (
    <div className={styles.headerContainer}>
      {isSearchVisible && (
        <UserSearch
          placeholder={`Search by ${
            isBroker ? "Client's Name/ID" : "Partner's  Name/email/ID"
          }`}
          optionType={isBroker ? 'client' : 'partner'}
        />
      )}
      {isBroker && (
        <div className={clsx(styles.headerItem, styles.backgroundWrapper)}>
          <a href={ROUTING.COMMISSIONS}>
            <BackgroundWrapper text={balance} type="profit" icon="profit" />
          </a>
        </div>
      )}
      <StyledDropdown
        menuItems={menuItems}
        className={clsx(styles.menu, {
          [styles.client]: isBroker,
        })}
        optionClasses={styles.option}
      >
        <Person
          type="menu"
          className={styles.profile}
          personName={fullName}
          email={email}
        />
      </StyledDropdown>
    </div>
  );
};

export default HeaderContent;
