import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { api } from 'api';
import { mapDateRange } from '../mapDateRange';
import { getErrorHandler } from '../getErrorHandler';
import { ReferralSource } from 'constants/referalSource';

export const useMyCommissionClientFeeLimits = ({
  userId,
  dateRange,
  source = ReferralSource.DIRECT,
}) => {
  const { data: feesLimits = {}, isLoading: isFeesLimitsLoading } = useQuery({
    queryKey: queryKeys.myCommissionClientFeeLimits(userId, dateRange),
    queryFn: async () => {
      const response = await api.getClientFeesLimits(userId, {
        ...mapDateRange(dateRange),
        source,
      });

      return response.data;
    },
    onError: getErrorHandler('Unable to load fees limits'),
  });

  return {
    feesLimits,
    isFeesLimitsLoading,
  };
};
