import { api } from 'api';
import { takeEvery, all, put, select, call } from 'redux-saga/effects';

import actions from 'redux/actions';
import { getUser, getUserId } from 'redux/sagas/auth/selectors';
import { USER_ROLES } from 'utils/constants';

export function* callLoadClientsBasedOnType(payload, partnerId) {
  const user = yield select(getUser);

  const isUserAdmin = user.role === USER_ROLES.ADMIN;

  if (isUserAdmin) {
    return yield api.getPartnerClientsCreatedWithinDateRange({
      userId: partnerId || user.id,
      params: payload,
    });
  }

  return yield api.getPartnerClients({
    userId: partnerId || user.id,
    params: payload,
  });
}

function* callLoadTopClientsList({ payload }) {
  try {
    const response = yield call(callLoadClientsBasedOnType, payload);

    yield put(actions.loadTopClients.success(response.data));
  } catch (error) {
    yield put(actions.loadTopClients.failure(error));
  }
}

function* callLoadLatestClientsList({ payload }) {
  try {
    const response = yield call(callLoadClientsBasedOnType, payload);

    yield put(actions.loadLatestClients.success(response.data));
  } catch (error) {
    yield put(actions.loadLatestClients.failure(error));
  }
}

function* callLoadAllClientsList({ payload }) {
  try {
    const { partnerId } = payload;
    const userId = partnerId ?? (yield select(getUserId));
    const response = yield call(callLoadClientsBasedOnType, payload, userId);

    yield put(actions.loadAllClients.success(response.data));
  } catch (error) {
    yield put(actions.loadAllClients.failure(error));
  }
}

function* callExportAllClientsList({
  payload: { requestData, onSuccess, onFailure, fileExtension },
}) {
  try {
    const { partnerId, ...params } = requestData;
    const userId = partnerId ?? (yield select(getUserId));
    const response = yield call(callLoadClientsBasedOnType, params, userId);

    if (response) {
      onSuccess(response.data, fileExtension);
    }
  } catch (error) {
    onFailure(error);
  }
}

function* callLoadClientsLimits({ payload }) {
  try {
    const userId = yield select(getUserId);
    const response = yield api.getPartnerClientsLimits(userId, payload);

    yield put(actions.loadClientsLimits.success(response.data));
  } catch (error) {
    yield put(actions.loadClientsLimits.failure(error));
  }
}

function* callLoadPartnerClientsLimits({ payload }) {
  try {
    const { partnerId, ...params } = payload;
    const response = yield api.getPartnerClientsLimits(partnerId, params);

    yield put(actions.loadPartnerClientsLimits.success(response.data));
  } catch (error) {
    yield put(actions.loadPartnerClientsLimits.failure(error));
  }
}

export default function* clientInfoSaga() {
  yield all([
    yield takeEvery(
      actions.loadLatestClients.request,
      callLoadLatestClientsList,
    ),
    yield takeEvery(actions.loadClientsLimits.request, callLoadClientsLimits),
    yield takeEvery(
      actions.loadPartnerClientsLimits.request,
      callLoadPartnerClientsLimits,
    ),
    yield takeEvery(actions.loadAllClients.request, callLoadAllClientsList),
    yield takeEvery(actions.exportAllClients.request, callExportAllClientsList),
    yield takeEvery(actions.loadTopClients.request, callLoadTopClientsList),
  ]);
}
