import React from 'react';
import PropTypes from 'prop-types';
import FooterButtons from './FooterButtons';
import StyledButton from 'components/Common/Buttons/StyledButton';
import { BUTTONS_TEXT, NOTIFICATION_MODAL } from 'utils/constants';
import styles from './styles.module.less';

const ModalFooter = ({ type, loading, onDefault, onCancel, onFinish }) => {
  let saveButtonText = BUTTONS_TEXT.SAVE;

  switch (type) {
    case 'saveCancel':
      saveButtonText = BUTTONS_TEXT.SAVE;

      break;
    case 'group':
      saveButtonText = BUTTONS_TEXT.ADD_PARTNERS_GROUP;

      break;
    case 'runCancel':
      saveButtonText = BUTTONS_TEXT.RUN;

      break;
    case 'approveCancel':
      saveButtonText = BUTTONS_TEXT.APPROVE;

      break;
    default:
      saveButtonText = '';

      break;
  }

  switch (type) {
    case 'group':
    case 'saveCancel':
    case 'runCancel':
    case 'approveCancel':
      return (
        <FooterButtons
          onCancel={onCancel}
          onFinish={onFinish}
          loading={loading}
          saveButtonText={saveButtonText}
        />
      );
    case 'defaultSaveCancel':
      return (
        <FooterButtons
          onDefault={onDefault}
          onCancel={onCancel}
          onFinish={onFinish}
          loading={loading}
        />
      );
    case 'confirm':
      return (
        <StyledButton
          type="primary"
          onClick={onCancel}
          text={NOTIFICATION_MODAL.BUTTON_TEXT}
          loading={loading}
        />
      );
    case 'ok':
      return <FooterButtons onFinish={onCancel} className={styles.okButton} />;
    default:
      return null;
  }
};

ModalFooter.defaultProps = {
  loading: false,
  type: null,
};

ModalFooter.propTypes = {
  loading: PropTypes.bool,
  onDefault: PropTypes.func,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  type: PropTypes.oneOf([
    'saveCancel',
    'runCancel',
    'group',
    'defaultSaveCancel',
  ]),
};

export default ModalFooter;
