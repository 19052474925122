import React from 'react';
import PropTypes from 'prop-types';

import { TfaForm } from 'components/TfaForm';
import { getErrorMessagebyServerName } from 'utils/messages';
import { useTfaAuthByUserId } from 'modules/shared/auth/hooks/useTfaAuthByUserId';
import { useUserById } from 'modules/shared/user/hooks/useUserById';

export const TwoFactorAuthentication = ({ userId }) => {
  const { user, isUserLoading } = useUserById({ userId });
  const {
    tfaCode,
    createTfaCode,
    isCreatingTfaCode,
    enableTfa,
    isEnablingTfa,
    enableTfaError,
    disableTfa,
    isDisablingTfa,
  } = useTfaAuthByUserId({ userId });

  const isLoading = isUserLoading || isEnablingTfa || isDisablingTfa;

  return (
    <TfaForm
      isTfaEnabled={user?.isTwoFactorAuthEnabled}
      isTfaLoading={isLoading}
      onTfaEnable={enableTfa}
      onTfaDisable={disableTfa}
      qrCode={tfaCode}
      isQrCodeLoading={isCreatingTfaCode}
      onQrCodeClick={createTfaCode}
      error={getErrorMessagebyServerName(enableTfaError)}
    />
  );
};

TwoFactorAuthentication.propTypes = {
  userId: PropTypes.number,
};
