import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from 'components/Common/Input/DropdownSelect';
import Password from './Password';
import DateInput from './DateInput';
import StyledInput from './StyledInput';
import Index from './DropdownSelect';
import PhoneInput from './PhoneInput';

import { FORM_INPUT_TYPES } from 'utils/constants';

const Input = ({ type, placeholder, className, ...other }) => {
  switch (type) {
    case FORM_INPUT_TYPES.DATE:
      return (
        <DateInput placeholder={placeholder} className={className} {...other} />
      );
    case FORM_INPUT_TYPES.PASSWORD:
    case FORM_INPUT_TYPES.NEW_PASSWORD:
    case FORM_INPUT_TYPES.CONFIRM_PASSWORD:
      return (
        <Password placeholder={placeholder} className={className} {...other} />
      );
    case FORM_INPUT_TYPES.CCY:
    case FORM_INPUT_TYPES.COUNTRY:
      return <Index placeholder={placeholder} {...other} />;
    case FORM_INPUT_TYPES.PHONE_NUMBER:
      return <PhoneInput {...other} />;
    case FORM_INPUT_TYPES.SELECT:
      return (
        <DropdownSelect
          type={type}
          placeholder={placeholder}
          className={className}
          {...other}
        />
      );
    default:
      return (
        <StyledInput
          type={type}
          maxLength={type === FORM_INPUT_TYPES.EMAIL ? 320 : 30}
          placeholder={placeholder}
          className={className}
          {...other}
        />
      );
  }
};

Input.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  other: PropTypes.any,
};

export default Input;
