import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import SiderMenu from 'components/Sidebar/SiderMenu';

import styles from './styles.module.less';

const MobileMenu = ({ isMenuOpen, type, onClose }) => (
  <Drawer
    closable={false}
    placement="left"
    visible={isMenuOpen}
    onClose={onClose}
    className={styles.siderMobile}
  >
    <SiderMenu onTriggerClick={onClose} type={type} closeOnClick />
  </Drawer>
);

MobileMenu.propTypes = {
  isMenuOpen: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func,
};

export default MobileMenu;
