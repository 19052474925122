import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import Actions from '../actions';

const initialState = {
  data: null,
};

export default createReducer(initialState)
  .handleAction(
    Actions.makePayment.request,
    produce((state) => {
      state.data = null;
    }),
  )
  .handleAction(
    Actions.makePayment.success,
    produce((state, { payload }) => {
      state.data = payload;
    }),
  )
  .handleAction(
    Actions.makePayment.failure,
    produce((state) => {
      state.data = null;
    }),
  );
