import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Drawer, Form } from 'antd';
import clsx from 'clsx';
import _ from 'lodash';

import StyledButton from 'components/Common/Buttons/StyledButton';
import CustomIcon from 'components/Icons';

import { FILTER_TYPES, FORM_INPUT_TYPES } from 'utils/constants';
import { getFormattedNumber } from 'utils/helpers';

import FilterItems from './FilterItems';
import FilterSkeleton from './FilterSkeleton';

import styles from './styles.module.less';

const TableFilterDrawer = ({
  selector,
  isFilterVisible,
  hideDrawer,
  handleFinish,
  filterItems,
  title,
  className,
}) => {
  const filterLimits = useSelector(selector);
  const [form] = Form.useForm();

  const onFinish = useCallback(
    (values) => {
      handleFinish(_.omitBy(values, _.isUndefined));
      hideDrawer();
    },
    [handleFinish, hideDrawer],
  );

  const onResetClick = useCallback(() => {
    form.resetFields();
    handleFinish({});
    hideDrawer();
  }, [form, handleFinish, hideDrawer]);

  return (
    <Drawer
      title={title}
      placement="right"
      closable
      visible={isFilterVisible}
      onClose={hideDrawer}
      className={clsx(styles.drawer, className)}
      closeIcon={
        <CustomIcon className={styles.tableFilterCross} type="cross" />
      }
    >
      <Form form={form} onFinish={onFinish}>
        {filterLimits ? (
          filterItems?.map(
            ({ type, label, placeholder, name, options, ...rest }) => {
              const max = Number(
                getFormattedNumber({
                  number: filterLimits[name + 'Max'],
                  maximumFractionDigits: 8,
                }),
              );
              const min = Number(
                getFormattedNumber({
                  number: filterLimits[name + 'Min'],
                  maximumFractionDigits: 8,
                }),
              );

              return (
                <FilterItems
                  key={name}
                  type={type}
                  name={name}
                  label={label}
                  placeholder={placeholder}
                  form={form}
                  className={styles.formItem}
                  isRequired={false}
                  minValue={min}
                  maxValue={max}
                  options={[
                    ...(options || []),
                    ...(filterLimits[name + 'Options'] || []),
                  ]}
                  {...rest}
                />
              );
            },
          )
        ) : (
          <FilterSkeleton />
        )}
        <StyledButton
          onClick={hideDrawer}
          type="primary"
          htmlType="submit"
          text="Save"
        />
        <StyledButton
          onClick={onResetClick}
          className={styles.tableFilterReset}
          text="Reset"
        />
      </Form>
    </Drawer>
  );
};

TableFilterDrawer.propTypes = {
  selector: PropTypes.func.isRequired,
  isFilterVisible: PropTypes.bool.isRequired,
  hideDrawer: PropTypes.func.isRequired,
  handleFinish: PropTypes.func.isRequired,
  filterItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.oneOf([
        FORM_INPUT_TYPES.SELECT,
        FILTER_TYPES.CHECKBOX,
        FILTER_TYPES.SLIDER,
      ]),
    }),
  ).isRequired,
  title: PropTypes.string,
  formName: PropTypes.string,
  className: PropTypes.string,
};

TableFilterDrawer.defaultProps = {
  title: 'Filter',
  formName: 'formFilter',
};

export default React.memo(TableFilterDrawer);
