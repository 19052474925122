import { api } from 'api';
import { takeEvery, all, put, select } from 'redux-saga/effects';

import actions from 'redux/actions';
import { getBankWires } from 'redux/sagas/withdrawals/selectors';
import { getErrorMessagebyServerName } from 'utils/messages';

function* callLoadBankWires({ payload }) {
  try {
    const response = yield api.userWithdrawsBankWire(payload);

    yield put(actions.loadBankWires.success(response.data));
  } catch (error) {
    yield put(actions.loadBankWires.failure(error));
  }
}

function* callBankWiresRequest({ payload: { values, onSuccess, onFailure } }) {
  try {
    const data = {
      ...values,
      amount: values.withdrawAmount,
    };

    yield api.requestBankWire(data);
    onSuccess();

    const { total } = yield select(getBankWires);

    const dataWithdraws = {
      offset: 0,
      limit: total + 1,
    };

    yield put(actions.loadBankWires.request(dataWithdraws));
  } catch (error) {
    const errorMessage = getErrorMessagebyServerName(error);
    onFailure(errorMessage);
  }
}

function* callBankWiresConfirm({ payload: { values, onSuccess, onFailure } }) {
  try {
    yield api.requestConfirmBankWire({ token: values.token });

    // Update balance after confirm withdrawal
    yield put(actions.getBalance.request());

    const { total } = yield select(getBankWires);

    const dataWithdraws = { offset: 0, limit: total };

    yield put(actions.loadBankWires.request(dataWithdraws));

    onSuccess();
  } catch (error) {
    onFailure();
  }
}

export default function* bankWiresSaga() {
  yield all([
    yield takeEvery(actions.loadBankWires.request, callLoadBankWires),
    yield takeEvery(actions.bankWiresRequest.request, callBankWiresRequest),
    yield takeEvery(actions.bankWiresConfirm.request, callBankWiresConfirm),
  ]);
}
