import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AutoComplete } from 'antd';

import ClientOptions from './Options/Client';
import DefaultOptions from './Options/Default';
import PartnerOptions from './Options/Partner';

import styles from './styles.module.less';

const optionTypes = {
  client: ClientOptions,
  partner: PartnerOptions,
  default: DefaultOptions,
};

const AutoCompleteInput = ({
  type,
  justify,
  filter,
  options,
  optionType,
  placeholder,
  className,
  onChange,
  isLoading,
  children,
  ...other
}) => {
  const [currentValue, setCurrentValue] = useState('');

  const onInputChange = useCallback(
    (value) => {
      setCurrentValue(value);
      onChange(value);
    },
    [onChange],
  );

  return (
    <AutoComplete
      backfill
      dropdownMatchSelectWidth={false}
      options={optionTypes[optionType]({
        filter,
        options,
        currentValue,
        justify,
        isLoading,
      })}
      dropdownClassName={clsx(styles.autoComplete, styles[optionType])}
      onSelect={setCurrentValue}
      placeholder={placeholder}
      onChange={onInputChange}
      className={clsx(styles.input, styles[type], className)}
      {...other}
    >
      {children}
    </AutoComplete>
  );
};

AutoCompleteInput.defaultProps = {
  options: [],
  justify: '',
  filter: () => true,
  onChange: () => null,
  optionType: 'default',
  isLoading: false,
};

AutoCompleteInput.propTypes = {
  type: PropTypes.string,
  justify: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.func,
  optionType: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};

export default AutoCompleteInput;
