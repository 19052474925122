import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Table } from 'antd';

import Spinner from 'components/Spinner';

import styles from './styles.module.less';

import EmptyText from './EmptyText';

const TABLES_WITH_GREY_NO_DATA_LABEL = ['topDashboard'];

const CustomTable = ({
  isLoading,
  columns,
  dataSource,
  onRow,
  sorterHandler,
  className,
  type,
  tableHeader,
  emptyMode,
  ...others
}) => {
  const isBlack = useMemo(
    () => !TABLES_WITH_GREY_NO_DATA_LABEL.includes(type),
    [type],
  );
  const loadingMask = useMemo(
    () => ({
      spinning: isLoading,
      indicator: <Spinner spinning spinFontSize={58} />,
    }),
    [isLoading],
  );

  const noData = useMemo(
    () => ({
      emptyText: <EmptyText isBlackText={isBlack} emptyMode={emptyMode} />,
    }),
    [emptyMode, isBlack],
  );

  const onChange = (pagination, filters, { field, order }) => {
    if (sorterHandler) {
      const sorter = {
        orderBy: field,
        orderDirection: order,
      };

      sorterHandler(order ? sorter : {});
    }
  };

  return (
    <Table
      onChange={onChange}
      showSorterTooltip={false}
      title={tableHeader}
      onRow={onRow}
      columns={columns}
      dataSource={dataSource}
      loading={loadingMask}
      className={clsx(styles.table, className, styles[type])}
      locale={noData}
      {...others}
    />
  );
};

CustomTable.defaultProps = {
  isLoading: false,
};

CustomTable.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  customMenuItems: PropTypes.func,
  isLoading: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.func.isRequired,
        PropTypes.string.isRequired,
        PropTypes.node.isRequired,
      ]),
      dataIndex: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      render: PropTypes.func,
    }).isRequired,
  ).isRequired,
  onRow: PropTypes.func,
  sorterHandler: PropTypes.func,
  dataSource: PropTypes.array,
  tableHeader: PropTypes.func,
  emptyMode: PropTypes.oneOf(['new']),
};

export default CustomTable;
