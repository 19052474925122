import moment from 'moment';
import { createRangeAccessKey } from './helpers';

export const NUMBER_FORMATS = {
  decimal: 'decimal',
  integer: 'integer',
  currency: 'currency',
  percent: 'percent',
  date: 'date',
};

export const COUNTRY_CODES = {
  AU: 'AU',
};

export const COUNTRY_NAME = {
  AUSTRALIA: 'australia',
};

export const LOGO = {
  FULL: 'Fusion Markets',
  SHORT: 'Markets',
};

export const THEME = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  BROKER: 'BROKER',
  MANAGER: 'MANAGER',
};

export const AUTH_TYPES = {
  NEW_PASSWORD: {
    TITLE: 'New Password',
    SUB_TITLE: 'Create and confirm a new password',
    PASSWORD: 'Create New Password',
    PASSWORD_PLACEHOLDER: 'Password',
    PASSWORD_ELEMENT_TEXT: `At least 8 characters, one Capital and Number`,
    CONFIRM: 'Confirm Password',
    CONFIRM_PLACEHOLDER: 'Confirm Password',
    GRADIENT: 'Create Account',
    GRADIENT_SUB_TEXT: 'Remember it? Try to ',
    GRADIENT_SUB_LINK: 'Sign In ',
    FORM_NAME: 'new-password',
  },
  RESET_PASSWORD: {
    LEFT_TITLE:
      'In order to reset your password, Please enter your registered email address.',
    TITLE: 'Reset Your Password',
    SUB_TITLE: 'Enter your Email and instructions will be sent to you!',
    EMAIL: 'Email',
    EMAIL_PLACEHOLDER: 'Enter your Email',
    GRADIENT: `Reset Password`,
    GRADIENT_SUB_TEXT: `Remember it? Try to `,
    GRADIENT_SUB_LINK: 'Sign In ',
    FORM_NAME: 'reset-password',
  },
  SIGN_IN: {
    LEFT_TITLE: `Let's Get Started`,
    LEFT_SUB_TITLE: 'Welcome to %BRAND% Partners Area.',
    TITLE: 'Sign In',
    SUB_TITLE: 'Sign in to continue to %BRAND%',
    EMAIL: 'Email',
    EMAIL_PLACEHOLDER: 'Enter your Email',
    PASSWORD: 'Password',
    PASSWORD_PLACEHOLDER: 'Password',
    GRADIENT: 'Sign In',
    GRADIENT_SUB_TEXT: `Don’t have an account? `,
    GRADIENT_SUB_LINK_TEXT: `Sign Up`,
    GRADIENT_SUB_LINK:
      'https://fusionmarkets.com/en/Partnership/Introducing-brokers',
    FORM_NAME: 'sign-in',
    TFA_CODE_TITLE: 'TFA Code',
    TFA_CODE: 'code',
    TFA_CODE_SEND: 'Send',
  },
  SIGN_UP: {
    ERROR_PASSWORD: 'Password',
    LEFT_TITLE: `Create Your Account`,
    LEFT_SUB_TITLE:
      'Sign Up to create, discover and connect with the global forex community.',
    TITLE: 'Sign Up',
    SUB_TITLE: 'It’s free to SignUp and only takes a minute',
    EMAIL: 'Email',
    EMAIL_PLACEHOLDER: 'Enter your Email',
    PASSWORD: 'Create Password',
    PASSWORD_PLACEHOLDER: 'Password',
    PASSWORD_ELEMENT_TEXT: `At least 8 characters, one Capital and Number`,
    CONFIRM: 'Confirm Password',
    CONFIRM_PLACEHOLDER: 'Confirm Password',
    CONFIRM_ELEMENT_TEXT: 'I confirm I am over 18 years and accept the ',
    CONFIRM_ELEMENT_LINK: `Terms and Conditions`,
    GRADIENT: `Create Account`,
    GRADIENT_SUB_TEXT: `Already have an account? `,
    GRADIENT_SUB_LINK: `Sign In`,
    FORM_NAME: 'sign-up',
  },
};

export const FORM_INPUT_TYPES = {
  TEXT: 'text',
  CHECKBOX_GROUP: 'checkboxGroup',
  DATE: 'date',
  EMAIL: 'email',
  PASSWORD: 'password',
  NEW_PASSWORD: 'newPassword',
  CONFIRM_PASSWORD: 'confirmPassword',
  COUNTRY: 'country',
  PHONE_NUMBER: 'tel',
  SELECT: 'select',
  SLIDER: 'slider',
  NUMBER: 'number',
  CCY: 'ccy',
};

export const NOTIFICATION_MODAL = {
  BUTTON_TEXT: 'Confirm',
};

export const FILTER_TYPES = {
  SELECTOR: 'selector',
  SLIDER: 'slider',
  CHECKBOX: 'checkbox',
};

export const FORMS = {
  ACCESS: 'Access',
  ADD_COMMISSION: 'Add Commission',
  AMOUNT: 'Amount',
  COMMISSION_DATE: 'Commission Date',
  COMMISSION_TYPE: 'Commission Type',
  CONFIRM_PASSWORD: 'Confirm password',
  CLIENT: 'Client',
  CREATE_PASSWORD: 'Create Password',
  ENTER_IBS_ID: 'Enter IBs ID',
  ENTER: 'Enter',
  ENTER_PASSWORD: 'Enter password',
  EMPLOYEE: 'Employee',
  EMAIL: 'Email',
  AFFILIATE_CODE: 'Affiliate refCode',
  REF_CODE: 'Partner refCode',
  FEE_REVSHARE: 'Brokerage Fee RevShare (%)',
  FIRST_NAME: 'First name',
  FOLLOWING_COUNTRIES: 'Following Countries',
  GRP: 'GRP.',
  LOT_REVSHARE: 'Symbol Lot RevShare ($)',
  LAST_NAME: 'Last name',
  IBS_ID: 'IBs ID',
  IB_CLIENT: 'IB Client',
  PASSWORD: 'Password',
  SPREAD_REVSHARE: 'Symbol Spread RevShare (%)',
  SUB_IBS_CTU: 'Sub-IBs Commission CUT ($)',
  SUB_IBS_CPA: 'Sub-IBs CPA ($)',
  VALUE_PIPS: 'Value Pips',
  LEVEL: 'Level',
  COMMENT: 'Comment',
  TYPE: 'Type',
  POSITION_COUNT_QUALIFIER: 'Position Count Qualifier',
  POSITION_COUNT_QUALIFIER_PLACEHOLDER: 'Enter position count qualifier',
  HOLD_TIME: 'Hold time (minutes)',
  HOLD_TIME_PLACEHOLDER: '0 min',
  SYMBOL: 'Symbol',
  SYMBOLS: 'Symbols',
  SYMBOL_PLACEHOLDER: 'Select Symbol',
  LOT_REQUIREMENT: 'Lot Requirement',
  PAYOUT: 'Payout',
  LOT_REQUIREMENT_PLACEHOLDER: 'Enter lot requirement',
  PAYOUT_PLACEHOLDER: '$ 0',
  DEPOSIT: 'Deposit',
  COUNTRIES: 'Countries',
  FULL_NAME: 'Full name',
  COUNTRY: 'Country',
};

export const BUTTONS_TEXT = {
  ADD: 'Add',
  CANCEL: 'Cancel',
  DEFAULT: 'Default',
  SAVE: 'Save',
  RESET_DEFAULT: 'Reset to Default',
  SHARE: 'Share',
  SHARE_LINK: 'Share link',
  ADD_PARTNERS_GROUP: 'Add Partners Group',
  RUN: 'Run',
  APPROVE: 'Approve',
  CONFIRM: 'Confirm',
};

export const TITLES = {
  ADD_EMPLOYEE: 'Add New Employee',
  ADD_PARTNER: 'Add New Partner',
  CREATE_COMMISSION: 'Create Commission/Adjustment',
  COMMISSIONS: 'Commissions',
  MULTI_TIER_IBS: 'Multi Tier Sub-IBs',
  MULTI_TIER_CPAS: 'Multi Tier Sub-CPAs',
  NEW_PARTNER: 'New Partner',
  REVSHARE: 'RevShare',
  SUB_IBS: 'Sub-IBs',
  SUB_CPAS: 'Sub-CPAs',
  CPA_RAF: 'CPA/RAF',
  OVERVIEW: 'Overview',
  PAYMENTS: 'Payments',
  TRADES: 'Trades',
  OPEN_TRADES: 'Open Trades',
  CLOSED_TRADES: 'Closed Trades',
  DETAILS: 'Details',
  PERMISSION_ACCESS: 'Permissions & Access',
  ADD_PARTNERS_GROUP: 'Add Partners Group',
  EDIT_PARTNERS_GROUP: 'Edit Partners Group',
  PARTNER_GROUP: 'Partner Group',
  PAYOUT_STRUCTURE: 'Payout structure',
  PAYOUT_FILTERS: 'Payout Filters',
  TIERS: 'Tiers',
  MAKE_PAYMENT: 'Make a Payment',
  BALANCE_HISTORY: 'Balance history',
  ALL_SYMBOLS: 'All symbols',
  ALL_COUNTRIES: 'All Countries',
  RESET_PASSWORD: 'Reset Password',
  ALL_IDS: 'All IDs',
  ALL_IB_IDS: 'All IB IDs',
  ALL_ACCOUNT_LOGINS: 'All Account Logins',
  ALL_NAMES: 'All Names',
  ALL_PARTNER_IDS: 'All Partner IDs',
  ALL_REGISTRATION_SOURCES: 'All Registration Sources',
  ALL_STATUSES: 'All Statuses',
  ALL_TYPES: 'All Types',
  ALL_PLATFORMS: 'All Platforms',
  BANK_WIRE: 'Bank Wire',
  BANK_WIRES: 'Bank Wires',
  ACCOUNT_TRANSFER: 'Transfer to trading account',
  ACCOUNT_TRANSFERS: 'Trading Account Transfers',
  YES: 'Yes',
  NO: 'No',
  TWO_FACTOR_AUTHENTICATION: 'Two-Factor Authentication',
  APPROVED: 'Approved',
  PENDING: 'Pending',
};

export const ERRORS = {
  FILTER_ITEMS: 'Error in components/FilterItems/useSetting.js',
  SORTED_FUNC: 'Error in sortedFunc.js',
};

export const PLATFORM_TYPES = {
  CLIENTS: 'broker',
  STAFF: 'client',
};

export const NEW_CLIENT_MODAL = {
  TITLE: 'Group Name',
  INPUT_PLACEHOLDER: 'Enter Group Name',
};

export const STAFF = {
  DASHBOARD: {
    TABLE_GRADIENT_TITLE: 'Top Partners',
    TABLE_GRADIENT_BUTTON: 'See all',
    TABLE_BOTTOM_TITLE: 'All Partners',
    TABLE_BOTTOM_BUTTON: 'See all',
    SELECTED_VALUE: [
      'Change status to declined',
      'Change status to pending',
      'Change status to approved',
    ],
    OPTIONS: [
      {
        value: 'Change status to declined',
      },
      { value: 'Change status to pending' },
      { value: 'Change status to approved' },
    ],
  },
  PARTNERS: {
    PLACEHOLDER_STATUS: 'Show Approved',
    STATUS_OPTIONS: [
      { label: 'Show All', value: '' },
      { label: 'Show Approved', value: 'APPROVED' },
      { label: 'Show Pending', value: 'PRENDING' },
      { label: 'Show Declined', value: 'DECLINED' },
    ],
    PLACEHOLDER_COMMISSION: 'Commissions',
    COMMISSION_OPTIONS: [{ key: 'managers', value: 'All IB Managers' }],
    TABS_NAMES: [
      { key: 'partners', tab: 'Partners' },
      { key: 'ib-partners-groups', tab: 'IB Groups' },
      { key: 'cpa-partners-groups', tab: 'Affiliate Groups' },
    ],
  },
  APPLICATIONS: {
    PLACEHOLDER_STATUS: 'Show Approved',
    TABLE_TITLE: 'All Partners',
    STATUS_OPTIONS: [
      { key: 'approved', value: 'Show Approved' },
      { key: 'pending', value: 'Show Pending' },
      { key: 'declined', value: 'Show Declined' },
    ],
    SELECTED_VALUE: [
      'Change status to declined',
      'Change status to pending',
      'Change status to approved',
    ],
    OPTIONS: [
      {
        value: 'Change status to declined',
      },
      { value: 'Change status to pending' },
      { value: 'Change status to approved' },
    ],
  },
  REPORTS: {
    PLACEHOLDER_DATE: 'Month to Date',
    DATE_OPTIONS: [
      {
        value: 'Month to Date',
      },
    ],
  },
  TRADES_REPORTS: {
    PLACEHOLDER_DATE: 'Month to Date',
    DATE_OPTIONS: [
      {
        value: 'Month to Date',
      },
    ],
  },
  STAFF_MANAGEMENT: {
    PLACEHOLDER_ACTIONS: 'Active',
    OPTIONS_ACTIONS: [
      {
        value: 'Active',
      },
    ],
    OPTIONS_CHECKBOX: [
      {
        value: 'Admin role to Disabled',
      },
      {
        value: 'Admin role to Enabled',
      },
      {
        value: 'Actions',
      },
    ],
  },
};

export const GROUPS_EXPORT_FIELDS = ['id', 'name'];

export const GROUPS_EXPORT_HEADERS = ['Group ID', 'Group Name'];

export const MANAGEMENT_EXPORT_FIELDS = [
  'name',
  'email',
  'date',
  'lastLogin',
  'access',
  'statusRole',
];

export const MANAGEMENT_EXPORT_HEADERS = [
  'Name',
  'Email',
  'Start date',
  'Last Login',
  'Access',
  'Status',
];

export const ALL_PARTNERS_EXPORT_FIELDS = [
  'id',
  'name',
  'sourceType',
  'registered',
  'parentUsername',
  'country',
  'managerId',
  'status',
];

export const ALL_PARTNERS_EXPORT_HEADERS = [
  'ID',
  'Source type',
  'Name',
  'Registered',
  'Parent Username',
  'Country',
  'Manager',
  'Status',
];

export const REPORTS_EXPORT_FIELDS = [
  'name',
  'tradingAccount',
  'email',
  'country',
  'registrationDate',
  'regSource',
  'approvalDate',
  'status',
  'firstDeposit',
  'netFunding',
  'netPin',
];

export const REPORTS_EXPORT_HEADERS = [
  'Name',
  'Trading Account',
  'Email',
  'Country',
  'Registration date',
  'Registration Source',
  'Approval Date',
  'Status',
  'First deposit date',
  'Net Funding',
  'Net pln',
];

export const COMMISSIONS_REPORTS_EXPORT_FIELDS = [
  'id',
  'commissionDate',
  'partnerID',
  'userID',
  'amount',
];

export const COMMISSIONS_REPORTS_EXPORT_HEADERS = [
  'ID',
  'Commission Date',
  'Partner ID',
  'Partner ID',
  'Amount',
];

export const TRADES_REPORTS_EXPORT_FIELDS = [
  'id',
  'name',
  'openTime',
  'closeTime',
  'volume',
  'direction',
  'symbol',
  'open',
  'price',
  'commission',
  'profit',
  'order',
  'swap',
];

export const TRADES_REPORTS_EXPORT_HEADERS = [
  'ID',
  'Name',
  'Open time',
  'Close Time',
  'Size',
  'Type',
  'Symbol',
  'Open',
  'Price',
  'Commission',
  'Profit',
  'Order',
  'Swap',
];

export const EXPORT_MENU_ITEMS = [
  {
    buttonText: 'Export Excel',
    fileExtension: 'xlsx',
  },
  {
    buttonText: 'Export CSV',
    fileExtension: 'csv',
  },
  {
    buttonText: 'Copy',
  },
];

export const EXPORT_DATA_TYPES = {
  TABLE: 'table',
  VOLUME: 'volume',
  OVERVIEW: 'overview',
  PERFORMANCE: 'performance',
  CUSTOMER_IDENTITY: 'Customer Identity',
};

/**
 * this is the date of start use the service,
 * used for "All time"
 */
export const INIT_DATE = '2015-01-01';

export const TIME_PERIODS = {
  ALL_TIME: 'allTime',
  MONTH_TO_DATE: 'montToDate',
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  SEVEN_DAYS: '7Days',
  THIRTY_DAYS: '30Days',
  WEEK_TO_DATE: 'weekToDate',
  PREV_WEEK: 'prevWeek',
  PREV_MONTH: 'prevMonth',
  PREV_QUARTER: 'prevQuarter',
  PREV_MONTH_TO_DATE: 'prevMonthToDate',
};

export const PERIODS_RANGE_MAP = {
  [TIME_PERIODS.ALL_TIME]: [moment(INIT_DATE), moment()],
  [TIME_PERIODS.TODAY]: [moment().startOf('day'), moment()],
  [TIME_PERIODS.YESTERDAY]: [
    moment().subtract(1, 'days').startOf('day'),
    moment().subtract(1, 'days').endOf('day'),
  ],
  [TIME_PERIODS.SEVEN_DAYS]: [
    moment().subtract(7, 'days').startOf('day'),
    moment(),
  ],
  [TIME_PERIODS.THIRTY_DAYS]: [
    moment().subtract(30, 'days').startOf('day'),
    moment(),
  ],
  [TIME_PERIODS.WEEK_TO_DATE]: [moment().startOf('week'), moment()],
  [TIME_PERIODS.MONTH_TO_DATE]: [moment().startOf('month'), moment()],
  [TIME_PERIODS.PREV_WEEK]: [
    moment().subtract(1, 'weeks').startOf('week'),
    moment().subtract(1, 'weeks').endOf('week'),
  ],
  [TIME_PERIODS.PREV_MONTH]: [
    moment().subtract(1, 'months').startOf('month'),
    moment().subtract(1, 'months').endOf('month'),
  ],
  [TIME_PERIODS.PREV_QUARTER]: [
    moment().subtract(1, 'quarters').startOf('quarter'),
    moment().subtract(1, 'quarters').endOf('quarter'),
  ],
  [TIME_PERIODS.PREV_MONTH_TO_DATE]: [
    moment().subtract(1, 'months').startOf('month'),
    moment().subtract(1, 'months'),
  ],
};

export const RANGE_PERIOD_MAP = Object.entries(PERIODS_RANGE_MAP).reduce(
  (result, [period, range]) => {
    const [start, end] = range;

    const rangeAccessKey = createRangeAccessKey(start, end);
    result[rangeAccessKey] = period;

    return result;
  },
  {},
);

export const AUTOCOMPLETE_OPTION_TYPES = {
  CLIENT: 'client',
  DEFAULT: 'default',
};

export const DEFAULT_SYMBOLS = {
  lot: [],
  spread: [],
  fee: [],
};

export const DEFAULT_FIELDS = {
  ib: 100,
  partnerIb: 0,
  partnerId: '',
  masterIb: 0,
  masterId: '',
  selfIb: 0,
};

export const THOUSANDS_DEVIDER = {
  group: {
    count: 3,
    replaceWith: ',',
  },
};

export const USER_STATUS = [
  'dormant',
  'rejected',
  'funded',
  'active',
  'posted',
];

export const LEADS = ['lead1', 'lead2', 'lead3', 'lead4', 'Lead4'];

export const COMMISSION_STATUS = {
  APPROVED: 'APPROVED',
  PENDING: 'PENDING',
};

export const WITHDRAWAL_TYPE = {
  BANK_WIRE: 'BANK_WIRE',
  ACCOUNT_TRANSFER: 'ACCOUNT_TRANSFER',
};

export const DEFAULT_PAGE_SIZE = 10;
