import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import {
  getEmployeeById,
  getUsersLoadingType,
} from 'redux/sagas/users/selectors';
import Actions from 'redux/actions';

import ModalContainer from 'components/Common/ModalContainer';
import ModalHeader from 'components/Modals/DetailAndPermissionsModal/ModalHeader';
import ModalTabs from 'components/Modals/DetailAndPermissionsModal/ModalTabs';

import useRequestLoading from 'utils/hooks/useRequestLoading';
import { TITLES, USER_ROLES } from 'utils/constants';

import styles from './styles.module.less';

const DetailAndPermissionsModal = ({
  isOpen,
  onCancel,
  onSave,
  activeKey,
  onTabChange,
  record,
  ...other
}) => {
  const [form] = Form.useForm();

  const employee = useSelector(getEmployeeById(record.id));
  const usersLoadingType = useSelector(getUsersLoadingType);

  const initialValues = useMemo(
    () => ({
      ...employee,
      isCountryControl: !!employee.controlledCountries.length,
      isApproved: employee.status === 'APPROVED',
      isAdmin: employee.role === 'ADMIN',
    }),
    [employee],
  );

  const isPatchEmployeeLoading = useRequestLoading(
    usersLoadingType,
    [`${Actions.patchEmployee.request}`],
    () => {
      onCancel();
      form.resetFields();
    },
  );

  const saveChanges = useCallback(async () => {
    try {
      const { isCountryControl, isApproved, isAdmin, ...formValues } =
        await form.validateFields();

      const values = Object.keys(formValues).reduce((result, key) => {
        if (formValues[key] !== '') {
          return {
            ...result,
            [key]: formValues[key],
          };
        }

        return result;
      }, {});

      onSave({
        id: employee.id,
        ...values,
        controlledCountries: isCountryControl ? values.controlledCountries : [],
        role: isAdmin ? USER_ROLES.ADMIN : USER_ROLES.MANAGER,
        status: isApproved ? 'APPROVED' : 'DECLINED',
      });
    } catch (e) {
      console.log('form is unvalidated: ', e);
    }
  }, [form, onSave, employee.id]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  return (
    <ModalContainer
      title={activeKey}
      isOpen={isOpen}
      onCancel={onCancel}
      onFinish={saveChanges}
      footerType={
        activeKey === TITLES.TWO_FACTOR_AUTHENTICATION ? null : 'saveCancel'
      }
      className={styles.modal}
      confirmLoading={isPatchEmployeeLoading}
    >
      <Form form={form} initialValues={initialValues}>
        <ModalHeader employee={employee} />
        <ModalTabs
          activeKey={activeKey}
          onTabChange={onTabChange}
          form={form}
          userId={employee.id}
          {...other}
        />
      </Form>
    </ModalContainer>
  );
};

DetailAndPermissionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  record: PropTypes.any,
  other: PropTypes.any,
  activeKey: PropTypes.string,
  onTabChange: PropTypes.func,
};

export default DetailAndPermissionsModal;
