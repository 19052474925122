import { getErrorHandler } from 'utils/getErrorHandler';
import { store } from 'providers/ReduxProvider';
import { firebaseManager } from 'modules/shared/firebase/manager';
import Actions from 'redux/actions';
import { getUserIdFromIdToken } from 'utils/getUserIdFromIdToken';

export async function handleAuthErrors(error) {
  const code = error?.response?.data?.error;

  if (
    [
      'TOKEN_EXPIRED',
      'TOKEN_INVALID',
      'TOKEN_MISSED',
      'AUTH_INCOMPLETE',
      'USER_DISABLED',
    ].includes(code)
  ) {
    const firebase = firebaseManager.getCurrentInstance();
    const fbUser = await firebase.getCurrentUser();

    const userIdToken = await fbUser?.getIdToken();
    const requestIdToken = error?.config?.headers?.['access-token'];

    const userId = getUserIdFromIdToken(userIdToken);
    const requestUserId = getUserIdFromIdToken(requestIdToken);

    // Dispatch log out action if the userId from user's token is the same as userId from the request's token.
    // They won't be the same after session on behalf of the partner finished and switched back to the employee's one.
    if (userId === requestUserId) {
      store.dispatch(
        firebase.isPrimaryInstance()
          ? Actions.logOut.request()
          : Actions.logOutWithPartner.request(),
      );
      getErrorHandler()(error);
    }
  }

  throw error;
}
