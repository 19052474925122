import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { BrandContext } from '../../contexts/BrandContext';
import { Brand } from 'constants/brand';

export const BrandProvider = ({ children }) => {
  const [brand, setBrand] = useState(process.env.REACT_APP_BRAND ?? Brand.FM);

  useEffect(() => {
    document.body.setAttribute('data-brand', brand);
  }, [brand]);

  const changeBrand = useCallback((brand) => {
    setBrand(brand);
  }, []);

  const value = useMemo(
    () => ({
      brand,
      changeBrand,
    }),
    [brand, changeBrand],
  );

  return (
    <BrandContext.Provider value={value}>{children}</BrandContext.Provider>
  );
};

BrandProvider.propTypes = {
  children: PropTypes.node,
};
