import React from 'react';
import PropTypes from 'prop-types';
import TabsSection from 'components/Common/TabsSection';

import { TITLES } from 'utils/constants';

import Permissions from './Permissions';
import Details from './Details';
import ResetPassword from './ResetPassword';
import { TwoFactorAuthentication } from './TwoFactorAuthentication';

import styles from './styles.module.less';

const TABS_NAMES = [
  { key: TITLES.DETAILS, tab: TITLES.DETAILS },
  { key: TITLES.PERMISSION_ACCESS, tab: TITLES.PERMISSION_ACCESS },
  { key: TITLES.RESET_PASSWORD, tab: TITLES.RESET_PASSWORD },
  {
    key: TITLES.TWO_FACTOR_AUTHENTICATION,
    tab: TITLES.TWO_FACTOR_AUTHENTICATION,
  },
];

const ModalTabs = ({ onTabChange, activeKey, form, userId }) => (
  <TabsSection
    activeKey={activeKey}
    type="modal"
    tabNames={TABS_NAMES}
    onChange={onTabChange}
    className={styles.tabs}
  >
    {activeKey === TITLES.DETAILS && <Details />}
    {activeKey === TITLES.PERMISSION_ACCESS && <Permissions form={form} />}
    {activeKey === TITLES.RESET_PASSWORD && <ResetPassword />}
    {activeKey === TITLES.TWO_FACTOR_AUTHENTICATION && (
      <TwoFactorAuthentication userId={userId} />
    )}
  </TabsSection>
);

ModalTabs.propTypes = {
  onTabChange: PropTypes.func,
  form: PropTypes.object,
  activeKey: PropTypes.string,
  userId: PropTypes.number,
};

export default React.memo(ModalTabs);
