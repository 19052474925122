import React from 'react';
import PropTypes from 'prop-types';

import CustomIcon from 'components/Icons';

import AutoCompleteInput from './AutoCompleteInput';
import StyledInput from './StyledInput';

const SearchInput = ({
  placeholder,
  options,
  className,
  classNameInput,
  ...others
}) => (
  <AutoCompleteInput
    type="search"
    options={options}
    className={className}
    {...others}
  >
    <StyledInput
      type="search"
      placeholder={placeholder}
      prefix={CustomIcon({ type: 'search' })}
      className={classNameInput}
    />
  </AutoCompleteInput>
);

SearchInput.defaultProps = {
  options: [],
};

SearchInput.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  classNameInput: PropTypes.string,
};

export default SearchInput;
