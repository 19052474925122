import PartnerIdInput from 'components/Modals/CommissionsModal/SubIBs/Inputs/PartnerIdInput';
import MasterIdInput from 'components/Modals/CommissionsModal/SubIBs/Inputs/MasterIdInput';

export const SUB_IBS_COMPONENTS = [
  {
    name: 'ib',
    label: 'IB',
    initialValue: 100,
  },
  {
    name: 'partnerIb',
    label: 'Partner IB',
    initialValue: 0,
    Component: PartnerIdInput,
  },
  {
    name: 'masterIb',
    label: 'Master IB',
    initialValue: 0,
    Component: MasterIdInput,
  },
  {
    name: 'selfIb',
    label: 'Self Rebate',
    initialValue: 0,
  },
];
