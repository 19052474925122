import React from 'react';
import { Brand } from 'constants/brand';

export const MENU_ICONS = {
  [Brand.FM]: {
    dashboard: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.44 13.035C22.3837 13.1719 22.2882 13.2892 22.1654 13.3719C22.0426 13.4547 21.8981 13.4992 21.75 13.5H19.5V21C19.5 21.5967 19.263 22.169 18.841 22.5909C18.419 23.0129 17.8468 23.25 17.25 23.25H14.25C14.0511 23.25 13.8603 23.1709 13.7197 23.0303C13.579 22.8896 13.5 22.6989 13.5 22.5V18C13.5 17.801 13.421 17.6103 13.2803 17.4696C13.1397 17.329 12.9489 17.25 12.75 17.25H11.25C11.0511 17.25 10.8603 17.329 10.7197 17.4696C10.579 17.6103 10.5 17.801 10.5 18V22.5C10.5 22.6989 10.421 22.8896 10.2803 23.0303C10.1397 23.1709 9.94893 23.25 9.75001 23.25H6.75001C6.15328 23.25 5.58098 23.0129 5.15902 22.5909C4.73707 22.169 4.50001 21.5967 4.50001 21V13.5H2.25001C2.10194 13.4992 1.95741 13.4547 1.83462 13.3719C1.71183 13.2892 1.61628 13.1719 1.56001 13.035C1.50258 12.8984 1.48689 12.7478 1.51492 12.6023C1.54295 12.4569 1.61344 12.3229 1.71751 12.2175L11.4675 2.46745C11.5372 2.39716 11.6202 2.34136 11.7116 2.30329C11.803 2.26521 11.901 2.24561 12 2.24561C12.099 2.24561 12.1971 2.26521 12.2884 2.30329C12.3798 2.34136 12.4628 2.39716 12.5325 2.46745L22.2825 12.2175C22.3866 12.3229 22.4571 12.4569 22.4851 12.6023C22.5131 12.7478 22.4974 12.8984 22.44 13.035Z"
          fill="currentColor"
        />
      </svg>
    ),
    clients: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.929 15C15.795 15 18.929 11.866 18.929 8C18.929 4.13401 15.795 1 11.929 1C8.06302 1 4.92902 4.13401 4.92902 8C4.92902 11.866 8.06302 15 11.929 15Z"
          fill="currentColor"
        />
        <path
          d="M19.707 14.223C19.478 13.994 19.234 13.78 18.984 13.571C18.1439 14.64 17.0719 15.5041 15.8489 16.0981C14.6259 16.6921 13.2839 17.0004 11.9243 16.9998C10.5647 16.9991 9.22301 16.6895 8.0006 16.0942C6.77819 15.499 5.70702 14.6338 4.86801 13.564C3.63135 14.5937 2.63719 15.8838 1.95648 17.342C1.27577 18.8002 0.925279 20.3907 0.930009 22C0.930009 22.2652 1.03537 22.5195 1.2229 22.7071C1.41044 22.8946 1.66479 23 1.93001 23H21.93C22.1952 23 22.4496 22.8946 22.6371 22.7071C22.8247 22.5195 22.93 22.2652 22.93 22C22.9336 20.555 22.6506 19.1236 22.0974 17.7887C21.5441 16.4538 20.7317 15.2418 19.707 14.223Z"
          fill="currentColor"
        />
      </svg>
    ),
    'my-commission': () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14 9H22V15H14V9Z" fill="currentColor" />
        <path
          d="M20 3H5C3.346 3 2 4.346 2 6V18C2 19.654 3.346 21 5 21H20C21.103 21 22 20.103 22 19V17H14C12.897 17 12 16.103 12 15V9C12 7.897 12.897 7 14 7H22V5C22 3.897 21.103 3 20 3Z"
          fill="currentColor"
        />
      </svg>
    ),
    withdrawalsSidebar: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 6C1 5.20435 1.28973 4.44129 1.80546 3.87868C2.32118 3.31607 3.02065 3 3.75 3H20.25C20.9793 3 21.6788 3.31607 22.1945 3.87868C22.7103 4.44129 23 5.20435 23 6V7.5H1V6ZM1 10.5V18C1 18.7956 1.28973 19.5587 1.80546 20.1213C2.32118 20.6839 3.02065 21 3.75 21H20.25C20.9793 21 21.6788 20.6839 22.1945 20.1213C22.7103 19.5587 23 18.7956 23 18V10.5H1ZM5.125 13.5H6.5C6.86467 13.5 7.21441 13.658 7.47227 13.9393C7.73013 14.2206 7.875 14.6022 7.875 15V16.5C7.875 16.8978 7.73013 17.2794 7.47227 17.5607C7.21441 17.842 6.86467 18 6.5 18H5.125C4.76033 18 4.41059 17.842 4.15273 17.5607C3.89487 17.2794 3.75 16.8978 3.75 16.5V15C3.75 14.6022 3.89487 14.2206 4.15273 13.9393C4.41059 13.658 4.76033 13.5 5.125 13.5Z"
          fill="currentColor"
        />
      </svg>
    ),
    partners: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.28516 4.28591C4.28516 3.46753 4.61026 2.68266 5.18894 2.10398C5.76762 1.5253 6.55249 1.2002 7.37087 1.2002C8.18925 1.2002 8.97412 1.5253 9.5528 2.10398C10.1315 2.68266 10.4566 3.46753 10.4566 4.28591C10.4566 5.10429 10.1315 5.88916 9.5528 6.46784C8.97412 7.04652 8.18925 7.37162 7.37087 7.37162C6.55249 7.37162 5.76762 7.04652 5.18894 6.46784C4.61026 5.88916 4.28516 5.10429 4.28516 4.28591V4.28591Z"
          fill="currentColor"
        />
        <path
          d="M14.744 6.73014L14.8088 6.77952C15.4634 7.26475 16.2834 7.47136 17.0898 7.35425C17.8962 7.23713 18.6235 6.80581 19.113 6.1544C19.6026 5.503 19.8146 4.6844 19.7028 3.87725C19.591 3.0701 19.1645 2.33993 18.5164 1.84609C17.8682 1.35226 17.051 1.13485 16.2431 1.2413C15.4353 1.34776 14.7023 1.76945 14.2042 2.41433C13.7061 3.05922 13.4833 3.87495 13.5844 4.68351C13.6855 5.49206 14.1024 6.22779 14.744 6.73014V6.73014Z"
          fill="currentColor"
        />
        <path
          d="M9.32783 8.91427C9.52162 8.54732 9.78742 8.22323 10.1094 7.96138C10.4313 7.69954 10.8027 7.50532 11.2015 7.39034C11.6002 7.27536 12.018 7.24199 12.4299 7.29222C12.8419 7.34245 13.2394 7.47525 13.5988 7.68268C13.9582 7.8901 14.2721 8.16789 14.5217 8.49943C14.7713 8.83096 14.9514 9.20943 15.0513 9.61219C15.1513 10.015 15.1689 10.4337 15.1033 10.8435C15.0376 11.2532 14.89 11.6455 14.6692 11.9969C14.2445 12.6729 13.5743 13.1579 12.7995 13.3502C12.0246 13.5424 11.2055 13.4269 10.514 13.0278C9.82258 12.6288 9.31271 11.9773 9.09152 11.2102C8.87032 10.4431 8.95503 9.6202 9.32783 8.91427V8.91427Z"
          fill="currentColor"
        />
        <path
          d="M3.5135 8.91406H7.63293C7.46322 9.39698 7.37065 9.91692 7.37065 10.4569C7.37065 11.6418 7.81653 12.7249 8.54939 13.5426H8.14208C7.34664 13.5425 6.5706 13.7882 5.92018 14.2462C5.26977 14.7041 4.77676 15.3518 4.50865 16.1007C3.99076 15.8927 3.50586 15.6104 3.06916 15.2629C1.90893 14.3295 1.19922 12.9502 1.19922 11.2283C1.19922 10.6146 1.44304 10.0259 1.87706 9.5919C2.31107 9.15789 2.89972 8.91406 3.5135 8.91406V8.91406Z"
          fill="currentColor"
        />
        <path
          d="M15.8565 13.5426C17.5305 13.5426 18.9561 14.6087 19.4884 16.1007C20.013 15.8862 20.499 15.607 20.9294 15.2629C22.0897 14.3295 22.7994 12.9502 22.7994 11.2283C22.7994 10.6146 22.5556 10.0259 22.1216 9.5919C21.6875 9.15789 21.0989 8.91406 20.4851 8.91406H16.3657C16.5354 9.39698 16.628 9.91692 16.628 10.4569C16.6294 11.5957 16.2095 12.6948 15.4492 13.5426H15.8565Z"
          fill="currentColor"
        />
        <path
          d="M17.9966 16.5177C18.1093 16.7893 18.171 17.0886 18.171 17.4002C18.171 19.1221 17.4628 20.5014 16.301 21.4348C15.1578 22.3528 13.6242 22.8002 11.9996 22.8002C10.3749 22.8002 8.84133 22.3528 7.69808 21.4348C6.53785 20.5014 5.82813 19.1221 5.82813 17.4002C5.82732 17.0961 5.88662 16.7948 6.00264 16.5136C6.11865 16.2325 6.28909 15.977 6.50416 15.762C6.71922 15.5469 6.97467 15.3765 7.25582 15.2605C7.53697 15.1444 7.83827 15.0851 8.14242 15.0859H15.8567C16.3151 15.0858 16.7632 15.2219 17.1442 15.4768C17.5251 15.7317 17.8218 16.094 17.9966 16.5177Z"
          fill="currentColor"
        />
      </svg>
    ),
    reports: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 19C3 19.7956 3.31607 20.5587 3.87868 21.1213C4.44129 21.6839 5.20435 22 6 22H18C18.7956 22 19.5587 21.6839 20.1213 21.1213C20.6839 20.5587 21 19.7956 21 19V8.828C20.9996 8.03276 20.6834 7.27023 20.121 6.708L16.293 2.878C15.7304 2.31572 14.9674 1.9999 14.172 2H6C5.20435 2 4.44129 2.31607 3.87868 2.87868C3.31607 3.44129 3 4.20435 3 5V19ZM17 11C17 10.7348 16.8946 10.4804 16.7071 10.2929C16.5196 10.1054 16.2652 10 16 10C15.7348 10 15.4804 10.1054 15.2929 10.2929C15.1054 10.4804 15 10.7348 15 11V17C15 17.2652 15.1054 17.5196 15.2929 17.7071C15.4804 17.8946 15.7348 18 16 18C16.2652 18 16.5196 17.8946 16.7071 17.7071C16.8946 17.5196 17 17.2652 17 17V11ZM13 13C13 12.7348 12.8946 12.4804 12.7071 12.2929C12.5196 12.1054 12.2652 12 12 12C11.7348 12 11.4804 12.1054 11.2929 12.2929C11.1054 12.4804 11 12.7348 11 13V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V13ZM9 14.995C8.99867 14.7298 8.89205 14.476 8.70357 14.2894C8.5151 14.1028 8.26022 13.9987 7.995 14C7.72978 14.0013 7.47596 14.108 7.28936 14.2964C7.10276 14.4849 6.99867 14.7398 7 15.005L7.01 17.005C7.01133 17.2702 7.11795 17.524 7.30643 17.7106C7.4949 17.8972 7.74978 18.0013 8.015 18C8.28022 17.9987 8.53404 17.892 8.72064 17.7036C8.90724 17.5151 9.01133 17.2602 9.01 16.995L9 14.995Z"
          fill="currentColor"
        />
      </svg>
    ),
  },
  [Brand.GP]: {
    dashboard: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 18V8.625L1.2 10L0 8.4L3 6.1L5 4.61789V4.575L11 0L22 8.4L20.8 9.975L19 8.625V18H3ZM5 16H9.5V11H12.5V16H17V7.1L11 2.525L5 7.1V16Z"
          fill="#1C1B1F"
        />
      </svg>
    ),
    withdrawalsSidebar: () => (
      <svg
        width="22"
        height="16"
        viewBox="0 0 22 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 9C12.1667 9 11.4583 8.70833 10.875 8.125C10.2917 7.54167 10 6.83333 10 6C10 5.16667 10.2917 4.45833 10.875 3.875C11.4583 3.29167 12.1667 3 13 3C13.8333 3 14.5417 3.29167 15.125 3.875C15.7083 4.45833 16 5.16667 16 6C16 6.83333 15.7083 7.54167 15.125 8.125C14.5417 8.70833 13.8333 9 13 9ZM6 12C5.45 12 4.97933 11.804 4.588 11.412C4.196 11.0207 4 10.55 4 10V2C4 1.45 4.196 0.979333 4.588 0.588C4.97933 0.196 5.45 0 6 0H20C20.55 0 21.021 0.196 21.413 0.588C21.8043 0.979333 22 1.45 22 2V10C22 10.55 21.8043 11.0207 21.413 11.412C21.021 11.804 20.55 12 20 12H6ZM8 10H18C18 9.45 18.1957 8.979 18.587 8.587C18.979 8.19567 19.45 8 20 8V4C19.45 4 18.979 3.804 18.587 3.412C18.1957 3.02067 18 2.55 18 2H8C8 2.55 7.804 3.02067 7.412 3.412C7.02067 3.804 6.55 4 6 4V8C6.55 8 7.02067 8.19567 7.412 8.587C7.804 8.979 8 9.45 8 10ZM19 16H2C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V3H2V14H19V16Z"
          fill="#1C1B1F"
        />
      </svg>
    ),
    clients: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 1 20 V 17.2 C 1 16.6333 1.146 16.1123 1.438 15.637 C 1.7293 15.1623 2.1167 14.8 2.6 14.55 C 3.6333 14.0333 4.6833 13.6457 5.75 13.387 C 6.8167 13.129 7.9 13 9 13 C 10.1 13 11.1833 13.129 12.25 13.387 C 13.3167 13.6457 14.3667 14.0333 15.4 14.55 C 15.8833 14.8 16.2707 15.1623 16.562 15.637 C 16.854 16.1123 17 16.6333 17 17.2 V 20 H 1 Z M 19 20 V 17 C 19 16.2667 18.796 15.5623 18.388 14.887 C 17.9793 14.2123 17.4 13.6333 16.65 13.15 C 17.5 13.25 18.3 13.4207 19.05 13.662 C 19.8 13.904 20.5 14.2 21.15 14.55 C 21.75 14.8833 22.2083 15.254 22.525 15.662 C 22.8417 16.0707 23 16.5167 23 17 V 20 H 19 Z M 9 12 C 7.9 12 6.9583 11.6083 6.175 10.825 C 5.3917 10.0417 5 9.1 5 8 C 5 6.9 5.3917 5.9583 6.175 5.175 C 6.9583 4.3917 7.9 4 9 4 C 10.1 4 11.0417 4.3917 11.825 5.175 C 12.6083 5.9583 13 6.9 13 8 C 13 9.1 12.6083 10.0417 11.825 10.825 C 11.0417 11.6083 10.1 12 9 12 Z M 15 12 C 14.8167 12 14.5833 11.9793 14.3 11.938 C 14.0167 11.896 13.7833 11.85 13.6 11.8 C 14.05 11.2667 14.3957 10.675 14.637 10.025 C 14.879 9.375 15 8.7 15 8 C 15 7.3 14.879 6.625 14.637 5.975 C 14.3957 5.325 14.05 4.7333 13.6 4.2 C 13.8333 4.1167 14.0667 4.0623 14.3 4.037 C 14.5333 4.0123 14.7667 4 15 4 C 16.1 4 17.0417 4.3917 17.825 5.175 C 18.6083 5.9583 19 6.9 19 8 C 19 9.1 18.6083 10.0417 17.825 10.825 C 17.0417 11.6083 16.1 12 15 12 Z M 3 18 H 15 V 17.2 C 15 17.0167 14.9543 16.85 14.863 16.7 C 14.771 16.55 14.65 16.4333 14.5 16.35 C 13.6 15.9 12.6917 15.5623 11.775 15.337 C 10.8583 15.1123 9.9333 15 9 15 C 8.0667 15 7.1417 15.1123 6.225 15.337 C 5.3083 15.5623 4.4 15.9 3.5 16.35 C 3.35 16.4333 3.2293 16.55 3.138 16.7 C 3.046 16.85 3 17.0167 3 17.2 V 18 Z M 9 10 C 9.55 10 10.021 9.804 10.413 9.412 C 10.8043 9.0207 11 8.55 11 8 C 11 7.45 10.8043 6.9793 10.413 6.588 C 10.021 6.196 9.55 6 9 6 C 8.45 6 7.9793 6.196 7.588 6.588 C 7.196 6.9793 7 7.45 7 8 C 7 8.55 7.196 9.0207 7.588 9.412 C 7.9793 9.804 8.45 10 9 10 Z"
          fill="#1C1B1F"
        />
      </svg>
    ),
    partners: () => (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 12V10.425C0 9.69167 0.371 9.104 1.113 8.662C1.85433 8.22067 2.81667 8 4 8C4.21667 8 4.425 8.004 4.625 8.012C4.825 8.02067 5.01667 8.04167 5.2 8.075C4.96667 8.40833 4.79167 8.76667 4.675 9.15C4.55833 9.53333 4.5 9.94167 4.5 10.375V12H0ZM6 12V10.375C6 9.29167 6.55433 8.41667 7.663 7.75C8.771 7.08333 10.2167 6.75 12 6.75C13.8 6.75 15.25 7.08333 16.35 7.75C17.45 8.41667 18 9.29167 18 10.375V12H6ZM19.5 12V10.375C19.5 9.94167 19.4457 9.53333 19.337 9.15C19.229 8.76667 19.0667 8.40833 18.85 8.075C19.0333 8.04167 19.221 8.02067 19.413 8.012C19.6043 8.004 19.8 8 20 8C21.2 8 22.1667 8.22067 22.9 8.662C23.6333 9.104 24 9.69167 24 10.425V12H19.5ZM12 8.75C11.05 8.75 10.2 8.875 9.45 9.125C8.7 9.375 8.25833 9.66667 8.125 10H15.9C15.75 9.66667 15.304 9.375 14.562 9.125C13.8207 8.875 12.9667 8.75 12 8.75ZM4 7C3.45 7 2.97933 6.804 2.588 6.412C2.196 6.02067 2 5.55 2 5C2 4.43333 2.196 3.95833 2.588 3.575C2.97933 3.19167 3.45 3 4 3C4.56667 3 5.04167 3.19167 5.425 3.575C5.80833 3.95833 6 4.43333 6 5C6 5.55 5.80833 6.02067 5.425 6.412C5.04167 6.804 4.56667 7 4 7ZM20 7C19.45 7 18.979 6.804 18.587 6.412C18.1957 6.02067 18 5.55 18 5C18 4.43333 18.1957 3.95833 18.587 3.575C18.979 3.19167 19.45 3 20 3C20.5667 3 21.0417 3.19167 21.425 3.575C21.8083 3.95833 22 4.43333 22 5C22 5.55 21.8083 6.02067 21.425 6.412C21.0417 6.804 20.5667 7 20 7ZM12 6C11.1667 6 10.4583 5.70833 9.875 5.125C9.29167 4.54167 9 3.83333 9 3C9 2.15 9.29167 1.43733 9.875 0.862C10.4583 0.287333 11.1667 0 12 0C12.85 0 13.5623 0.287333 14.137 0.862C14.7123 1.43733 15 2.15 15 3C15 3.83333 14.7123 4.54167 14.137 5.125C13.5623 5.70833 12.85 6 12 6ZM12 2C11.7167 2 11.4793 2.09567 11.288 2.287C11.096 2.479 11 2.71667 11 3C11 3.28333 11.096 3.52067 11.288 3.712C11.4793 3.904 11.7167 4 12 4C12.2833 4 12.521 3.904 12.713 3.712C12.9043 3.52067 13 3.28333 13 3C13 2.71667 12.9043 2.479 12.713 2.287C12.521 2.09567 12.2833 2 12 2Z"
          fill="#110D3D"
        />
      </svg>
    ),
    reports: () => (
      <svg
        width="18"
        height="19"
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 19C2.16667 19 1.45833 18.7083 0.875 18.125C0.291667 17.5417 0 16.8333 0 16V13H3V0.5H4.5H6H7.5H9H10.5H12H13.5H15H16.5H18V16C18 16.8333 17.7083 17.5417 17.125 18.125C16.5417 18.7083 15.8333 19 15 19H3ZM15 17C15.2833 17 15.5207 16.904 15.712 16.712C15.904 16.5207 16 16.2833 16 16V2H5V13H14V16C14 16.2833 14.096 16.5207 14.288 16.712C14.4793 16.904 14.7167 17 15 17ZM6 6V4H12V6H6ZM6 9V7H12V9H6ZM14 6C13.7167 6 13.4793 5.904 13.288 5.712C13.096 5.52067 13 5.28333 13 5C13 4.71667 13.096 4.479 13.288 4.287C13.4793 4.09567 13.7167 4 14 4C14.2833 4 14.5207 4.09567 14.712 4.287C14.904 4.479 15 4.71667 15 5C15 5.28333 14.904 5.52067 14.712 5.712C14.5207 5.904 14.2833 6 14 6ZM14 9C13.7167 9 13.4793 8.904 13.288 8.712C13.096 8.52067 13 8.28333 13 8C13 7.71667 13.096 7.479 13.288 7.287C13.4793 7.09567 13.7167 7 14 7C14.2833 7 14.5207 7.09567 14.712 7.287C14.904 7.479 15 7.71667 15 8C15 8.28333 14.904 8.52067 14.712 8.712C14.5207 8.904 14.2833 9 14 9ZM3 17H12V15H2V16C2 16.2833 2.09567 16.5207 2.287 16.712C2.479 16.904 2.71667 17 3 17ZM2 17C2 17 2 16.904 2 16.712C2 16.5207 2 16.2833 2 16V15V17Z"
          fill="currentColor"
        />
      </svg>
    ),
    'my-commission': () => (
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 2V14C20 14.55 19.8043 15.021 19.413 15.413C19.021 15.8043 18.55 16 18 16H2C1.45 16 0.979333 15.8043 0.588 15.413C0.196 15.021 0 14.55 0 14V2C0 1.45 0.196 0.979333 0.588 0.588C0.979333 0.196 1.45 0 2 0H18C18.55 0 19.021 0.196 19.413 0.588C19.8043 0.979333 20 1.45 20 2ZM2 4H18V2H2V4ZM2 8V14H18V8H2Z"
          fill="#1C1B1F"
        />
      </svg>
    ),
  },
};
