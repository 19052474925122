import { combineComponents } from 'utils/combineComponents';
import { BrandProvider } from 'modules/shared/brand/providers/BrandProvider';
import { ThemeProvider } from 'modules/shared/theme/providers/ThemeProvider';
import { HelmetProvider } from './HelmetProvider';
import { QueryClientProvider } from './QueryClientProvider';
import { ReduxProvider } from './ReduxProvider';
import { RouterProvider } from './RouterProvider';

export const Providers = combineComponents([
  ThemeProvider,
  BrandProvider,
  HelmetProvider,
  QueryClientProvider,
  ReduxProvider,
  RouterProvider,
]);
