import { createAction, createAsyncAction } from 'typesafe-actions';

export default {
  findEmployees: createAsyncAction(
    'employees/find/request',
    'employees/find/success',
    'employees/find/failure',
  )(),
  findAllEmployees: createAsyncAction(
    'findAllEmployees/find/request',
    'findAllEmployees/find/success',
    'findAllEmployees/find/failure',
  )(),
  findManagers: createAsyncAction(
    'managers/find/request',
    'managers/find/success',
    'managers/find/failure',
  )(),
  findPartners: createAsyncAction(
    'partners/find/request',
    'partners/find/success',
    'partners/find/failure',
  )(),
  findAllPartners: createAsyncAction(
    'findAllPartners/find/request',
    'findAllPartners/find/success',
    'findAllPartners/find/failure',
  )(),
  createPartner: createAsyncAction(
    'partner/create/request',
    'partner/create/success',
    'partner/create/failure',
  )(),
  patchPartner: createAsyncAction(
    'partner/patch/request',
    'partner/patch/success',
    'partner/patch/failure',
  )(),
  dropLink: createAction('dropAffiliateLink')(),
  copyAffiliateLink: createAsyncAction(
    'copyAffiliateLink/action/request',
    'copyAffiliateLink/action/success',
    'copyAffiliateLink/action/failure',
  )(),
  removePartner: createAsyncAction(
    'partner/remove/request',
    'partner/remove/success',
    'partner/remove/failure',
  )(),
  createEmployee: createAsyncAction(
    'employee/create/request',
    'employee/create/success',
    'employee/create/failure',
  )(),
  patchEmployee: createAsyncAction(
    'employee/patch/request',
    'employee/patch/success',
    'employee/patch/failure',
  )(),
  removeEmployee: createAsyncAction(
    'employee/remove/request',
    'employee/remove/success',
    'employee/remove/failure',
  )(),
};
