import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useBrandAvatar } from 'utils/hooks/useBrandAvatar';
import styles from './styles.module.less';

const PersonAvatar = ({ imageURL, isOnline, type }) => {
  const BrandedAvatar = useBrandAvatar();

  return (
    <Avatar
      src={imageURL}
      icon={<BrandedAvatar />}
      className={clsx(styles.iconBackground, styles[type], {
        [styles.circle]: isOnline,
      })}
    />
  );
};

PersonAvatar.propTypes = {
  imageURL: PropTypes.string,
  isOnline: PropTypes.bool,
  type: PropTypes.string,
};

export default PersonAvatar;
