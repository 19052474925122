import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import styles from './styles.module.less';

const DateCell = (current) => (
  <div className={clsx('ant-picker-cell-inner', styles.dateCell)}>
    {current.date()}
  </div>
);

DateCell.propTypes = {
  current: PropTypes.object,
};

export default DateCell;
