import React, { useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import CustomIcon from 'components/Icons';

import styles from './styles.module.less';

const Password = ({ placeholder, className, ...other }) => {
  const passwordIcon = useCallback(
    (isVisible) => (
      <CustomIcon type={isVisible ? 'password-show' : 'password-hidden'} />
    ),
    [],
  );

  return (
    <Input.Password
      placeholder={placeholder}
      className={clsx(styles.passwordInput, className)}
      iconRender={passwordIcon}
      {...other}
    />
  );
};

Password.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  other: PropTypes.any,
};

export default Password;
