import { createSelector } from 'reselect';

import { USER_ROLES } from '../../../utils/constants';

export const getUserRole = ({ auth }) => auth.user?.role;

export const isAdminSelector = createSelector(
  getUserRole,
  (role) => role === USER_ROLES.ADMIN,
);

export const getUserInfo = ({ auth }) => ({
  ...auth.userInfo,
  role: auth.user?.role,
});

export const getUserId = ({ auth }) => auth.user.id;
export const getUserBalanceNum = ({ auth }) => auth.balanceNum;

export const getUser = ({ auth }) => auth.user;

export const getManager = ({ auth }) => auth.manager;

export const getSaveDetails = ({ auth }) => auth.saveDetails;
