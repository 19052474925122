import React from 'react';
import PropTypes from 'prop-types';
import { NUMBER_FORMATS } from 'utils/constants';
import { getFormattedNumber } from 'utils/helpers';

const TableColFormatted = ({
  payload,
  style,
  minimumFractionDigits,
  maximumFractionDigits,
  useGrouping,
  group,
  preFormatted,
  className,
}) => {
  const formatted = getFormattedNumber({
    number: payload || 0,
    style,
    minimumFractionDigits,
    maximumFractionDigits,
    useGrouping,
    group,
    signDisplay: payload < 0,
  });

  return (
    <span className={className}>
      {preFormatted}
      {formatted}
    </span>
  );
};

TableColFormatted.propTypes = {
  payload: PropTypes.number,
  style: PropTypes.oneOf([
    NUMBER_FORMATS.decimal,
    NUMBER_FORMATS.currency,
    NUMBER_FORMATS.percent,
  ]),
  minimumFractionDigits: PropTypes.number,
  maximumFractionDigits: PropTypes.number,
  useGrouping: PropTypes.bool,
  group: PropTypes.object,
  preFormatted: PropTypes.string,
  className: PropTypes.string,
};

TableColFormatted.defaultProps = {
  style: NUMBER_FORMATS.decimal,
  minimumFractionDigits: 2,
  useGrouping: false,
  payload: 0,
  group: {},
  preFormatted: '',
};

export default TableColFormatted;
