import React, { useCallback, useEffect } from 'react';
import styles from '../Trades/styles.module.less';
import TotalCommissions from '../Trades/TradesOverview/TotalCommissions';
import {
  getClientCommissionOverview,
  getClientTotalCommissionOverview,
} from 'redux/sagas/clientInfo/selectors';
import VolumeTrades from '../Trades/TradesOverview/VolumeTrades';
import VolumeOverview from '../../Common/StatisticTiles/VolumeOverview';
import TableWithFilter from '../../Table/TableWithFilter';
import { getExportMenu } from 'utils/export';
import { EXPORT_DATA_TYPES } from 'utils/constants';
import { CLIENTS_FEES_COLUMNS, CLIENTS_FEES_FILTERS } from './config';
import PropTypes from 'prop-types';
import { useAccountById } from 'modules/shared/account/hooks/useAccountById';
import { useTableState } from 'modules/shared/table/hooks/useTableState';
import { useClientFees } from 'utils/hooks/useClientFees';
import { useClientFeesLimits } from 'utils/hooks/useClientsFeesLimits';
import { mapDateRangeToSplitType } from '../../../utils/mapDateRangeToSplitType';
import Actions from 'redux/actions';
import { useDispatch } from 'react-redux';
import { useVolumeOverview } from 'utils/hooks/useVolumeOverview';

const Fees = ({ dateRange, accountId }) => {
  const dispatch = useDispatch();

  const { accountDetails } = useAccountById({ accountId });

  const { tableState, setTableState } = useTableState({
    sorter: { orderBy: 'date' },
  });

  const { fees, isFeesFetching } = useClientFees({
    accountId,
    dateRange,
    ...tableState,
  });

  const { feesLimits } = useClientFeesLimits({ accountId, dateRange });

  const { series, enabledSeries, handleSeriesChange } = useVolumeOverview({
    accountId,
    dateRange,
  });

  const menuItems = useCallback(
    (currentData) =>
      getExportMenu({
        fileName: accountDetails?.name || '',
        exportType: EXPORT_DATA_TYPES.TABLE,
        sourceList: currentData,
        exportFields: CLIENTS_FEES_COLUMNS.map(({ dataIndex }) => dataIndex),
        header: CLIENTS_FEES_COLUMNS.map(({ title }) => title),
      }),
    [accountDetails],
  );

  const selector = useCallback(
    () => ({
      dataSource: fees?.rows,
      total: fees?.count,
    }),
    [fees],
  );

  useEffect(() => {
    const [dateFrom, dateTo] = dateRange;

    const splitType = mapDateRangeToSplitType(dateRange);

    const payload = {
      accountId,
      splitType,
      dateFrom: dateFrom.toISOString(),
      dateTo: dateTo.toISOString(),
    };

    dispatch(Actions.loadClientTradesOverview.request(payload));
  }, [accountId, dispatch, dateRange]);

  const volumeSelector = useCallback(
    () => ({
      enabledSeries,
      series,
    }),
    [series, enabledSeries],
  );

  return (
    <>
      <div className={styles.tradesTiles}>
        <TotalCommissions selector={getClientTotalCommissionOverview} />
        <VolumeTrades selector={getClientCommissionOverview} />

        <VolumeOverview
          selector={volumeSelector}
          onSeriesChanged={handleSeriesChange}
          title="Volume"
          className={styles.volumeChart}
        />
      </div>
      <TableWithFilter
        customMenuItems={menuItems}
        filterSelector={() => feesLimits}
        isLoading={isFeesFetching}
        selector={selector}
        loadData={setTableState}
        rowKey="id"
        columns={CLIENTS_FEES_COLUMNS}
        filterItems={CLIENTS_FEES_FILTERS}
        title="Fees"
        type="trades"
        className={styles.tradesTable}
      />
    </>
  );
};

Fees.propTypes = {
  accountId: PropTypes.number.isRequired,
  dateRange: PropTypes.arrayOf(PropTypes.string),
};

export default Fees;
