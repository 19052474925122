import { createAsyncAction, createAction } from 'typesafe-actions';

export default {
  findPartnersGroups: createAsyncAction(
    'partnersGroups/find/request',
    'partnersGroups/find/success',
    'partnersGroups/find/failure',
  )(),
  findAllPartnersGroups: createAsyncAction(
    'findAllPartnersGroups/find/request',
    'findAllPartnersGroups/find/success',
    'findAllPartnersGroups/find/failure',
  )(),
  findPartnersForGroups: createAsyncAction(
    'partnersForGroups/find/request',
    'partnersForGroups/find/success',
    'partnersForGroups/find/failure',
  )(),
  createPartnersGroup: createAsyncAction(
    'partnersGroup/create/request',
    'partnersGroup/create/success',
    'partnersGroup/create/failure',
  )(),
  patchPartnersGroup: createAsyncAction(
    'partnersGroup/patch/request',
    'partnersGroup/patch/success',
    'partnersGroup/patch/failure',
  )(),
  removePartnersGroup: createAsyncAction(
    'partnersGroup/remove/request',
    'partnersGroup/remove/success',
    'partnersGroup/remove/failure',
  )(),
  loadCpaQualifier: createAsyncAction(
    'loadCpaQualifier/action/request',
    'loadCpaQualifier/action/success',
    'loadCpaQualifier/action/failure',
  )(),
  loadTiers: createAsyncAction(
    'loadTiers/action/request',
    'loadTiers/action/success',
    'loadTiers/action/failure',
  )(),
  resetCpaQualifier: createAction('partnersGroup/cpaQualifier/reset')(),
  createCpaQualifier: createAsyncAction(
    'cpaQualifier/create/request',
    'cpaQualifier/create/success',
    'cpaQualifier/create/failure',
  )(),
};
