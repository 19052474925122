import React, { useCallback, useMemo, useState } from 'react';

import CommissionsModal from 'components/Modals/CommissionsModal';
import StyledDropdown from 'components/Common/Dropdown/StyledDropdown';

import { TITLES } from 'utils/constants';

import styles from './styles.module.less';

const onStopPropagation = (e) => e.stopPropagation();

const TableColDropdown = (props) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [activeTab, setActiveTab] = useState('');

  const onCommissionModalCancel = useCallback(() => {
    setActiveTab(null);
  }, []);

  const menuItems = useMemo(
    () => [
      {
        text: TITLES.REVSHARE,
        onClick: () => {
          setActiveTab(TITLES.REVSHARE);
          setIsDropdownVisible(false);
        },
      },
      {
        text: TITLES.SUB_IBS,
        onClick: () => {
          setActiveTab(TITLES.SUB_IBS);
          setIsDropdownVisible(false);
        },
      },
    ],
    [],
  );

  return (
    <div onClick={onStopPropagation}>
      <CommissionsModal
        isOpen={!!activeTab}
        activeTab={activeTab}
        onTabChange={setActiveTab}
        onCancel={onCommissionModalCancel}
        {...props}
      />
      <StyledDropdown
        classNameButton={styles.dropdown}
        menuItems={menuItems}
        className={styles.overlay}
        onVisibleChange={setIsDropdownVisible}
        visible={isDropdownVisible}
      />
    </div>
  );
};

export default TableColDropdown;
