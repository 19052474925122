import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';

export const mapToOptions = (
  dictionary,
  valueFieldAccessor,
  additionalFields = {},
) =>
  Object.entries(dictionary).map(([dictionaryKey, dictionaryValue]) => {
    const isValueByKeyObject = isPlainObject(dictionaryValue);
    const item = {
      label: isValueByKeyObject
        ? dictionaryValue?.[valueFieldAccessor]
        : dictionaryValue,
      value: dictionaryKey,
    };

    if (!isEmpty(additionalFields) && isValueByKeyObject) {
      Object.entries(additionalFields).forEach(
        ([additionalFieldName, additionalFieldAccessor]) => {
          item[additionalFieldName] =
            dictionaryValue?.[additionalFieldAccessor];
        },
      );
    }

    return item;
  });
