import { useCallback, useRef } from 'react';

export default function useDebounce(handler, delay = 300) {
  const ref = useRef(null);

  return useCallback(
    (...args) => {
      clearTimeout(ref.current);
      ref.current = setTimeout(() => handler(...args), delay);
    },
    [delay, handler],
  );
}
