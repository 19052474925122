import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import StyledButton from 'components/Common/Buttons/StyledButton';
import ModalContainer from 'components/Common/ModalContainer';
import TabsSection from 'components/Common/TabsSection';
import RevShare from './RevShare';
import SubIBs from './SubIBs';

import {
  TITLES,
  BUTTONS_TEXT,
  DEFAULT_SYMBOLS,
  DEFAULT_FIELDS,
} from 'utils/constants';

import styles from './styles.module.less';
import {
  getSettings,
  getSubIBSettings,
} from 'redux/sagas/commissionSettings/selectors';
import Actions from 'redux/actions';

const TABS = [
  { key: TITLES.REVSHARE, tab: TITLES.REVSHARE },
  { key: TITLES.SUB_IBS, tab: TITLES.SUB_IBS },
];

const CommissionsModal = ({
  isOpen,
  onCancel,
  record: user,
  activeTab,
  onTabChange,
}) => {
  const dispatch = useDispatch();
  const settings = useSelector(getSettings);
  const subIBSettings = useSelector(getSubIBSettings);
  const [form] = Form.useForm();
  const [selectedSymbols, setSelectedSymbols] = useState(DEFAULT_SYMBOLS);

  useEffect(() => {
    setSelectedSymbols(() => ({
      ...settings,
    }));
    form.setFieldsValue(subIBSettings);
  }, [form, settings, subIBSettings]);

  useEffect(() => {
    if (isOpen && user?.id) {
      dispatch(Actions.loadSettings.request({ groupId: user?.id }));

      return () => {
        dispatch(Actions.resetSettings());
      };
    }
  }, [dispatch, user?.id, isOpen]);

  const resetToDefault = () => {
    form.setFieldsValue(DEFAULT_FIELDS);
    setSelectedSymbols(DEFAULT_SYMBOLS);
  };

  const onSave = () => {
    const subIbFields = form.getFieldsValue(true);
    const subIbSettings = Object.entries(subIbFields).map(([key, value]) => ({
      name: 'sub',
      key,
      value,
    }));

    const settings = Object.entries(selectedSymbols).reduce(
      (result, [name, symbols]) => {
        const formattedSymbols = symbols.map((symbol) => ({
          name,
          key: symbol.value,
          value: symbol.amount,
          isRemoved: symbol.isRemoved,
        }));

        return result.concat(formattedSymbols);
      },
      [],
    );
    dispatch(
      Actions.createSettings.request({
        groupId: user?.id,
        settings: settings.concat(subIbSettings),
      }),
    );
    onCancel();
  };

  const closeHandler = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <div>
      <ModalContainer
        title={
          <div className={styles.title}>
            {
              <>
                <span className={styles.titleName}>{user?.name}:&nbsp;</span>
                <span className={styles.titleId}>ID {user?.id}</span>
              </>
            }
          </div>
        }
        isOpen={isOpen}
        onCancel={closeHandler}
        onFinish={onSave}
        footerType="saveCancel"
        className={styles.modalContainer}
      >
        <TabsSection
          type="modal"
          activeKey={activeTab}
          onChange={onTabChange}
          tabNames={TABS}
          tabBarExtraContent={
            <StyledButton
              type="primary"
              text={BUTTONS_TEXT.RESET_DEFAULT}
              onClick={resetToDefault}
            />
          }
        >
          <RevShare
            selectedSymbols={selectedSymbols}
            setSelectedSymbols={setSelectedSymbols}
          />
          <Form form={form}>
            <SubIBs form={form} />
          </Form>
        </TabsSection>
      </ModalContainer>
    </div>
  );
};

CommissionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  record: PropTypes.object,
  activeTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
};

export default CommissionsModal;
