import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  PARTNERS_TRADES_COLUMNS,
  PARTNERS_TRADES_FILTER,
} from 'components/Clients/Trades/config';
import TableWithFilter from 'components/Table/TableWithFilter';
import TradesSummary from 'components/MyCommission/TradesSummary';
import TotalCommissions from 'components/Clients/Trades/TradesOverview/TotalCommissions';
import VolumeTrades from 'components/Clients/Trades/TradesOverview/VolumeTrades';

import useExportMenu from 'utils/hooks/useExportMenu';
import useLoad from 'utils/hooks/useLoad';
import useTradesSummary from './hooks/useTradesSummary';

import { EXPORT_DATA_TYPES } from 'utils/constants';

import Actions from 'redux/actions';
import {
  getMyPartnersTradesTable,
  getMyPartnersTradesCount,
  getMyPartnersTradesFilters,
  getPartnersEarnedCommissionChartData,
  getPartnersTotalCommissionChartData,
} from 'redux/sagas/myCommission/selectors';

import styles from './styles.module.less';

const MyPartnersTradesTable = ({ dateRange, showSummary = false, title }) => {
  const dispatch = useDispatch();
  const tradesCount = useSelector(getMyPartnersTradesCount);
  const exportMenu = useExportMenu({
    fileName: title,
    exportType: EXPORT_DATA_TYPES.TABLE,
    exportFields: PARTNERS_TRADES_COLUMNS.map(({ dataIndex }) => dataIndex),
    header: PARTNERS_TRADES_COLUMNS.map(({ title }) => title),
    action: Actions.exportMyPartnersTrades,
    dateRange,
    sorter: {
      orderBy: 'closeDate',
      orderDirection: 'descend',
    },
    limit: tradesCount,
  });

  const { rowSelection, handleRowSelection, summary } = useTradesSummary();

  const loadData = useLoad(Actions.loadMyPartnersTrades, dateRange, {
    orderBy: 'closeDate',
    orderDirection: 'descend',
  });

  const rangePayload = useMemo(
    () => ({
      dateFrom: dateRange[0].toISOString(),
      dateTo: dateRange[1].toISOString(),
    }),
    [dateRange],
  );

  useEffect(() => {
    dispatch(Actions.loadMyPartnersTradesFilterLimits.request(rangePayload));
  }, [rangePayload, dispatch]);

  useEffect(() => {
    if (showSummary) {
      dispatch(Actions.loadMyPartnersTradesCommissions.request(rangePayload));
    }
  }, [showSummary, rangePayload, dispatch]);

  return (
    <>
      {showSummary && (
        <div className={styles.tradesTiles}>
          <TotalCommissions selector={getPartnersTotalCommissionChartData} />
          <VolumeTrades selector={getPartnersEarnedCommissionChartData} />
        </div>
      )}

      <TableWithFilter
        customMenuItems={exportMenu}
        filterSelector={getMyPartnersTradesFilters}
        selector={getMyPartnersTradesTable}
        loadData={loadData}
        rowKey="id"
        columns={PARTNERS_TRADES_COLUMNS}
        filterItems={PARTNERS_TRADES_FILTER}
        rowSelection={rowSelection}
        onRow={handleRowSelection}
        summary={() =>
          showSummary ? <TradesSummary summary={summary} /> : null
        }
        title={title}
        className={styles.tradesTable}
      />
    </>
  );
};

MyPartnersTradesTable.propTypes = {
  dateRange: PropTypes.array,
  showSummary: PropTypes.bool,
  title: PropTypes.string,
};

export default MyPartnersTradesTable;
