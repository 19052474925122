import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

import styles from './styles.module.less';

const FeesSummary = ({ summary }) => {
  const { totalCommission, totalAmount } = summary;

  return (
    <Table.Summary fixed>
      <Table.Summary.Row className={styles.summary}>
        <Table.Summary.Cell colSpan={4} index={1}>
          Total
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1} index={2}>
          ${totalAmount}
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={1} index={3}>
          ${totalCommission}
        </Table.Summary.Cell>
        <Table.Summary.Cell colSpan={2} index={4} />
      </Table.Summary.Row>
    </Table.Summary>
  );
};

FeesSummary.propTypes = {
  summary: PropTypes.shape({
    totalCommission: PropTypes.number,
    totalAmount: PropTypes.number,
  }),
};

export default FeesSummary;
