import { createSelector } from 'reselect';

export const getMyClientsTradesTable = ({ myCommission }) =>
  myCommission.clientsTrades.table;

export const getMyClientsTradesCount = ({ myCommission }) =>
  myCommission.clientsTrades.table.total;

export const getMyClientsTradesFilters = ({ myCommission }) =>
  myCommission.clientsTrades.filterLimits;

export const getMyPartnersTradesTable = ({ myCommission }) =>
  myCommission.partnersTrades.table;

export const getMyPartnersTradesCount = ({ myCommission }) =>
  myCommission.partnersTrades.table.total;

export const getMyPartnersTradesFilters = ({ myCommission }) =>
  myCommission.partnersTrades.filterLimits;

const volumeSelector = ({ myCommission }) => myCommission.overview;

export const getMyCommissionTradesOverview = createSelector(
  volumeSelector,
  (overview) => {
    let enabledSeries = overview.volume.filter(({ enabled }) => enabled);
    const others = overview.volume
      .filter(({ enabled }) => !enabled)
      .reduce((acc, { value }) => acc + value, 0);

    if (others) {
      enabledSeries.push({
        key: 'Others',
        title: 'Others',
        value: Number(others.toFixed(2)),
      });
    }

    if (enabledSeries.length === 0) {
      enabledSeries = [
        {
          key: 'EURUSD',
          title: 'EURUSD',
          value: 0,
        },
        {
          key: 'AUDUSD',
          title: 'AUDUSD',
          value: 0,
        },
        {
          key: 'GBPUSD',
          title: 'GBPUSD',
          value: 0,
        },
        {
          key: 'Others',
          title: 'Others',
          value: 0,
        },
      ];
    }

    return {
      enabledSeries,
      series: overview.volume,
      ...overview,
    };
  },
);

export const getMyPaymentsTable = ({ myCommission }) =>
  myCommission.payments.table;

export const getMyBalanceTable = ({ myCommission }) =>
  myCommission.balance.table;

export const getMyPaymentsCount = ({ myCommission }) =>
  myCommission.payments.table.total;

export const getMyPaymentsFilters = ({ myCommission }) =>
  myCommission.payments.filterLimits;

export const getMyBalanceFilters = ({ myCommission }) =>
  myCommission.balance.filterLimits;

export const getClientsTotalCommissionChartData = ({ myCommission }) =>
  myCommission.clientsTrades.totalCommissionTileChartData;

export const getClientsEarnedCommissionChartData = ({ myCommission }) =>
  myCommission.clientsTrades.earnedCommissionTileChartData;

export const getPartnersTotalCommissionChartData = ({ myCommission }) =>
  myCommission.partnersTrades.totalCommissionTileChartData;

export const getPartnersEarnedCommissionChartData = ({ myCommission }) =>
  myCommission.partnersTrades.earnedCommissionTileChartData;
