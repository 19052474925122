import { initializeApp, deleteApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { FbInstanceName } from '../constants/fbInstanceName';

export class FirebaseService {
  constructor(config, brand, name) {
    this.brand = brand;
    this.name = name;
    this.app = initializeApp(config, brand.concat(name));
  }

  getAuth() {
    return getAuth(this.app);
  }

  async getCurrentUser() {
    const auth = this.getAuth();
    await auth.authStateReady();

    return auth.currentUser;
  }

  async delete() {
    await deleteApp(this.app);
  }

  isPrimaryInstance() {
    return this.name === FbInstanceName.PRIMARY;
  }

  isSecondaryInstance() {
    return this.name === FbInstanceName.SECONDARY;
  }
}
