import { useCallback, useMemo, useState } from 'react';
import { getFormattedNumber } from 'utils/helpers';

const useFeesSummary = () => {
  const [selected, setSelected] = useState({
    rows: [],
    keys: [],
  });

  const rowSelection = useMemo(
    () => ({
      hideSelectAll: true,
      onChange: (keys, rows) => {
        setSelected({ rows, keys });
      },
      selectedRowKeys: selected.keys,
      columnWidth: 0,
      renderCell: () => null,
    }),
    [selected.keys],
  );

  const handleRowSelection = useCallback(
    (record) => ({
      onClick: () => {
        setSelected(({ keys, rows }) => {
          const keyIndex = keys.indexOf(record.id);

          if (keyIndex !== -1) {
            return {
              keys: keys.filter((key) => key !== record.id),
              rows: rows.filter(({ id }) => id !== record.id),
            };
          }

          return {
            keys: [...keys, record.id],
            rows: [...rows, record],
          };
        });
      },
    }),
    [],
  );

  const summary = useMemo(
    () =>
      selected.rows.reduce(
        ({ totalCommission, totalAmount }, { commission, amount }) => ({
          totalCommission: getFormattedNumber({
            number: Number(totalCommission) + commission,
          }),
          totalAmount: getFormattedNumber({
            number: Number(totalAmount) + amount,
          }),
        }),
        { totalCommission: 0, totalAmount: 0 },
      ),
    [selected.rows],
  );

  return {
    rowSelection,
    handleRowSelection,
    summary,
  };
};

export default useFeesSummary;
