import { useTheme } from 'modules/shared/theme/hooks/useTheme';
import { useMemo } from 'react';
import { THEME } from '../constants';

export const useLogoThemedTextColor = ({ darkModeColor, lightModeColor }) => {
  const { theme } = useTheme();

  return useMemo(() => {
    const COLORS_MAP = {
      [THEME.DARK]: darkModeColor,
      [THEME.LIGHT]: lightModeColor,
    };

    return COLORS_MAP[theme];
  }, [theme, darkModeColor, lightModeColor]);
};
