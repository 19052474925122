import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import clsx from 'clsx';
import SiderMenu from './SiderMenu';

import styles from './styles.module.less';

const { Sider } = Layout;

const Sidebar = ({ type }) => {
  const [isSiderCollapsed, setSiderCollapsed] = useState(false);

  const onTriggerClick = useCallback(() => {
    setSiderCollapsed((state) => !state);
  }, []);

  return (
    <Sider
      width={285}
      collapsedWidth={62}
      collapsible
      trigger={null}
      collapsed={isSiderCollapsed}
      className={clsx(styles.sider, {
        [styles.siderCollapsed]: isSiderCollapsed,
      })}
    >
      <SiderMenu
        onTriggerClick={onTriggerClick}
        isCollapsed={isSiderCollapsed}
        type={type}
      />
    </Sider>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

export default Sidebar;
