import { createAsyncAction } from 'typesafe-actions';

export default {
  loadTopClients: createAsyncAction(
    'loadTopClients/request',
    'loadTopClients/success',
    'loadTopClients/failure',
  )(),
  loadLatestClients: createAsyncAction(
    'loadLatestClients/request',
    'loadLatestClients/success',
    'loadLatestClients/failure',
  )(),
  loadAllClients: createAsyncAction(
    'loadAllClients/request',
    'loadAllClients/success',
    'loadAllClients/failure',
  )(),
  exportAllClients: createAsyncAction(
    'exportAllClients/request',
    'exportAllClients/success',
    'exportAllClients/failure',
  )(),
  loadClientsLimits: createAsyncAction(
    'loadClientsLimits/request',
    'loadClientsLimits/success',
    'loadClientsLimits/failure',
  )(),
  loadPartnerClientsLimits: createAsyncAction(
    'loadPartnerClientsLimits/request',
    'loadPartnerClientsLimits/success',
    'loadPartnerClientsLimits/failure',
  )(),
};
