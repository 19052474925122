import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../queryKeys';
import { api } from 'api';
import { mapDateRange } from '../mapDateRange';
import { getErrorHandler } from '../getErrorHandler';

export const useClientFees = ({
  accountId,
  dateRange,
  pagination,
  filters,
  sorter,
}) => {
  const {
    data: fees = {},
    isFetching: isFeesFetching,
    isLoading: isFeesLoading,
  } = useQuery({
    queryKey: queryKeys.clientFees(
      accountId,
      dateRange,
      pagination,
      filters,
      sorter,
    ),
    queryFn: async () => {
      const response = await api.getFees(accountId, {
        ...mapDateRange(dateRange),
        ...pagination,
        ...filters,
        ...sorter,
      });

      return response.data;
    },
    onError: getErrorHandler('Unable to load fees report'),
    keepPreviousData: true,
  });

  return {
    fees,
    isFeesFetching,
    isFeesLoading,
  };
};
