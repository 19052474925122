import React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';
import Option from './Option';

import styles from '../../styles.module.less';

const PartnerOptions = ({ options, currentValue, justify, isLoading }) => {
  const optionsList = options?.map(({ id, fullName, email, refCode }) => ({
    value: id,
    label: (
      <Option
        name={fullName}
        currentValue={currentValue}
        suggested={id}
        justify={justify}
        email={email}
        refcode={refCode}
      />
    ),
  }));

  return isLoading
    ? [
        ...optionsList,
        {
          value: undefined,
          label: <Spinner spinFontSize={20} className={styles.spinner} />,
        },
      ]
    : optionsList;
};

PartnerOptions.defaultProps = {
  currentValue: '',
  isLoading: false,
  justify: '',
};

PartnerOptions.propTypes = {
  options: PropTypes.array.isRequired,
  currentValue: PropTypes.string,
  justify: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default PartnerOptions;
