import { mapValues } from 'lodash';

const getQueryKeyCreator =
  (resource) =>
  (...deps) =>
    [resource, ...deps];

export const queryKeys = mapValues(
  {
    account: 'account',
    accountMetrics: 'accountMetrics',
    adminBsUsers: 'adminBsUsers',
    adminBsUsersLimits: 'adminBsUsersLimits',
    balanceDetails: 'balanceDetails',
    brandMetrics: 'brandMetrics',
    brandMetricsTimeSeries: 'brandMetricsTimeSeries',
    bsUserLimits: 'bsUserLimits',
    bsUsers: 'bsUsers',
    clientFees: 'clientFees',
    clientFeesLimits: 'clientFeesLimits',
    cpaCommissions: 'cpaCommissions',
    cpaCommissionsSum: 'cpaCommissionsSum',
    pendingCpaCommissionsReport: 'pendingCpaCommissionsReport',
    pendingCpaCommissionsReportLimits: 'pendingCpaCommissionsReportLimits',
    approvedCpaCommissionsReport: 'approvedCpaCommissionsReport',
    approvedCpaCommissionsReportLimits: 'approvedCpaCommissionsReportLimits',
    feesReport: 'feesReport',
    feesReportLimits: 'feesReportLimits',
    hasSubIb: 'hasSubIb',
    myCommissionClientFeeLimits: 'myCommissionClientFeeLimits',
    myCommissionClientFees: 'myCommissionClientFees',
    partnerMetrics: 'partnerMetrics',
    partnerMetricsTimeSeries: 'partnerMetricsTimeSeries',
    partnerSettings: 'partnerSettings',
    partnersOptions: 'partnersOptions',
    symbols: 'symbols',
    topAccounts: 'topAccounts',
    topCountries: 'topCountries',
    topPartners: 'topPartners',
    totalCommissionTimeSeries: 'totalCommissionTimeSeries',
    user: 'user',
    volumeOverview: 'volumeOverview',
    withdrawals: 'withdrawals',
    withdrawalsLimits: 'withdrawalsLimits',
  },
  getQueryKeyCreator,
);
