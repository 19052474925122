import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Layout } from 'antd';
import ScrollToTop from 'containers/Common/Routes/ScrollToTop';
import Sidebar from 'components/Sidebar';
import Header from 'components/Header';
import MobileMenu from 'components/Sidebar/MobileMenu';

import styles from './styles.module.less';

const { Content } = Layout;

const MainLayout = ({ children, type }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const layoutRef = useRef(null);
  const { section, sectionChild } = useParams();

  const showMenu = useCallback(() => {
    setMenuOpen((value) => !value);
  }, []);

  return (
    <Layout className={styles.mainLayout}>
      <Sidebar type={type} isMenuOpen={isMenuOpen} />
      <MobileMenu type={type} isMenuOpen={isMenuOpen} onClose={showMenu} />
      <section ref={layoutRef} className={styles.contentLayout}>
        <ScrollToTop layoutRef={layoutRef} />
        <Header
          type={type}
          section={section}
          sectionChild={sectionChild}
          showMenu={showMenu}
        />
        <Content>{children}</Content>
      </section>
    </Layout>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['client', 'broker']),
};

export default MainLayout;
