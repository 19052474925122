import { api } from 'api';
import { takeEvery, all, put, select, takeLatest } from 'redux-saga/effects';

import actions from 'redux/actions';
import { getUserId } from 'redux/sagas/auth/selectors';
import { mapDateRangeToSplitType } from 'utils/mapDateRangeToSplitType';
import { ReferralSource } from 'constants/referalSource';
import { getResource } from 'config/resources';
import { ResourceName } from 'config/resources/resourceNames';

function* callLoadMyCommissionOverview({ payload }) {
  try {
    const userId = yield select(getUserId);
    const symbolsOverviewRequest = getResource(
      ResourceName.FEES_SYMBOLS_OVERVIEW_BY_USER_ID_REQUEST,
    )[process.env.REACT_APP_BRAND];

    const [commissions, symbolsOverview] = yield all([
      api.getPartnerMetricsByUserId(userId, {
        ...payload,
        types: ['commission'],
      }),
      symbolsOverviewRequest(userId, payload),
    ]);

    yield put(
      actions.loadMyCommissionOverview.success({
        commission: commissions.data[0].value,
        series: symbolsOverview.data.series,
      }),
    );
  } catch (error) {
    yield put(actions.loadMyCommissionOverview.failure(error));
  }
}

function* callLoadMyClientsTrades({ payload }) {
  try {
    const userId = yield select(getUserId);
    const response = yield api.getUserTrades(userId, {
      ...payload,
      source: ReferralSource.DIRECT,
    });

    yield put(actions.loadMyClientsTrades.success(response.data));
  } catch (error) {
    yield put(actions.loadMyClientsTrades.failure(error));
  }
}

function* callLoadMyClientsTradesFilterLimits({ payload }) {
  try {
    const userId = yield select(getUserId);
    const response = yield api.getUserTradesLimits(userId, {
      ...payload,
      source: ReferralSource.DIRECT,
    });

    yield put(actions.loadMyClientsTradesFilterLimits.success(response.data));
  } catch (error) {
    yield put(actions.loadMyClientsTradesFilterLimits.failure(error));
  }
}

function* callExportMyClientsTrades({
  payload: { requestData, onSuccess, onFailure, fileExtension },
}) {
  try {
    const userId = yield select(getUserId);
    const response = yield api.getUserTrades(userId, {
      ...requestData,
      source: ReferralSource.DIRECT,
    });

    if (response?.data) {
      onSuccess(response.data, fileExtension);
    }
  } catch (error) {
    onFailure(error);
  }
}

function* callLoadMyPartnersTrades({ payload }) {
  try {
    const userId = yield select(getUserId);
    const response = yield api.getUserTrades(userId, {
      ...payload,
      source: ReferralSource.SUB,
    });

    yield put(actions.loadMyPartnersTrades.success(response.data));
  } catch (error) {
    yield put(actions.loadMyPartnersTrades.failure(error));
  }
}

function* callLoadMyPartnersTradesFilterLimits({ payload }) {
  try {
    const userId = yield select(getUserId);
    const response = yield api.getUserTradesLimits(userId, {
      ...payload,
      source: ReferralSource.SUB,
    });

    yield put(actions.loadMyPartnersTradesFilterLimits.success(response.data));
  } catch (error) {
    yield put(actions.loadMyPartnersTradesFilterLimits.failure(error));
  }
}

function* callExportMyPartnersTrades({
  payload: { requestData, onSuccess, onFailure, fileExtension },
}) {
  try {
    const userId = yield select(getUserId);
    const response = yield api.getUserTrades(userId, {
      ...requestData,
      source: ReferralSource.SUB,
    });

    if (response?.data) {
      onSuccess(response.data, fileExtension);
    }
  } catch (error) {
    onFailure(error);
  }
}

function* callLoadPayments({ payload }) {
  try {
    const { partnerId: userId, ...params } = payload;
    const response = yield api.getUserPayments(userId, params);

    yield put(actions.loadMyPayments.success(response.data));
  } catch (error) {
    yield put(actions.loadMyPayments.failure(error));
  }
}

function* callExportPayments({
  payload: { requestData, onSuccess, onFailure, fileExtension },
}) {
  try {
    const { partnerId, ...params } = requestData;
    const response = yield api.getUserPayments(partnerId, params);

    if (response) {
      onSuccess(response.data, fileExtension);
    }
  } catch (error) {
    onFailure(error);
  }
}

function* callLoadBalance({ payload }) {
  try {
    const { userId, ...params } = payload;
    const response = yield api.getUserBalanceHistory(userId, params);

    yield put(actions.loadMyBalance.success(response.data));
  } catch (error) {
    yield put(actions.loadMyBalance.failure(error));
  }
}

function* callExportBalance({
  payload: { requestData, onSuccess, onFailure, fileExtension },
}) {
  try {
    const { partnerId, ...params } = requestData;
    const response = yield api.getUserBalanceHistoryReport(partnerId, params);

    if (response) {
      onSuccess(response.data, fileExtension);
    }
  } catch (error) {
    onFailure(error);
  }
}

function* callLoadPaymentsFilterLimits({ payload }) {
  try {
    const { partnerId, ...params } = payload;

    const response = yield api.getUserPaymentsLimits(partnerId, params);

    yield put(actions.loadMyPaymentsFilterLimits.success(response.data));
  } catch (error) {
    yield put(actions.loadMyPaymentsFilterLimits.failure(error));
  }
}

function* callLoadBalanceFilterLimits({ payload }) {
  try {
    const { userId, ...params } = payload;

    const response = yield api.getUserBalanceHistoryLimits(userId, params);

    yield put(actions.loadMyBalanceFilterLimits.success(response.data));
  } catch (error) {
    yield put(actions.loadMyBalanceFilterLimits.failure(error));
  }
}

function* callLoadMyClientsTradesCommissions({ payload }) {
  try {
    const userId = yield select(getUserId);

    const params = {
      splitType: mapDateRangeToSplitType([payload.dateFrom, payload.dateTo]),
      ...payload,
    };

    const totalCommissionsRequest = api.getUserCommissionsTimeSeries(userId, {
      ...params,
      classification: 'TOTAL',
    });
    const earnedCommissionsRequest = api.getUserCommissionsTimeSeries(userId, {
      ...params,
      classification: 'EARNED_FROM_REFERRALS',
    });

    const [totalCommissions, earnedCommissions] = yield all([
      totalCommissionsRequest,
      earnedCommissionsRequest,
    ]);

    yield put(
      actions.loadMyClientsTradesCommissions.success({
        totalCommissions: totalCommissions.data,
        earnedCommissions: earnedCommissions.data,
      }),
    );
  } catch (error) {
    yield put(actions.loadMyClientsTradesCommissions.failure(error));
  }
}

function* callLoadMyPartnersTradesCommissions({ payload }) {
  try {
    const userId = yield select(getUserId);
    const params = {
      userId,
      splitType: mapDateRangeToSplitType([payload.dateFrom, payload.dateTo]),
      ...payload,
    };

    const earnedCommissionsRequest = api.getUserCommissionsTimeSeries(userId, {
      ...params,
      classification: 'EARNED_FROM_SUB_IBS',
    });

    const totalCommissionsRequest = api.getUserTradesTotalCommissionsTimeSeries(
      userId,
      {
        ...params,
        source: ReferralSource.SUB,
      },
    );

    const [earnedCommissions, totalCommissions] = yield all([
      earnedCommissionsRequest,
      totalCommissionsRequest,
    ]);

    yield put(
      actions.loadMyPartnersTradesCommissions.success({
        earnedCommissions: earnedCommissions.data,
        totalCommissions: totalCommissions.data,
      }),
    );
  } catch (error) {
    yield put(actions.loadMyPartnersTradesCommissions.failure(error));
  }
}

export default function* clientInfoSaga() {
  yield all([
    yield takeEvery(
      actions.loadMyClientsTrades.request,
      callLoadMyClientsTrades,
    ),
    yield takeEvery(
      actions.loadMyClientsTradesFilterLimits.request,
      callLoadMyClientsTradesFilterLimits,
    ),
    yield takeEvery(
      actions.exportMyClientsTrades.request,
      callExportMyClientsTrades,
    ),
    yield takeEvery(
      actions.loadMyPartnersTrades.request,
      callLoadMyPartnersTrades,
    ),
    yield takeEvery(
      actions.loadMyPartnersTradesFilterLimits.request,
      callLoadMyPartnersTradesFilterLimits,
    ),
    yield takeEvery(
      actions.exportMyPartnersTrades.request,
      callExportMyPartnersTrades,
    ),
    yield takeLatest(
      actions.loadMyCommissionOverview.request,
      callLoadMyCommissionOverview,
    ),
    yield takeEvery(actions.loadMyPayments.request, callLoadPayments),
    yield takeEvery(actions.exportMyPayments.request, callExportPayments),
    yield takeEvery(actions.loadMyBalance.request, callLoadBalance),
    yield takeEvery(actions.exportBalance.request, callExportBalance),
    yield takeEvery(
      actions.loadMyPaymentsFilterLimits.request,
      callLoadPaymentsFilterLimits,
    ),
    yield takeEvery(
      actions.loadMyBalanceFilterLimits.request,
      callLoadBalanceFilterLimits,
    ),
    yield takeEvery(
      actions.loadMyClientsTradesCommissions.request,
      callLoadMyClientsTradesCommissions,
    ),
    yield takeEvery(
      actions.loadMyPartnersTradesCommissions.request,
      callLoadMyPartnersTradesCommissions,
    ),
  ]);
}
