import { firebaseFactory } from '../factory';
import { FbInstanceName } from '../constants/fbInstanceName';

class FirebaseManager {
  constructor(firebaseFactory) {
    this.firebaseFactory = firebaseFactory;
    this.fbInstances = [];

    this.init();
  }

  async init() {
    const persistedInstances = this._getPersistedInstances();

    // Restore persisted instances
    if (persistedInstances.length !== 0) {
      persistedInstances.forEach(({ brand, name }) =>
        this.addInstance(brand, name),
      );

      return;
    }

    // Create default firebase instance
    this.addInstance(process.env.REACT_APP_BRAND, FbInstanceName.PRIMARY);
  }

  addInstance(brand, name) {
    const fbInstance = this.firebaseFactory.create(brand, name);

    this.fbInstances.push(fbInstance);
    this._persistInstances();

    return this.getCurrentInstance();
  }

  getCurrentInstance() {
    return this.fbInstances[this.fbInstances.length - 1];
  }

  async removeInstance() {
    // Keep default firebase instance
    if (this.fbInstances.length === 1) {
      return;
    }

    const fbInstance = this.fbInstances.pop();
    this._persistInstances();

    await fbInstance.delete();
  }

  _persistInstances() {
    // Do not persist instances if the default instance is the only one
    if (this.fbInstances.length === 1) {
      localStorage.removeItem('fbInstances');

      return;
    }

    localStorage.setItem(
      'fbInstances',
      JSON.stringify(
        this.fbInstances.map(({ brand, name }) => ({ brand, name })),
      ),
    );
  }

  _getPersistedInstances() {
    return JSON.parse(localStorage.getItem('fbInstances') || '[]');
  }
}

export const firebaseManager = new FirebaseManager(firebaseFactory);
