import { useQuery } from '@tanstack/react-query';

import { api } from 'api';
import { getErrorHandler } from '../getErrorHandler';
import { queryKeys } from 'utils/queryKeys';
import { useBrand } from 'modules/shared/brand/hooks/useBrand';

export const usePartnersOptions = (data = {}) => {
  const { brand: defaultBrand } = useBrand();

  const { data: partnersOptions } = useQuery({
    queryKey: queryKeys.partnersOptions(
      data.brand ?? defaultBrand,
      data.regSource,
    ),
    queryFn: async () => {
      const partners = await api.getAllPartnersOptions({
        brand: data.brand ?? defaultBrand,
        ...(data.regSource && { regSource: data.regSource }),
      });

      return partners.data.map(({ id, groupId }) => ({
        label: String(id),
        value: id,
        groupId,
      }));
    },
    onError: getErrorHandler('Unable to load Partner Id options'),
  });

  return { partnersOptions };
};
