import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import BackgroundWrapper from '../BackgroundWrapper';
import { Tooltip } from 'antd';
import { USER_STATUS, LEADS } from 'utils/constants';
import { TABLE_DATA_INDEX } from 'utils/tableSchema';

const MESSAGE = {
  approved: 'The client has been verified and is able to start trading.',
  'lead 1-4': 'They have begun their application but not yet finished.',
  rejected: 'Unfortunately, the ID documents provided were not approved.',
  funded:
    'A payment has been made to an account of theirs and they are ready to start trading.',
  dormant: 'It has been over 90 days since the client has traded.',
  active: 'The client has funded their account and started trading.',
  posted:
    'Documents have been submitted but not yet reviewed by the Fusion team.',
};

const StatusWrapper = ({ text, ...other }) => {
  const status = useMemo(
    () => other.record[TABLE_DATA_INDEX.BS_USER_STATUS],
    [other],
  );

  const tooltipMessage = useMemo(() => {
    if (USER_STATUS.includes(status)) {
      return MESSAGE[status];
    }

    if (LEADS.includes(status)) {
      return MESSAGE['lead 1-4'];
    }

    return MESSAGE[text] || '';
  }, [text, status]);

  const label = useMemo(() => {
    if (USER_STATUS.includes(status)) {
      return status;
    }

    if (LEADS.includes(status)) {
      return 'lead 1-4';
    }

    return text;
  }, [text, status]);

  const type = useMemo(() => {
    if (label === 'lead 1-4') {
      return 'lead1-4';
    }

    return '';
  }, [label]);

  if (!text) {
    return null;
  }

  return (
    <div>
      <Tooltip title={tooltipMessage} overlayClassName="tooltip-wrapper">
        <div>
          <BackgroundWrapper text={label} {...other} type={type} />
        </div>
      </Tooltip>
    </div>
  );
};

StatusWrapper.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  record: PropTypes.object,
  onClick: PropTypes.func,
  justify: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
};

export default StatusWrapper;
