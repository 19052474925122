import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

const PopupContainer = ({ rootRef, containerClass, children }) => {
  const getPopupContainer = useCallback(
    (trigger) => rootRef?.current || trigger?.parentElement || trigger,
    [rootRef],
  );

  const childComponent = React.Children.only(children);
  const childWithPopupContainer = React.cloneElement(childComponent, {
    getPopupContainer,
  });

  const onDropdownClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <div onClick={onDropdownClick} className={containerClass}>
      {childWithPopupContainer}
    </div>
  );
};

PopupContainer.defaultProps = {
  rootRef: () => null,
  containerClass: '',
};

PopupContainer.propTypes = {
  children: PropTypes.node.isRequired,
  rootRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
  containerClass: PropTypes.string,
};

export default PopupContainer;
