import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import clsx from 'clsx';
import CustomIcon from 'components/Icons';
import MenuItem from './MenuItem';
import SubMenuComponent from './SubMenuComponent';
import { sideMenuItems, SUBMENU_KEYS } from './config';

import styles from './styles.module.less';
import { useBrandReports } from 'utils/hooks/userBrandReports';

const SideMenu = ({ isCollapsed, pathname, type, onClick, className }) => {
  const reportsSubMenuItems = useBrandReports();

  const openSubmenuKey = useMemo(
    () =>
      reportsSubMenuItems.some(({ path }) => path === pathname) && !isCollapsed
        ? SUBMENU_KEYS.REPORT
        : undefined,
    [pathname, isCollapsed, reportsSubMenuItems],
  );

  const sideMenuOptions = useMemo(
    () => sideMenuItems(type, reportsSubMenuItems),
    [type, reportsSubMenuItems],
  );

  return (
    <Menu
      mode="inline"
      selectedKeys={[pathname]}
      openKeys={openSubmenuKey}
      inlineIndent={16}
      expandIcon={CustomIcon({ type: 'big-arrow-down' })}
      className={clsx(className, styles.sideMenu)}
    >
      {sideMenuOptions.map(({ icon, title, path, subMenuItems }) =>
        !!subMenuItems ? (
          <SubMenuComponent
            key={path}
            icon={icon}
            title={title}
            subMenuItems={subMenuItems}
            isCollapsed={isCollapsed}
            className={styles.sideMenuItem}
          />
        ) : (
          <MenuItem
            key={path}
            icon={icon}
            title={title}
            path={path}
            pathname={pathname}
            onClick={onClick}
          />
        ),
      )}
    </Menu>
  );
};

SideMenu.propTypes = {
  isCollapsed: PropTypes.bool,
  pathname: PropTypes.string,
  type: PropTypes.oneOf(['client', 'broker']),
  className: PropTypes.string,
  onClick: PropTypes.func,
};

SideMenu.defaultProps = {
  type: 'broker',
};

export default SideMenu;
