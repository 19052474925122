export const getClients = ({ search }) => ({
  clients: search.clients,
  isLoading: search.isLoading,
  offset: search.offset,
  total: search.total,
});

export const getSearchLimits = ({ search }) => ({
  offset: search.offset,
  limit: search.limit,
  search: search.search,
});
