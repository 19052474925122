import { createAction, createAsyncAction } from 'typesafe-actions';

export default {
  loadDashboardLatestClients: createAsyncAction(
    'loadDashboardLatestClients/request',
    'loadDashboardLatestClients/success',
    'loadDashboardLatestClients/failure',
  )(),
  loadDashboardPartners: createAsyncAction(
    'loadDashboardPartners/request',
    'loadDashboardPartners/success',
    'loadDashboardPartners/failure',
  )(),
  loadDashboardOverviewType: createAsyncAction(
    'loadDashboardOverviewType/request',
    'loadDashboardOverviewType/success',
    'loadDashboardOverviewType/failure',
  )(),
  dashboardPatchPartner: createAsyncAction(
    'dashboardPatchPartner/patch/request',
    'dashboardPatchPartner/patch/success',
    'dashboardPatchPartner/patch/failure',
  )(),
  dashboardFindManagers: createAsyncAction(
    'dashboardFindManagers/find/request',
    'dashboardFindManagers/find/success',
    'dashboardFindManagers/find/failure',
  )(),
  updateDashboardOverview: createAction('dashboard/updateDashboardOverview')(),
  setDashboardType: createAction('dashboard/setDashboardType')(),
};
