import React from 'react';
import PropTypes from 'prop-types';

import FormInput from 'components/Common/Input/FormInput';
import CheckboxGroup from 'components/Common/Input/CheckboxGroup';
import CustomSlider from 'components/Common/Input/CustomSlider';

import { FORM_INPUT_TYPES } from 'utils/constants';

const FilterItems = ({
  type,
  name,
  label,
  placeholder,
  minValue,
  maxValue,
  ...rest
}) => {
  switch (type) {
    case FORM_INPUT_TYPES.CHECKBOX_GROUP:
      return <CheckboxGroup name={name} formLabel={label} {...rest} />;
    case FORM_INPUT_TYPES.SLIDER:
      return (
        <CustomSlider
          name={name}
          label={label}
          minValue={minValue}
          maxValue={maxValue}
          {...rest}
        />
      );
    default:
      return (
        <FormInput
          type={type}
          name={name}
          label={label}
          isRequired={false}
          placeholder={placeholder}
          {...rest}
        />
      );
  }
};

FilterItems.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
};

export default FilterItems;
