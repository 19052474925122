import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import CustomIcon from 'components/Icons';
import DropdownSelect from 'components/Common/Input/DropdownSelect';

import styles from './styles.module.less';

const TagsInput = ({ options, placeholder, className }) => (
  <DropdownSelect
    mode="multiple"
    options={options}
    showArrow={false}
    placeholder={placeholder}
    className={clsx(styles.selectContainer, className)}
    removeIcon={<CustomIcon type="close" className={styles.closeIcon} />}
  />
);

TagsInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      code: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

export default TagsInput;
