import React from 'react';
import { Button, Row } from 'antd';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import CustomIcon from 'components/Icons';
import styles from './styles.module.less';

const StyledButton = ({
  text,
  icon,
  className,
  type,
  size,
  shape,
  children,
  ...other
}) => {
  const buttonContent = children || (
    <>
      {icon && CustomIcon({ type: icon, className: styles.iconComponent })}
      {text && <span>{text}</span>}
    </>
  );

  return (
    <Button
      size={size}
      shape={shape}
      className={clsx(styles.commonButton, className, styles[type], {
        [styles.buttonWithIcon]: type !== 'primary' && text && icon,
      })}
      {...other}
    >
      <Row
        wrap={false}
        align="middle"
        justify="center"
        className={styles.buttonContainer}
      >
        {buttonContent}
      </Row>
    </Button>
  );
};

StyledButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf([
    'default',
    'primary',
    'icon',
    'iconDefault',
    'iconPrimary',
    'with-primary-icon',
    'text',
    'primaryWithIcon',
    'copy',
    'outline',
  ]),
  size: PropTypes.oneOf(['small', 'middle', 'large', undefined]),
  children: PropTypes.node,
  other: PropTypes.any,
  shape: PropTypes.oneOf(['round', 'circle']),
};

StyledButton.defaultProps = {
  type: 'default',
  size: 'middle',
  shape: 'round',
};

export default StyledButton;
