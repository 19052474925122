import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function ScrollToTop({ layoutRef }) {
  const { pathname } = useLocation();

  useEffect(() => {
    layoutRef.current?.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

ScrollToTop.propTypes = {
  layoutRef: PropTypes.object,
};
