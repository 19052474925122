import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Pagination } from 'antd';
import Text from 'antd/lib/typography/Text';

import CustomIcon from 'components/Icons';
import StyledButton from 'components/Common/Buttons/StyledButton';

import styles from './styles.module.less';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';

const PaginationPanel = ({
  totalItems,
  displayedCount,
  currentPage,
  pageSize,
  handlePageChange,
  handleLoadMore,
  className,
  paginationClass,
}) => {
  const isMoreShown = pageSize * currentPage < totalItems;

  const customArrow = useCallback((currentPage, type, originalElement) => {
    if (type === 'prev' || type === 'next') {
      return <CustomIcon type="double-arrow" />;
    }

    return originalElement;
  }, []);

  return (
    <div className={clsx(styles.paginationPanel, className)}>
      <div className={styles.numberContainer}>
        Showing <span className={styles.number}>{displayedCount}</span>
        &nbsp;from <span className={styles.number}>{totalItems}</span> entries
      </div>
      {isMoreShown && (
        <StyledButton
          type="primaryWithIcon"
          icon="show-more"
          onClick={handleLoadMore}
          text="Show More"
          className={styles.showMoreButton}
        />
      )}
      {totalItems > 0 && (
        <Pagination
          showLessItems
          showSizeChanger={false}
          itemRender={customArrow}
          pageSize={pageSize}
          current={currentPage}
          total={totalItems}
          onChange={handlePageChange}
          className={paginationClass}
        />
      )}
    </div>
  );
};

PaginationPanel.propTypes = {
  handleLoadMore: PropTypes.func,
  handlePageChange: PropTypes.func,
  totalItems: PropTypes.number,
  displayedCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  className: PropTypes.string,
  paginationClass: PropTypes.string,
};

PaginationPanel.defaultProp = {
  totalItems: 1,
  currentPage: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  displayedCount: 1,
};

export default PaginationPanel;
