import React, { useCallback } from 'react';
import clsx from 'clsx';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';

import PopupContainer from 'components/PopupContainer';
import DateCell from 'components/Common/DatePicker/DateCell';

import { DAY_MONTH_YEAR_FORMAT } from 'utils/timeConstants';

import styles from './styles.module.less';

const StyledDatePicker = ({
  placeholder,
  onDateChange,
  containerClass,
  className,
  ...other
}) => {
  const onCalendarChange = useCallback(
    (dates) => {
      if (dates[0] && dates[1] && onDateChange) {
        onDateChange(dates);
      }
    },
    [onDateChange],
  );

  return (
    <PopupContainer containerClass={containerClass}>
      <DatePicker
        allowClear={false}
        suffixIcon={null}
        dateRender={DateCell}
        placeholder={placeholder}
        className={clsx(styles.datePicker, className)}
        dropdownClassName={styles.datePickerCalendar}
        onCalendarChange={onCalendarChange}
        inputReadOnly
        format={DAY_MONTH_YEAR_FORMAT}
        {...other}
      />
    </PopupContainer>
  );
};

StyledDatePicker.propTypes = {
  placeholder: PropTypes.string,
  onDateChange: PropTypes.func,
  containerClass: PropTypes.string,
  className: PropTypes.string,
  other: PropTypes.any,
};

export default StyledDatePicker;
