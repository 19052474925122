import { combineReducers } from 'redux';
import auth from './auth';
import users from './users';
import partnersGroups from './partnersGroups';
import dashboard from './dashboard';
import clientInfo from './clientInfo';
import clients from './clients';
import staffDashboard from './staffDashboard';
import search from './search';
import withdrawals from './withdrawals';
import myCommission from './myCommission';
import reports from './reports';
import commissionSettings from './commissionSettings';
import payment from './payment';
import logs from './logs';

const reducer = combineReducers({
  auth,
  users,
  partnersGroups,
  dashboard,
  clientInfo,
  staffDashboard,
  search,
  withdrawals,
  myCommission,
  clients,
  reports,
  performance,
  commissionSettings,
  payment,
  logs,
});

export default reducer;
