import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import Actions from '../actions';

const LIMIT = 20;

const initialState = {
  clients: [],
  isLoading: false,
  search: '',
  offset: 0,
  limit: LIMIT,
  total: 10,
};

export default createReducer(initialState)
  .handleAction(
    Actions.searchClients.request,
    produce((state, { payload }) => {
      state.clients = [];
      state.isLoading = true;
      state.search = payload.search;
      state.offset = 0;
      state.limit = LIMIT;
    }),
  )
  .handleAction(
    Actions.searchMoreClients.request,
    produce((state) => {
      state.offset += LIMIT;
      state.isLoading = true;
    }),
  )
  .handleAction(
    Actions.searchClients.success,
    produce((state, { payload }) => {
      state.clients = [...state.clients, ...payload.rows];
      state.total = payload.count;
      state.isLoading = false;
    }),
  )
  .handleAction(
    Actions.searchClients.failure,
    produce((state) => {
      state.isLoading = false;
    }),
  )
  .handleAction(
    Actions.resetSearch,
    produce(() => initialState),
  );
