import authActions from './auth';
import usersActions from './users';
import partnersGroups from './partnersGroups';
import dashboard from './dashboard';
import clientInfo from './clientInfo';
import clients from './clients';
import staffDashboard from './staffDashboard';
import search from './search';
import withdrawals from './withdrawals';
import myCommission from './myCommission';
import reports from './reports';
import commissionSettings from './commissionSettings';
import logs from './logs';
import payment from './payment';

export default {
  ...authActions,
  ...usersActions,
  ...partnersGroups,
  ...dashboard,
  ...clientInfo,
  ...clients,
  ...staffDashboard,
  ...search,
  ...withdrawals,
  ...myCommission,
  ...reports,
  ...performance,
  ...commissionSettings,
  ...payment,
  ...logs,
};
