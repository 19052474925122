import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import StyledButton from 'components/Common/Buttons/StyledButton';
import CustomIcon from 'components/Icons';
import Person from 'components/Common/Person';

import Actions from 'redux/actions';
import { getManager } from 'redux/sagas/auth/selectors';

import styles from './styles.module.less';
import { useBrand } from 'modules/shared/brand/hooks/useBrand';
import { SVG_MASK_TYPES } from './config';

const AccountManager = ({ isCollapsed }) => {
  const { brand } = useBrand();

  const dispatch = useDispatch();
  const manager = useSelector(getManager);

  useEffect(() => {
    dispatch(Actions.findManager.request());
  }, [dispatch]);

  if (manager?.hidden) {
    return null;
  }

  const redirectToEmail = () => {
    window.open('mailto:' + manager?.email, '_blank');
  };

  return isCollapsed ? (
    <StyledButton
      type="iconPrimary"
      shape="circle"
      icon="manager"
      onClick={redirectToEmail}
      className={styles.circleButton}
    />
  ) : (
    <div className={styles.managerContainer}>
      <CustomIcon className={styles.rectangle} type={SVG_MASK_TYPES[brand]} />
      <h3 className={styles.title}>Account Manager</h3>
      <Person
        type="manager"
        personName={manager?.fullName}
        email={manager?.email}
      />
      <StyledButton
        type="primary"
        text="Send email"
        onClick={redirectToEmail}
        className={styles.button}
      />
    </div>
  );
};

AccountManager.propTypes = {
  personName: PropTypes.string,
  email: PropTypes.string,
  isCollapsed: PropTypes.bool,
};

export default AccountManager;
