import { useMemo } from 'react';
import { useBrand } from 'modules/shared/brand/hooks/useBrand';
import { getResource } from 'config/resources';

export const useBrandedResource = (resource) => {
  const { brand } = useBrand();

  const foundResource = useMemo(() => getResource(resource) || {}, [resource]);

  return foundResource[brand];
};
