import { useBrandedResource } from './useBrandedResource';
import { ResourceName } from 'config/resources/resourceNames';
import { useQuery } from '@tanstack/react-query';
import { getErrorHandler } from '../getErrorHandler';
import { queryKeys } from '../queryKeys';

export const useBrandRevShares = ({ symbolsRequestOptions = {}, runQuery }) => {
  const { rows, symbolsLoadFunction } = useBrandedResource(
    ResourceName.REV_SHARE,
  );

  const { data: symbols, isLoading: isSymbolsLoading } = useQuery({
    queryKey: queryKeys.symbols(symbolsRequestOptions),
    queryFn: async () => {
      const result = await symbolsLoadFunction();

      return result.map((symbol) => ({
        id: symbol.value ?? symbol.id,
        label: symbol.label ?? symbol.value,
        value: symbol.value ?? symbol.label,
      }));
    },
    enabled: runQuery,
    onError: getErrorHandler('Unable to load symbols'),
  });

  return { rows, symbols, isSymbolsLoading };
};
