import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import MenuHeader from 'components/Sidebar/MenuHeader';
import AccountManager from './AccountManager';
import SideMenu from './SideMenu';

import styles from './styles.module.less';

const SiderMenu = ({
  onTriggerClick,
  type,
  isCollapsed,
  closeOnClick = false,
}) => {
  const { section } = useParams();

  const trigger = closeOnClick && { onClick: onTriggerClick };

  return (
    <>
      <MenuHeader isCollapsed={isCollapsed} onTriggerClick={onTriggerClick} />
      <SideMenu
        pathname={`/${section}`}
        type={type}
        isCollapsed={isCollapsed}
        className={styles.sideMenu}
        {...trigger}
      />
      {type === 'broker' && (
        <AccountManager
          personName="Dasha Rizoy"
          email="@dasha.rizoy"
          isCollapsed={isCollapsed}
        />
      )}
    </>
  );
};

SiderMenu.propTypes = {
  onTriggerClick: PropTypes.func,
  type: PropTypes.string,
  isCollapsed: PropTypes.bool,
  closeOnClick: PropTypes.bool,
};

export default SiderMenu;
