import React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';
import Option from './Option';

const ClientOptions = ({ options, currentValue, justify, isLoading }) => {
  const optionsList = options?.map(({ id, login, name }) => ({
    value: id,
    label: (
      <Option
        name={name}
        currentValue={currentValue}
        suggested={login}
        justify={justify}
      />
    ),
  }));

  return isLoading
    ? [
        ...optionsList,
        {
          value: undefined,
          label: <Spinner spinFontSize={20} />,
        },
      ]
    : optionsList;
};

ClientOptions.defaultProps = {
  currentValue: '',
  isLoading: false,
  justify: '',
};

ClientOptions.propTypes = {
  options: PropTypes.array.isRequired,
  currentValue: PropTypes.string,
  justify: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default ClientOptions;
