import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getUserRole } from 'redux/sagas/auth/selectors';
import { THEME, USER_ROLES } from 'utils/constants';

import { useTheme } from '../useTheme';

/**
 * Persists theme only if user is authenticated.
 */
export const useThemePersistor = () => {
  const { theme, changeTheme } = useTheme();

  const role = useSelector(getUserRole);

  useEffect(() => {
    const theme = localStorage.getItem('theme');

    if (role) {
      // Restore saved theme or fallback to a dark one for partners. Employees can use light theme only.
      const newTheme =
        role === USER_ROLES.BROKER ? theme || THEME.DARK : THEME.LIGHT;

      changeTheme(newTheme);
    }
  }, [changeTheme, role]);

  useEffect(() => {
    if (role) {
      localStorage.setItem('theme', theme);
    }
  }, [theme, role]);
};
