import { createAction, createAsyncAction } from 'typesafe-actions';

export default {
  loadClientCommissions: createAsyncAction(
    'loadClientCommissions/action/request',
    'loadClientCommissions/action/success',
    'loadClientCommissions/action/failure',
  )(),
  loadTotalCommissions: createAsyncAction(
    'loadTotalCommissions/action/request',
    'loadTotalCommissions/action/success',
    'loadTotalCommissions/action/failure',
  )(),
  loadClientPayments: createAsyncAction(
    'clientsPaymentsData/action/request',
    'clientsPaymentsData/action/success',
    'clientsPaymentsData/action/failure',
  )(),
  loadClientPaymentsLimits: createAsyncAction(
    'loadClientPaymentsLimits/action/request',
    'loadClientPaymentsLimits/action/success',
    'loadClientPaymentsLimits/action/failure',
  )(),
  loadClientTrades: createAsyncAction(
    'loadClientTrades/request',
    'loadClientTrades/success',
    'loadClientTrades/failure',
  )(),
  loadClientTradesOverview: createAsyncAction(
    'loadClientTradesOverview/request',
    'loadClientTradesOverview/success',
    'loadClientTradesOverview/failure',
  )(),
  loadFilterLimits: createAsyncAction(
    'loadFilterLimits/request',
    'loadFilterLimits/success',
    'loadFilterLimits/failure',
  )(),
  loadPartnerEarnings: createAsyncAction(
    'loadPartnerEarnings/request',
    'loadPartnerEarnings/success',
    'loadPartnerEarnings/failure',
  )(),
  exportPartnerEarnings: createAsyncAction(
    'exportPartnerEarnings/request',
    'exportPartnerEarnings/success',
    'exportPartnerEarnings/failure',
  )(),
  loadPartnerEarningsFilterLimits: createAsyncAction(
    'loadPartnerEarningsFilterLimits/request',
    'loadPartnerEarningsFilterLimits/success',
    'loadPartnerEarningsFilterLimits/failure',
  )(),
  updateClientTradesOverview: createAction(
    'clientInfo/updateClientTradesOverview',
  )(),
};
