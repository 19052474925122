import { useCallback, useState } from 'react';

const useToggle = () => {
  const [toggleState, setToggleState] = useState(false);

  const handleToggle = useCallback(
    (value) => () => {
      setToggleState(value);
    },
    [],
  );

  return [toggleState, handleToggle];
};

export default useToggle;
