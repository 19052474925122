import axios from 'axios';
import { appendAuthTokens } from '../interceptors/appendAuthTokens';
import { handleAuthErrors } from '../interceptors/handleAuthErrors';

export class ApiCore {
  constructor() {
    const client = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      paramsSerializer: (params) => String(new URLSearchParams(params)),
    });

    client.interceptors.request.use(appendAuthTokens);
    client.interceptors.response.use(undefined, handleAuthErrors);

    this.client = client;
  }
}
