import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { EXPORT_DATA_TYPES } from 'utils/constants';
import { getExportMenu } from 'utils/export';
import {
  CLIENTS_FEES_COLUMNS,
  CLIENTS_FEES_FILTERS,
} from 'components/Clients/Fees/config';
import styles from './styles.module.less';

import TableWithFilter from 'components/Table/TableWithFilter';
import { useMyCommissionClientFees } from 'utils/hooks/useMyCommissionClientFees';
import { useUser } from 'utils/hooks/useUser';
import PropTypes from 'prop-types';
import { useTableState } from 'modules/shared/table/hooks/useTableState';
import TotalCommissions from 'components/Clients/Trades/TradesOverview/TotalCommissions';
import VolumeTrades from 'components/Clients/Trades/TradesOverview/VolumeTrades';
import {
  getClientsEarnedCommissionChartData,
  getClientsTotalCommissionChartData,
} from 'redux/sagas/myCommission/selectors';
import Actions from 'redux/actions';
import { useMyCommissionClientFeeLimits } from 'utils/hooks/useMyCommissionClientFeeLimits';
import FeesSummary from 'components/MyCommission/FeesSummary';
import useFeesSummary from './hooks/useFeesSummary';
import { ReferralSource } from 'constants/referalSource';
import { usePartnerFeesCommissionsTimeSeries } from 'utils/hooks/usePartnerFeesCommissionsTimeSeries';
import { mapDateRangeToSplitType } from 'utils/mapDateRangeToSplitType';

const FeesTable = ({
  dateRange,
  title,
  showSummary = false,
  showSubIb = false,
}) => {
  const dispatch = useDispatch();

  const { id: userId } = useUser();

  const { tableState, setTableState } = useTableState({
    sorter: { orderBy: 'date' },
  });

  const referralSource = showSubIb ? ReferralSource.SUB : ReferralSource.DIRECT;

  const { fees, isFeesFetching } = useMyCommissionClientFees({
    userId,
    dateRange,
    ...tableState,
    source: referralSource,
  });

  const { feesLimits } = useMyCommissionClientFeeLimits({
    userId,
    dateRange,
    source: referralSource,
  });

  const { rowSelection, handleRowSelection, summary } = useFeesSummary();

  const menuItems = useCallback(
    (currentData) =>
      getExportMenu({
        fileName: title,
        exportType: EXPORT_DATA_TYPES.TABLE,
        sourceList: currentData,
        exportFields: CLIENTS_FEES_COLUMNS.map(({ dataIndex }) => dataIndex),
        header: CLIENTS_FEES_COLUMNS.map(({ title }) => title),
      }),
    [title],
  );

  const rangePayload = useMemo(
    () => ({
      dateFrom: dateRange[0].toISOString(),
      dateTo: dateRange[1].toISOString(),
    }),
    [dateRange],
  );

  const {
    loadCommissionStats,
    totalCommissions = {},
    earnedCommissions = {},
  } = usePartnerFeesCommissionsTimeSeries({
    userId,
    dateRange,
    splitType: mapDateRangeToSplitType([
      rangePayload.dateFrom,
      rangePayload.dateTo,
    ]),
  });

  const selector = useCallback(
    () => ({
      dataSource: fees?.rows,
      total: fees?.count,
    }),
    [fees],
  );

  const limitsSelector = useCallback(() => feesLimits, [feesLimits]);

  useEffect(() => {
    if (showSummary) {
      showSubIb
        ? loadCommissionStats()
        : dispatch(
            Actions.loadMyClientsTradesCommissions.request(rangePayload),
          );
    }
  }, [showSummary, rangePayload, dispatch, loadCommissionStats, showSubIb]);

  const totalCommissionSelector = useCallback(
    () => totalCommissions,
    [totalCommissions],
  );
  const earnedCommissionSelector = useCallback(
    () => earnedCommissions,
    [earnedCommissions],
  );

  return (
    <>
      {showSummary && (
        <div className={styles.tradesTiles}>
          <TotalCommissions
            selector={
              showSubIb
                ? totalCommissionSelector
                : getClientsTotalCommissionChartData
            }
          />
          <VolumeTrades
            selector={
              showSubIb
                ? earnedCommissionSelector
                : getClientsEarnedCommissionChartData
            }
          />
        </div>
      )}

      <TableWithFilter
        customMenuItems={menuItems}
        filterSelector={limitsSelector}
        selector={selector}
        loadData={setTableState}
        isLoading={isFeesFetching}
        rowKey="id"
        columns={CLIENTS_FEES_COLUMNS}
        filterItems={CLIENTS_FEES_FILTERS}
        title={title}
        rowSelection={showSummary && rowSelection}
        onRow={showSummary && handleRowSelection}
        summary={() => showSummary && <FeesSummary summary={summary} />}
        className={styles.tradesTable}
        emptyMode="new"
      />
    </>
  );
};

FeesTable.propTypes = {
  title: PropTypes.string,
  dateRange: PropTypes.array,
  showSummary: PropTypes.bool,
  showSubIb: PropTypes.bool,
};

export default FeesTable;
