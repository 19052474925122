import React from 'react';
import { Skeleton } from 'antd';

import styles from './styles.module.less';

const FilterSkeleton = () => (
  <>
    <div className={styles.skeletonContainer}>
      <Skeleton active title={false} paragraph={{ rows: 1 }} />
      <Skeleton.Input active />
    </div>
    <div className={styles.skeletonContainer}>
      <Skeleton active title={false} paragraph={{ rows: 3 }} />
    </div>
    <div className={styles.skeletonContainer}>
      <Skeleton active title={false} paragraph={{ rows: 1 }} />
      <div className={styles.skeletonInlineContainer}>
        <Skeleton.Input active />
        <Skeleton.Input active />
      </div>
    </div>
    <div className={styles.skeletonContainer}>
      <Skeleton active title={false} paragraph={{ rows: 1 }} />
      <div className={styles.skeletonInlineContainer}>
        <Skeleton.Input active />
        <Skeleton.Input active />
      </div>
    </div>
  </>
);

export default FilterSkeleton;
