import { createReducer } from 'typesafe-actions';
import produce from 'immer';
import moment from 'moment';

import { MONTH_DAY_YEAR_FORMAT } from 'utils/timeConstants';
import { NUMBER_FORMATS } from 'utils/constants';
import { getFormattedNumber } from 'utils/helpers';

import Actions from '../actions';

const initialState = {
  loadingType: `${Actions.auth.request}`,
  isAuthenticated: false,
  isTwoFactorAuth: false,
  qrDataUrl: '',
  user: null,
  userInfo: null,
  manager: {},
  role: '',
  error: '',
  balance: 0,
  balanceNum: 0,
  saveDetails: false,
};

export default createReducer(initialState)
  .handleAction(
    [
      Actions.auth.request,
      Actions.logIn.request,
      Actions.logInWithPartner.request,
      Actions.twoFactorAuth.request,
      Actions.sendLinkToEmailForSignUp.request,
      Actions.sendLinkToEmailForResetPassword.request,
      Actions.logOut.request,
      Actions.logOutWithPartner.request,
      Actions.generateTFAqr.request,
      Actions.turnTwoFactorAuth.request,
    ],
    produce((state, { type }) => {
      state.loadingType = type;
      state.error = '';
    }),
  )
  .handleAction(
    [
      Actions.sendLinkToEmailForSignUp.success,
      Actions.sendLinkToEmailForResetPassword.success,
    ],
    produce((state) => {
      state.loadingType = '';
      state.error = '';
    }),
  )
  .handleAction(
    [
      Actions.auth.success,
      Actions.logIn.success,
      Actions.logInWithPartner.success,
      Actions.logOut.success,
      Actions.logOutWithPartner.success,
      Actions.turnTwoFactorAuth.success,
    ],
    produce((state, { payload }) => {
      state.loadingType = '';
      state.user = payload;
      state.isAuthenticated = !!payload;

      if (payload) {
        state.isTwoFactorAuth = payload?.isTwoFactorAuthEnabled;

        state.userInfo = {
          paymentId: '00123521',
          phoneNumber: payload.phoneNumber || '--',
          email: payload.email,
          name: `${payload.firstName} ${payload.lastName}`,
          registeredOn: moment(payload.createdAt).format(MONTH_DAY_YEAR_FORMAT),
        };
      }
    }),
  )
  .handleAction(
    [
      Actions.auth.failure,
      Actions.logIn.failure,
      Actions.logInWithPartner.failure,
      Actions.twoFactorAuth.failure,
      Actions.logOut.failure,
      Actions.logOutWithPartner.failure,
      Actions.sendLinkToEmailForSignUp.failure,
      Actions.sendLinkToEmailForResetPassword.failure,
      Actions.turnTwoFactorAuth.failure,
    ],
    produce((state, { payload }) => {
      state.loadingType = '';
      state.error = payload;
    }),
  )
  .handleAction(
    Actions.changeIsTwoFactorAuth,
    produce((state, { payload }) => {
      state.isTwoFactorAuth = payload;
      state.loadingType = '';
    }),
  )
  .handleAction(
    Actions.generateTFAqr.success,
    produce((state, { payload }) => {
      state.loadingType = '';
      state.error = '';
      state.qrDataUrl = payload;
    }),
  )
  .handleAction(
    Actions.findManager.success,
    produce((state, { payload }) => {
      state.manager = payload;
    }),
  )
  .handleAction(
    Actions.findManager.failure,
    produce((state) => {
      state.manager = { hidden: true };
    }),
  )
  .handleAction(
    Actions.getBalance.success,
    produce((state, { payload }) => {
      state.balance = getFormattedNumber({
        number: payload.balance,
        style: NUMBER_FORMATS.currency,
        minimumFractionDigits: 2,
      });
      state.balanceNum = payload.balance;
    }),
  )
  .handleAction(
    Actions.getBalance.failure,
    produce((state) => {
      state.balance = getFormattedNumber({
        number: 0,
        style: NUMBER_FORMATS.currency,
        minimumFractionDigits: 2,
      });
      state.balanceNum = 0;
    }),
  )
  .handleAction(
    Actions.saveDetails,
    produce((state, { payload }) => {
      state.saveDetails = payload;
    }),
  )
  .handleAction(
    Actions.setUser,
    produce((state, { payload }) => {
      state.user = payload;
    }),
  );
