import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Common/Loader';
import ErrorBoundary from 'HOC/ErrorBoundary';

const SuspenseWrapper = ({ position, children }) => (
  <ErrorBoundary>
    <Suspense fallback={Loader({ type: position, text: 'Loading ...' })}>
      {children}
    </Suspense>
  </ErrorBoundary>
);

SuspenseWrapper.propTypes = {
  position: PropTypes.oneOf(['center', undefined]),
  children: PropTypes.node.isRequired,
};

export default SuspenseWrapper;
