import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Actions from 'redux/actions';

import DetailAndPermissionsModal from 'components/Modals/DetailAndPermissionsModal';
import StyledDropdown from 'components/Common/Dropdown/StyledDropdown';

import { TITLES } from 'utils/constants';
import useToggle from 'utils/hooks/useToggle';

import styles from './styles.module.less';

const TableColEditDropdown = (props) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isModalOpen, onToggleButton] = useToggle();

  const menuItems = useMemo(
    () => [
      {
        text: TITLES.DETAILS,
        onClick: () => {
          setActiveTab(TITLES.DETAILS);
          onToggleButton(true)();
        },
      },
      {
        text: TITLES.PERMISSION_ACCESS,
        onClick: () => {
          setActiveTab(TITLES.PERMISSION_ACCESS);
          onToggleButton(true)();
        },
      },
      {
        text: TITLES.RESET_PASSWORD,
        onClick: () => {
          setActiveTab(TITLES.RESET_PASSWORD);
          onToggleButton(true)();
        },
      },
      {
        text: TITLES.TWO_FACTOR_AUTHENTICATION,
        onClick: () => {
          setActiveTab(TITLES.TWO_FACTOR_AUTHENTICATION);
          onToggleButton(true)();
        },
      },
    ],
    [],
  );

  const onDropdownVisibleChange = useCallback((value) => {
    setIsDropdownVisible(value);
  }, []);

  const onSave = useCallback(
    (formValues) => {
      dispatch(Actions.patchEmployee.request(formValues));
    },
    [dispatch],
  );

  const onTabChange = useCallback((activeKey) => {
    setActiveTab(activeKey);
  }, []);

  return (
    <>
      <StyledDropdown
        classNameButton={styles.dropdown}
        menuItems={menuItems}
        className={styles.overlay}
        onVisibleChange={onDropdownVisibleChange}
        visible={isDropdownVisible}
      />
      <DetailAndPermissionsModal
        activeKey={activeTab}
        isOpen={isModalOpen}
        onCancel={onToggleButton(false)}
        onSave={onSave}
        onTabChange={onTabChange}
        {...props}
      />
    </>
  );
};

export default TableColEditDropdown;
