import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import Actions from '../actions';

const initialState = {
  partners: {
    items: [],
    total: 0,
    options: {
      managerIdOptions: [],
      groupIdOptions: [],
      statusOptions: [
        { value: 'APPROVED', label: 'Approved' },
        { value: 'PENDING', label: 'Pending' },
        { value: 'DECLINED', label: 'Declined' },
      ],
      partnerNameOptions: [],
    },
  },
  clients: [],
  employees: {
    items: [],
    total: 0,
    options: {
      statusOptions: [
        { value: 'APPROVED', label: 'Enabled' },
        { value: 'DECLINED', label: 'Disabled' },
      ],
      roleOptions: [
        { value: 'MANAGER', label: 'Manager' },
        { value: 'ADMIN', label: 'Admin' },
      ],
    },
  },
  current: null,
  loadingType: '',
  error: '',
  affiliate: '',
};

export default createReducer(initialState)
  .handleAction(
    Actions.dropLink,
    produce((state) => {
      state.affiliate = '';
    }),
  )
  .handleAction(
    [
      Actions.createPartner.request,
      Actions.patchPartner.request,
      Actions.removePartner.request,
      Actions.createEmployee.request,
      Actions.patchEmployee.request,
      Actions.removeEmployee.request,
      Actions.findPartners.request,
      Actions.findManagers.request,
      Actions.findEmployees.request,
    ],
    produce((state, { type }) => {
      state.loadingType = type;
      state.error = '';
    }),
  )
  .handleAction(
    [
      Actions.createPartner.failure,
      Actions.patchPartner.failure,
      Actions.removePartner.failure,
      Actions.createEmployee.failure,
      Actions.patchEmployee.failure,
      Actions.removeEmployee.failure,
      Actions.findPartners.failure,
      Actions.findManagers.failure,
      Actions.findEmployees.failure,
    ],
    produce((state, { type, payload }) => {
      state.loadingType = type;
      state.error = payload;
    }),
  )
  .handleAction(
    [Actions.findPartners.success],
    produce((state, { payload: { count, rows, ...values } }) => {
      state.partners.items = rows;
      state.partners.total = count;
      state.partners.options = {
        ...state.partners.options,
        ...values,
      };

      const {
        groupIdOptions: prevGroupIdOptions,
        managerIdOptions: prevManagerIdOptions,
        partnerNameOptions: prevPartnerNameOptions,
      } = state.partners.options;

      const groupIdOptions = (rows || [])
        .filter(
          ({ group }) =>
            group &&
            !prevGroupIdOptions.find(({ value }) => value === group.id),
        )
        .map(({ group: { id, name } }) => ({
          value: id,
          label: name,
        }));

      const managerIdOptions = (rows || [])
        .filter(
          ({ manager }) =>
            manager &&
            !prevManagerIdOptions.find(({ value }) => value === manager.id),
        )
        .map(({ manager }) => ({
          value: manager.id,
          label: manager.fullName,
        }));

      const partnerNameOptions = (rows || [])
        .filter(
          (item) =>
            !prevPartnerNameOptions.find(
              ({ value }) => value === item.fullName,
            ),
        )
        .map((item) => {
          const value = item.fullName;
          const label = value;

          return { value, label };
        });

      state.partners.options.groupIdOptions = [
        ...prevGroupIdOptions,
        ...groupIdOptions,
      ];
      state.partners.options.managerIdOptions = [
        ...prevManagerIdOptions,
        ...managerIdOptions,
      ];
      state.partners.options.partnerNameOptions = [
        ...prevPartnerNameOptions,
        ...partnerNameOptions,
      ];
      state.loadingType = '';
      state.error = '';
    }),
  )
  .handleAction(
    [Actions.createPartner.success],
    produce((state, { payload }) => {
      state.partners.items = [
        ...new Set([
          ...state.partners.items,
          { ...payload, groupId: payload.groupId || null },
        ]),
      ];
      state.loadingType = '';
      state.error = '';
    }),
  )
  .handleAction(
    [Actions.patchPartner.success],
    produce((state, { payload }) => {
      const partners = state.partners.items.map((partner) => {
        const findIndex = payload.findIndex((item) => item.id === partner.id);

        if (findIndex !== -1) {
          return {
            ...partner,
            ...payload[findIndex],
            groupId: payload[findIndex].groupId || null,
          };
        }

        return partner;
      });

      state.partners.items = partners;

      state.loadingType = '';
      state.error = '';
    }),
  )
  .handleAction(
    [Actions.removePartner.success],
    produce((state, { payload }) => {
      const partners = state.partners.items.filter((partner) =>
        payload.rows.every((item) => item.id !== partner.id),
      );

      state.partners.items = partners;
      state.loadingType = '';
      state.error = '';
    }),
  )
  .handleAction(
    [Actions.findManagers.success],
    produce((state, { payload }) => {
      const prevManagerIdOptions = state.partners.options.managerIdOptions;
      const managerIdOptions = (payload?.rows || [])
        .filter(
          (item) =>
            item &&
            !prevManagerIdOptions.find(({ value }) => value === item.id),
        )
        .map((item) => ({
          value: item.id,
          label: item.fullName,
        }));

      state.partners.options.managerIdOptions = [
        ...prevManagerIdOptions,
        ...managerIdOptions,
      ];
      state.loadingType = '';
      state.error = '';
    }),
  )
  .handleAction(
    [Actions.searchPartners.success],
    produce((state, { payload }) => {
      state.partners.options.partnerNameOptions = (payload?.rows || []).map(
        (item) => ({
          value: item.name,
          label: item.name,
        }),
      );
    }),
  )
  .handleAction(
    [Actions.findEmployees.success],
    produce((state, { payload }) => {
      state.employees.items = payload.rows;
      state.employees.total = payload.count;

      state.loadingType = '';
      state.error = '';
    }),
  )
  .handleAction(
    [Actions.findPartnersGroups.success],
    produce((state, { payload }) => {
      const prevGroupIdOptions = state.partners.options.groupIdOptions;
      const groupIdOptions = (payload?.rows || [])
        .filter(
          ({ id }) => !prevGroupIdOptions.find((group) => group.value === id),
        )
        .map(({ id, name }) => ({
          value: id,
          label: name,
        }));
      state.partners.options.groupIdOptions = [
        ...prevGroupIdOptions,
        ...groupIdOptions,
      ];
    }),
  )
  .handleAction(
    [Actions.createEmployee.success],
    produce((state, { payload }) => {
      state.employees.items = [
        ...new Set([
          ...state.employees.items,
          { ...payload, groupId: payload.groupId || null },
        ]),
      ];
      state.loadingType = '';
      state.error = '';
    }),
  )
  .handleAction(
    [Actions.patchEmployee.success],
    produce((state, { payload }) => {
      const employees = state.employees.items.map((employee) => {
        const findIndex = payload.findIndex((item) => item.id === employee.id);

        if (findIndex !== -1) {
          return {
            ...employee,
            ...payload[findIndex],
            groupId: payload[findIndex].groupId || null,
          };
        }

        return employee;
      });

      state.employees.items = employees;

      state.loadingType = '';
      state.error = '';
    }),
  )
  .handleAction(
    [Actions.removeEmployee.success],
    produce((state, { payload }) => {
      const employees = state.employees.items.filter((employee) =>
        payload.rows.every((item) => item.id !== employee.id),
      );

      state.employees.items = employees;
      state.loadingType = '';
      state.error = '';
    }),
  );
