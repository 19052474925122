import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import CustomIcon from 'components/Icons';
import ModalFooter from './ModalFooter';

import styles from './styles.module.less';

const maskStyle = { background: 'rgba(0, 0, 0, 0.65)' };

const ModalContainer = ({
  title,
  isOpen,
  confirmLoading,
  onDefault,
  onCancel,
  onFinish,
  footerType,
  footerControls,
  children,
  className,
  ...others
}) => (
  <Modal
    closeIcon={CustomIcon({ type: 'close' })}
    maskStyle={maskStyle}
    title={title}
    centered
    visible={isOpen}
    onCancel={onCancel}
    className={clsx(styles.modal, className)}
    footer={
      footerControls ||
      ModalFooter({
        type: footerType,
        loading: confirmLoading,
        onDefault,
        onCancel,
        onFinish,
      })
    }
    {...others}
  >
    {children}
  </Modal>
);

ModalContainer.propTypes = {
  title: PropTypes.node,
  isOpen: PropTypes.bool.isRequired,
  confirmLoading: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onDefault: PropTypes.func,
  onFinish: PropTypes.func,
  footerControls: PropTypes.node,
  footerType: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ModalContainer;
