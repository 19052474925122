import { NUMBER_FORMATS } from 'utils/constants';
import { getFormattedNumber } from './helpers';
import moment from 'moment';
import { TILE_CHART_SERIES_ACCESS_KEYS } from '../redux/reducers/dashboard';
import { Brand } from 'constants/brand';

const TODAY = 'Today';

const charts = {
  registrations: {
    brandColors: {
      [Brand.FM]: ['#2CCDCD'],
      [Brand.GP]: ['#80CA8A'],
      [Brand.DAY1]: ['#2CCDCD'],
    },
    chartType: 'bar',
  },
  ['open-accounts']: {
    brandColors: {
      [Brand.FM]: ['#FE6B6B'],
      [Brand.GP]: ['#80CA8A'],
      [Brand.DAY1]: ['#FE6B6B'],
    },
    chartType: 'line',
  },
  ['funded-accounts']: {
    brandColors: {
      [Brand.FM]: ['#415DAA'],
      [Brand.GP]: ['#110D3D'],
      [Brand.DAY1]: ['#415DAA'],
    },
    chartType: 'bar',
  },
  ['active-accounts']: {
    brandColors: {
      [Brand.FM]: ['#51B56D'],
      [Brand.GP]: ['#FE6B6B'],
      [Brand.DAY1]: ['#51B56D'],
    },
    chartType: 'line',
  },
  ['total-commissions']: {
    brandColors: ['#AA74EF'],
    chartType: 'area',
    fillOptions: 'gradient',
    showGrid: true,
  },
  ['commissions-earned']: {
    brandColors: {
      [Brand.FM]: ['#2CCDCD'],
      [Brand.GP]: ['#110D3D'],
      [Brand.DAY1]: ['#2CCDCD'],
    },
    chartType: 'area',
    fillOptions: 'gradient',
    showGrid: true,
  },
  impressions: {
    brandColors: ['#2CCDCD'],
    chartType: 'bar',
  },
  withdrawals: {
    brandColors: {
      [Brand.FM]: ['#FD6A3C'],
      [Brand.GP]: ['#FE6B6B'],
      [Brand.DAY1]: ['#FD6A3C'],
    },
    chartType: 'line',
  },
  deposits: {
    brandColors: {
      [Brand.FM]: ['#415DAA'],
      [Brand.GP]: ['#110D3D'],
      [Brand.DAY1]: ['#415DAA'],
    },
    chartType: 'bar',
  },
  [TILE_CHART_SERIES_ACCESS_KEYS.COMMISSIONS]: {
    brandColors: {
      [Brand.FM]: ['#415DAA'],
      [Brand.GP]: ['#110D3D'],
      [Brand.DAY1]: ['#415DAA'],
    },
    chartType: 'line',
  },
  leads: {
    brandColors: ['#51B56D'],
    chartType: 'bar',
  },
};

const areaChartOptions = {
  fill: {
    type: 'gradient',
    gradient: {
      shadeIntensity: 0.25,
      opacityFrom: 0.6,
      opacityTo: 0,
      stops: [0, 100],
    },
  },
  grid: {
    show: true,
    borderColor: 'var(--grid-color)',
    position: 'back',
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
};

export const getEnabledSeries = (chartsData) =>
  chartsData.reduce(
    (acc, chartData) => {
      if (chartData.enabled) {
        acc.series.push(chartData.series);
        acc.colors.push(chartData.color);
      }

      return acc;
    },
    { colors: [], series: [] },
  );

const axisLabelFormatter = (num) => {
  switch (true) {
    case num > 10 ** 9:
      return num / 10 ** 9 + 'B';
    case num > 10 ** 6:
      return num / 10 ** 6 + 'M';
    case num > 999:
      return num / 10 ** 3 + 'K';
    default:
      return num;
  }
};

const handleCrosshairs = (element, currentColor, positionY) => {
  const gradient = element.querySelector(
    '.apexcharts-inner.apexcharts-graphical defs linearGradient stop',
  );

  gradient.setAttribute('stop-color', currentColor);

  const crosshairs = element.querySelector('.apexcharts-xcrosshairs');
  crosshairs.setAttribute('height', `${198.35 - positionY}`);
  crosshairs.setAttribute('y', `${positionY - 18}`);
  crosshairs.setAttribute('rx', '10');
  crosshairs.setAttribute('ry', '10');
};

const handleTooltip = (element, positionX, positionY) => {
  const tooltip = element.querySelector('.apexcharts-tooltip');

  if (tooltip) {
    const offsetY = positionY - 35;
    const offsetX = positionX + 36 - tooltip.offsetWidth / 2;

    tooltip.style.transform = `translate(${offsetX}px, ${offsetY}px)`;
  }
};

export const getPerformanceChartOptions = (
  colors,
  selectedPeriod,
  noData = false,
) => ({
  chart: {
    events: {
      mouseMove: (function () {
        let pointsXvaluesCache = null;
        let pointsYvaluesCache = null;
        let seriesIndexCache = null;
        let dataPointIndexCache = null;

        return function (event, chartContext, config) {
          const seriesXvalues = config.globals.seriesXvalues;
          const seriesYvalues = config.globals.seriesYvalues;
          const seriesIndex = config.seriesIndex;
          const dataPointIndex =
            config.dataPointIndex === -1 ? 0 : config.dataPointIndex;
          const currentColor = config.globals.colors[seriesIndex];

          if (
            pointsXvaluesCache === seriesXvalues &&
            pointsYvaluesCache === seriesYvalues &&
            seriesIndexCache === seriesIndex &&
            dataPointIndexCache === dataPointIndex
          ) {
            return;
          }

          pointsXvaluesCache = seriesXvalues;
          pointsYvaluesCache = seriesYvalues;
          seriesIndexCache = seriesIndex;
          dataPointIndexCache = dataPointIndex;

          if (seriesIndex !== -1) {
            handleTooltip(
              chartContext.el,
              seriesXvalues[seriesIndex][dataPointIndex] - 8,
              seriesYvalues[seriesIndex][dataPointIndex],
            );
            handleCrosshairs(
              chartContext.el,
              currentColor,
              seriesYvalues[seriesIndex][dataPointIndex],
            );
          }
        };
      })(),
    },
    id: 'chart',
    width: '100%',
    height: '100%',
    parentHeightOffset: 0,
    offsetX: -15,
    type: 'line',
    fontFamily: '',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
    animations: {
      enabled: false,
    },
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: !noData,
    shared: false,
    x: {
      show: false,
    },
    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      const title = w.globals.seriesNames.length
        ? w.globals.seriesNames[seriesIndex]
        : '';
      const formattedNumber = getFormattedNumber({
        number: series[seriesIndex][dataPointIndex],
        style:
          title === 'Commissions'
            ? NUMBER_FORMATS.currency
            : NUMBER_FORMATS.decimal,
        signDisplay: false,
        group: {
          count: 3,
          replaceWith: ',',
        },
      });

      return (
        '<div class="tooltip">' +
        '<span class="tooltip-title">' +
        title +
        '</span>' +
        '<span class="tooltip-value">' +
        formattedNumber +
        '</span>' +
        '</div>'
      );
    },
  },
  colors: colors,
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: 'smooth',
    lineCap: 'round',
  },
  grid: {
    borderColor: 'var(--grid-color)',
  },
  markers: {
    strokeColors: 'var(--header-notification-bg)',
    strokeWidth: 5,
    strokeOpacity: 1,
    hover: {
      sizeOffset: 11,
    },
  },
  xaxis: {
    ...(selectedPeriod === TODAY && { type: 'datetime' }),
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      ...(selectedPeriod === TODAY && {
        datetimeFormatter: {
          year: 'yyyy',
          month: "MMM 'yy",
          day: 'dd MMM',
          hour: 'HH:mm',
        },
      }),
      ...(selectedPeriod !== TODAY && {
        formatter: function (value) {
          return moment(value).format('DD MMM');
        },
      }),
      hideOverlappingLabels: true,
      trim: true,
      style: {
        colors: 'var(--info-title)',
        fontSize: '12px',
        fontWeight: '400',
      },
    },
    tooltip: {
      enabled: false,
    },
    crosshairs: {
      show: true,
      width: 36,
      opacity: 1,
      stroke: {
        width: 0,
      },
      fill: {
        type: 'gradient',
        gradient: {
          colorFrom: '#AA74EF',
          colorTo: 'var(--layout-color)',
          stops: [0, 100],
          opacityFrom: 0.8,
          opacityTo: 0,
        },
      },
    },
  },
  yaxis: {
    forceNiceScale: true,
    labels: {
      formatter: axisLabelFormatter,
      style: {
        colors: 'var(--info-title)',
        fontSize: '12px',
        fontWeight: '400',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
});

export const getChartOptions = (type, brand, width = 100, height = 70) => {
  const { brandColors, chartType } = charts[type];
  const colors = brandColors[brand] ?? brandColors;

  const options = {
    chart: {
      type: chartType,
      height: `${height}px`,
      width: `${width}px`,
      sparkline: {
        enabled: true,
      },
      events: {
        mouseMove: (function () {
          let pointsArrayCache = null;
          let seriesIndexCache = null;
          let dataPointIndexCache = null;

          return function (event, chartContext, config) {
            const pointsArray = config.globals.pointsArray;
            const seriesIndex = config.seriesIndex;
            const dataPointIndex =
              config.dataPointIndex === -1 ? 0 : config.dataPointIndex;

            if (
              pointsArrayCache === pointsArray &&
              seriesIndexCache === seriesIndex &&
              dataPointIndexCache === dataPointIndex
            ) {
              return;
            }

            pointsArrayCache = pointsArray;
            seriesIndexCache = seriesIndex;
            dataPointIndexCache = dataPointIndex;

            if (seriesIndex !== -1 && chartType !== 'bar') {
              const position = pointsArray[seriesIndex][dataPointIndex];
              handleTooltip(chartContext.el, position[0] - 36, 35);
            } else {
              const position = event.layerX;
              handleTooltip(chartContext.el, position - 36, 35);
            }
          };
        })(),
      },
    },
    tooltip: {
      shared: false,
      x: {
        show: false,
      },
      custom: ({ dataPointIndex, w: context }) => {
        const { series } = context.config;

        const seriesValues = series[0].data;
        const formattedNumber = getFormattedNumber({
          number: seriesValues[dataPointIndex].y,
          signDisplay: false,
          group: {
            count: 3,
            replaceWith: ',',
          },
        });

        const formattedDate = moment(seriesValues[dataPointIndex].x)
          .utc()
          .format('MMM Do');

        return `<div class="tooltip">
                  <div class="tooltip-value">Value: ${formattedNumber}</div>
                  <div class="tooltip-value">Date: ${formattedDate}</div>
                </div>
               `;
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 6,
        horizontal: false,
        columnWidth: '50%',
        colors: {
          backgroundBarColors: Array(10).fill(colors[0]),
          backgroundBarOpacity: 0.2,
          backgroundBarRadius: 6,
        },
      },
    },
    colors: colors,
    markers: {
      strokeColors: 'var(--header-notification-bg)',
      strokeWidth: 5,
      strokeOpacity: 1,
      hover: {
        sizeOffset: 7,
      },
    },
  };

  if (chartType === 'line') {
    options.stroke = {
      width: 6,
      curve: 'smooth',
      lineCap: 'round',
    };
  }

  if (chartType === 'area') {
    options.fill = areaChartOptions.fill;
    options.grid = areaChartOptions.grid;
  }

  return { options, chartType };
};

export const CHART_SIZES = {
  tile: {
    width: '128px',
    height: '128px',
  },
  block: {
    width: '210px',
    height: '210px',
  },
};

export const getDonutChartOptions = (labels, colors, type = 'tile') => ({
  chart: {
    ...CHART_SIZES[type],
    type: 'donut',
    sparkline: {
      enabled: true,
    },
  },
  fill: {
    type: 'gradient',
  },
  stroke: {
    show: false,
  },
  tooltip: {
    custom: ({ series, seriesIndex, w }) => {
      const title = w.globals.seriesNames.length
        ? w.globals.seriesNames[seriesIndex]
        : '';

      const value = `${series[seriesIndex]}%`;

      return (
        '<div class="tooltip">' +
        '<span class="tooltip-title">' +
        title +
        '</span>' +
        '&nbsp;&nbsp;' +
        '<span class="tooltip-value">' +
        value +
        '</span>' +
        '</div>'
      );
    },
  },
  labels,
  colors,
});
