export const getSettings = ({ commissionSettings }) => {
  const initial = {
    lot: [],
    spread: [],
    fee: [],
  };

  return commissionSettings.settings.reduce((result, s) => {
    if (!Object.keys(initial).includes(s.name)) {
      return result;
    }

    result[s.name].push({ value: s.key, grp: s.key, amount: s.value });

    return result;
  }, initial);
};

export const getSubIBSettings = ({ commissionSettings }) => {
  const initial = {
    ib: 100,
    partnerIb: 0,
    partnerId: '',
    masterIb: 0,
    masterId: '',
    selfIb: 0,
  };

  return commissionSettings.settings.reduce((result, s) => {
    if (s.name !== 'sub') {
      return result;
    }

    if (s.key === 'partnerId' || s.key === 'masterId') {
      result[s.key] = s.value || '';
    } else {
      result[s.key] = s.value;
    }

    return result;
  }, initial);
};
