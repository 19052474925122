import { TABLE_DATA_INDEX, TABLE_TITLE, tableCell } from 'utils/tableSchema';
import { DAY_MONTH_YEAR_FORMAT } from 'utils/timeConstants';
import { FORM_INPUT_TYPES, NUMBER_FORMATS, TITLES } from 'utils/constants';
import { mapToOptions } from 'utils/mapToOptions';
import { COUNTRY_CODES_MAP } from 'utils/countries';

const countryOptions = mapToOptions(COUNTRY_CODES_MAP, 'value');

export const CLIENTS_FEES_COLUMNS = [
  {
    title: TABLE_TITLE.LOGIN,
    dataIndex: TABLE_DATA_INDEX.LOGIN,
    key: TABLE_DATA_INDEX.LOGIN,
    sorter: true,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: TABLE_TITLE.NAME,
    dataIndex: TABLE_DATA_INDEX.ACCOUNT_NAME,
    key: TABLE_DATA_INDEX.ACCOUNT_NAME,
  },
  {
    title: TABLE_TITLE.SYMBOL,
    key: TABLE_DATA_INDEX.SYMBOL,
    sorter: false,
    dataIndex: TABLE_DATA_INDEX.SYMBOL,
  },
  {
    title: TABLE_TITLE.AMOUNT,
    key: TABLE_DATA_INDEX.AMOUNT,
    sorter: true,
    dataIndex: TABLE_DATA_INDEX.AMOUNT,
    render: tableCell.formatted({ style: NUMBER_FORMATS.currency }),
  },
  {
    title: TABLE_TITLE.COMMISSION,
    key: TABLE_DATA_INDEX.COMMISSION,
    sorter: true,
    dataIndex: TABLE_DATA_INDEX.COMMISSION,
    render: tableCell.formatted({ style: NUMBER_FORMATS.currency }),
  },
  {
    title: TABLE_TITLE.COUNTRY,
    key: TABLE_DATA_INDEX.COUNTRY,
    sorter: false,
    dataIndex: TABLE_DATA_INDEX.COUNTRY,
    render: tableCell.flag(),
  },
  {
    title: TABLE_TITLE.DATE,
    key: TABLE_DATA_INDEX.DATE,
    dataIndex: TABLE_DATA_INDEX.DATE,
    sorter: true,
    render: tableCell.date(DAY_MONTH_YEAR_FORMAT),
  },
];

export const CLIENTS_FEES_FILTERS = [
  {
    label: TABLE_TITLE.LOGIN,
    placeholder: TITLES.ALL_ACCOUNT_LOGINS,
    name: TABLE_DATA_INDEX.LOGIN,
  },
  {
    label: TABLE_TITLE.NAME,
    placeholder: TITLES.ALL_NAMES,
    name: TABLE_DATA_INDEX.ACCOUNT_NAME,
  },
  {
    type: FORM_INPUT_TYPES.SLIDER,
    label: 'Amount',
    name: 'amount',
    numberFormat: NUMBER_FORMATS.currency,
  },
  {
    type: FORM_INPUT_TYPES.SELECT,
    label: 'County',
    placeholder: TITLES.ALL_COUNTRIES,
    name: 'country',
    options: [{ value: null, label: 'All Countries' }, ...countryOptions],
    mode: 'multiple',
  },
  {
    type: FORM_INPUT_TYPES.SELECT,
    label: 'Symbol',
    placeholder: TITLES.ALL_SYMBOLS,
    name: 'symbol',
    allowClear: true,
    mode: 'multiple',
  },
  {
    type: FORM_INPUT_TYPES.SLIDER,
    label: 'Commission',
    name: 'commission',
    numberFormat: NUMBER_FORMATS.currency,
  },
];
