import { api } from 'api';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getErrorHandler } from 'utils/getErrorHandler';
import { queryKeys } from 'utils/queryKeys';
import { useDispatch } from 'react-redux';
import Actions from '../../../../../redux/actions';
import { useFirebase } from 'modules/shared/firebase/hooks/useFirebase';
import { signInWithCustomToken } from 'firebase/auth';

export const useTfaAuthByUserId = ({ userId }) => {
  const userQueryKey = queryKeys.user(userId);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { firebase } = useFirebase();

  const {
    data: tfaCode,
    mutateAsync: createTfaCode,
    isLoading: isCreatingTfaCode,
  } = useMutation({
    mutationFn: async () => {
      const response = await api.createTfaCode(userId);

      return response.data;
    },
    onError: getErrorHandler('Unable to create two factor authentication code'),
  });

  const {
    mutateAsync: enableTfa,
    isLoading: isEnablingTfa,
    error: enableTfaError,
  } = useMutation({
    mutationFn: async (data) => {
      const response = await api.enableTfa(userId, data);

      await signInWithCustomToken(
        firebase.getAuth(),
        response.data.accessToken,
      );

      return response.data;
    },
    onSuccess: async (data) => {
      dispatch(Actions.logIn.success(data.user));
      queryClient.setQueryData(userQueryKey, data.user);
    },
    onError: getErrorHandler('Unable to turn on two factor authentication'),
  });

  const { mutateAsync: disableTfa, isLoading: isDisablingTfa } = useMutation({
    mutationFn: async () => {
      const response = await api.disableTfa(userId);

      await signInWithCustomToken(
        firebase.getAuth(),
        response.data.accessToken,
      );

      return response.data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(userQueryKey, data.user);
    },
    onError: getErrorHandler('Unable to turn off two factor authentication'),
  });

  return {
    tfaCode,
    createTfaCode,
    isCreatingTfaCode,

    enableTfa,
    isEnablingTfa,
    enableTfaError,

    disableTfa,
    isDisablingTfa,
  };
};
