import { takeLatest, select, put } from 'redux-saga/effects';
import actions from 'redux/actions';
import { getUser } from 'redux/sagas/auth/selectors';
import { getAccountTransfers } from 'redux/sagas/withdrawals/selectors';
import { message } from 'antd';
import { api } from 'api';
import { getResponseMessage } from 'utils/getResponseMessage';

function* callLoadAccountTransferOptions() {
  const user = yield select(getUser);

  try {
    const response = yield api.getAccountTransfersOptions(user.id);

    yield put(actions.loadAccountTransferOptions.success(response.data));
  } catch (err) {
    yield put(actions.loadAccountTransferOptions.failure(err));
    message.error('Unable to load account transfer options');
  }
}

function* callCreateAccountTransfer({
  payload: { values, onSuccess, onFailure },
}) {
  const user = yield select(getUser);
  const accountTransfers = yield select(getAccountTransfers);

  try {
    const { login, platform, regulator } = JSON.parse(values.accountId);
    const response = yield api.createAccountTransfer(user.id, {
      login,
      platform,
      amount: values.amount,
      regulator,
    });
    yield put(actions.createAccountTransfer.success(response.data));
    yield put(
      actions.loadAccountTransfers.request({
        offset: 0,
        limit: accountTransfers.total + 1,
      }),
    );
    yield put(actions.getBalance.request());

    onSuccess();
    message.success(
      'The trading account transfer has been successfully processed',
    );
  } catch (err) {
    yield put(actions.createAccountTransfer.failure(err));
    onFailure(err);

    message.error(
      `Unable to process the trading account transfer. ${getResponseMessage(
        err,
      )}`,
    );
  }
}

function* callLoadAccountTransfers({ payload }) {
  const user = yield select(getUser);

  try {
    const response = yield api.getAccountTransfersByUserId(user.id, payload);
    yield put(actions.loadAccountTransfers.success(response.data));
  } catch (error) {
    yield put(actions.loadAccountTransfers.failure(error));
  }
}

export default function* accountTransfersSaga() {
  yield takeLatest(
    actions.loadAccountTransferOptions.request,
    callLoadAccountTransferOptions,
  );
  yield takeLatest(
    actions.createAccountTransfer.request,
    callCreateAccountTransfer,
  );
  yield takeLatest(
    actions.loadAccountTransfers.request,
    callLoadAccountTransfers,
  );
}
