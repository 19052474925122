import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import { replaceArrayItem } from 'utils/replaceArrayItem';
import Actions from '../actions';

const initialState = {
  loadingType: '',
  error: '',
  commissions: {
    dataSource: [],
    total: 0,
    filters: null,
  },
  registrations: {
    dataSource: [],
    total: 0,
    filters: null,
  },
  trades: {
    dataSource: [],
    total: 0,
    filters: null,
  },
  payments: {
    dataSource: [],
    total: 0,
    filters: null,
  },
  accountTransfers: {
    dataSource: [],
    total: 0,
    filters: null,
  },
  balances: {
    dataSource: [],
    total: 0,
    filters: {},
  },
  clientsRegistrations: {
    dataSource: [],
    total: 0,
    filters: { refCodes: [1, 2, 3] },
  },
  adjustments: {
    dataSource: [],
    total: 0,
    filters: {},
  },
};

export default createReducer(initialState)
  .handleAction(
    [
      Actions.loadLatestPartnersReport.request,
      Actions.loadRegistrationReport.request,
      Actions.loadCommissionsReport.request,
      Actions.loadTradesReport.request,
      Actions.loadPaymentsReport.request,
      Actions.loadAccountTransfersReport.request,
      Actions.loadBalancesReport.request,
      Actions.loadAdjustmentsReport.request,
      Actions.rejectBankWire.request,
      Actions.approveAccountTransferTaxInvoice.request,
      Actions.approveBankWireTaxInvoice.request,
    ],
    produce((state, { type }) => {
      state.loadingType = type;
      state.error = '';
    }),
  )
  .handleAction(
    [
      Actions.loadLatestPartnersReport.failure,
      Actions.loadRegistrationReport.failure,
      Actions.loadCommissionsReport.failure,
      Actions.loadTradesReport.failure,
      Actions.loadPaymentsReport.failure,
      Actions.loadAccountTransfersReport.failure,
      Actions.loadBalancesReport.failure,
      Actions.loadAdjustmentsReport.failure,
      Actions.rejectBankWire.failure,
      Actions.approveAccountTransferTaxInvoice.failure,
      Actions.approveBankWireTaxInvoice.failure,
    ],
    produce((state, { payload }) => {
      state.loadingType = '';
      state.error = payload;
    }),
  )
  .handleAction(
    Actions.loadRegistrationReport.success,
    produce((state, { payload }) => {
      state.clientsRegistrations.total = payload.count;
      state.clientsRegistrations.dataSource = payload.rows;
      state.loadingType = '';
    }),
  )
  .handleAction(
    Actions.loadLatestPartnersReport.success,
    produce((state, { payload }) => {
      state.registrations.total = payload.count;
      state.registrations.dataSource = payload.rows;
      state.loadingType = '';
    }),
  )
  .handleAction(
    Actions.loadLatestPartnersReportLimits.success,
    produce((state, { payload }) => {
      state.registrations.filters = payload;
    }),
  )
  .handleAction(
    Actions.loadCommissionsReport.success,
    produce((state, { payload }) => {
      state.commissions.total = payload.count;
      state.commissions.dataSource = payload.rows;
      state.loadingType = '';
    }),
  )
  .handleAction(
    Actions.loadCommissionsReportLimits.success,
    produce((state, { payload }) => {
      state.commissions.filters = payload;
    }),
  )
  .handleAction(
    Actions.loadTradesReport.success,
    produce((state, { payload }) => {
      state.trades.total = payload.count;
      state.trades.dataSource = payload.rows;
      state.loadingType = '';
    }),
  )
  .handleAction(
    Actions.loadTradesReportLimits.success,
    produce((state, { payload }) => {
      state.trades.filters = payload;
    }),
  )
  .handleAction(
    Actions.loadPaymentsReport.success,
    produce((state, { payload }) => {
      state.payments.total = payload.count;
      state.payments.dataSource = payload.rows;
      state.loadingType = '';
    }),
  )
  .handleAction(
    Actions.loadPaymentsReportLimits.success,
    produce((state, { payload }) => {
      state.payments.filters = payload;
    }),
  )
  .handleAction(
    Actions.loadAccountTransfersReport.success,
    produce((state, { payload }) => {
      state.accountTransfers.total = payload.count;
      state.accountTransfers.dataSource = payload.rows;
      state.loadingType = '';
    }),
  )
  .handleAction(
    Actions.loadAccountTransfersReportLimits.success,
    produce((state, { payload }) => {
      state.accountTransfers.filters = payload;
    }),
  )
  .handleAction(
    Actions.approveAccountTransferTaxInvoice.success,
    produce((state, { payload }) => {
      state.accountTransfers.dataSource = replaceArrayItem(
        state.accountTransfers.dataSource,
        payload,
      );
      state.loadingType = '';
    }),
  )
  .handleAction(
    Actions.approveBankWireTaxInvoice.success,
    produce((state, { payload }) => {
      state.payments.dataSource = replaceArrayItem(
        state.payments.dataSource,
        payload,
      );
      state.loadingType = '';
    }),
  )
  .handleAction(
    Actions.loadBalancesReport.success,
    produce((state, { payload }) => {
      state.balances.total = payload.count;
      state.balances.dataSource = payload.rows;
      state.loadingType = '';
    }),
  )
  .handleAction(
    Actions.loadAdjustmentsReport.success,
    produce((state, { payload }) => {
      state.adjustments.total = payload.count;
      state.adjustments.dataSource = payload.rows;
      state.loadingType = '';
    }),
  )
  .handleAction(
    Actions.loadAdjustmentsReportLimits.success,
    produce((state, { payload }) => {
      state.adjustments.filters = payload;
    }),
  )
  .handleAction(
    Actions.rejectBankWire.success,
    produce((state, { payload }) => {
      state.payments.dataSource = replaceArrayItem(
        state.payments.dataSource,
        payload,
      );
      state.loadingType = '';
    }),
  );
