import { createSelector } from 'reselect';

export const getAffiliateLink = ({ users }) => users.affiliate;

export const getUsersLoadingType = ({ users }) => users.loadingType;

export const getPartnersName = ({ users }) =>
  users.partners.options.partnerNameOptions;

export const getUsersError = ({ users }) => users.error;

export const getOptionsForPartnersTable = ({ users }) => users.partners.options;

export const getOptionsForEmployeesTable = ({ users }) =>
  users.employees.options;

export const getEmployees = ({ users }) => users.employees.items;

export const getEmployeeById = (id) =>
  createSelector(getEmployees, (items) =>
    items?.find((item) => item.id === id),
  );

export const getPartners = ({ users }) => users.partners.items;

export const getPartnerById = (id) =>
  createSelector(getPartners, (items) => items?.find((item) => item.id === id));

export const getOptionsForPartnersTableByType = (type) =>
  createSelector(getOptionsForPartnersTable, (options) => options?.[type]);

export const getOptionsForEmployeesTableByType = (type) =>
  createSelector(getOptionsForEmployeesTable, (options) => options?.[type]);

export const mapPartnersToTableRows = (partners) =>
  partners.map(
    ({
      id,
      fullName,
      type,
      createdAt,
      lastLoggedAt,
      status,
      managerId,
      group,
      groupId,
      commission,
      registrations,
      refCode,
      email,
      country,
      tradesCount,
      commissionGrowth,
      registrationsGrowth,
      tradesGrowth,
    }) => ({
      id,
      fullName,
      type,
      createdAt,
      lastLoggedAt,
      managerId,
      status,
      group,
      groupId,
      commission,
      registrations,
      refCode,
      link: 'Copy link',
      email,
      country,
      tradesCount,
      commissionGrowth,
      registrationsGrowth,
      tradesGrowth,
    }),
  );

export const getPartnersForTable = ({ users }) => ({
  loadingType: users.loadingType,
  total: users.partners.total,
  dataSource: mapPartnersToTableRows(users.partners.items),
});

export const getEmployeesForTable = ({ users }) => ({
  loadingType: users.loadingType,
  total: users.employees.total,
  dataSource: users.employees.items.map(
    ({
      id,
      fullName,
      isTwoFactorAuthEnabled,
      email,
      createdAt,
      lastLoggedAt,
      role,
      status,
    }) => ({
      id,
      fullName,
      email,
      isTwoFactorAuthEnabled: isTwoFactorAuthEnabled ? 'enabled' : 'disabled',
      createdAt,
      lastLoggedAt,
      role,
      status,
    }),
  ),
});
