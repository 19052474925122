import { takeLatest, put } from 'redux-saga/effects';
import actions from 'redux/actions';
import { api } from 'api';

function* callLoadCommissionsReport({ payload }) {
  try {
    const response = yield api.getCommissionsReport(payload);
    yield put(actions.loadCommissionsReport.success(response.data));
  } catch (error) {
    yield put(actions.loadCommissionsReport.failure(error));
  }
}

function* callLoadCommissionsReportLimits({ payload }) {
  try {
    const response = yield api.getCommissionsReportLimits(payload);
    yield put(actions.loadCommissionsReportLimits.success(response.data));
  } catch (error) {
    yield put(actions.loadCommissionsReportLimits.failure(error));
  }
}

function* callExportCommissionsReport({
  payload: { requestData, onSuccess, onFailure, fileExtension },
}) {
  try {
    const response = yield api.getCommissionsReport(requestData);
    onSuccess(response.data, fileExtension);
  } catch (error) {
    onFailure(error);
  }
}

export function* commissionsSaga() {
  yield takeLatest(
    actions.loadCommissionsReport.request,
    callLoadCommissionsReport,
  );
  yield takeLatest(
    actions.loadCommissionsReportLimits.request,
    callLoadCommissionsReportLimits,
  );
  yield takeLatest(
    actions.loadAllCommissionsReport.request,
    callExportCommissionsReport,
  );
}
