import React from 'react';

import Root from './containers/Common/Root';
import Routes from './containers/Common/Routes';

export const App = () => (
  <Root>
    <Routes />
  </Root>
);
