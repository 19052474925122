import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useTheme } from 'modules/shared/theme/hooks/useTheme';

import styles from './styles.module.less';
import { useLogo } from 'utils/hooks/useLogo';

const Logo = ({ isColumn, isCollapsed }) => {
  const { theme } = useTheme();

  const BrandedLogo = useLogo();

  return (
    <div className={clsx(styles.logo, { [styles.logoColumns]: isColumn })}>
      <BrandedLogo isCollapsed={isCollapsed} theme={theme} />
    </div>
  );
};

Logo.propTypes = {
  text: PropTypes.string,
  isColumn: PropTypes.bool,
  isCollapsed: PropTypes.bool,
};

export default Logo;
