import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ROUTING } from 'config';

export const PrivateRoute = ({ children, ...others }) => {
  const { isAuthenticated, isTwoFactorAuth } = useSelector(({ auth }) => auth);
  const location = useLocation();

  const search = `?next=${location.pathname}${location.search}`;

  switch (true) {
    case isAuthenticated: {
      return <Route {...others}>{children}</Route>;
    }
    case isTwoFactorAuth: {
      return (
        <Redirect
          to={{
            pathname: ROUTING.LOGIN_TWO_FACTOR,
            search,
          }}
        />
      );
    }
    default: {
      return (
        <Redirect
          to={{
            pathname: ROUTING.LOGIN,
            search,
          }}
        />
      );
    }
  }
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
  rest: PropTypes.any,
};
