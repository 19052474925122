import { all, put, takeLatest, select, call } from 'redux-saga/effects';

import actions from 'redux/actions';
import { getUser } from 'redux/sagas/auth/selectors';
import { USER_ROLES } from 'utils/constants';
import { getSearchLimits } from './selectors';
import { api } from 'api';
import { callLoadClientsBasedOnType } from '../clients';

function* callSearchClients({ payload }) {
  try {
    const user = yield select(getUser);
    const limit = yield select(getSearchLimits);

    if (user.role === USER_ROLES.BROKER) {
      const response = yield call(callLoadClientsBasedOnType, limit, user.id);

      yield put(actions.searchClients.success(response.data));
    } else {
      const data = {
        ...limit,
        ...(payload?.search && { search: payload.search }),
        ...(payload?.brand && { brand: payload.brand }),
        role: [USER_ROLES.BROKER],
      };

      const response = yield api.getUsers(data);

      if (payload?.isPartner) {
        yield put(actions.searchPartners.success(response.data));
      } else {
        yield put(actions.searchClients.success(response.data));
      }
    }
  } catch (error) {
    yield put(actions.searchClients.failure(error));
  }
}

export default function* searchSaga() {
  yield all([
    yield takeLatest(actions.searchClients.request, callSearchClients),
    yield takeLatest(actions.searchMoreClients.request, callSearchClients),
    yield takeLatest(actions.searchPartners.request, callSearchClients),
  ]);
}
