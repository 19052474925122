import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import clsx from 'clsx';

import { NUMBER_FORMATS } from 'utils/constants';
import { getFormattedNumber } from 'utils/helpers';

import styles from './styles.module.less';

const TableColEntityGrowth = ({
  currentPeriodValue,
  entityGrowth,
  cellFormat = NUMBER_FORMATS.currency,
}) => {
  const style = useMemo(
    () => (entityGrowth > 0 ? styles.positive : styles.negative),
    [entityGrowth],
  );

  const changes = useMemo(
    () =>
      entityGrowth !== undefined &&
      getFormattedNumber({
        number: entityGrowth,
        style: NUMBER_FORMATS.percent,
        signDisplay: true,
      }),
    [entityGrowth],
  );

  const profit = useMemo(
    () =>
      currentPeriodValue !== undefined
        ? getFormattedNumber({
            number: currentPeriodValue,
            style: cellFormat,
            minimumFractionDigits: 2,
          })
        : '--',
    [cellFormat, currentPeriodValue],
  );

  return (
    <div>
      <span>{profit}</span>
      {changes !== undefined && (
        <span className={clsx(style, styles.percent)}>{changes}</span>
      )}
    </div>
  );
};

TableColEntityGrowth.propTypes = {
  currentPeriodValue: PropTypes.number,
  entityGrowth: PropTypes.number,
  cellFormat: PropTypes.oneOf(Object.values(NUMBER_FORMATS)),
};

export default TableColEntityGrowth;
