import React from 'react';
import clsx from 'clsx';
import { Input } from 'antd';

import styles from './styles.module.less';

// TODO: solve this
// eslint-disable-next-line react/prop-types
const StyledInput = ({ type, placeholder, className, ...other }, ref) => (
  <Input
    ref={ref}
    type={type}
    placeholder={placeholder}
    className={clsx(styles.input, styles[type], className)}
    {...other}
  />
);

// StyledInput.propTypes = {
//   type: PropTypes.string,
//   placeholder: PropTypes.string,
//   className: PropTypes.string,
//   other: PropTypes.any,
// };

export default React.forwardRef(StyledInput);
