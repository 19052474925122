import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import MainLayout from 'HOC/MainLayout';
import SuspenseWrapper from 'HOC/SuspenseWrapper';

import { ROUTING } from 'config';
import { PLATFORM_TYPES } from 'utils/constants';

const Dashboard = React.lazy(() => import('containers/Staff/Dashboard'));
const StaffManagement = React.lazy(() =>
  import('containers/Staff/StaffManagement'),
);
const Partners = React.lazy(() => import('containers/Staff/Partners'));
const Settings = React.lazy(() => import('containers/Common/Settings'));
const PartnerProfile = React.lazy(() =>
  import('containers/Staff/PartnerProfile'),
);
const LatestPartnersReport = React.lazy(() =>
  import('containers/Staff/Reports/LatestPartnerReports'),
);
const RegistrationsReport = React.lazy(() =>
  import('containers/Staff/Reports/RegistrationReport'),
);
const TradesReport = React.lazy(() =>
  import('containers/Staff/Reports/TradesReport'),
);
const CommissionReport = React.lazy(() =>
  import('containers/Staff/Reports/CommissionReport'),
);
const CpaCommissionReport = React.lazy(() =>
  import('containers/Staff/Reports/CpaCommissionReport'),
);
const PaymentReports = React.lazy(() =>
  import('containers/Staff/Reports/PaymentsReport'),
);
const BalancesReport = React.lazy(() =>
  import('containers/Staff/Reports/BalancesReport'),
);
const AdjustmentsReport = React.lazy(() =>
  import('containers/Staff/Reports/AdjustmentsReport'),
);
const ClientsProfile = React.lazy(() =>
  import('containers/Brokers/ClientsProfile'),
);
const FeesReport = React.lazy(() =>
  import('containers/Staff/Reports/FeesReport'),
);

const ROUTES = [
  {
    path: ROUTING.CLIENT_DASHBOARD,
    Component: Dashboard,
  },
  {
    path: ROUTING.STAFF_MANAGEMENT,
    Component: StaffManagement,
  },
  {
    path: ROUTING.CLIENT_PARTNERS,
    Component: Partners,
  },
  {
    path: ROUTING.SETTING,
    Component: Settings,
  },
  {
    path: ROUTING.REGISTRATION_REPORTS,
    Component: RegistrationsReport,
  },
  {
    path: ROUTING.LATEST_PARTNERS_REPORTS,
    Component: LatestPartnersReport,
  },
  {
    path: ROUTING.COMMISSION_REPORTS,
    Component: CommissionReport,
  },
  {
    path: ROUTING.CPA_COMMISSION_REPORTS,
    Component: CpaCommissionReport,
  },
  {
    path: ROUTING.TRADES_REPORTS,
    Component: TradesReport,
  },
  {
    path: ROUTING.FEES_REPORT,
    Component: FeesReport,
  },
  {
    path: ROUTING.PAYMENTS_REPORTS,
    Component: PaymentReports,
  },
  {
    path: ROUTING.BALANCES_REPORTS,
    Component: BalancesReport,
  },
  {
    path: ROUTING.ADJUSTMENTS_REPORT,
    Component: AdjustmentsReport,
  },
  {
    path: ROUTING.PARTNER_PROFILE,
    Component: PartnerProfile,
  },
  {
    path: ROUTING.PARTNERS_PARTNER_PROFILE,
    Component: PartnerProfile,
  },
  {
    path: ROUTING.LATEST_PARTNERS_REPORTS_PARTNER_PROFILE,
    Component: PartnerProfile,
  },
  {
    path: ROUTING.COMMISSION_REPORTS_PARTNER_PROFILE,
    Component: PartnerProfile,
  },
  {
    path: ROUTING.CPA_COMMISSION_REPORTS_PARTNER_PROFILE,
    Component: PartnerProfile,
  },
  {
    path: ROUTING.CLIENTS_PROFILE,
    Component: ClientsProfile,
  },
];

const StaffRouter = () => (
  <MainLayout type={PLATFORM_TYPES.STAFF}>
    <Switch>
      {ROUTES.map(({ path, Component }) => (
        <Route exact path={path} key={path}>
          <SuspenseWrapper>
            <Component />
          </SuspenseWrapper>
        </Route>
      ))}
      <Redirect from="*" to={ROUTING.DASHBOARD} />
    </Switch>
  </MainLayout>
);

export default StaffRouter;
