import React, { useMemo } from 'react';
import { Dropdown } from 'antd';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import PopupContainer from 'components/PopupContainer';
import DropdownMenu from 'components/Common/Dropdown/DropdownMenu';
import StyledButton from 'components/Common/Buttons/StyledButton';
import CustomIcon from 'components/Icons';

import styles from './styles.module.less';

const StyledDropdown = ({
  menuItems,
  rootRef,
  className,
  optionClasses,
  classNameButton,
  containerClass,
  onVisibleChange,
  visible,
  children,
  ...other
}) => {
  const additionalParams = useMemo(
    () => visible && { onVisibleChange, visible },
    [onVisibleChange, visible],
  );

  return (
    <PopupContainer
      rootRef={rootRef}
      containerClass={clsx(styles.dropdownContainer, containerClass)}
    >
      <Dropdown
        {...additionalParams}
        overlay={DropdownMenu({ menuItems, className, optionClasses })}
        placement="bottomRight"
        trigger="click"
        icon={CustomIcon({ type: 'options' })}
        {...other}
      >
        {children ? (
          <div>{children}</div>
        ) : (
          <StyledButton
            className={classNameButton}
            type="iconDefault"
            icon="options"
            size="small"
            shape="circle"
          />
        )}
      </Dropdown>
    </PopupContainer>
  );
};

StyledDropdown.propTypes = {
  menuItems: PropTypes.any.isRequired,
  rootRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.elementType }),
  ]),
  onVisibleChange: PropTypes.func,
  visible: PropTypes.bool,
  className: PropTypes.string,
  optionClasses: PropTypes.string,
  containerClass: PropTypes.string,
  classNameButton: PropTypes.string,
  children: PropTypes.node,
};

export default StyledDropdown;
