import React from 'react';
import PropTypes from 'prop-types';
import Text from 'antd/lib/typography/Text';

import { TABLE_DATA_INDEX } from 'utils/tableSchema';

import TableColDropdown from '../TableColDropdown/TableColDropdown';
import TableColEditDropdown from '../TableColDropdown/TableColEditDropdown';

const TableEditableCell = ({ type, onChange, ...props }) => {
  switch (type) {
    case TABLE_DATA_INDEX.DROPDOWN:
      return <TableColDropdown onChange={onChange} {...props} />;
    case TABLE_DATA_INDEX.EDITABLE_DROPDOWN:
      return <TableColEditDropdown onChange={onChange} {...props} />;
    default:
      return <Text>{type}</Text>;
  }
};

TableEditableCell.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  record: PropTypes.object.isRequired,
};

export default TableEditableCell;
