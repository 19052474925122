import React, { useCallback, useMemo } from 'react';
import keyBy from 'lodash/keyBy';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import StyledButton from 'components/Common/Buttons/StyledButton';
import FormInput from 'components/Common/Input/FormInput';
import StyledTags from 'components/Common/StyledTags';
import Loader from 'components/Common/Loader/index';

import { FORM_INPUT_TYPES, FORMS, BUTTONS_TEXT, TITLES } from 'utils/constants';

import styles from './styles.module.less';

const FormRow = ({
  name,
  selectedSymbols,
  setSelectedSymbols,
  label,
  className,
  symbols = [],
  isSymbolsLoading,
}) => {
  const [form] = Form.useForm();

  const filteredSelectedSymbols = useMemo(
    () =>
      selectedSymbols.reduce((result, symbol) => {
        if (!symbol.isRemoved) {
          const symbolToAdd = {
            ...symbol,
            ...(name === 'lot' && { sign: '$' }),
          };

          result.push(symbolToAdd);
        }

        return result;
      }, []),
    [selectedSymbols, name],
  );

  const symbolsByIdMap = useMemo(() => keyBy(symbols, 'id'), [symbols]);

  const onTagsChange = useCallback(
    (removedSymbol) => {
      setSelectedSymbols((prevState) => ({
        ...prevState,
        [name]: prevState[name].map((symbol) =>
          symbol.value === removedSymbol
            ? { ...symbol, isRemoved: true }
            : symbol,
        ),
      }));
    },
    [name, setSelectedSymbols],
  );

  const onFinish = useCallback(
    (symbol) => {
      setSelectedSymbols((prevState) => ({
        ...prevState,
        [name]: [
          ...prevState[name],
          { ...symbol, label: symbolsByIdMap[symbol.value]?.label },
        ],
      }));
      form.resetFields();
    },
    [form, name, setSelectedSymbols, symbolsByIdMap],
  );

  const onSymbolSelected = useCallback(
    (value) => {
      form.setFieldsValue({ grp: symbolsByIdMap[value]?.label ?? value });

      if (value === TITLES.ALL_SYMBOLS) {
        form.resetFields(['grp']);
      }
    },
    [form, symbolsByIdMap],
  );
  const availableOptions = useMemo(() => {
    const options = symbols.filter(
      ({ value }) =>
        !filteredSelectedSymbols.some(
          ({ value: selectedValue }) => selectedValue === value,
        ),
    );

    return [{ value: 'All Symbols' }, ...options];
  }, [symbols, filteredSelectedSymbols]);

  return (
    <div className={clsx(styles.symbolContainer, className)}>
      <Form form={form} onFinish={onFinish} className={styles.form}>
        <FormInput
          type={FORM_INPUT_TYPES.SELECT}
          name="value"
          label={label}
          placeholder="Select Symbol"
          options={isSymbolsLoading ? [] : availableOptions}
          onChange={onSymbolSelected}
          className={styles.selectContainer}
          inputClassName={styles.select}
          notFoundContent={isSymbolsLoading ? <Loader type="small" /> : null}
        />
        <FormInput
          name="grp"
          disabled
          isRequired={false}
          placeholder={FORMS.GRP}
          inputClassName={styles.inputGRP}
        />
        <FormInput
          name="amount"
          type="amount"
          maxLength="5"
          placeholder={FORMS.AMOUNT}
          inputClassName={styles.input}
        />
        <StyledButton
          htmlType="submit"
          type="primary"
          text={BUTTONS_TEXT.ADD}
          className={styles.addButton}
        />
      </Form>
      <StyledTags tags={filteredSelectedSymbols} onTagRemove={onTagsChange} />
    </div>
  );
};

FormRow.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  selectedSymbols: PropTypes.array,
  symbols: PropTypes.array,
  isSymbolsLoading: PropTypes.bool,
  setSelectedSymbols: PropTypes.func,
  className: PropTypes.string,
};

export default React.memo(FormRow);
