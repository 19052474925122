import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import TableFooter from './TableFooter';
import CustomTable from './index';
import HeaderWithButtons from './TableHeader/HeaderWithButtons';

const TableWithPagination = ({
  selector,
  loadData,
  additionalLoadData,
  title,
  isShowStatus,
  columns,
  onRow,
  showDrawer,
  filters,
  initialPageSize,
  activeTab,
  tabName,
  customMenuItems,
  ...others
}) => {
  const dispatch = useDispatch();

  const [sorter, setSorter] = useState([]);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const { dataSource, total, isLoading } = useSelector(selector);

  useEffect(() => {
    if ((!activeTab && !tabName) || activeTab === tabName) {
      setCurrentPage(1);

      setPageSize(initialPageSize);
      loadData(0, initialPageSize, filters, sorter);

      if (additionalLoadData) {
        additionalLoadData.map(({ action, value }) => {
          dispatch(action(value));
        });
      }
    }
  }, [
    filters,
    initialPageSize,
    loadData,
    sorter,
    additionalLoadData,
    activeTab,
    tabName,
    dispatch,
  ]);

  const handleLoadMore = useCallback(() => {
    loadData(0, pageSize + initialPageSize, filters, sorter);
    setCurrentPage(1);
    setPageSize((prevState) => prevState + initialPageSize);
  }, [filters, initialPageSize, loadData, pageSize, sorter]);

  const handlePageChange = useCallback(
    (pageIndex) => {
      loadData(pageIndex - 1, pageSize, filters, sorter);
      setCurrentPage(pageIndex);
    },
    [filters, loadData, pageSize, sorter],
  );

  const tableHeader = useCallback(
    (currentPageData) => (
      <HeaderWithButtons
        showDrawer={showDrawer}
        currentPageData={currentPageData}
        customMenuItems={customMenuItems}
        title={title}
        filters={filters}
      />
    ),
    [customMenuItems, showDrawer, title, filters],
  );

  const footer = useCallback(
    (currentPageData) => (
      <TableFooter
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        currentPageCount={currentPageData.length}
        handlePageChange={handlePageChange}
        handleLoadMore={handleLoadMore}
      />
    ),
    [total, pageSize, currentPage, handlePageChange, handleLoadMore],
  );

  const pagination = useMemo(
    () => ({
      pageSize: dataSource?.length,
      current: currentPage,
      total,
    }),
    [currentPage, dataSource?.length, total],
  );

  return (
    <CustomTable
      columns={columns}
      dataSource={dataSource}
      onRow={onRow}
      sorterHandler={setSorter}
      pagination={pagination}
      showDrawer={showDrawer}
      title={others.tableHeader ?? tableHeader}
      footer={footer}
      isLoading={isLoading}
      {...others}
    />
  );
};

TableWithPagination.defaultProps = {
  initialPageSize: DEFAULT_PAGE_SIZE,
};

TableWithPagination.propTypes = {
  initialPageSize: PropTypes.number,
  selector: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
  additionalLoadData: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      value: PropTypes.shape({
        attributes: PropTypes.arrayOf(PropTypes.string),
        brand: PropTypes.string,
        limit: PropTypes.number,
      }),
    }),
  ),
  title: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
  tabName: PropTypes.string,
  isShowStatus: PropTypes.bool,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.oneOfType([
        PropTypes.func.isRequired,
        PropTypes.string.isRequired,
        PropTypes.node.isRequired,
      ]),
      dataIndex: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      render: PropTypes.func,
    }).isRequired,
  ).isRequired,
  showDrawer: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filters: PropTypes.object,
  onRow: PropTypes.func,
  customMenuItems: PropTypes.func,
};

export default React.memo(TableWithPagination);
