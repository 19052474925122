import { FORM_INPUT_TYPES } from '../../../../../utils/constants';

export const resetPasswordFields = [
  {
    type: FORM_INPUT_TYPES.NEW_PASSWORD,
    label: 'New Password',
    placeholder: 'Enter',
    isRequired: true,
  },
  {
    type: FORM_INPUT_TYPES.CONFIRM_PASSWORD,
    label: 'Confirm Password',
    placeholder: 'Enter',
    isRequired: true,
  },
];
