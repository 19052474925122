import { FORMS } from 'utils/constants';

class RevShare {
  static instance = new RevShare();

  RevShareType = {
    LOT: 'lot',
    SPREAD: 'spread',
    FEE: 'fee',
  };

  REV_SHARE_CONFIG = {
    [this.RevShareType.LOT]: {
      name: this.RevShareType.LOT,
      label: FORMS.LOT_REVSHARE,
    },
    [this.RevShareType.SPREAD]: {
      name: this.RevShareType.SPREAD,
      label: FORMS.SPREAD_REVSHARE,
    },
    [this.RevShareType.FEE]: {
      name: this.RevShareType.FEE,
      label: FORMS.FEE_REVSHARE,
    },
  };

  _build(...revShareTypes) {
    return revShareTypes.map(
      (revShareType) => this.REV_SHARE_CONFIG[revShareType],
    );
  }

  getFmRevSharesRows() {
    return this._build(...Object.values(this.RevShareType));
  }

  getGpRevSharesRows() {
    return this._build(...Object.values(this.RevShareType));
  }

  getDay1RevSharesRows() {
    return this._build(this.RevShareType.FEE);
  }
}

export const RevShareService = RevShare.instance;
