export const CHART_COLORS = [
  '#008FFB',
  '#00E396',
  '#FEB019',
  '#FF4560',
  '#775DD0',
  '#3F51B5',
  '#03A9F4',
  '#4CAF50',
  '#F9CE1D',
  '#FF9800',
  '#33B2DF',
  '#546E7A',
  '#D4526E',
  '#13D8AA',
  '#A5978B',
  '#4ECDC4',
  '#C7F464',
  '#81D4FA',
  '#546E7A',
  '#FD6A6A',
  '#2B908F',
  '#F9A3A4',
  '#90EE7E',
  '#FA4443',
  '#69D2E7',
  '#449DD1',
  '#F86624',
  '#EA3546',
  '#662E9B',
  '#C5D86D',
  '#D7263D',
  '#1B998B',
  '#2E294E',
  '#F46036',
  '#E2C044',
  '#662E9B',
  '#F86624',
  '#F9C80E',
  '#EA3546',
  '#43BCCD',
  '#5C4742',
  '#A5978B',
  '#8D5B4C',
  '#5A2A27',
  '#C4BBAF',
  '#A300D6',
  '#7D02EB',
  '#5653FE',
  '#2983FF',
  '#00B1F2',
  '#567fb1',
  '#304b92',
  '#6d0201',
  '#719ecf',
  '#e72dc4',
  '#a17bea',
  '#1e9098',
  '#72009f',
  '#9e99db',
  '#98e054',
  '#b375ce',
  '#b14a0a',
  '#933c98',
  '#bd004a',
  '#8e6692',
  '#cfbe4b',
  '#6f76f4',
  '#773c87',
  '#699166',
  '#322bc4',
  '#f4d19e',
  '#10fd2f',
  '#914718',
  '#d66875',
  '#babaa1',
  '#16ce5c',
  '#185efd',
  '#2bc526',
  '#f82548',
  '#cdf81f',
  '#f2b946',
  '#e643c8',
  '#dd3dc1',
  '#abea7c',
  '#71d093',
  '#ded647',
  '#8f1831',
  '#9c6d42',
  '#a080e8',
  '#aae880',
  '#48134c',
  '#8217f0',
  '#66bad2',
  '#d29377',
  '#d170f7',
  '#66a066',
  '#277d1a',
  '#5c9083',
  '#f0bd8a',
  '#244ad8',
  '#1ec1d7',
  '#8894f6',
  '#a5393f',
  '#e405b8',
  '#d5d6c8',
  '#5252a0',
  '#f02467',
  '#60fa95',
  '#df03ad',
  '#90b331',
  '#d9ee5e',
  '#5f7e49',
  '#238a38',
  '#437e36',
  '#91659b',
  '#e2b4fb',
  '#99288f',
  '#d28eba',
  '#c42bfc',
  '#f4b5e6',
  '#706992',
  '#aff131',
  '#c383ef',
  '#961b29',
  '#b695fd',
  '#69247e',
  '#9756b1',
  '#fc2a45',
  '#8dd8a7',
  '#195a9a',
  '#777297',
  '#5ce00c',
  '#2124c5',
  '#81ae9a',
  '#c53c2d',
  '#a49a1a',
  '#ed1eb7',
  '#c21a1c',
  '#b9e550',
  '#a60419',
  '#6b2b5c',
  '#f0344b',
  '#cd57a7',
  '#1b11c5',
  '#5b2444',
  '#a5bbb7',
  '#3af81a',
  '#6b0f1b',
  '#c51db6',
  '#8fd42a',
  '#51c6b7',
  '#e4d95b',
  '#194089',
  '#594fd4',
  '#8ae9cf',
  '#9ddd4b',
  '#17eebf',
  '#e361c5',
  '#b269e7',
  '#e50b1c',
  '#d4eae8',
  '#d52b36',
  '#101752',
  '#f520e5',
  '#c21c8d',
  '#d430ea',
  '#e7be88',
  '#bad1a2',
  '#8d1695',
  '#4b3fa5',
  '#38369a',
  '#496864',
  '#54dfc5',
  '#20a1d8',
  '#1552be',
  '#145394',
  '#54f828',
  '#c21ed8',
  '#5979dd',
  '#473c9f',
  '#654ded',
  '#f5d1c6',
  '#6f2ed5',
  '#d8b96d',
  '#3894a8',
  '#a423b9',
  '#18d696',
  '#4b2199',
  '#f3e3f6',
  '#d73f4a',
  '#d3ab1f',
  '#a4916e',
  '#406d28',
  '#6b259a',
  '#982d2a',
  '#313ef9',
  '#1ca5fd',
  '#f47cae',
  '#3f9a17',
  '#2120ab',
  '#7e7baa',
  '#8d461d',
  '#327cd1',
  '#bd2bc7',
  '#3278e3',
  '#da445f',
  '#8913f4',
  '#771a33',
  '#413e57',
  '#6909de',
  '#27b897',
  '#9e036d',
  '#839b5b',
];
