import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Form } from 'antd';
import PropTypes from 'prop-types';

import Input from 'components/Common/Input';
import { getInputRules } from 'utils/inputValidatiors';

import styles from './styles.module.less';

const FormInput = ({
  type,
  placeholder,
  errorText,
  className,
  inputClassName,
  extraLabel,
  value,
  children,
  rules = [],
  max,
  tooltip,
  autoComplete = 'off',
  customRulesOnly = false,
  name = undefined,
  isRequired = true,
  label = '',
  ...other
}) => {
  const formRules = useMemo(() => {
    if (customRulesOnly) {
      return rules;
    }

    return isRequired
      ? getInputRules(errorText || label, type, max).concat(rules)
      : [];
  }, [type, errorText, label, isRequired, rules, max, customRulesOnly]);

  return (
    <div className={clsx(styles.container, className)}>
      <Form.Item
        label={label}
        name={name || type}
        rules={formRules}
        colon={false}
        initialValue={value}
        validateTrigger={['onSubmit', 'onBlur']}
        className={styles.formItem}
        tooltip={tooltip}
      >
        {children || (
          <Input
            type={type}
            placeholder={placeholder || label}
            className={inputClassName}
            autoComplete={autoComplete}
            {...other}
          />
        )}
      </Form.Item>
      {extraLabel}
    </div>
  );
};

export const FormInputPropTypes = {
  type: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  extraLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.string,
  errorText: PropTypes.string,
  autoComplete: PropTypes.string,
  tooltip: PropTypes.string,
  children: PropTypes.node,
  rules: PropTypes.array,
  other: PropTypes.any,
  customRulesOnly: PropTypes.bool,
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

FormInput.propTypes = FormInputPropTypes;

export default FormInput;
