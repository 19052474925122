import { createReducer } from 'typesafe-actions';
import produce from 'immer';

import Actions from '../actions';

const initialState = {
  topClients: {
    table: {
      dataSource: [],
      total: 0,
      isLoading: true,
    },
  },
  allClients: {
    table: {
      dataSource: [],
      total: 0,
      isLoading: true,
    },
  },
  latestClients: {
    table: {
      dataSource: [],
      total: 0,
      isLoading: true,
    },
  },
  filterLimits: null,
  partnerClientFilterLimits: null,
};

export default createReducer(initialState)
  .handleAction(
    Actions.loadTopClients.request,
    produce((state) => {
      state.topClients.table.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadTopClients.success,
    produce((state, { payload }) => {
      state.topClients.table.isLoading = false;
      state.topClients.table.dataSource = payload.rows;
      state.topClients.table.total = payload.count;
    }),
  )
  .handleAction(
    Actions.loadTopClients.failure,
    produce((state) => {
      state.topClients.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadLatestClients.request,
    produce((state) => {
      state.latestClients.table.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadLatestClients.success,
    produce((state, { payload }) => {
      state.latestClients.table.isLoading = false;
      state.latestClients.table.dataSource = payload.rows;
      state.latestClients.table.total = payload.count;
    }),
  )
  .handleAction(
    Actions.loadLatestClients.failure,
    produce((state) => {
      state.latestClients.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadAllClients.request,
    produce((state) => {
      state.allClients.table.isLoading = true;
    }),
  )
  .handleAction(
    Actions.loadAllClients.success,
    produce((state, { payload }) => {
      state.allClients.table.isLoading = false;
      state.allClients.table.dataSource = payload.rows;
      state.allClients.table.total = payload.count;
    }),
  )
  .handleAction(
    Actions.loadAllClients.failure,
    produce((state) => {
      state.allClients.table.isLoading = false;
    }),
  )
  .handleAction(
    Actions.loadClientsLimits.success,
    produce((state, { payload }) => {
      state.filterLimits = payload;
    }),
  )
  .handleAction(
    Actions.loadPartnerClientsLimits.success,
    produce((state, { payload }) => {
      state.partnerClientFilterLimits = payload;
    }),
  );
