import React from 'react';
import PropTypes from 'prop-types';
import StyledButton from 'components/Common/Buttons/StyledButton';
import { BUTTONS_TEXT } from 'utils/constants';
import styles from './styles.module.less';

const FooterButtons = ({
  loading,
  onDefault,
  onCancel,
  onFinish,
  saveButtonText,
  className,
}) => (
  <>
    {onDefault && (
      <StyledButton
        text={BUTTONS_TEXT.DEFAULT}
        onClick={onDefault}
        className={styles.defaultButton}
      />
    )}
    {onCancel && (
      <StyledButton
        text={BUTTONS_TEXT.CANCEL}
        onClick={onCancel}
        className={styles.cancelButton}
      />
    )}
    {onFinish && (
      <StyledButton
        text={saveButtonText}
        type="primary"
        onClick={onFinish}
        className={className}
        loading={loading}
      />
    )}
  </>
);

FooterButtons.defaultProps = {
  saveButtonText: BUTTONS_TEXT.SAVE,
  loading: false,
  className: styles.saveButton,
};

FooterButtons.propTypes = {
  loading: PropTypes.bool,
  onDefault: PropTypes.func,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  saveButtonText: PropTypes.string,
  className: PropTypes.string,
};

export default FooterButtons;
